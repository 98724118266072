import { Button, Col, Row, Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/container";
import { useGetCashOutHourlyWorkerList } from "../../zustand/store";
import SingleCashOut from "./singleCashOut";

function CashOutHourlyWorkerPage() {
  const navigate = useNavigate();
  const getCashOutList = useGetCashOutHourlyWorkerList();

  const positions = [
    ...new Set(getCashOutList?.data?.map(({ position }) => position)),
  ];

  useEffect(() => {
    getCashOutList.execute();
  }, []);

  return (
    <PageContainer
      title={
        <>
          <Button onClick={() => navigate("/cash-out")}>Go back</Button> Cash
          Out List
        </>
      }
    >
      <Spin spinning={getCashOutList.loading}>
        <Tabs
          items={positions.map((p, i) => ({
            label: p,
            key: p.toLowerCase(),
            children: (
              <Row key={i} gutter={[10, 10]}>
                {getCashOutList?.data
                  ?.filter(({ position }) => position === p)
                  ?.map((data,i) => {
                    return (
                      <Col span={24} key={i}>
                        <SingleCashOut data={data} />
                      </Col>
                    );
                    // return <Card></Card>;
                  })}
              </Row>
            ),
          }))}
        />
      </Spin>
    </PageContainer>
  );
}

export default CashOutHourlyWorkerPage;
