import React from "react";

import { Button, Card, Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import { PageContainer } from "../../../components/container";
import {
  NEXT_HISTORY,
  NEXT_ORDER,
  PREV_HISTORY,
  PREV_ORDER,
  PRINT,
  REFRESH_PAGE,
  SERVE,
  ZOOM,
  buttonSign
} from "../../../utils/kbd";
import {
  useEditBakerKbd,
  useGetMe,
  useRequestBakerCode
} from "../../../zustand/store";
import {
  ContentPart,
  KbdButton,
  KeyboardWrapper,
  SidePart,
  Wrapper,
} from "./styles";

const horizontalKbd = {
  1: PREV_ORDER,
  2: NEXT_ORDER,
  3: ZOOM,
  4: PREV_HISTORY,
  5: SERVE,
  6: REFRESH_PAGE,
  // 7: REFRESH_ORDERS,
  7: PRINT,
  8: NEXT_HISTORY,
};

const verticalKbd = {
  4: PREV_ORDER,
  8: NEXT_ORDER,
  3: ZOOM,
  7: SERVE,
  // 2: REFRESH_ORDERS,
  2: PRINT,
  6: PREV_HISTORY,
  1: REFRESH_PAGE,
  5: NEXT_HISTORY,
};

function Timer({ bakerSetting, remained, setRemained }) {
  const expiresAt = bakerSetting?.expiresAt;
  useEffect(() => {
    setTimeout(() => {
      const currentTime = Date.now();
      setRemained((expiresAt - currentTime) / 1000);
    }, 1000);
  }, [expiresAt, remained]);

  return (
    <div>
      {Math.floor(remained / 60)}:
      {("0" + String(Math.floor(remained % 60))).slice(-2)}
    </div>
  );
}

function CodeGeneration() {
  const me = useGetMe();
  const [remained, setRemained] = useState(null);
  const [bakerSetting, setBakerSetting] = useState(
    me.data?.restaurant?.bakerSetting
  );
  const expiresAt = bakerSetting?.expiresAt;

  const requestCode = useRequestBakerCode();
  //   const getTelephony = useGetTelephony();

  useEffect(() => {
    setBakerSetting(requestCode.data || me.data?.restaurant?.bakerSetting);
    setTimeout(() => {
      const currentTime = Date.now();
      setRemained((expiresAt - currentTime) / 1000);
    }, 1000);
    // getTelephony.execute();
  }, []);
  return (
    <>
      {bakerSetting && remained > 0 && remained !== null ? (
        <div>
          <Typography.Text bold copyable>
            {bakerSetting?.code}
          </Typography.Text>
          <Timer
            bakerSetting={bakerSetting}
            remained={remained}
            setRemained={setRemained}
          />
        </div>
      ) : (
        <>
          <Button
            onClick={() => {
              requestCode.execute({
                onSuccess: (res) => {
                  setBakerSetting(
                    res.data || me.data?.restaurant?.bakerSetting
                  );

                  const currentTime = Date.now();
                  const expiresAt = res.data?.expiresAt;
                  setRemained((expiresAt - currentTime) / 1000);
                },
              });
            }}
          >
            Request code
          </Button>
        </>
      )}
    </>
  );
}

function BakerSetting() {
  const me = useGetMe();
  const editBakerKbd = useEditBakerKbd();
  const kbd = me.data?.restaurant?.bakerSetting?.kbd;
  return (
    <PageContainer title={"Baker Setting"}>
      {/* <Card size="small"> */}
      <Wrapper>
        <ContentPart>
          <Row gutter={[10, 10]}>
            <Col>
              <Card size="small" style={{ width: "fit-content" }}>
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    editBakerKbd.execute({
                      data: {
                        layout: "horizontal",
                        kbd: horizontalKbd,
                      },
                    });
                  }}
                  {...(JSON.stringify(kbd) === JSON.stringify(horizontalKbd)
                    ? { type: "primary", disabled: true }
                    : { loading: editBakerKbd.loading })}
                >
                  Use
                </Button>
                <KeyboardWrapper>
                  {[1, 2, 3, 4, 5, 6, 7, 8]?.map((value) => (
                    <KbdButton size="small">
                      {buttonSign?.[horizontalKbd?.[value]]}
                    </KbdButton>
                  ))}
                </KeyboardWrapper>
              </Card>
            </Col>
            <Col>
              <Card size="small">
                <Button
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    editBakerKbd.execute({
                      data: {
                        layout: "vertical",
                        kbd: verticalKbd,
                      },
                    });
                  }}
                  {...(JSON.stringify(kbd) === JSON.stringify(verticalKbd)
                    ? { type: "primary", disabled: true }
                    : { loading: editBakerKbd.loading })}
                >
                  Use
                </Button>
                <KeyboardWrapper vertical>
                  {[4, 8, 3, 7, 2, 6, 1, 5]?.map((value) => (
                    <KbdButton size="small">
                      {buttonSign?.[verticalKbd?.[value]]}
                    </KbdButton>
                  ))}
                </KeyboardWrapper>
              </Card>
            </Col>
          </Row>
        </ContentPart>
        <SidePart>
          <Card size="small">
            <div>
              <div>1) Click the button below to get the code.</div>
              <div>
                2) Go to the website{" "}
                <a
                  href="https://hisob.org/login"
                  target="_blank"
                  rel="noreferrer"
                >
                  hisob.org/login.
                </a>
              </div>
              <div>
                3) Enter the code you received from clicking the button.
              </div>
            </div>
            <br />
            <CodeGeneration />
          </Card>
        </SidePart>
      </Wrapper>
      {/* </Card> */}
    </PageContainer>
  );
}

export default BakerSetting;
