import React, { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import { useGetUnPaidHourlyWorker } from "../../../zustand/store";
import { Button, Card, Col, Empty, Row, Spin, Table, Tabs } from "antd";
import moment from "moment";
import { thousandSeparator } from "../../../utils/numberManager";
import CashOutModal from "./cashOutModal";
import { useNavigate } from "react-router-dom";

function HourlyWorkers() {
  const navigate = useNavigate();
  const hourlyWorkers = useGetUnPaidHourlyWorker();
  const [chosenHourlyWorker, setChosenHourlyWorker] = useState(null);

  useEffect(() => {
    hourlyWorkers.execute();
  }, []);
  return (
    <Wrapper>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Spin spinning={hourlyWorkers.loading}>
            <Card size="small">
              {hourlyWorkers?.data?.length ? (
                <Tabs
                  items={hourlyWorkers?.data?.map(({ position, list }) => ({
                    key: position,
                    label: position,
                    children: (
                      <>
                        <Table
                          pagination={false}
                          loading={hourlyWorkers.loading}
                          size="small"
                          columns={[
                            {
                              title: "FullName",
                              dataIndex: "fullname",
                              key: "fullname",
                            },
                            {
                              title: "HourlyWage",
                              dataIndex: "hourlyWage",
                              key: "hourlyWage",
                            },
                            {
                              title: "Total Hours",
                              dataIndex: "totalHours",
                              key: "totalHours",
                            },
                            {
                              title: "Wage",
                              dataIndex: "wage",
                              key: "wage",
                            },
                            {
                              title: "Action",
                              dataIndex: "action",
                              key: "action",
                            },
                          ]}
                          dataSource={list?.map(({ user, activeness }) => {
                            const totalHours = (
                              activeness?.reduce(
                                (t, { startedAt, endedAt }) =>
                                  t + moment(endedAt).diff(startedAt),
                                0
                              ) / 3_600_000
                            ).toFixed(2);
                            const hourlyWage =
                              user?.hourlyRate?.[position] || 0;
                            const wage = (totalHours * hourlyWage).toFixed(2);
                            return {
                              fullname: user?.fullname,
                              hourlyWage: `$${hourlyWage}`,
                              totalHours,
                              wage: `$${thousandSeparator(wage)}`,
                              action: (
                                <>
                                  <Button
                                    type="primary"
                                    onClick={() => {
                                      setChosenHourlyWorker({
                                        position,
                                        user,
                                        activeness,
                                      });
                                    }}
                                  >
                                    CashOut
                                  </Button>
                                </>
                              ),
                            };
                          })}
                        />
                        <CashOutModal
                          open={Boolean(chosenHourlyWorker)}
                          data={{
                            position: chosenHourlyWorker?.position,
                            data: hourlyWorkers?.data
                              ?.find(
                                ({ position }) =>
                                  position === chosenHourlyWorker?.position
                              )
                              ?.list?.find(
                                ({ user }) =>
                                  user?.id === chosenHourlyWorker?.user?.id
                              ),
                          }}
                          onCancel={() => setChosenHourlyWorker(null)}
                        />
                      </>
                    ),
                  }))}
                />
              ) : (
                <Table
                  pagination={false}
                  loading={hourlyWorkers.loading}
                  size="small"
                  columns={[
                    {
                      title: "FullName",
                      dataIndex: "fullname",
                      key: "fullname",
                    },
                    {
                      title: "HourlyWage",
                      dataIndex: "hourlyWage",
                      key: "hourlyWage",
                    },
                    {
                      title: "Total Hours",
                      dataIndex: "totalHours",
                      key: "totalHours",
                    },
                    {
                      title: "Wage",
                      dataIndex: "wage",
                      key: "wage",
                    },
                    {
                      title: "Action",
                      dataIndex: "action",
                      key: "action",
                    },
                  ]}
                  dataSource={[]}
                />
              )}
            </Card>
          </Spin>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            block
            onClick={() => navigate("/cash-out/hourly-workers")}
          >
            History
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
}

export default HourlyWorkers;
