import { ConfigProvider, message } from "antd";
import "antd/dist/reset.css";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/ru";
import "dayjs/locale/uz";
import "moment/locale/en-gb";
import "moment/locale/ru";
import "moment/locale/uz";
import moment from "moment/moment";
import { BrowserRouter } from "react-router-dom";
import "swiper/css";
import MainPage from "./pages/mainPage";
import en from "antd/lib/locale/en_GB";
import ru from "antd/lib/locale/ru_RU";
import "leaflet/dist/leaflet.css";
import "./App.css";
import { useLangStore } from "./zustand/store";

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime);


var updateLocale = require("dayjs/plugin/updateLocale");

dayjs.extend(updateLocale);

const ln = {
  ru,
  en,
};

function App() {
  const { lang } = useLangStore();
  dayjs.updateLocale(lang.toLowerCase(), { weekStart: 1 });
  dayjs.locale(lang?.toLowerCase());
  moment.locale(lang.toLowerCase());
  const [messageApi, contextHolder] = message.useMessage();

  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#6045e2",
          // borderRadius: 2,

          // Alias Token
          // colorBgContainer: "#f6ffed",
        },
      }}
      locale={ln[lang.toLowerCase()] || ru}
    >
      <BrowserRouter>
        {contextHolder}
        <MainPage />
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
