import React from "react";
import { Logo, SidebarBtn, UserBox, Wrapper } from "./styles";

import { MenuOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Row, Tag, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  logout,
  useGetMe,
  useGetUsersRestaurants,
  useSidebar,
} from "../../zustand/store";
import { LangText } from "../langManagement";
import { botUsername } from "../../constants/general";
import { formatPhoneNumberIntl } from "react-phone-number-input";
const { Text } = Typography;

function Navbar() {
  const me = useGetMe();
  const data = me.data;
  const restaurant = data?.restaurant;
  const user = data?.user;
  // const restaurants = useGetUsersRestaurants();
  const navigate = useNavigate();
  const sidebar = useSidebar();
  // const { changeLang, lang } = useLangStore();
  const po = restaurant?.project_owner;
  const roles = user?.inStore?.roles?.roles;

  const shareLink = `https://t.me/${botUsername}?start=${user?.id}`

  return (
    <Wrapper>
      {data ? (
        <SidebarBtn>
          <Button onClick={() => sidebar.toggle()}>
            <MenuOutlined />
          </Button>
        </SidebarBtn>
      ) : (
        <></>
      )}

      <Logo>
        <Typography.Title
          level={3}
          className="font-futura"
          style={{ margin: 0 }}
          onClick={() => navigate("/")}
        >
          <span>{restaurant?.name || "Hisob"}</span>
        </Typography.Title>
      </Logo>
      <Row gutter={10}>
        <Col>
          {me.data ? (
            <Dropdown
              menu={{
                items: [
                  {
                    label: (
                      <div style={{ textAlign: "center" }}>
                        <div>
                          <Typography.Title style={{ margin: 0 }} level={5} strong underline>
                            {user?.fullname
                              || user?.inStore?.fullname
                              || "NO_NAME"}
                          </Typography.Title>
                        </div>
                        <div>
                          <Typography.Text
                          // level={5} 
                          // strong 
                          // underline
                          >
                            {formatPhoneNumberIntl(user?.phone_number)
                              || "NO_NUMBER"}
                          </Typography.Text>
                        </div>
                        <div>
                          {roles?.map((r, i) => (
                            <Tag
                              key={i}
                              style={
                                roles.length === i + 1 ? { margin: 0 } : {}
                              }
                            >
                              {r}
                            </Tag>
                          ))}
                        </div>
                      </div>
                    ),
                    onClick: () => navigate("/me"),
                  },
                  ...(
                    // restaurants.data
                    data?.list
                      ?.filter(({ id }) => (id !== restaurant?.id))
                      ?.map(({ name, id }) => ({
                        label: <Card size="small">{name}</Card>,
                        onClick: () => {
                          // localStorage.setItem("hisob_token", token);
                          localStorage.setItem("restaurantId", id);
                          window.location.reload(false);
                        },
                      })) || []),
                  ...(po ? [{
                    label: (
                      <div style={{ textAlign: "center" }}>
                        <Button block level={5} strong underline>
                          Add Restaurant
                        </Button>
                      </div>
                    ),
                    onClick: () => navigate("/register-restaurant"),
                  }] : []),
                  // ...(!user?.chatID ? [{
                  //   label: (
                  //     <div style={{ textAlign: "center" }}>
                  //       <Button block level={5} strong underline

                  //       >
                  //         Connect Telegram
                  //       </Button>
                  //     </div>
                  //   ),
                  //   onClick: () => {
                  //     window.open(shareLink)
                  //   },
                  // }] : []),
                  {
                    label: (
                      <Button
                        block
                        type="primary"
                        style={{ textAlign: "center" }}
                      >
                        <LangText id="Log Out" />
                      </Button>
                    ),
                    onClick: logout,
                  },
                ],
              }}
            >
              <UserBox>
                <Button>
                  <Text>{user?.fullname || "NO_NAME"}</Text>
                </Button>
              </UserBox>
            </Dropdown>
          ) : (
            <Button
              size="large"
              onClick={() => navigate("/login")}
              type="primary"
            >
              LogIn
            </Button>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
}

export default Navbar;
