import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row
} from "antd";
import React from "react";
import { LangText, useLangText } from "../../../../components/langManagement";
import {
  useGetStaffList,
  usePaySalary
} from "../../../../zustand/store";
const { TextArea } = Input;
function PaySalary({ open, staffId, onClose }) {
  const [form] = Form.useForm();
  const paySalary = usePaySalary();
  const staffList = useGetStaffList();
  const text = useLangText();

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={
        <>
          <LangText id="paySalaryOf" />{" "}
          {staffList?.data?.find(({ id }) => id === staffId)?.fullname}
        </>
      }
    >
      <Form
        form={form}
        onFinish={(e) => {
          const data = { ...e, staffId };
          paySalary.execute({
            data,
            onSuccess: () => {
              form.resetFields();
              onClose();
            },
          });
        }}
        layout="vertical"
        hideRequiredMark
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="amount"
              label={<LangText id="price" />}
              rules={[{ required: true, message: text({ id: "enterPrice" }) }]}
            >
              <InputNumber
                min={0}
                style={{ width: "100%" }}
                placeholder={text({ id: "enterPrice" })}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => value.replace(/\$\s?|( *)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={<LangText id="description" />}>
              <TextArea
                rows={5}
                style={{ width: "100%" }}
                placeholder={text({ id: "enterDescription" })}
              />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item name="addedAt" label={<LangText id="addedAt" />}>
              <DatePicker
                allowClear={false}
                defaultValue={dayjs()}
                placeholder={text({ id: "enterAddedAt" })}
              />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item label="">
              <div style={{ display: "flex", gap: "10px" }}>
                <Button type="primary" htmlType="submit">
                  <LangText id="add" />
                </Button>
                <Button onClick={onClose} type="primary" ghost>
                  <LangText id="cancel" />
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default PaySalary;
