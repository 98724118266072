import React, { useEffect, useState } from "react";
import {
  useAdvertisingAddress,
  useAdvertisingLogs,
  useGetAddresses,
  useGetMe,
} from "../../../zustand/store";
import { Badge, Button, Card, Col, List, Row } from "antd";
import Typography from "antd/es/typography/Typography";
import { CheckCircleFilled, CheckOutlined } from "@ant-design/icons";

const BadgeRibbon = ({ children, done }) =>
  done ? (
    <Badge.Ribbon color="blue" text={<CheckOutlined />}>
      {children}
    </Badge.Ribbon>
  ) : (
    children
  );

function CurrentLocation() {
  const [currentAddress, setCurrentAddress] = useState();
  const address = useGetAddresses();
  const advertisingAddress = useAdvertisingAddress();
  const me = useGetMe();
  const advertisingLogs = useAdvertisingLogs();

  const toggleFloor = (floor) => {
    advertisingAddress.execute({
      data: { state: "TOGGLE_FLOOR", floorNumber: floor },
    });
  };

  const finishAdvertising = () => {
    advertisingAddress.execute({
      data: { state: "FINISH" },
      onSuccess: () => {
        advertisingLogs.execute({ force: true });
      },
    });
  };

  useEffect(() => {
    const addressId = me?.data?.user?.inStore?.advertisingData?.addressId;
    setCurrentAddress(address.data?.find(({ id }) => id === addressId));
  }, [address.data]);
  return (
    <Card bordered={false} size="small">
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <List bordered>
            <List.Item style={{ justifyContent: "center" }}>
              <div>📍 {currentAddress?.name}</div>
            </List.Item>
          </List>
        </Col>
        {/* <Col span={24}>
          Floors
          <Row gutter={[10, 10]}>
            {Array(20)
              .fill("")
              .map((v, i) =>
                i + 1 !== 13 ? (
                  <Col>
                    <BadgeRibbon
                      done={me?.data?.user?.advertisingData?.floors?.find(
                        ({ floorNumber }) => floorNumber === i + 1
                      )}
                    >
                      <Card
                        onClick={() => {
                          toggleFloor(i + 1);
                        }}
                      >
                        <Typography.Text>{i + 1}</Typography.Text>
                      </Card>
                    </BadgeRibbon>
                  </Col>
                ) : (
                  <></>
                )
              )}
          </Row>
        </Col> */}
        <Button block size="large" type="primary" onClick={finishAdvertising}>
          Finish
        </Button>
      </Row>
    </Card>
  );
}

export default CurrentLocation;
