const NODE_ENV = 
// "production" || 
process.env?.NODE_ENV;

const URL = {
  development: "http://localhost:7646",
  production: "https://api.hisob.org",
};

const PRINTER_URL = {
  development: "http://localhost:9898",
  production: "https://printer.hisob.org",
};

const WS_URL = {
  development: "ws://localhost:7647",
  // production: "ws://5.161.204.87:7647",
  production: "wss://ws.hisob.org",
  // production: "wss://beon-ws.kaktus365.uz",
  // production: "wss://beon-app.com",
  // production: "wss://ws.hisob.org",
};

export const WS_BASE_URL = WS_URL[NODE_ENV] || WS_URL.production;

export const BASE_URL = URL[NODE_ENV] || URL.production;

export const PRINTER_BASE_URL = PRINTER_URL[NODE_ENV] || PRINTER_URL.production;
