import React, { useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import {
  logout,
  useGetMe,
  useGetUsersRestaurants,
  useSidebar,
} from "../../zustand/store";
import DashboardPage from "../dashboardPage";
import OrdersPage from "../ordersPage";
import WarehousePage from "../warehousePage";

import { Alert, Button, Card, Spin, Typography, message } from "antd";
import { LangText } from "../../components/langManagement";
import { PrivateComp } from "../../components/roleBasedAccesses";
import {
  ADVERTISER,
  DRIVER,
  ORDER_TAKER,
  PIZZA_MAKER,
} from "../../constants/roles";
import AddressPage from "../addressPage";
import AdvertisersPage from "../advertisersPage";
import AreaPage from "../areaPage";
import BakerPage from "../bakerPage";
import BakerPage72 from "../bakerPage72";
import Calculator from "../calculator";
import CashOutAppPage from "../cashOutAppPage";
import CashOutDriverPage from "../cashOutDriverPage";
import CashOutHourlyWorkerPage from "../cashOutHourlyWorkerPage";
import CashOutPage from "../cashOutPage";
import CreateOrderPage from "../createOrderPage";
import AgreementPage from "../docs/agreementPage";
import DriversPage from "../driversPage";
import QRScanner from "../driversPage/activeDriver/QRScanner";
import FinancePage from "../financePage";
import LandingPage from "../landingPage";
import LoginPage from "../loginPage";
import AddOrderPage from "../makeOrderPage";
import MePage from "../mePage";
import PrinterPage from "../printerPage";
import RegisterRestaurant from "../registerRestaurantPage";
import RestaurantsPage from "../restaurantsPage";
import RolesPage from "../rolesPage";
import SettingPage from "../settingPage";
import SingleOrderOfDriver from "../singleOrderOfDriver";
import SingleRestaurantCashoutPage from "../singleRestaurantCashoutPage";
import SingleRestaurantPage from "../singleRestaurantPage";
import UsersPage from "../usersPage";
import {
  ContentPart,
  NavbarPart,
  PagePart,
  SidebarPart,
  Wrapper,
} from "./styles";
import JoinPage from "../joinPage";
const { Text } = Typography;


// https://hisob.org/policy
// https://hisob.org/terms
// https://hisob.org/support
// https://hisob.org/zoom-documantation
// https://hisob.org/configure


const DefaultPage = () => {
  const [navbarHeight, setNavbarHeight] = useState(0);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      const clientHeight = document.querySelector(".navbar-part")?.clientHeight;
      const clientWidth = document.querySelector(".sidebar-part")?.clientWidth;
      setNavbarHeight(clientHeight || 0);
      setSidebarWidth(clientWidth || 0);
    }, 1000);
  }, []);

  const token = localStorage.getItem("hisob_new_token");
  const sidebar = useSidebar();
  const { pathname } = useLocation();
  const navigate = useNavigate();


  const me = useGetMe();


  // useEffect(() => {
  //   if (pathname !== "/login") {
  //     if (token) {
  //       me.execute({
  //         onError: (e) => {
  //           navigate("/");
  //           message.error(
  //             <Text>
  //               {typeof e?.response?.data === "string" ? (
  //                 e?.response?.data
  //               ) : (
  //                 <>Something went wrong 🙁 </>
  //               )}
  //               <Button size="small" onClick={logout}>
  //                 <LangText id="logout" />
  //               </Button>
  //             </Text>
  //           );
  //         },
  //         onSuccess: (res) => {
  //           const data = res?.data;
  //           if (data?.device?.name === "BAKER") {
  //             if (pathname !== "/pizzaiolo72") {
  //               navigate("/pizzaiolo72");
  //             }
  //           }
  //           const restaurantId = data.restaurant?.id;
  //           if (restaurantId) {
  //             const rId = localStorage.getItem("restaurantId");
  //             if (restaurantId !== rId) {
  //               localStorage.setItem("restaurantId", restaurantId);
  //             }
  //           }
  //         },
  //       });

  //       // let token_list = localStorage.getItem("token_list");
  //       // let token = localStorage.getItem("hisob_new_token");
  //       // token_list = JSON.parse(token_list);
  //       // token_list = token_list?.filter((t) => t !== token);
  //       // if (token_list?.length) {
  //       //   restaurants.execute({ params: { token_list } });
  //       // }
  //     } else {
  //       navigate("/login");
  //     }
  //   }
  // }, []);



  return <>
    {token && me?.success ? (
      <>
        <NavbarPart className="navbar-part">
          <Navbar />
        </NavbarPart>
        <PagePart navbarHeight={navbarHeight}>
          <SidebarPart
            open={sidebar.status}
            className="sidebar-part"
          >
            <Sidebar />
          </SidebarPart>
          <ContentPart sidebarWidth={sidebarWidth}>
            <Outlet />
          </ContentPart>
        </PagePart>
      </>
    ) : !token || (me?.success && !me.data) ? (
      <>
        <LandingPage />
      </>
    ) : (
      <></>
    )}
  </>
}


function MainPage() {
  const restaurants = useGetUsersRestaurants();


  const { pathname } = useLocation();
  const navigate = useNavigate();
  const sidebar = useSidebar();

  // REQUESTS
  const me = useGetMe();
  const token = localStorage.getItem("hisob_new_token");
  useEffect(() => {
    if (pathname !== "/login") {
      if (token) {
        me.execute({
          onError: (e) => {
            navigate("/");
            message.error(
              <Text>
                {typeof e?.response?.data === "string" ? (
                  e?.response?.data
                ) : (
                  <>Something went wrong 🙁 </>
                )}
                <Button size="small" onClick={logout}>
                  <LangText id="logout" />
                </Button>
              </Text>
            );
          },
          onSuccess: (res) => {
            const data = res?.data;
            if (data?.device?.name === "BAKER") {
              if (pathname !== "/pizzaiolo72") {
                navigate("/pizzaiolo72");
              }
            }
            const restaurantId = data.restaurant?.id;
            if (restaurantId) {
              const rId = localStorage.getItem("restaurantId");
              if (restaurantId !== rId) {
                localStorage.setItem("restaurantId", restaurantId);
              }
            }
          },
        });

        // let token_list = localStorage.getItem("token_list");
        // let token = localStorage.getItem("hisob_new_token");
        // token_list = JSON.parse(token_list);
        // token_list = token_list?.filter((t) => t !== token);
        // if (token_list?.length) {
        //   restaurants.execute({ params: { token_list } });
        // }
      } else {
        navigate("/login");
      }
    }
  }, []);

  return (
    <Spin spinning={me.loading}>
      {me?.errorData?.response?.data?.status === "BLOCKED" ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card
            size="small"
            bordered={false}
          // style={{ width: "100%", height: "100%" }}
          >
            <Alert
              type="error"
              showIcon
              message={<>{me.errorData.response?.data?.status}</>}
              description={<>{me.errorData.response?.data?.msg}</>}
            />
          </Card>
        </div>
      ) : (
        <Wrapper>
          <Routes>
            <Route path="/join" element={<JoinPage />} />
            <Route path="/roles" element={<RolesPage />} />
            <Route path="/agreement" element={<AgreementPage />} />
            <Route path="/page" element={<LandingPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/register-restaurant"
              element={<RegisterRestaurant />}
            />
            <Route
              path="/drivers-page/scan"
              element={<PrivateComp p={DRIVER} />}
            >
              <Route path="" element={<QRScanner />} />
            </Route>
            <Route path="/pizzaiolo" element={<PrivateComp p={PIZZA_MAKER} />}>
              <Route path="" element={<BakerPage />} />
            </Route>
            <Route
              path="/pizzaiolo72"
              element={
                <PrivateComp
                  device={"BAKER"}
                //  p={PIZZA_MAKER}
                />
              }
            >
              <Route path="" element={<BakerPage72 />} />
            </Route>

            <Route
              path="/drivers-page/order/:orderId"
              element={<PrivateComp p={DRIVER} />}
            >
              <Route path="" element={<SingleOrderOfDriver />} />
            </Route>

            <Route path="/add-order" element={<PrivateComp p={ORDER_TAKER} />}>
              <Route path="" element={<AddOrderPage />} />
            </Route>
            <Route
              path="/"
              element={
                <DefaultPage />
                // token && getMe?.success ? (
                //   <>
                //     <NavbarPart className="navbar-part">
                //       <Navbar />
                //     </NavbarPart>
                //     <PagePart navbarHeight={navbarHeight}>
                //       <SidebarPart
                //         open={sidebar.status}
                //         className="sidebar-part"
                //       >
                //         <Sidebar />
                //       </SidebarPart>
                //       <ContentPart sidebarWidth={sidebarWidth}>
                //         <Outlet />
                //       </ContentPart>
                //     </PagePart>
                //   </>
                // ) : !token || (getMe?.success && !getMe.data) ? (
                //   <>
                //     <LandingPage />
                //   </>
                // ) : (
                //   <></>
                // )
              }
            >
              <Route path="/" element={<DashboardPage />} />

              <Route path="/orders" element={<PrivateComp />}>
                <Route path="" element={<OrdersPage />} />
              </Route>

              <Route path="/warehouse" element={<PrivateComp />}>
                <Route path="" element={<WarehousePage />} />
              </Route>
              <Route path="/users" element={<PrivateComp />}>
                <Route path="" element={<UsersPage />} />
              </Route>
              <Route path="/finance" element={<PrivateComp />}>
                <Route path="" element={<FinancePage />} />
              </Route>
              <Route path="/address" element={<PrivateComp />}>
                <Route path="" element={<AddressPage />} />
              </Route>
              <Route path="/address-area" element={<PrivateComp />}>
                <Route path="" element={<AreaPage />} />
              </Route>
              <Route path="/menu" element={<PrivateComp />}>
                <Route path="" element={<CreateOrderPage />} />
              </Route>
              <Route path="/cash-out" element={<PrivateComp />}>
                <Route path="" element={<CashOutPage />} />
              </Route>
              <Route
                path="/restaurants"
                element={<PrivateComp project_owner />}
              >
                <Route path="" element={<RestaurantsPage />} />
              </Route>
              <Route
                path="/restaurants/:id"
                element={<PrivateComp project_owner />}
              >
                <Route path="" element={<SingleRestaurantPage />} />
              </Route>
              <Route
                path="/restaurants/:id/cashout/:cashoutId"
                element={<PrivateComp project_owner />}
              >
                <Route path="" element={<SingleRestaurantCashoutPage />} />
              </Route>
              <Route path="/cash-out/drivers" element={<PrivateComp />}>
                <Route path="" element={<CashOutDriverPage />} />
              </Route>
              <Route path="/cash-out/app" element={<PrivateComp />}>
                <Route path="" element={<CashOutAppPage />} />
              </Route>
              <Route path="/cash-out/hourly-workers" element={<PrivateComp />}>
                <Route path="" element={<CashOutHourlyWorkerPage />} />
              </Route>
              <Route path="/printer" element={<PrivateComp />}>
                <Route path="" element={<PrinterPage />} />
              </Route>
              {/* <Route path="/telephony" element={<PrivateComp />}>
                <Route path="" element={<TelephonyPage />} />
              </Route> */}
              <Route
                path="/advertisers-page"
                element={<PrivateComp p={ADVERTISER} />}
              >
                <Route path="" element={<AdvertisersPage />} />
              </Route>
              <Route path="/drivers-page" element={<PrivateComp p={DRIVER} />}>
                <Route path="" element={<DriversPage />} />
              </Route>
              <Route path="/me" element={<MePage />} />
              <Route path="/calculator" element={<Calculator />} />
              <Route path="/setting" element={<SettingPage />} />
            </Route>
          </Routes>
        </Wrapper>
      )}
    </Spin>
  );
}

export default MainPage;
