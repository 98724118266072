import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const ContentPart = styled.div`
  width: calc(100% - (300px + 10px));
`;

export const SidePart = styled.div`
  width: 300px;
`;

export const KeyboardListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: flex-start;
`;

export const KeyboardWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  width: ${({ vertical }) => (vertical ? "100px" : "200px")};
  border: 1px solid black;
  div {
    width: calc((100%) / ${({ vertical }) => (vertical ? 2 : 4)});
    height: 50px;
  }
`;

export const KbdButton = styled.div`
  border: 1px solid black;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  line-height: 1;
`;
