import styled from "styled-components"

export const Wrapper = styled.div`
  min-height: 100vh;
  height: fit-content;
  padding: 30px 10px;
`;

export const FormWrapper = styled.div`
  max-width: 500px;
  margin: auto;
`;