import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { SelectOrEnter } from "../../../../components/form";
import { LangText, text } from "../../../../components/langManagement";
import size from "../../../../constants/size";
import {
  useAddOrderTemplate,
  useEditOrderTemplate,
  useGetMenuSubCategory,
  useGetOrderTemplate,
} from "../../../../zustand/store";
import NewOptionModal from "./newOptionModal";

const { useForm } = Form;

function CreateNew({
  // menuList,
  extraList,
  menu,
  onClose,
  open,
  edit,
  setEdit,
  data,
}) {
  const [form] = useForm();
  const [ingredients, setIngredients] = useState([]);
  const [flavors, setFlavors] = useState([]);
  const [newOption, setNewOption] = useState({
    open: false,
    defaultValues: null,
  });
  const [options, setOptions] = useState([]);
  const addOrderTemplate = useAddOrderTemplate();
  const editOrderTemplate = useEditOrderTemplate();
  const getOrderTemplate = useGetOrderTemplate();
  const subCategories = useGetMenuSubCategory();
  const menuList = subCategories.data;

  useEffect(() => {
    if (edit?.edit) {
      const d = edit.data;

      form.setFieldsValue({
        ...d,
        size: { label: size[d.size], value: d.size },
      });
      const extra = d.extra;

      extra?.forEach(({ id, amount }) => {
        form.setFieldValue(`extra_checkbox_${id}`, true);
        form.setFieldValue(`extra_required_amount_${id}`, amount);
      });
      setIngredients(Array.isArray(d?.ingredients) ? d?.ingredients : []);
      setFlavors(d?.flavors || []);
      setOptions(
        d?.options?.map((option) => ({
          ...option,
          foods: option?.foods?.map(({ id, extra }) => ({
            ...menuList?.find(({ id: mId }) => mId === id),
            extra,
          })),
        })) || []
      );
    }
  }, [edit?.edit, menuList]);

  const menuCategoryId = data?.id;
  const categoryName = data?.name;

  useEffect(() => {
    if (open) {
      subCategories.execute({
        // params: { menuCategoryId } 
      });
    }
  }, [open])


  return (
    <>
      <Drawer
        title={
          <>
            {menu.name} / {data?.name}
          </>
          // <LangText id="orderTemplate" />
        }
        width={"50%"}
        onClose={() => {
          form.resetFields();
          setIngredients([]);
          setOptions([]);
          onClose();
        }}
        open={open}
      // bodyStyle={{ paddingBottom: 80 }}
      >
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={(e) => {
            const extra = Object.entries(e).reduce((t, [key, value]) => {
              const reserved = "extra_checkbox_";
              if (key.startsWith(reserved) && value) {
                const id = key.slice(reserved?.length);
                const amount = e?.[`extra_required_amount_${id}`] || 0;
                return [...t, { id, amount }];
              }
              return t;
            }, []);
            const data = {
              ...e,
              ingredients,
              flavors,
              options: options.map((option) => ({
                ...option,
                foods: option?.foods?.map(({ id, extra }) => ({ id, extra })),
              })),
              menuCategoryId,
              extra,
            };

            (edit.edit ? editOrderTemplate : addOrderTemplate).execute({
              data,
              params: { id: edit?.data?.id },
              onSuccess: () => {
                getOrderTemplate.execute({ force: true });
                if (!(!edit.edit && categoryName === "Pizza")) {
                  form.resetFields();
                  setIngredients([]);
                  setOptions([]);
                  setFlavors([]);
                }
                if (edit?.edit) {
                  onClose();
                }
              },
            });
          }}
        >
          <Form.Item
            name="name"
            label={LangText({ id: "name" })}
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Row gutter={[15]}>
            <Col span={12}>
              <Form.Item
                name="price"
                label={LangText({ id: "price" })}
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{
                    width: "100%",
                  }}
                  addonBefore={"$"}
                  placeholder={text({ id: "enterPrice" })}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }
                  parser={(value) => value.replace(/\$\s?|( *)/g, "")}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="size"
                label={LangText({ id: "size" })}
              //   rules={[{ required: true }]}
              >
                <SelectOrEnter
                  options={[
                    { label: size["12_md"], value: "12_md" },
                    { label: size["14_lg"], value: "14_lg" },
                    { label: size["16_x-lg"], value: "16_x-lg" },
                    { label: size["sm"], value: "sm" },
                    { label: size["md"], value: "md" },
                    { label: size["lg"], value: "lg" },
                    { label: size["x-lg"], value: "x-lg" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="ingredient" label={LangText({ id: "ingredient" })}>
            <SelectOrEnter
              onChange={(e) => {
                if (e?.label?.trim()?.length) {
                  setIngredients((prev) => [
                    ...new Set([
                      ...prev,
                      ...e?.label?.split(",")?.map((v) => v?.trim()),
                    ]),
                  ]);
                }
              }}
            />
          </Form.Item>
          <div style={{ marginBottom: "15px" }}>
            {ingredients?.map((item, i) => (
              <Tag key={i}>
                <Row gutter={[10]}>
                  <Col>{item}</Col>
                  <CloseOutlined
                    onClick={(e) => {
                      setIngredients((prev) => prev?.filter((v, j) => i !== j));
                    }}
                  />
                </Row>
              </Tag>
            ))}
          </div>
          <Row
            gutter={[10, 10]}
          // style={{width:"100%"}}
          >
            <Col span={20}>
              <Form.Item name="flavor" label={LangText({ id: "flavor" })}>
                <SelectOrEnter
                  onChange={(e) => {
                    if (e?.label?.trim()?.length) {
                      setFlavors((prev) => [
                        ...new Set([
                          ...prev,
                          ...e?.label?.split(",")?.map((v) => v?.trim()),
                        ]),
                      ]);
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                name="flavorAmount"
                label={LangText({ id: "flavorAmount" })}
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          {flavors?.length ? (
            <div style={{ marginBottom: "15px" }}>
              {flavors?.map((item, i) => (
                <Tag key={i}>
                  <Row gutter={[10]}>
                    <Col>{item}</Col>
                    <CloseOutlined
                      onClick={(e) => {
                        setFlavors((prev) => prev?.filter((v, j) => i !== j));
                      }}
                    />
                  </Row>
                </Tag>
              ))}
            </div>
          ) : (
            <></>
          )}
          {extraList?.length ? (
            <Form.Item label="Extra">
              <Card size="small">
                <Row gutter={[10, 10]}>
                  {extraList?.map(({ id, name, price }) => (
                    <Col span={24}>
                      <Row gutter={[10, 10]}>
                        <Col>
                          <Form.Item
                            style={{ margin: 0 }}
                            // noStyle
                            valuePropName="checked"
                            name={`extra_checkbox_${id}`}
                          >
                            <Checkbox defaultChecked={false}>
                              {name} <b>${price}</b>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item
                            style={{ margin: 0 }}
                            name={`extra_required_amount_${id}`}
                          >
                            <InputNumber
                              style={{ width: "170px" }}
                              placeholder="Required free amount"
                              min={0}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </Row>
              </Card>
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item label="Options">
            <Button
              type="primary"
              ghost
              onClick={() => setNewOption({ open: true })}
            >
              New Option
            </Button>
          </Form.Item>
          <div>
            {options?.map(({ length, numberOfTopping, foods }, index) => (
              <>
                <Row gutter={[10, 10]}>
                  {foods.map(({ id, name, size: s }) => (
                    <Col key={id}>
                      <Card size="small">
                        {name} {s && <Tag>
                          {size[s]}
                        </Tag>}
                      </Card>
                    </Col>
                  ))}
                  <Col span={24}>
                    length: {length}
                    {numberOfTopping ? <>
                      <br />
                      numberOfTopping: {numberOfTopping}
                    </> : <></>}
                  </Col>
                  <Col span={24}>
                    <Row gutter={[10, 10]}>
                      <Col>
                        <Button
                          type="primary"
                          ghost
                          onClick={() => {
                            setNewOption({ open: true, editIndex: index });
                          }}
                        >
                          <EditOutlined />
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          danger
                          onClick={() => {
                            setOptions((prev) =>
                              prev?.filter((v, i) => index !== i)
                            );
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {index !== options.length - 1 ? <Divider /> : <br />}
              </>
            ))}
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {<LangText id={edit.edit ? "edit" : "save"} />}
            </Button>
          </Form.Item>
          {!edit.edit
            && data?.name === "Pizza"
            && <Form.Item>
              <Button
                // type="primary"
                //  htmlType="submit"
                onClick={() => {
                  form.resetFields();
                  setIngredients([]);
                  setOptions([]);
                  setFlavors([]);
                }}
              >
                {<LangText id={"clear"} />}
              </Button>
            </Form.Item>}
        </Form>
      </Drawer>
      <NewOptionModal
        onOk={(v) => {
          setOptions((prev) =>
            newOption?.editIndex || newOption?.editIndex === 0
              ? prev?.map((pv, i) => (i === newOption?.editIndex ? v : pv))
              : [...prev, v]
          );
          setNewOption({ open: false });
        }}
        editIndex={newOption?.editIndex}
        options={options}
        open={newOption?.open}
        onCancel={() => setNewOption({ open: false })}
        menu={menu}
        categories={menu?.categories || []}
      />
    </>
  );
}

export default CreateNew;