import {
  LeftHalfCircle,
  LeftHalfWrapper,
  RightHalfCircle,
  RightHalfWrapper,
} from "./styles";

export const LeftHalf = ({ reverse }) => {
  return (
    <LeftHalfWrapper reverse={reverse}>
      <LeftHalfCircle reverse={reverse} />
    </LeftHalfWrapper>
  );
};

export const RightHalf = ({ reverse }) => {
  return (
    <RightHalfWrapper reverse={reverse}>
      <RightHalfCircle reverse={reverse} />
    </RightHalfWrapper>
  );
};

// export const Logo = () => {
//   return (
    
//   );
// };
