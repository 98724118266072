function degToRad(degrees) {
    return degrees * (Math.PI / 180);
}

export function calcDist(lat1, lon1, lat2, lon2) {
    // const radius = 6_371; // km
    const radius = 3_958.8; // mi
    const phi1 = degToRad(lat1);
    const phi2 = degToRad(lat2);
    const deltaPhi = degToRad(lat2 - lat1);
    const deltaLambda = degToRad(lon2 - lon1);

    const a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
              Math.cos(phi1) * Math.cos(phi2) *
              Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = radius * c; 

    return distance; // km
}
