export const dict = {
  logout: "Logout",
  statistics: "Statistics",
  back:"back",

  // ORDERS PAGE
  orders: "Orders",
  plusOrder: "+ Order",
  addOrder: "Add Order",
  inProcess: "In Process",
  closed: "Closed",
  edit: "Edit",
  closeEdit: "Close Edit",
  client: "Client",
  fullname: "Full Name",
  phoneNumber: "Phone Number",
  address: "Address",
  update: "Update",
  cancel: "Cancel",
  pic: "Picture",
  name: "Name",
  price: "Price",
  status: "Status",
  created: "Created",
  started: "Started",
  finished: "Finished",
  installed: "Installed",
  done: "Done",

  description: "Description",
  deadline: "Deadline",
  daysLeft: "Days Left",
  daysPassed: "Days Passed",
  action: "Action",

  add: "Add",
  materials: "Materials",
  usedMaterial: "Used Material",
  amount: "Amount",
  addMaterial: "Add Material",
  currency: "Currency",
  waiting: "Waiting...",

  salary: "Salary",
  paySalary: "Pay Salary",
  finAccount: "Financial Account",

  pay: "Pay",

  closeOrder: "Close Order",
  next: "Next",

  addPrice: "Add Price",

  save: "Save",
  orderDetail: "Order Detail",

  // WAREHOUSE PAGE
  warehouse: "Warehouse",
  existInWarehouse: "Exist in Warehouse",
  willBeUsedForBelowOrders: "Will be used for below orders",
  existsAfterUsing: "Exists After Using",

  measurement: "Measurement",
  category: "Category",

  submit: "Submit",

  goods: "Goods",
  singleGoods: "Goods",
  addGoods: "Add Goods",

  staffList: "Staff List",
  staff: "Staff",
  addStaff: "Add Staff",
  plusStaff: "+ Staff",
  id: "ID",

  role: "Role",

  permissions: "Permissions",
  editPermissions: "Edit Permissions",
  list: "List",

  telegramChatId: "Telegram Chat ID",
  paySalaryOf: "Pay Salary of: ",
  salaryOf: "Salary of: ",
  order: "Order",
  date: "Date",

  finance: "Finance",

  addAccount: "Add Account",
  plusAccount: "+ Account",

  paymentMethod: "Payment Method",

  more: "More",
  INCOME: "INCOME",
  EXPENSE: "EXPENSE",

  ORDER: "ORDER",
  GOODS: "GOODS",
  SALARY: "SALARY",

  last30Days: "Last 30 Days",
  thisWeek: "This Week",
  prevWeek: "Previous Week",
  thisMonth: "This Month",
  prevMonth: "Previous Month",

  stock: "Stock",

  materialName: "Material Name",
  orderName: "Order Name",

  // PLACEHOLDERS
  selectCurrency: "Please select a currency",
  selectFinAccount: "Please select a financial account",
  selectStaff: "Please select a staff",
  selectUsedMaterial: "Please select a used material",

  enterMaterialName: "Please enter material name",
  enterOrderName: "Please enter order name",
  enterName: "Please enter name",
  enterDescription: "Please enter description",
  enterPrice: "Please enter price",
  enterFullname: "Please enter full name",
  enterPhoneNumber: "Please enter your phone number",
  enterAddress: "Please enter your address",
  enterDeadline: "Please enter the deadline",
  enterAmount: "Please enter the amount",
  enterTgChatId: "Please enter the Telegram chat ID",
  enterCode: "Please enter the code used with Telegram bot",

  selectOrEnterCurrency: "Please select or enter the currency",
  selectOrEnterPaymentMethod: "Please select or enter the payment method",
  selectOrEnterCategory: "Please select or enter the category",
  selectOrEnterMeasurement: "Please select or enter the measurement unit",

  code: "Code",

  all:"All",
  managers:"Managers",
  seeDeleted:"See deleted",

  products:"Products",
  
  // NOT FOUNDS
  orderNotFound: "Order not found",
  createNow: "Create a new one",
  goodsNotFound: "Goods not found",
  accountNotFound: "Account not found",
};
