import {
  CheckCircleOutlined,
  CheckOutlined,
  CloudSyncOutlined,
  DownOutlined,
  // HistoryOutlined,
  LeftOutlined,
  PrinterOutlined,
  ReloadOutlined,
  RightOutlined,
  UpOutlined,
  ZoomInOutlined,
} from "@ant-design/icons";

export const PREV_ORDER = "PREV_ORDER";
export const NEXT_ORDER = "NEXT_ORDER";
export const PREV_HISTORY = "PREV_HISTORY";
export const NEXT_HISTORY = "NEXT_HISTORY";
export const SERVE = "SERVE";
export const REFRESH_ORDERS = "REFRESH_ORDERS";
// export const REFRESH_HISTORY = "REFRESH_HISTORY";
export const REFRESH_PAGE = "REFRESH_PAGE";
export const ZOOM = "ZOOM";
export const PRINT = "PRINT";

export const buttonSign = {
  PREV_ORDER: <LeftOutlined />,
  NEXT_ORDER: <RightOutlined />,
  PREV_HISTORY: <UpOutlined />,
  NEXT_HISTORY: <DownOutlined />,
  SERVE: <CheckCircleOutlined />,
  REFRESH_ORDERS: <CloudSyncOutlined />,
  // REFRESH_HISTORY: <HistoryOutlined />,
  REFRESH_PAGE: <ReloadOutlined />,
  ZOOM: <ZoomInOutlined />,
  PRINT:<PrinterOutlined/>
};
