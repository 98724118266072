import dayjs from "dayjs";

const dateOptions = [
  {
    label: "last30Days",
    value: "last 30 days",
    range: [dayjs().add(-30, "days"), dayjs()],
  },
  {
    label: "thisWeek",
    value: "This week",
    range: [dayjs().startOf("week"), dayjs()],
  },
  {
    label: "prevWeek",
    value: "Prev week",
    range: [
      dayjs().subtract(1, "week").startOf("week"),
      dayjs().subtract(1, "week").endOf("week"),
    ],
  },
  {
    label: "thisMonth",
    value: "This month",
    range: [dayjs().startOf("month"), dayjs().endOf("month")],
  },
  {
    label: "prevMonth",
    value: "Prev month",
    range: [
      dayjs().subtract(1, "month").startOf("month"),
      dayjs().subtract(1, "month").endOf("month"),
    ],
  },
];

export {dateOptions}