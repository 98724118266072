import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
} from "antd";
import L from "leaflet"; // Import Leaflet library
import React, { useEffect, useRef, useState } from "react";
import {
  FeatureGroup,
  MapContainer,
  Polygon,
  TileLayer,
  useMap,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/container";
import { pointInPolygon } from "../../utils/map";
import {
  useAddArea,
  useDeleteArea,
  useEditArea,
  useGetAreas,
  useGetMe,
} from "../../zustand/store";
import { AreasListWrapper } from "./styles";

function FitBoundsToMarkers({ markers }) {
  const map = useMap();

  useEffect(() => {
    if (markers.length === 0) return;

    const markerCoordinates = markers;
    // .map(({ coords: marker }) =>
    //   L.latLng(marker.lat, marker.lng)
    // );
    const bounds = L.latLngBounds(markerCoordinates);

    map.fitBounds(bounds);
  }, [markers, map]);

  return null; // This component doesn't render anything
}

export function AreaListPart({ polygonMouseOver, setMouseOver }) {
  const editArea = useEditArea();
  const deleteArea = useDeleteArea();
  const areas = useGetAreas();

  useEffect(() => {
    areas.execute();
  }, []);

  return (
    <AreasListWrapper>
      {areas?.data?.map(({ polygon, price, name, id, color }, index) => (
        <Card
          size="small"
          title={
            <div
              style={{
                backgroundColor:
                  id === polygonMouseOver ? "lightgreen" : "white",
              }}
            >
              Area {index + 1}
            </div>
          }
          style={{ width: "100%" }}
          onMouseOver={() => {
            setMouseOver?.(id);
          }}
          onMouseOut={() => {
            setMouseOver?.(null);
          }}
        >
          <Form
            // size="small"
            title={
              <div
                style={{
                  backgroundColor:
                    id === polygonMouseOver ? "lightgreen" : "white",
                }}
              >
                Area {index + 1}
              </div>
            }
            style={{ width: "100%" }}
            layout="vertical"
            onFinish={(e) => {
              editArea.execute({ params: { id }, data: e });
            }}
          >
            <Form.Item name={`name`}>
              <Input
                placeholder="Name"
                style={{ width: "100%" }}
                defaultValue={name}
              />
            </Form.Item>
            <Form.Item name={`price`}>
              <InputNumber
                addonBefore="$"
                placeholder="Price"
                style={{ width: "100%" }}
                defaultValue={price}
              />
            </Form.Item>
            <Form.Item name={`color`}>
              <Input
                // addonBefore="$"
                type="color"
                // placeholder="Price"
                style={{ width: "100%" }}
                defaultValue={color}
              />
            </Form.Item>
            <Row gutter={[10, 10]}>
              <Col>
                <Form.Item style={{ margin: 0 }}>
                  <Button htmlType="submit">Update</Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item style={{ margin: 0 }}>
                  <Popconfirm
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      deleteArea.execute({ params: { id } });
                    }}
                    title="Delete"
                    description={<div>Do you really want to delete this?</div>}
                  >
                    <Button>Delete</Button>
                  </Popconfirm>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      ))}
    </AreasListWrapper>
  );
}

function AreaPage() {
  // { geojson, setGeojson }
  const navigate = useNavigate();
  const areas = useGetAreas();
  const addArea = useAddArea();

  const me = useGetMe();
  const [mouseOver, setMouseOver] = useState(null);
  const [polygonMouseOver, setPolygonMouseOver] = useState(null);
  const [geojson, setGeojson] = useState();
  const [position, setPosition] = useState([]);
  const [boundsList, setBoundsList] = useState([]);

  const [form] = Form.useForm();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (me.data) {
      const coords = me?.data?.restaurant?.coords;
      setPosition([coords.lat, coords.lng]);
    }
  }, [me?.data]);

  useEffect(() => {
    // setTimeout(() => {
    setReady(true);
    areas.execute({
      onSuccess: (res) => {
        const data = res?.data;
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
          form.setFieldValue(`location_${item?.id}`, item?.price);
        }
      },
    });
    // }, 1000);
  }, []);

  const ref = useRef(null);

  const handleChange = () => {
    const geo = ref.current?.toGeoJSON();
    if (geo?.type === "FeatureCollection") {
      setGeojson(geo);
    }
  };

  const [map, setMap] = useState(null);

  const [coords, setCoords] = useState({});

  useEffect(() => {
    if (!map) return;

    map.target.addEventListener("mousemove", (e) => {
      setCoords({ lat: e.latlng.lat, lng: e.latlng.lng });
    });
  }, [map]);

  useEffect(() => {
    if (areas.data) {
      setBoundsList([]);
      setBoundsList(
        areas?.data?.reduce((t, { polygon }) => t.concat(polygon), [])
      );
    }
  }, [areas.data]);

  const { lat, lng } = coords;

  return (
    <PageContainer
      title={
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button onClick={() => navigate("/address")}>Back</Button>
          Area
        </div>
      }
      //  style={{ width: "300px", height: "200px" }}
    >
      {/* <div style={{ width: "33%", textAlign: "center", overflow: "auto" }}>
        <p>{JSON.stringify(mouseOver, null, 2)}</p>
      </div> */}
      <Row gutter={[10, 10]}>
        <Col span={18}>
          {ready && position?.length ? (
            <MapContainer
              style={{ width: "100%", height: "500px" }}
              center={position}
              zoom={13}
              scrollWheelZoom={true}
              whenReady={setMap}
              //   whenCreated={setMap}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              {areas?.data?.map(({ polygon, id, color }) => (
                <Polygon
                  eventHandlers={{
                    mouseover: () => {
                      setPolygonMouseOver(id);
                    },
                    mouseout: () => {
                      setPolygonMouseOver(null);
                    },
                  }}
                  pathOptions={{
                    color:
                      mouseOver === id
                        ? "red"
                        : pointInPolygon(
                            lat,
                            lng,
                            // geometry.coordinates[0].map(([a, b]) => [b, a])
                            polygon
                          )
                        ? "green"
                        : color || "grey",
                  }}
                  positions={polygon}
                />
              ))}
              <FeatureGroup ref={ref}>
                <EditControl
                  position="topright"
                  onEdited={handleChange}
                  onCreated={handleChange}
                  onDeleted={handleChange}
                  draw={{
                    rectangle: false,
                    circle: false,
                    polyline: false,
                    polygon: true,
                    marker: false,
                    circlemarker: false,
                  }}
                />
              </FeatureGroup>
              {boundsList.length ? (
                <FitBoundsToMarkers markers={boundsList} />
              ) : (
                <></>
              )}
            </MapContainer>
          ) : (
            <></>
          )}

          <Button
            disabled={!geojson}
            style={{ margin: "15px 0" }}
            onClick={() => {
              const coordsList = geojson?.features?.map(({ geometry }) =>
                geometry.coordinates[0].map(([a, b]) => [b, a])
              );

              addArea.execute({
                data: { coordsList },
                onSuccess: () => {
                  setGeojson(null);
                  setReady(false);
                  setTimeout(() => {
                    setReady(true);
                  }, 100);
                },
              });
            }}
          >
            Save new Polygons
          </Button>
          {lat && (
            <div>
              <b>latitude</b>: {lat?.toFixed(4)} <br />
              <b>longitude</b>: {lng?.toFixed(4)}
            </div>
          )}
        </Col>
        <Col span={6}>
          <Card size="small">
            <AreaListPart polygonMouseOver={polygonMouseOver} />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}

export default AreaPage;
