import React from "react";
import { Wrapper } from "./styles";
import { Button, Typography } from "antd";
import { useStopDelivery } from "../../../zustand/store";
import { AddOrderBtn } from "../activeDriver";

function StopDelivery() {
  const stopDelivery = useStopDelivery();
  return (
    <Wrapper>
      <div>
        <Typography.Text>
          You have no order. Do you want stop delivery?
        </Typography.Text>
        <br />
        <br />
        {/* </div>
      <div> */}
        <Button
          type="primary"
          onClick={() => {
            stopDelivery.execute();
          }}
        >
          StopDelivery
        </Button>
      </div>
      <AddOrderBtn />
    </Wrapper>
  );
}

export default StopDelivery;
