import React, { useEffect } from "react";
import { Wrapper } from "./styles";
import { useGetMe } from "../../zustand/store";
import { DRIVER } from "../../constants/roles";
import StartDelivery from "./startDelivery";
import ActiveDriver from "./activeDriver";

function DriversPage() {
  const me = useGetMe();
  useEffect(() => {
    // me.execute();
  }, []);
  const user = me?.data?.user?.inStore;
  return (
    <Wrapper>
      {me.loading && !me?.data ? (
        <>Loading...</>
      ) : user?.roles?.roles?.includes(DRIVER) ? (
        !user?.startedDeliveryAt ? (
          <StartDelivery />
        ) : (
          <ActiveDriver />
        )
      ) : (
        <>You are not a driver</>
      )}
    </Wrapper>
  );
}

export default DriversPage;
