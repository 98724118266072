import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { useCancelOrder } from "../../../zustand/store";
import { useParams } from "react-router-dom";

function CancelModal({ open, onCancel, closePage }) {
  const { orderId } = useParams();

  const cancelOrder = useCancelOrder();

  return (
    <Modal
      footer={false}
      title="Cancelation Modal"
      open={open}
      onCancel={onCancel}
    >
      <Form
        layout="vertical"
        onFinish={(e) => {
          cancelOrder.execute({
            data: { reason: e.reason, orderId },
            onSuccess: () => {
              onCancel();
              closePage();
            },
          });
        }}
      >
        <Form.Item name="reason" label={"Reason"}>
          <Input.TextArea />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" loading={cancelOrder.loading}>
            Submit Cancelation
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CancelModal;
