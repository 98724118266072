import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
`;

export const ConnectedPhoneList = styled.div`
  width: calc(100% - (300px + 10px));
`;

export const ConnectData = styled.div`
  width: 300px;
`;
