// src/components/LandingPage.js
import React from "react";
import { Typography, Button, List, Card, Layout } from "antd";
import "./main.css"; // Import the external styles

import memo from "../../assets/images/memo.png";
import smartphone from "../../assets/images/smartphone.png";
import { ArrowDownOutlined } from "@ant-design/icons";
import Navbar from "../../components/navbar";
import { NavbarWrapper, Content, Body, FooterWrapper } from "./styles";

const { Footer } = Layout;
const { Title, Paragraph, Link } = Typography;

const prosData = [
  {
    title: "Efficient Order Management",
    description: "Streamline orders, reduce errors, and enhance efficiency.",
  },
  {
    title: "Enhanced Customer Experience",
    description:
      "Provide faster service with improved accuracy and customer satisfaction.",
  },
  {
    title: "Insightful Sales Analytics",
    description:
      "Gain valuable insights into sales performance for informed decision-making.",
  },
];

const LandingPage = () => {
  return (
    <Layout className="layout">
      <NavbarWrapper>
        <Navbar />
      </NavbarWrapper>

      <Body>
        <Content>
          <Title className="font-futura" level={3}>
            Still Writing Orders on Paper? 📝
          </Title>
          <Paragraph>
            Upgrade to Hisob and digitize your ordering process for faster,
            accurate, and efficient operations.
          </Paragraph>
        </Content>
        <Content>
          <Title className="font-futura" level={3}>
            Still Using a Phone Calculator for Orders? 📱
          </Title>
          <Paragraph>
            Upgrade to Hisob and simplify your order management for a more
            efficient and error-free experience.
          </Paragraph>
        </Content>
        <Content>
          <Title className="font-futura" level={3}>
            It's Time to Start Automating Your Business! ⚙️
          </Title>
          <Paragraph>
            Elevate your restaurant with Hisob's powerful features. Experience
            efficiency and growth — make the switch today!
          </Paragraph>
          <Button size="large" type="primary">
            Start today
          </Button>
        </Content>
      </Body>
      <FooterWrapper>
        <Link
          href="https://hisob.org"
          // target="_blank"
        >
          Hisob
        </Link>{" "}
        ©{new Date().getFullYear()} created by{" "}
        <Link href="https://t.me/tmb_01" target="_blank">
          Maraim
        </Link>
        {/* </Footer> */}
      </FooterWrapper>
    </Layout>
  );
};

export default LandingPage;
