import { orderTypes } from "../constants/orderType";
import { thousandSeparator } from "./numberManager";

// export const extraToppingPrice = (selectedOption) =>
//   selectedOption?.reduce(
//     (ti, vi) =>
//       ti + (vi?.reduce((tj, vj) => tj + (vj?.toppingPrice || 0), 0) || 0),
//     0
//   );

export const extraPriceCalc = (selectedOption) =>
  selectedOption?.reduce(
    (ti, vi) =>
      ti + (vi?.reduce((tj, vj) => tj + (vj?.extraPrice || 0), 0) || 0),
    0
  );

export const calcSubTotal = (selectedFood) => {
  return selectedFood?.reduce((t, v, i) => {
    let extraPrice = extraPriceCalc(v?.selectedOption);
    extraPrice = v?.extraPrice || extraPrice || 0;
    return t + (v.price + extraPrice) * v.amount;
  }, 0);
};

export const calcTotals = (
  selectedFood,
  deliveryFee,
  serviceFee,
  order_type,
  withTax
) => {
  const subTotal = calcSubTotal(selectedFood);
  const taxRate = withTax ? 11.5 : 0;
  const isDelivery = order_type === orderTypes.DELIVERY;
  deliveryFee = isDelivery ? Number(deliveryFee) || 0 : 0;
  serviceFee =
    isDelivery && selectedFood.length && deliveryFee
      ? Number(serviceFee) || 0
      : 0;

  const tax = ((subTotal + deliveryFee + serviceFee) / 100) * taxRate;
  const sum = subTotal + deliveryFee + tax + serviceFee;
  const total = thousandSeparator(sum.toFixed(2));

  return {
    subTotal: subTotal.toFixed(2),
    deliveryFee,
    serviceFee,
    tax: tax.toFixed(2),
    total,
  };
};
