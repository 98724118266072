import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0px 13px 13px;
  /* position:"fixed";
  top:0;
  right:0;
  bottom: 0; */
  @media only screen and (max-width: 850px) {
    padding: 0px 10px ${({ lastOne }) => (lastOne ? "100px" : "10px")};
  }
`;

export const CardsListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const CardWrapper = styled.div`
  width: ${({ pinned }) => (pinned ? "fit-content" : "195px")};
  max-width: 195px;
  @media only screen and (max-width: 850px) {
    width: ${({ pinned }) => (pinned ? "fit-content" : "160px")};
    /* max-width: 160px; */
  }
`;

export const BreakBlock = styled.div`
  flex-basis: 100%;
  height: 0px;
`;

export const GridWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 15px;
`;
