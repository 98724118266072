import { Modal } from "antd";
import styled from "styled-components"

export const AmountDisplay = styled.div`
  width: 100%;
  height: 85px;
  border: 1px solid lightgrey;
  font-size: 50px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 10px;
`;

export const ModalWrapper = styled(Modal)`
  @media only screen and (max-width: 580px) {
    max-width: 100vw;
    .ant-modal-content{
        padding: 20px 15px 20px;
    }
  }
`;
