import {
  DownOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Form,
  Input,
  Modal,
  Row,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import { mainColor } from "../../components/styles/styles";
import {
  useAddMenuCategoryName,
  useAddMenuName,
  useDeleteMenuName,
  useDeleteOrderTemplate,
  useEditMenuCategory,
  useGetMenuName,
  useGetOrderTemplate,
  useUpdateMenuCategoryNameSort,
} from "../../zustand/store";
import MenuItems from "./menuItems";

const CategoryLabel = ({ category, index, categories }) => {
  const edit = useUpdateMenuCategoryNameSort();
  const getMenu = useGetOrderTemplate();
  const editMenuCategory = useEditMenuCategory();

  const [editName, setEditName] = useState(false);
  const [name, setName] = useState("");

  const move = ({ id, name, index, move }) => {
    const s = [
      ...categories.slice(0, index),
      ...categories.slice(index + 1),
    ];
    s.splice(index + move, 0, { id, name });
    const sCategory = s.map(({ id, name }, i) => ({ id, name, sort: i + 1 }));
    // setSortCategory(sCategory);
    edit.execute({
      data: { data: sCategory },
      // onSuccess: onCancel
    });
  };

  const editCategoryName = () => {
    if (name.trim() !== category.name) {
      editMenuCategory.execute({
        params: { id: category?.id },
        data: { name },
        onSuccess: () => {
          setEditName(false);
        },
      });
    } else {
      setEditName(false);
    }
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Button
        loading={edit.loading || getMenu.loading}
        disabled={!(category?.sort > 1)}
        size="small"
        onClick={() =>
          category?.sort > 1
            ? move({
              id: category?.id,
              name: category?.name,
              index,
              move: -1,
            })
            : {}
        }
      >
        <LeftOutlined />
      </Button>
      <div
        style={{
          margin: "0 0 0 5px",
          display: "flex",
          alignItems: "center",
          // gap: [10, 10],
        }}
      >
        <div>
          {editName ? (
            <Input
              style={{ width: `${(category?.name?.length || 0) * 13}px` }}
              value={name}
              loading={editMenuCategory.loading}
              onChange={(e) => {
                setName(e.target.value);
              }}
              autoFocus
              onBlur={editCategoryName}
              onKeyUp={(e) => {
                if (e.code === "Enter") {
                  editCategoryName();
                }
              }}
            />
          ) : (
            <div
              onDoubleClick={() => {
                setEditName(true);
                setName(category?.name);
              }}
            >
              {category?.name}
            </div>
          )}
        </div>
        <div style={{ marginLeft: "5px" }}>
          {category?.color ? (
            <Tag
              style={{ border: "1px solid rgba(0, 0, 0, 0.2)" }}
              color={category?.color}
            > </Tag>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Button
        loading={edit.loading || getMenu.loading}
        disabled={!(categories?.length > category?.sort)}
        size="small"
        onClick={() =>
          categories?.length > category?.sort
            ? move({
              id: category?.id,
              name: category?.name,
              index,
              move: +1,
            })
            : {}
        }
      >
        <RightOutlined />
      </Button>
    </div>
  );
};

function CreateOrderPage() {
  const getMenu = useGetOrderTemplate();
  const deleteMenu = useDeleteOrderTemplate();
  const [newMenu, setNewMenu] = useState(false);
  const [menuId, setMenuId] = useState(null);
  const [newMenuCategory, setNewMenuCategory] = useState(false);
  // const [selectedMenu, setSelectedMenu] = useState();
  // const getMenuName = useGetMenuName();
  const addMenuName = useAddMenuName();
  const addMenuCategoryName = useAddMenuCategoryName();


  const onEdit = (targetKey, action) => {
    const actions = {
      add: () => setNewMenuCategory(true),
      remove: () => { },
    };
    actions?.[action]?.();
  };

  useEffect(() => {
    getMenu.execute();
  }, []);


  useEffect(() => {
    if (getMenu.success && getMenu.data) {
      let mId = Number(localStorage.getItem("menuId"));
      setMenuId(mId);
      // if (mId) {
      //   // menuId = Number(menuId);
      //   setMenuId(getMenu.data?.find(({ id }) => id === mId)?.id);
      // } else {
      //   setMenuId(getMenu.data?.[0].id);
      // }
    }
  }, [getMenu.success]);

  return (
    <PageContainer title={LangText({ id: "menu" })}>
      <Card size="small">
        <Tabs
          // defaultActiveKey={menuId}
          activeKey={menuId}
          onEdit={onEdit}
          size="small"
          type="editable-card"
          onChange={(key) => {
            localStorage.setItem("menuId", key);
            setMenuId(key);
          }}
          // defaultActiveKey="1"
          items={getMenu?.data
            ?.map((menu, index) => ({
              label: menu?.name,
              key: menu.id,
              closeIcon: <PlusOutlined />,
              closable: false,
              children: <>
                {/* <Row gutter={[5, 5]}>
                  <Col>
                    {getMenu.data ? (
                      <Dropdown
                        menu={{
                          items: getMenu.data?.map((menu) => ({
                            key: menu?.id,
                            onClick: () => {
                              setSelectedMenu(menu);
                              localStorage.setItem("menuId", menu?.id);
                            },
                            style:
                              menu?.name === selectedMenu?.name
                                ? { backgroundColor: "#6045e250", color: mainColor }
                                : {},
                            label: menu?.name,
                          })),
                        }}
                      >
                        <Button>
                          {selectedMenu?.name} <DownOutlined />
                        </Button>
                      </Dropdown>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col>
                    <Button type="primary" onClick={() => setNewMenu(true)}>
                      <PlusOutlined />
                    </Button>
                  </Col>
                </Row>
                <br /> */}
                <Card size="small">
                  <Tabs
                    // tabBarExtraContent={
                    //   <Button onClick={() => setSortCategory(true)}>
                    //     <SortAscendingOutlined />
                    //   </Button>
                    // }
                    onEdit={onEdit}
                    size="small"
                    type="editable-card"
                    defaultActiveKey="1"
                    items={
                      // selectedMenu
                      menu
                        ?.categories
                        ?.map((category, index) => ({
                          label: (
                            <CategoryLabel
                              category={category}
                              index={index}
                              categories={menu?.categories}
                            />
                          ),
                          key: category.id,
                          closeIcon: <PlusOutlined />,
                          closable: false,
                          children: <MenuItems category={category} menu={
                            // selectedMenu
                            menu
                          } />,
                        }))
                        .concat([{}])}
                  />
                </Card>
              </>,
            }))
            .concat([{}])}
        />
      </Card>
      <Modal
        destroyOnClose
        title="Menu Name"
        footer={false}
        open={newMenu}
        // onClose={() => setNewMenu(false)}
        onCancel={() => setNewMenu(false)}
        size="small"
        centered
      >
        <Form
          onFinish={(data) => {
            addMenuName.execute({
              data,
              onSuccess: () => {
                setNewMenu(false);
              },
            });
          }}
        >
          <Form.Item name="name">
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item style={{ margin: "0" }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={addMenuName.loading}
              disabled={addMenuName.loading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        destroyOnClose
        title="Menu Category"
        footer={false}
        open={newMenuCategory}
        // onClose={() => setNewMenu(false)}
        onCancel={() => setNewMenuCategory(false)}
        size="small"
        centered
      >
        <Form
          onFinish={(data) => {
            addMenuCategoryName.execute({
              data: { ...data, menuId },
              onSuccess: () => {
                setNewMenuCategory(false);
              },
            });
          }}
        >
          <Form.Item name="name">
            <Input placeholder="Name" autoFocus />
          </Form.Item>
          <Form.Item style={{ margin: "0" }}>
            <Button
              loading={addMenuCategoryName.loading}
              disabled={addMenuCategoryName.loading}
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      {/* <RearrangeModal
        open={sortCategory}
        onCancel={() => setSortCategory(false)}
        selectedMenu={selectedMenu}
      /> */}
    </PageContainer>
  );
}

export default CreateOrderPage;
