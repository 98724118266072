import React, { useEffect } from "react";
import { PageContainer } from "../../components/container";
import { useGetRestaurantsList } from "../../zustand/store";
import AppFee from "./appFee";
import { Button, Tabs } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import History from "./history";

function SingleRestaurantPage() {
  const restaurants = useGetRestaurantsList();

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    restaurants.execute();
  }, []);
  return (
    <PageContainer
      title={
        <>
          <Button
            onClick={() => {
              navigate("/restaurants");
            }}
          >
            GoBack
          </Button>{" "}
          {restaurants.data?.find(({ id: rID }) => rID === id)?.name}
        </>
      }
    >
      <Tabs
        items={[
          {
            label: "Active",
            key: "active",
            children: (
              <>
                <AppFee />
              </>
            ),
          },
          {
            label: "History",
            key: "history",
            children: (
              <>
                <History />
              </>
            ),
          },
        ]}
      />
    </PageContainer>
  );
}

export default SingleRestaurantPage;
