import React from "react";
import { Wrapper } from "./styles";
// import { createGlobalStyle } from "styled-components";
// const GlobalStyle = createGlobalStyle`
//   @import url(https://themes.googleusercontent.com/fonts/css?kit=dpiI8CyVsrzWsJLBFKehGpLhv3qFjX7dUn1mYxfCXhI);
// `;
import "./main.css";
import { useGetMe } from "../../../zustand/store";
// import { Logo } from "../../../assets/icons/components";

import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";

function AgreementPage() {
  const me = useGetMe();
  const restaurant = me.data?.restaurant || {};
  const data = {
    restaurantName: restaurant.name || "_______________________",
    startDate: "_____________",
    durationMonths: "______",
    serviceChargePerDelivery: restaurant.serviceFee || "________",
    maxAmount: restaurant.maxServiceFee || "_________",
  };
  const {
    restaurantName,
    startDate,
    durationMonths,
    serviceChargePerDelivery,
    maxAmount,
  } = data;

  return (
    <>
      {/* <GlobalStyle /> */}
      <>
        <body class="c10 doc-content">
          {/* <img src={logo} alt="logo"/> */}
          <div className="logo">
            <Logo />
          </div>

          <p class="c3" style={{ textAlign: "center" }}>
            <span class="c0" style={{ fontSize: "32px" }}>
              Point of Sale System Agreement
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">1. Parties Involved:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              This agreement is entered into by and between Hisob, hereinafter
              referred to as "Provider" and <b>{restaurantName}</b>, hereinafter
              referred to as "Client."
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">2. Description of Services:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              Provider agrees to install, configure, and maintain a
              comprehensive Point of Sale (POS) system named "Hisob". The POS
              system includes the following specific functionalities:
            </span>
          </p>
          {/* <br /> */}
          <ul class="c2 lst-kix_u605up2ev8lg-0 start">
            <li class="c3 c6 li-bullet-0">
              <span class="c7">Menu Customization:</span>
              <span class="c5">
                &nbsp;Tailoring the "Hisob" POS system to accommodate and
                customize menus as per the requirements.
              </span>
            </li>
          </ul>
          <br />
          <ul class="c2 lst-kix_u605up2ev8lg-0">
            <li class="c3 c6 li-bullet-0">
              <span class="c7">Order Processing:</span>
              <span class="c5">
                &nbsp;Implementing an efficient order processing system,
                ensuring seamless and accurate handling of customer orders.
              </span>
            </li>
          </ul>
          <br />
          <ul class="c2 lst-kix_u605up2ev8lg-0">
            <li class="c3 c6 li-bullet-0">
              <span class="c7">Financial Management:</span>
              <span class="c5">
                &nbsp;Integrating features for tracking income and expenses
                related to sales transactions within the "Hisob" POS system.
              </span>
            </li>
          </ul>
          <br />
          <ul class="c2 lst-kix_u605up2ev8lg-0">
            <li class="c3 c6 li-bullet-0">
              <span class="c7">Driver Management:</span>
              <span class="c5">
                &nbsp;Creating a dedicated page for drivers to manage delivery
                tasks efficiently.
              </span>
            </li>
          </ul>
          <br />
          <ul class="c2 lst-kix_u605up2ev8lg-0">
            <li class="c3 c6 li-bullet-0">
              <span class="c7">Advertiser Page:</span>
              <span class="c5">
                &nbsp;Providing a page for advertisers to distribute flyers and
                promotional material to clients using the "Hisob" POS system.
              </span>
            </li>
          </ul>
          <br />
          <ul class="c2 lst-kix_u605up2ev8lg-0">
            <li class="c3 c6 li-bullet-0">
              <span class="c7">Statistical Analysis:</span>
              <span class="c9">
                &nbsp;Implementing a statistics page for comprehensive data
                analysis, enabling the business to make informed decisions.
              </span>
            </li>
          </ul>
          <br />
          <ul class="c2 lst-kix_u605up2ev8lg-0">
            <li class="c3 c6 li-bullet-0">
              <span class="c7">Staff Management:</span>
              <span class="c5">
                &nbsp;Incorporating features for managing staff, including
                scheduling, attendance tracking, and performance monitoring.
              </span>
            </li>
          </ul>
          <br />
          <ul class="c2 lst-kix_u605up2ev8lg-0">
            <li class="c3 c6 li-bullet-0">
              <span class="c7">Inventory Tracking:</span>
              <span class="c5">
                &nbsp;Providing a simplified inventory tracking feature for
                adding purchased items for the restaurant and recording them as
                expenses.
              </span>
            </li>
          </ul>
          <br />
          <p class="c3">
            <span class="c5">
              The above functionalities collectively aim to enhance operational
              efficiency and overall business performance for the business.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">3. Term of Agreement:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              This agreement shall commence on {startDate} and continue for an
              initial term of {durationMonths} month(s), renewable by mutual
              agreement.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">4. Service Fees:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              For the use of the "Hisob" POS system server, Client agrees to pay
              Provider an amount of <b>${serviceChargePerDelivery}</b> per
              delivery facilitated through the system. The total payments for
              the POS system server will accrue in real-time until reaching a
              maximum total amount of <b>${maxAmount}</b>. Client shall monitor
              the total payable amount, visible in real-time within the "Hisob"
              POS system. Client agrees to make payments for the POS system
              server just before reaching the maximum amount. Payments are due
              within 7 days after reaching the maximum amount.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">5. Payment Terms:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c9">
              In the event of non-payment, Provider reserves the right to
              suspend or terminate services.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">6. Support and Maintenance:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              Provider agrees to provide technical support and maintenance for
              the "Hisob" POS system. Emergency support will be available as
              needed.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">7. Responsibilities of Client:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              Client is responsible for training its staff on the proper use of
              the "Hisob" POS system and ensuring the security of access
              credentials. Client agrees to promptly notify Provider of any
              suspected security breaches.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">8. Data Ownership:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              Client retains ownership of all data entered into the "Hisob" POS
              system. Provider agrees not to use or disclose client data for any
              purpose other than providing the agreed-upon services.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c7">9. Termination:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              Either party may terminate this agreement with 7 days written
              notice. Upon termination, Client agrees to return any hardware or
              software provided by Provider.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">10. Confidentiality:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c9">
              Both parties agree to keep confidential any proprietary or
              sensitive information disclosed during the term of this agreement,
              including but not limited to payment details related to the
              "Hisob" POS system. Information regarding payments for the "Hisob"
              POS system should not be disclosed to third parties.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">11. Governing Law:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c9">
              This agreement shall be governed by and construed in accordance
              with the laws of the State of South Carolina, USA. Any disputes
              arising under or in connection with this agreement shall be
              subject to the exclusive jurisdiction of the state and federal
              courts located within the State of South Carolina.
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c0">12. Miscellaneous:</span>
          </p>
          {/* <br /> */}
          <p class="c3">
            <span class="c5">
              Any modifications to this agreement must be made in writing and
              signed by both parties.
            </span>
          </p>
          <br />
          <br />
          <br />
          <p class="c3">
            <span class="c0">Signatures:</span>
          </p>
          <br />
          <p class="c3">
            <span class="c5">
              Provider (<b>Hisob</b>):
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c5">
              Signatory: Maraim
              Tukhtasunov                                        Role: Owner
            </span>
          </p>
          <p class="c11">
            <span class="c5">
              Signature: __________________________________________ Date:
              ___________________
            </span>
          </p>
          <br />
          <br />
          <br />
          <br />
          <p class="c3">
            <span class="c5">
              Client (<b>{restaurantName}</b>):
            </span>
          </p>
          <br />
          <p class="c3">
            <span class="c5">
              Signatory: __________________________________________ Role:
              ___________________
            </span>
          </p>
          <br />
          {/* <p class="c3">
            <span class="c5">Role: ____________________________</span>
          </p> */}
          {/* <br /> */}
          <p class="c3">
            <span class="c9">
              Signature: __________________________________________ Date:
              ___________________
            </span>
          </p>
        </body>
      </>
    </>
  );
}

export default AgreementPage;
