const doubleWidth = "doubleWidth";
const doubleHeight = "doubleHeight";
const two_x_size = "two_x_size";
const three_x_size = "three_x_size";
const four_x_size = "four_x_size";
const five_x_size = "five_x_size";
const six_x_size = "six_x_size";
const underline = "underline";
const bold = "bold";
const invert = "invert";
const none = "none";

export const enhancedSizeList = [
  doubleWidth,
  doubleHeight,
  none,
  two_x_size,
  three_x_size,
  four_x_size,
  five_x_size,
  six_x_size,
];

export const textStyleList = [underline, bold, invert];

export const specialChars = {
  [none]: "",
  [doubleWidth]: "^",
  [doubleHeight]: "^^",
  [two_x_size]: "^^^",
  [three_x_size]: "^^^^",
  [four_x_size]: "^^^^^",
  [five_x_size]: "^^^^^^",
  [six_x_size]: "^^^^^^^",
  [underline]: "_",
  [bold]: '"',
  [invert]: "`",
};

export const dict = {
  [none]: "None",
  [doubleWidth]: "Double Width",
  [doubleHeight]: "Double Height",
  [two_x_size]: "2x",
  [three_x_size]: "3x",
  [four_x_size]: "4x",
  [five_x_size]: "5x",
  [six_x_size]: "6x",
  [underline]: "Underline",
  [bold]: "Bold",
  [invert]: "Invert",
};

export const charDict = (id) => {
  return dict[id] || id;
};

export const char = (t) => {
  return specialChars[t] || "";
};

export const insertToMarkUp = (text, setting = {}) => {
  text = String(text || "")
    .split("_")
    .join("\\_")
    .split('"')
    .join('\\"');
  let { visibility, size, style } = setting;
  style = style || [];
  if (visibility !== false) {
    return `${char(size)}${style?.map((s) => char(s)).join("")}${text}${[
      ...style,
    ]
      .reverse()
      ?.map((s) => char(s))
      .join("")}${char(size)}`;
  }
  return "";
};

export const receiptPartList = [
  "order_type",
  "phone_number",
  "address",
  "date",
  "time",
  "desc",
  "call",
  "amount",
  "name",
  "size",
  "price",
  "customization",
  "sub_total",
  "total",
];
