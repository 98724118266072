import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  List,
  Popconfirm,
  Row,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { LangText } from "../../../../components/langManagement";
import {
  useAddExtra,
  useDeleteExtra,
  useEditExtra,
  useGetOrderTemplate,
} from "../../../../zustand/store";
import {
  FULL_SIDE,
  LEFT_SIDE,
  RIGHT_SIDE,
} from "../../../../constants/general";
import size from "../../../../constants/size";

function Extra({ open, onClose, menuCategoryId, extraList }) {
  // const [extraList, setExtraList] = useState([]);
  // const extraList = useGetExtra();
  const addExtra = useAddExtra();
  const deleteExtra = useDeleteExtra();
  const editExtra = useEditExtra();
  const menu = useGetOrderTemplate();

  const [edit, setEdit] = useState(null);

  const [form] = Form.useForm();

  // useEffect(() => {
  //   extraList.execute();
  // }, []);

  useEffect(() => {
    if (open) {
      form.resetFields();
      setEdit(null);
    }
  }, [open]);

  return (
    <Drawer open={open} title={"Extra"} onClose={onClose}>
      <Card size="small">
        <Form
          form={form}
          layout="vertical"
          onFinish={(e) => {
            (edit ? editExtra : addExtra).execute({
              params: { id: edit?.id },
              data: { ...e, menuCategoryId },
              onSuccess: () => {
                form.resetFields();
                setEdit(null);
              },
            });
          }}
          autoComplete="off"
        >
          <Row gutter={[10, 10]}>
            <Col span={16}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please input extra name!" },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Price"
                name="price"
                rules={[
                  { required: true, message: "Please input extra price!" },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  placeholder="Price"
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={
              <div>
                Items{" "}
                <Tooltip title="Separate with comma (,)">
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            name="items"
            rules={[{ required: true, message: "Please input extra items!" }]}
          >
            <Input placeholder="Items" />
          </Form.Item>
          <Form.Item label="Sides" name="sides">
            <Checkbox.Group>
              <Row>
                {[LEFT_SIDE, FULL_SIDE, RIGHT_SIDE].map((s) => (
                  <Col>
                    <Checkbox value={s}>{s}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <div
              style={{
                display: "flex",
                width: "fit-content",
                marginLeft: "auto",
                gap: "10px",
              }}
            >
              {edit && (
                <Button
                  onClick={() => {
                    form.resetFields();
                    setEdit(null);
                  }}
                >
                  <LangText id={"Cancel"} />
                </Button>
              )}
              <Button
                loading={addExtra.loading || editExtra.loading}
                type="primary"
                htmlType="submit"
              >
                <LangText id={edit ? "Edit" : "Add"} />
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Card>
      <List loading={menu.loading}>
        {extraList?.map(({ id, name, price, sides, items }) => (
          <List.Item key={id}>
            <Card
              style={{ width: "100%" }}
              size="small"
              actions={[
                <EditOutlined
                  onClick={() => {
                    form.setFieldsValue({
                      name,
                      price,
                      items: items.map(({ name }) => name).join(", "),
                    });
                    setEdit({ id, name, price, items });
                  }}
                />,
                <Popconfirm
                  title="Delete"
                  description="Do you really want to delete this?"
                  okText="Delete"
                  loading={deleteExtra.loading}
                  onConfirm={() => {
                    deleteExtra.execute({ params: { id } });
                  }}
                >
                  <DeleteOutlined />
                </Popconfirm>,
              ]}
            >
              <Typography.Title level={5}>
                {name} ${price || 0}
              </Typography.Title>
              <div>
                {items.map(({ name }) => (
                  <Tag>{name}</Tag>
                ))}
              </div>
              <br />
              <div>
                {sides ? (
                  sides?.map((s) => <Tag color="blue">{s}</Tag>)
                ) : (
                  <Tag color="blue">{FULL_SIDE}</Tag>
                )}
              </div>
            </Card>
          </List.Item>
        ))}
      </List>
    </Drawer>
  );
}

export default Extra;
