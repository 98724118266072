import { DeleteOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  List,
  Modal,
  Row,
  Tabs,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import size from "../../../../../constants/size";

function NewOptionModal({ menu, open, onCancel, onOk, editIndex, options, categories }) {
  // const getMenu = useGetOrderTemplate();
  const [foods, setFoods] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (editIndex || editIndex === 0) {
      const option = options[editIndex];
      setFoods(option.foods);
      form.setFieldsValue({
        length: option?.length || 1,
        numberOfTopping: option?.numberOfTopping || 1,
      });
    } else {
      setFoods([]);
      form.setFieldsValue({
        length: 1,
        numberOfTopping: 1,
      });
    }
  }, [open, editIndex]);

  return (
    <Modal
      onOk={() => {
        const values = form.getFieldsValue();
        onOk({ ...values, foods });
      }}
      centered
      open={open}
      onCancel={onCancel}
      title={"chooseOptions"}
    >
      <List
        dataSource={foods}
        renderItem={(
          {
            // category,
            createdAt,
            createdBy,
            id: fId,
            ingredients,
            name,
            price,
            size: s,
            updatedAt,
            updatedBy,
            extra,
            menuCategoryId,
          },
          i
        ) => {
          const category = menu?.categories?.find(
            ({ id }) => id === menuCategoryId
          );
          
          return (
            <List.Item id={i} style={{ width: "100%" }}>
              <Row gutter={[10, 10]} style={{ width: "100%" }}>
                <Col span={24}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div span={23}>
                      {name} {s ? <Tag>{size[s]}</Tag> : <></>}
                    </div>
                    <div
                      span={1}
                    // onClick={() =>
                    //   setFoods((prev) => prev.filter((v, index) => index !== i))
                    // }
                    >
                      <Button
                        danger
                        onClick={() =>
                          setFoods((prev) =>
                            prev.filter((v, index) => index !== i)
                          )
                        }
                      >
                        <DeleteOutlined />
                      </Button>
                    </div>
                  </div>
                </Col>
                {extra?.length ? (
                  <Col span={24}>
                    <Card size="small">
                      <Row gutter={[10, 10]}>
                        {extra?.map(({ id, name, price, amount }) => {
                          id = Number(id);
                          const ext = category?.extra?.find(
                            (c) => c?.id === id
                          );
                          return (
                            <Col span={24} key={id}>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "10px",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ height: "fit-content" }}>
                                  {ext?.name} <b>${ext?.price}</b>
                                </div>
                                <div>
                                  <InputNumber
                                    addonBefore="$"
                                    // defaultValue={price || ext?.price}
                                    value={price || ext?.price}
                                    style={{ width: "80px" }}
                                    placeholder="Required free amount"
                                    min={0}
                                    onChange={(price) => {
                                      setFoods((prev) =>
                                        prev?.map((f) =>
                                          f?.id === fId
                                            ? {
                                              ...f,
                                              extra: extra?.map((e) =>
                                                Number(e.id) === id
                                                  ? { ...e, price }
                                                  : e
                                              ),
                                            }
                                            : f
                                        )
                                      );
                                    }}
                                  />
                                </div>
                                <div>
                                  <InputNumber
                                    addonAfter="pc"
                                    // defaultValue={amount}
                                    value={amount}
                                    style={{ width: "100px" }}
                                    placeholder="Required free amount"
                                    min={0}
                                    onChange={(amount) => {
                                      setFoods((prev) =>
                                        prev?.map((f) =>
                                          f?.id === fId
                                            ? {
                                              ...f,
                                              extra: extra?.map((e) =>
                                                Number(e.id) === id
                                                  ? { ...e, amount }
                                                  : e
                                              ),
                                            }
                                            : f
                                        )
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                    </Card>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>
            </List.Item>
          );
        }}
      />
      <Form layout="vertical" form={form}>
        <Form.Item label="length" name="length">
          <InputNumber min={1} />
        </Form.Item>
        {/* {foods.find(({ name, extra }) => (extra)) ? (
          <Form.Item label="numberOfTopping" name="numberOfTopping">
            <InputNumber min={1} />
          </Form.Item>
        ) : (
          <></>
        )} */}
      </Form>
      <Tabs
        items={
          // menu?.
          categories?.map((category, i) => ({
            label: category?.name,
            key: category.id,
            children: (
              // <MenuItems category={category} menu={selectedMenu} />,
              <Row gutter={[10, 10]} slot="15">
                {category?.items
                  // ?.filter(({ display_type }) => !display_type)
                  ?.map((data, i) => {
                    const {
                      category,
                      createdAt,
                      createdBy,
                      id,
                      ingredients,
                      name,
                      price,
                      size: s,
                      updatedAt,
                      updatedBy,
                      display_type,
                      sub_items
                    } = data;
                    if (display_type) {
                      return (
                        <Col span={24} key={id}>
                          <Row gutter={[10, 10]}>
                            {
                              sub_items?.map((data) => (
                                <Col span={8} key={data?.id}>
                                  <Card
                                    style={{
                                      opacity: foods.find((f) => f?.id === data?.id)
                                        ? "0.4"
                                        : "1",
                                      // backgroundColor:"black"
                                    }}
                                    onClick={() => {
                                      setFoods((prev) =>
                                        prev.find((p) => p.id === data?.id)
                                          ? prev.filter((p) => p?.id !== data?.id)
                                          : [...prev, data]
                                      );
                                    }}
                                    size="small"
                                  >
                                    {data?.name}{" "}
                                    {data?.size ? (
                                      <>
                                        <br />
                                        <Tag>{size[data?.size]}</Tag>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Card>
                                </Col>
                              ))
                            }
                          </Row>

                        </Col>
                      )
                    } else {
                      return (
                        <Col span={8} key={id}>
                          <Card
                            style={{
                              opacity: foods.find((f) => f?.id === id)
                                ? "0.4"
                                : "1",
                              // backgroundColor:"black"
                            }}
                            onClick={() => {
                              setFoods((prev) =>
                                prev.find((p) => p.id === id)
                                  ? prev.filter((p) => p?.id !== id)
                                  : [...prev, data]
                              );
                            }}
                            size="small"
                          >
                            {name}{" "}
                            {s ? (
                              <>
                                <br />
                                <Tag>{size[s]}</Tag>
                              </>
                            ) : (
                              <></>
                            )}
                          </Card>
                        </Col>
                      );
                    }
                  })}
              </Row>
            ),
          }))}
      />
    </Modal>
  );
}

export default NewOptionModal;
