// import { DualAxes } from "@ant-design/plots";
import { useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import {
  useGetAddresses,
  useGetOrdersList,
} from "../../../../../zustand/store";
function OrderByAddress() {
  const orders = useGetOrdersList();
  const addresses = useGetAddresses();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (orders?.data && addresses?.data) {
      setData([]);
      let addressData = addresses?.data?.reduce(
        (t, { name }) => ({ ...t, [name]: 0 }),
        {}
      );
      addressData["other"] = 0;

      const dataObj = orders?.data?.reduce((t, order) => {
        const address = order?.userData?.address;
        if (address) {
          const a = t[address];
          if (a || a === 0) {
            return { ...t, [address]: a + 1 };
          } else {
            return { ...t, other: t.other + 1 };
          }
        } else {
          return t;
        }
      }, addressData);

      const dataList = Object.entries(dataObj).reduce(
        (t, [name, amount]) => (amount ? [...t, { name, amount }] : t),
        []
      );

      setData(dataList);
    }
  }, [orders.data, addresses?.data]);

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey={"amount"} fill="#6045e2" />
        </ComposedChart>
        {/* <ComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" scale="band" />
        <YAxis />
        <Tooltip />
        <Bar name="Delivery" dataKey={"amount"} barSize={20} fill="#6045e2" />
      </ComposedChart> */}
      </ResponsiveContainer>
    </>
  );
}

export default OrderByAddress;
