import React, { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import moment from "moment";
import { Tag } from "antd";
import { CustomTag, OrderTypeTag } from "../styles";

export function Timer() {
  const [timer, setTimer] = useState(new Date(Date.now()));

  useEffect(() => {
    setTimeout(() => {
      setTimer(new Date(Date.now()));
    }, 1000);
  }, [timer]);
  return <Wrapper>{moment(timer).format("HH:mm:ss")}</Wrapper>;
}

export function Seconds({ date }) {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    setTimer(parseInt(moment().diff(moment(date)) / 1000));
    setTimeout(() => {
      setTimer(parseInt(moment().diff(moment(date)) / 1000));
    }, 1000);
  }, [timer]);

  const second = timer % 60;
  const m = parseInt(timer / 60);
  const minute = m % 60;
  const h = parseInt(m / 60);
  const hour = parseInt(h);
  const addZero = (number) => `0${number}`.slice(-2);
  return (
    <CustomTag type={timer > 600 ? "danger" : "success"}>
      {/* {timer}   */}
      {hour}:{addZero(minute)}:{addZero(second)}
      {/* <br />
      {hour}:{minute}:{second} */}
    </CustomTag>
  );
}
