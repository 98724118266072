import React, { useEffect, useState } from "react";
import { LangText } from "../../../components/langManagement";
import { Button, Card, Col, Row, Table } from "antd";
import {
  useGetActiveDrivers,
  useGetOrderTemplate,
  useGetUnCashedOutDriverList,
} from "../../../zustand/store";
import moment from "moment";
import CashOutModal from "./cashOutModal";
import { useNavigate } from "react-router-dom";
function Drivers() {
  const activeDrivers = useGetActiveDrivers();
  const uncashedOutDrivers = useGetUnCashedOutDriverList();
  // const [chosenDriver, setChosenDriver] = useState(null);
  const [chosenCashOut, setChosenCashOut] = useState(null);
  const menu = useGetOrderTemplate();
  const navigate = useNavigate();

  useEffect(() => {
    menu.execute();
    activeDrivers.execute();
    uncashedOutDrivers.execute();
  }, []);
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Card size="small">
            <Table
              loading={activeDrivers.loading}
              size="small"
              pagination={false}
              columns={[
                // {
                //   width: "40px",
                //   title: <LangText id="id" />,
                //   dataIndex: "id",
                //   key: "id",
                // },
                {
                  title: <LangText id="fullname" />,
                  dataIndex: "fullname",
                  key: "fullname",
                },
                {
                  title: <LangText id="date" />,
                  dataIndex: "date",
                  key: "date",
                  // width: "200px",
                },
                {
                  title: <LangText id="orders" />,
                  dataIndex: "orders",
                  key: "orders",
                  // width: "180px",
                },
                {
                  // width: "100px",
                  title: <LangText id="action" />,
                  dataIndex: "action",
                  key: "action",
                },
              ]}
              dataSource={uncashedOutDrivers?.data?.map(
                (
                  value
                  // {
                  // advertisingData,
                  // chatID,
                  // created_at,
                  // description,
                  // extra,
                  // fullname,
                  // id,
                  // is_active,
                  // orders,
                  // owner,
                  // permissions,
                  // phone_number,
                  // roles,
                  // square_device_id,
                  // startedAdvertisingAt,
                  // startedDeliveryAt,
                  // updated_at,
                  // }
                ) => {
                  const {
                    app_fee_payment,
                    createdAt,
                    date,
                    driver,
                    id,
                    orders,
                    overallTotals,
                    payment,
                    payment_id,
                    position,
                    user,
                  } = value;
                  return {
                    fullname: user?.[0]?.fullname,
                    orders: orders?.length || 0,
                    date: moment(date, "YYYY/MM/DD").format("DD/MM/YYYY"),
                    action: orders?.length ? (
                      <Button
                        key={id}
                        type="primary"
                        onClick={() => setChosenCashOut(value)}
                      >
                        CashOut
                      </Button>
                    ) : (
                      <></>
                    ),
                  };
                }
              )}
            />
          </Card>
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            block
            onClick={() => navigate("/cash-out/drivers")}
          >
            History
          </Button>
        </Col>
      </Row>
      {chosenCashOut ? (
        <CashOutModal
          data={
            chosenCashOut
            // activeDrivers?.data?.find((d) => d?.id === chosenDriver)
          }
          open={chosenCashOut}
          onCancel={() => setChosenCashOut(null)}
        />
      ) : (
        <></>
      )}
    </>
  );
}

export default Drivers;
