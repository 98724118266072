import {
  LoadingOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Card, Col, Empty, FloatButton, Row, Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { Receipt } from "../../../components/receipt";
import {
  useAcceptOrder,
  useAddOrderPrintRequest,
  useGetAddresses,
  useGetDriverCalculation,
  useGetOrderTemplate,
  useOrderOfDelivery,
} from "../../../zustand/store";
import { CashOutModalBody } from "../../cashOutPage/drivers/cashOutModal";
import { handlePrint } from "../../ordersListPage";
import StopDelivery from "../stopDelivery";
import { Wrapper } from "./styles";
import moment from "moment";
import Report from "./report";
export const addCoords = (name, coords) => {
  if (coords?.lat && coords?.lng) {
    return (
      <a
        href={`https://www.google.com/maps/dir/?api=1&destination=${coords?.lat},${coords?.lng}`}
      >
        {name}
      </a>
    );
  } else {
    return name;
  }
};

export const AddOrderBtn = () => {
  const navigate = useNavigate();
  return (
    <FloatButton
      icon={<PlusOutlined />}
      type="primary"
      onClick={() => navigate("/drivers-page/scan")}
    />
  );
};

function DriverCalculation() {
  const driverCalculation = useGetDriverCalculation();
  useEffect(() => {
    driverCalculation.execute();
  }, []);
  const today = moment().subtract(6, "hours");
  const days = {
    [today.format("DD/MM/YYYY")]: "Today",
    [today.subtract(1, "day").format("DD/MM/YYYY")]: "Yesterday",
  };

  return (
    <Spin spinning={driverCalculation.loading} tip="Loading..." size="large">
      {driverCalculation?.data?.length ? (
        <Tabs
          size="small"
          type="card"
          items={[...(driverCalculation?.data || [])]
            ?.reverse()
            ?.map((data, i) => {
              const date = moment(data?.date, "YYYY/MM/DD").format(
                "DD/MM/YYYY"
              );
              return {
                key: i,
                label: days[date] || date,
                children: <CashOutModalBody data={data} showTip driver />,
              };
            })}
        />
      ) : (
        <>
          <Empty />
        </>
      )}
    </Spin>
  );
}

function ActiveDriver() {
  const navigate = useNavigate();
  const orders = useOrderOfDelivery();
  const addresses = useGetAddresses();
  const menu = useGetOrderTemplate();
  const acceptOrder = useAcceptOrder();
  const addPrintRequest = useAddOrderPrintRequest();

  useEffect(() => {
    orders.execute();
    addresses.execute();
    menu.execute();
  }, []);
  return orders.loading && !orders.data ? (
    <>Loading...</>
  ) : (
    <Wrapper>
      <Tabs
        items={[
          {
            key: "1",
            label: "Active",
            children: orders?.data?.length ? (
              <>
                <Row
                  gutter={[10, 10]}
                  //  bordered
                  //  size="small"
                >
                  {orders?.data?.map(
                    ({
                      addedAt,
                      cashAmount,
                      cash_out,
                      closedAt,
                      created_at,
                      driver,
                      id,
                      isClosed,
                      msgId,
                      orderId,
                      selectedFood,
                      updated_at,
                      userData,
                      accepted_by_driver,
                      order_type,
                      createdAt,
                      totals,
                    }) => {
                      const addressData = userData?.addressData;

                      const { subTotal, tax, deliveryFee, total, serviceFee } =
                        totals || {};

                      return (
                        <Col
                          key={id}
                          span={24}
                          onClick={() =>
                            accepted_by_driver &&
                            navigate(`/drivers-page/order/${id}`)
                          }
                        >
                          <Card
                            size="small"
                            actions={
                              !accepted_by_driver
                                ? [
                                    <div
                                      onClick={() => {
                                        acceptOrder.execute({
                                          data: { orderId: id },
                                        });
                                      }}
                                    >
                                      Accept an order
                                    </div>,
                                  ]
                                : []
                              // .concat(
                              //   addPrintRequest.loading ? (
                              //     <LoadingOutlined />
                              //   ) : (
                              //     <PrinterOutlined
                              //       onClick={() =>
                              //         addPrintRequest.execute({
                              //           params: { orderId: id },
                              //         })
                              //       }
                              //     />
                              //   )
                              // )
                            }
                          >
                            <div>
                              <div>
                                {addCoords(
                                  userData?.address,
                                  addressData?.coords
                                )}{" "}
                                <b>
                                  <u>
                                    {userData?.block || ""}{" "}
                                    {userData?.roomNumber}
                                  </u>
                                </b>
                              </div>
                              <div>
                                DeliveryAndServiceFee: $
                                {deliveryFee + serviceFee}
                              </div>
                              <div>
                                <b>Total: ${total}</b>
                              </div>
                              {userData?.call ? <div>CALL</div> : <></>}
                            </div>
                          </Card>
                        </Col>
                      );
                    }
                  )}
                </Row>
                <AddOrderBtn />
              </>
            ) : (
              <StopDelivery />
            ),
          },
          {
            key: "2",
            label: "Calculation",
            children: <DriverCalculation />,
          },
          {
            key: "3",
            label: "Report",
            children: <Report />,
          },
        ]}
      />
    </Wrapper>
  );
}

export default ActiveDriver;
