import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Row,
  Switch,
  Table,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/container";
import { thousandSeparator } from "../../utils/numberManager";
import {
  useGetRestaurantsList,
  useUpdateRestaurant,
} from "../../zustand/store";

function RestaurantsPage() {
  const restaurants = useGetRestaurantsList();
  const editRestaurant = useUpdateRestaurant();

  const [active, setActive] = useState();
  const columns = [
    // "id",
    "name",
    "limit",
    "status",
    "action",
  ];

  const navigate = useNavigate();

  useEffect(() => {
    restaurants.execute();
    setActive(true);
  }, []);
  return (
    <PageContainer title="Restaurants">
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Card></Card>
        </Col>
        <Col span={24}>
          <Card size="small">
            <Table
              loading={editRestaurant.loading || restaurants.loading}
              size="small"
              pagination={false}
              columns={columns.map((column) => ({
                key: column,
                dataIndex: column,
                title:
                  column === "status" ? (
                    <>
                      <Checkbox
                        onChange={(e) => {
                          setActive(e.target.checked);
                        }}
                        checked={active}
                      >
                        Active
                      </Checkbox>
                    </>
                  ) : (
                    column
                  ),
              }))}
              dataSource={restaurants.data
                ?.filter(({ is_active }) =>
                  active ? is_active && active : true
                )
                ?.map((data) => ({
                  ...data,
                  key: data.id,
                  status: (
                    <>
                      <Switch
                        onChange={(e) => {
                          editRestaurant.execute({
                            params: { id: data?.id },
                            data: { is_active: e },
                          });
                        }}
                        checked={data?.is_active}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </>
                  ),
                  limit: (
                    <div>
                      <div style={{ display: "flex" }}>
                        <div>Single: $</div>
                        <Typography.Text
                          style={{ width: "100px" }}
                          editable={{
                            text: String(data?.serviceFee),
                            onChange: (e) => {
                              const amount = Number(e);
                              if (amount !== data?.serviceFee) {
                                editRestaurant.execute({
                                  params: { id: data?.id },
                                  data: { serviceFee: amount || 0 },
                                });
                              }
                            },
                          }}
                        >
                          {data?.serviceFee}
                        </Typography.Text>
                      </div>
                      <div style={{ display: "flex" }}>
                        <div>Max: $</div>
                        <Typography.Text
                          style={{ width: "100px" }}
                          editable={{
                            text: String(data?.maxServiceFee),
                            onChange: (e) => {
                              const amount = Number(e);
                              if (amount !== data?.maxServiceFee) {
                                editRestaurant.execute({
                                  params: { id: data?.id },
                                  data: { maxServiceFee: amount || 0 },
                                });
                              }
                            },
                          }}
                        >
                          {thousandSeparator(data?.maxServiceFee)}
                        </Typography.Text>
                      </div>
                    </div>
                  ),
                  action: (
                    <>
                      <Button
                        type="primary"
                        onClick={() => {
                          navigate(`/restaurants/${data?.id}`);
                        }}
                      >
                        Visit
                      </Button>
                    </>
                  ),
                }))}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}

export default RestaurantsPage;
