import styled from "styled-components";

export const Wrapper = styled.div`
  width: ${({ fullscreen }) => (fullscreen ? "100vw" : "100%")};
  height: ${({ fullscreen }) => (fullscreen ? "100vh" : "fit-content")};
  position: ${({ fullscreen }) => (fullscreen ? "fixed" : "relative")};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const FullScreen = styled.div`
  /* position: absolute; */
  /* top: 10px;
  right: 10px; */
  /* z-index: 99999; */
  /* background-color: black; */
  display: flex;
  /* width: 100px;
  height: 100px; */
  font-size: 30px;
  background-color: white;
`;
