import { Tabs } from "antd";
import React from "react";
import { PageContainer } from "../../components/container";
import { DRIVER, HOURLY_WORKER } from "../../constants/roles";
import Drivers from "./drivers";
import HourlyWorkers from "./hourlyWorkers";
import AppFee from "./appFee";

function CashOutPage() {
  return (
    <PageContainer title="CashOut">
      <Tabs
        items={[
          {
            key: "1",
            label: DRIVER,
            children: <Drivers />,
          },
          {
            key: "2",
            label: HOURLY_WORKER,
            children: <HourlyWorkers />,
          },
          {
            key: "3",
            label: "App",
            children: <AppFee />,
          },
        ]}
      />
    </PageContainer>
  );
}

export default CashOutPage;
