import { Button, Card, Empty } from "antd";
import React from "react";
import { Text } from "../langManagement";

const EmptyBox = ({ text, create }) => (
  <Card>
    <Empty
      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
      imageStyle={{ height: 60 }}
      description={<span>{text}</span>}
    >
      <Button type="primary" onClick={create}>
        <Text id="createNow" />
      </Button>
    </Empty>
  </Card>
);

export default EmptyBox;
