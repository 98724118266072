import { Alert, Button, Typography } from "antd";
import React from "react";
import { BodyPart, Title, Wrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { useGetMe } from "../../zustand/store";

export function PageContainer({ children, title, button, onClick, rightPart, style }) {
  const navigate = useNavigate();
  const me = useGetMe();
  return (
    <Wrapper style={style}>
      {title || button ? (
        <Title>
          <span>{title}</span>
          {button && (
            <Button onClick={onClick} type="primary">
              {button}
            </Button>
          )}
          {rightPart}
        </Title>
      ) : (
        <></>
      )}

      <BodyPart>
        {me?.data && !me?.data?.restaurant?.sqr_token ? (
          <>
            <Alert
              message={
                <>
                  Square is not connected, Please visit{" "}
                  <Typography.Link onClick={() => navigate("/finance")}>
                    Finance page
                  </Typography.Link>{" "}
                  to connect it
                </>
              }
              type="error"
              showIcon
            />
            <br />
          </>
        ) : (
          <></>
        )}
        {children}
      </BodyPart>
    </Wrapper>
  );
}
