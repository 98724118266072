import { Card, Col, Row, Tag, Typography } from "antd";
import React, { useState } from "react";
import { mainColor } from "../../../components/styles/styles";
import size from "../../../constants/size";
import { thousandSeparator } from "../../../utils/numberManager";
import { useGetMe } from "../../../zustand/store";
import CustomizationModal from "../customizationModal";
import SpecialCustomizationModal from "../specialCustomizationModal";
import { CardWrapper, CardsListWrapper, GridWrapper, Wrapper } from "./styles";
import SubCategoryModal from "./subCategoryModal";

export function SingleItem({
  data,
  setSpecialCustomization,
  setCustomization,
  addFood,
  // menuItems,
  setSubCategory,
  subCategory,
  selectedFood,
  pinned,
}) {
  const {
    size: s,
    category,
    created_at,
    flavorAmount,
    flavors,
    id,
    ingredients,
    name,
    options,
    price,
    type,
    updated_at,
    extra,
    display_type,
    subCategoryId,
    sub_items
  } = data;
  const requiredExtra = data?.extra?.find(({ amount }) => amount > 0);

  const onClick = () => {
    if (options && options?.length) {
      setSpecialCustomization(data);
    } else if (requiredExtra || (flavors?.length > 0 && flavorAmount > 0)) {
      setCustomization(data);
    } else {
      addFood(data);
    }
  };

  const onContextMenu = (e) => {
    e.preventDefault();
    if (options && options?.length) {
      setSpecialCustomization(data);
    } else {
      setCustomization(data);
    }
  };

  if (display_type) {
    // menuItems = menuItems.filter(({ subCategoryId }) => subCategoryId === id);
    if (display_type === "GRID") {
      return (
        <GridWrapper>
          {sub_items?.map((data) => (
            <SingleItem
              data={data}
              setSpecialCustomization={setSpecialCustomization}
              setCustomization={setCustomization}
              addFood={addFood}
              menuItems={sub_items}
              subCategory={subCategory}
              setSubCategory={setSubCategory}
              selectedFood={selectedFood}
            />
          ))}
        </GridWrapper>
      );
    } else if (display_type === "COLLECTED") {
      return (
        <CardWrapper pinned={pinned}>
          <Card
            // style={{ width: "fit-content" }}
            style={{
              border: "1px solid black",
            }}
            size="small"
            bordered
            onClick={() => {
              setSubCategory(sub_items);
            }}
            // onContextMenu={onContextMenu}
          >
            <Row gutter={[5, 5]}>
              <Col
                span={24}
                // style={{ display: "flex", marginBottom: "5px" }}
              >
                <Typography.Text
                  strong
                  style={{
                    marginRight: "10px",
                    fontSize: "20px",
                    lineHeight: 1,
                  }}
                >
                  {name}
                </Typography.Text>
                {s ? (
                  <Tag
                    style={{
                      margin: 0,
                    }}
                    color={mainColor}
                  >
                    {size[s]}
                  </Tag>
                ) : (
                  <></>
                )}
              </Col>
              <Col
              // style={{ display: "flex" }}
              >
                <Row gutter={[10]}>
                  {sub_items?.map(({ price, id }) => (
                    <Col>
                      <Typography.Text
                        strong
                        mark={selectedFood?.some(({ id: fId }) => fId === id)}
                        // type="success"
                        // style={{ color: "lightgreen" }}
                      >
                        ${thousandSeparator(price?.toFixed(2))}
                      </Typography.Text>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </Card>
        </CardWrapper>
      );
    } else {
      return <></>;
    }
  } else {
    return (
      <>
        {/* <CardWrapper pinned={pinned}>
          <Card
            // style={{
            //   backgroundColor: selectedFood?.some(({ id: fId }) => fId === id)
            //     ? "lightgreen"
            //     : undefined,
            // }}
            size="small"
            bordered
            onClick={onClick}
            onContextMenu={onContextMenu}
          >
            <Row gutter={[5, 5]}>
              <Col span={24}>
                <Typography.Text
                  strong
                  style={{
                    marginRight: "10px",
                    fontSize: "20px",
                    lineHeight: 1,
                  }}
                >
                  {name}
                </Typography.Text>
                {s ? (
                  <Tag
                    style={{
                      margin: 0,
                    }}
                    color={mainColor}
                  >
                    {size[s]}
                  </Tag>
                ) : (
                  <></>
                )}
              </Col>
              <Col span={24}>
                <Typography.Text
                  strong
                  mark={selectedFood?.some(({ id: fId }) => fId === id)}
                >
                  ${thousandSeparator(price?.toFixed(2))}
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        </CardWrapper> */}
        <CardWrapper pinned={pinned}>
          <Card
            // style={{
            //   backgroundColor: selectedFood?.some(({ id: fId }) => fId === id)
            //     ? "lightgreen"
            //     : undefined,
            // }}
            style={{
              border: "1px solid black",
            }}
            size="small"
            bordered
            onClick={onClick}
            onContextMenu={onContextMenu}
          >
            <div gutter={[5, 5]}>
              <div style={{ marginBottom: "5px" }} span={24}>
                <Typography.Text
                  strong
                  style={{
                    marginRight: "10px",
                    fontSize: "20px",
                    lineHeight: 1,
                  }}
                >
                  {name}
                </Typography.Text>
                {s ? (
                  <Tag
                    style={{
                      margin: 0,
                    }}
                    color={mainColor}
                  >
                    {size[s]}
                  </Tag>
                ) : (
                  <></>
                )}
              </div>
              <div span={24}>
                <Typography.Text
                  strong
                  mark={selectedFood?.some(({ id: fId }) => fId === id)}
                >
                  ${thousandSeparator(price?.toFixed(2))}
                </Typography.Text>
              </div>
            </div>
          </Card>
        </CardWrapper>
      </>
    );
  }
}

function ItemsOfCategory({
  selectedFood,
  setSelectedFood,
  addFood,
  menuItems,
  lastOne,
  pinned,
}) {
  const [customization, setCustomization] = useState(null);
  const [specialCustomization, setSpecialCustomization] = useState(null);
  const [subCategory, setSubCategory] = useState({ open: false, list: [] });
  const me = useGetMe();
  const user = me.data?.user?.inStore;
  const extra = user?.extra;
  const ots = extra?.ots;

  return (
    <Wrapper lastOne={lastOne}>
      <CardsListWrapper>
        {menuItems
          ?.filter(({ subCategoryId }, i, a) => !subCategoryId)
          ?.map((data, i, a) => {
            return (
              <SingleItem
                selectedFood={selectedFood}
                data={data}
                setSpecialCustomization={setSpecialCustomization}
                setCustomization={setCustomization}
                addFood={addFood}
                menuItems={menuItems}
                subCategory={subCategory}
                setSubCategory={setSubCategory}
                pinned={pinned}
              />
            );
          })}
      </CardsListWrapper>
      <CustomizationModal
        open={Boolean(customization)}
        onCancel={() => setCustomization(null)}
        data={customization}
        selectedFood={selectedFood}
        setSelectedFood={setSelectedFood}
      />
      <SpecialCustomizationModal
        open={Boolean(specialCustomization)}
        data={specialCustomization}
        onCancel={() => setSpecialCustomization(null)}
        onOk={(data) => {
          addFood(data);
          setSpecialCustomization(null);
        }}
      />
      {subCategory?.length ? (
        <SubCategoryModal
          open={subCategory?.length}
          list={subCategory || []}
          onCancel={() => setSubCategory([])}
          setSpecialCustomization={(e) => {
            setSpecialCustomization(e);
            setSubCategory([]);
          }}
          setCustomization={(e) => {
            setCustomization(e);
            setSubCategory([]);
          }}
          addFood={(e) => {
            addFood(e);
            setSubCategory([]);
          }}
          menuItems={menuItems}
          subCategory={subCategory}
          setSubCategory={setSubCategory}
          selectedFood={selectedFood}
        />
      ) : (
        <></>
      )}
    </Wrapper>
  );
}

export default ItemsOfCategory;
