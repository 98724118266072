import {
  ArrowDownOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  EditOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, List, Modal, Row, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { mainColor } from "../../../components/styles/styles";
import { useGetAreas, useGetOrderTemplate } from "../../../zustand/store";
import { AreaListItem } from "../../addressPage";

function SettingModal({
  open,
  onCancel,
  menuType,
  setMenuType,
  checkoutSide,
  setCheckoutSide,
}) {
  const getMenu = useGetOrderTemplate();
  // const areas = useGetAreas();

  const [swipe, setSwipe] = useState("");

  useEffect(() => {
    if (getMenu.data?.length) {
      const s = localStorage.getItem("swipe_selected_food");
      setSwipe(s);
    }
  }, [getMenu.data]);
  return (
    <Modal centered footer closable={false} open={open} onCancel={onCancel}>
      <Tabs
        items={[
          {
            key: "General",
            label: "General",
            children: (
              <div>
                <Row gutter={[10, 10]}>
                  {[
                    {
                      key: "2",
                      type: "group",
                      label: "SWIPE",
                      children: [
                        {
                          key: "2_1",
                          label: (
                            <Row justify={"space-between"}>
                              <Col>
                                <DeleteOutlined />
                              </Col>
                              <Col>
                                <MinusOutlined />
                              </Col>
                              <Col>
                                <EditOutlined />
                              </Col>
                            </Row>
                          ),
                          onClick: () => {
                            localStorage.setItem(
                              "swipe_selected_food",
                              "ld_rs"
                            );
                            setSwipe("ld_rs");
                          },
                          style:
                            swipe === "ld_rs"
                              ? {
                                backgroundColor: "#6045e250",
                                color: mainColor,
                              }
                              : {},
                        },
                        {
                          key: "2_2",
                          label: (
                            <Row justify={"space-between"}>
                              <Col>
                                <EditOutlined />
                              </Col>
                              <Col>
                                <MinusOutlined />
                              </Col>
                              <Col>
                                <DeleteOutlined />
                              </Col>
                            </Row>
                          ),
                          onClick: () => {
                            localStorage.setItem(
                              "swipe_selected_food",
                              "ls_rd"
                            );
                            setSwipe("ls_rd");
                          },
                          style:
                            swipe === "ls_rd"
                              ? {
                                backgroundColor: "#6045e250",
                                color: mainColor,
                              }
                              : {},
                        },
                      ],
                    },
                    {
                      key: "3",
                      type: "group",
                      label: "TYPE",
                      children: [
                        {
                          key: "3_1",
                          label: (
                            <>
                              <ArrowRightOutlined /> Horizontal
                            </>
                          ),
                          onClick: () => {
                            localStorage.setItem("menuType", "horizontal");
                            setMenuType("horizontal");
                          },
                          style:
                            menuType === "horizontal"
                              ? {
                                backgroundColor: "#6045e250",
                                color: mainColor,
                              }
                              : {},
                        },
                        {
                          key: "3_2",
                          label: (
                            <>
                              <ArrowDownOutlined /> Vertical
                            </>
                          ),
                          onClick: () => {
                            localStorage.setItem("menuType", "vertical");
                            setMenuType("vertical");
                          },
                          style:
                            menuType === "vertical"
                              ? {
                                backgroundColor: "#6045e250",
                                color: mainColor,
                              }
                              : {},
                        },
                      ],
                    },
                    {
                      key: "4",
                      type: "group",
                      label: "CHECKOUT",
                      children: [
                        {
                          key: "4_1",
                          label: <>Right</>,
                          onClick: () => {
                            localStorage.setItem("checkoutSide", "right");
                            setCheckoutSide("right");
                          },
                          style:
                            checkoutSide === "right"
                              ? {
                                backgroundColor: "#6045e250",
                                color: mainColor,
                              }
                              : {},
                        },
                        {
                          key: "4_2",
                          label: <>Left</>,
                          onClick: () => {
                            localStorage.setItem("checkoutSide", "left");
                            setCheckoutSide("left");
                          },
                          style:
                            checkoutSide === "left"
                              ? {
                                backgroundColor: "#6045e250",
                                color: mainColor,
                              }
                              : {},
                        },
                      ],
                    },
                  ].map(({ label, onClick, children, style }) =>
                    children ? (
                      <Col span={24}>
                        <Card size="small">
                          <Row gutter={[10, 10]}>
                            <Col span={24}>{label}</Col>
                            {children.map(
                              ({ label, onClick, children, style }) => (
                                <Col>
                                  <Button style={style} onClick={onClick}>
                                    {label}
                                  </Button>
                                </Col>
                              )
                            )}
                          </Row>
                        </Card>
                      </Col>
                    ) : (
                      <Col>
                        <Button style={style} onClick={onClick}>
                          {label}
                        </Button>
                      </Col>
                    )
                  )}
                </Row>
              </div>
            ),
          },
          {
            key: "Area",
            label: "Area",
            children: (
              <AreaListItem bordered size="small" />
            ),
          },
        ]}
      />
    </Modal>
  );
}

export default SettingModal;
