// import { DualAxes } from "@ant-design/plots";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useGetOrdersList } from "../../../../../zustand/store";
import {
  // orderTypeColor,
  orderTypes,
} from "../../../../../constants/orderType";

function OrderByHour({ orders, orderTypeColor }) {
  const orderTypesList = [...Object.keys(orders)].sort();

  const getOrders = useGetOrdersList();
  const [data, setData] = useState([]);
  useEffect(() => {
    setData([]);
    if (getOrders?.data && Object.keys(orders).length) {
      const objList = {};
      const totalObj = {};
      getOrders?.data?.forEach((order) => {
        const addedAt = new Date(order?.addedAt);
        const time = `${addedAt.getHours()}:00`;
        const value = objList[`${time}__${order.order_type}`];
        objList[`${time}__${order.order_type}`] = (value || 0) + 1;
        totalObj[time] = (totalObj[time] || 0) + 1;
      });
      let hours = [
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
      ];

      const dataList = hours.reduce((prev, hour) => {
        const time = `${hour}:00`;
        const total = totalObj[time] || 0;
        // const delivery = objList[`${time}__${orderTypes.DELIVERY}`] || 0;
        // const dineIn = objList[`${time}__${orderTypes.DINE_IN}`] || 0;
        if (prev.length === 0 && !total) {
          return prev;
        } else {
          return [
            ...prev,
            {
              name: time,

              ...orderTypesList.reduce(
                (t, oType) => ({
                  ...t,
                  [oType]: objList[`${time}__${oType}`] || 0,
                }),
                {}
              ),

              // [orderTypes.DELIVERY]: delivery,
              // [orderTypes.DINE_IN]: dineIn,
              [orderTypes.ALL]: total,
            },
          ];
        }
      }, []);
      const lastIndex = dataList.reduce(
        (t, v, i) => (v[orderTypes.ALL] > 0 ? i : t),
        null
      );
      setData(dataList.slice(0, lastIndex + 1));
    }
  }, [getOrders.data, orders]);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {orderTypesList.map((name) => (
          <Bar
            name={name}
            dataKey={name}
            //   barSize={20}
            fill={orderTypeColor[name] || "#6045e2"}
            stackId={"bar"}
          />
        ))}

        {/* <Bar
          name="Dine In"
          dataKey={orderTypes.DINE_IN}
          //   barSize={20}
          fill="#42a5f5"
          stackId={"bar"}
        /> */}
        <Line name="Total" dataKey={orderTypes.ALL} stroke="#e24581" />
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default OrderByHour;
