import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  List,
  Row,
  Tag,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { LangText } from "../../../components/langManagement";
import MapBox from "./mapBox";
import {
  CloseOutlined,
  GlobalOutlined,
  HeatMapOutlined,
  PushpinOutlined,
} from "@ant-design/icons";
import {
  useAddAddress,
  useEditAddress,
  useGetAddresses,
  useGetAreaAndAddresses,
  useGetAreas,
} from "../../../zustand/store";
import { SelectOrEnter } from "../../../components/form";

const BlockBox = () => {
  // const [numberOfFloors, setNumberOfFloors] = useState(1);

  return (
    <List.Item style={{ width: "100%" }}>
      <Row>
        <Col span={24}>
          <Form.Item label="Block 1">
            <Input placeholder="Building name" />
          </Form.Item>
          <Row gutter={[10, 10]}>
            <Col span={8}>
              <Form.Item label="Start">
                <InputNumber min={1} placeholder="Number of floors" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Stop">
                <InputNumber min={1} placeholder="Number of floors" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Exclude">
                <Input placeholder="Number of floors" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          {/* <Row gutter={[0, 5]}>
            {Array(numberOfFloors)
              .fill("")
              .map((v, i) => (
                <Col>
                  <Tag>{i}</Tag>
                </Col>
              ))}
          </Row> */}
        </Col>
      </Row>
    </List.Item>
  );
};

function AddressDrawer({ data, edit, open, onClose }) {
  const [mapOpen, setMapOpen] = useState(false);
  const [markerCoords, setMarkerCoords] = useState({});
  const [blocks, setBlocks] = useState([]);
  const [form] = Form.useForm();
  const [insideArea, setInsideArea] = useState(null);
  const area = useGetAreas();
  const addAddress = useAddAddress();
  const editAddress = useEditAddress();
  const getAddresses = useGetAddresses();
  const areaAndAddress = useGetAreaAndAddresses();
  const id = data?.id;
  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
      const coords = data?.coords;
      setMarkerCoords(coords || {});
      setBlocks(data.blocks || []);
      // setTimeout(() => {
      //   coords && setMapOpen(true);
      // }, 10);
    } else {
      form.setFieldsValue({
        name: "",
      });
      // const coords = data?.coords;
      setMarkerCoords({});
      setBlocks([]);
      // setTimeout(() => {
      //   setMapOpen(true);
      // }, 10);
    }
    form.setFieldValue("deliveryFee", data?.deliveryFee || 5);
    if (open) {
      setTimeout(() => {
        setMapOpen(true);
      }, 10);
    } else {
      setMapOpen(false);
    }
  }, [open]);
  return (
    <Drawer
      width={"50%"}
      destroyOnClose
      open={open}
      onClose={() => {
        setMapOpen(false);
        onClose();
      }}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete={false}
        onFinish={(data) => {
          (edit ? editAddress : addAddress).execute({
            data: {
              ...data,
              coords: mapOpen ? markerCoords : {},
              blocks: blocks ? JSON.stringify(blocks) : null,
              areaId: insideArea?.id || undefined,
            },
            params: { id },
            onSuccess: () => {
              form.resetFields();
              setMarkerCoords({});
              // setMapOpen(false);
              // getAddresses.execute({ force: true });
              areaAndAddress.execute({ force: true });
              area.execute({ force: true });
              if (edit) {
                onClose();
              }
            },
            onError: () => {
              message.error("Something went wrong");
            },
          });
        }}
      >
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={LangText({ id: "address" })}
              required
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={0}>
            <Form.Item label={LangText({ id: " " })}>
              {mapOpen ? (
                <CloseOutlined onClick={() => setMapOpen(false)} />
              ) : (
                <GlobalOutlined onClick={() => setMapOpen(true)} />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          {mapOpen ? (
            <MapBox
              name={form.getFieldValue("name")}
              markerCoords={markerCoords}
              setMarkerCoords={setMarkerCoords}
              insideArea={insideArea}
              setInsideArea={setInsideArea}
              form={form}
            />
          ) : (
            <></>
          )}
        </Form.Item>
        <Form.Item name="blocks" label={LangText({ id: "Blocks" })}>
          <SelectOrEnter
            onChange={(e) => {
              if (e?.label?.trim()?.length) {
                setBlocks((prev) => [
                  ...new Set([
                    ...prev,
                    ...e?.label?.split(",")?.map((v) => v?.trim()),
                  ]),
                ]);
              }
            }}
          />
        </Form.Item>
        <div style={{ marginBottom: "15px" }}>
          {blocks?.map((item, i) => (
            <Tag key={i}>
              <Row gutter={[10]}>
                <Col>{item}</Col>
                <CloseOutlined
                  onClick={(e) => {
                    setBlocks((prev) => prev?.filter((v, j) => i !== j));
                  }}
                />
              </Row>
            </Tag>
          ))}
        </div>

        <Form.Item
          required
          rules={[{ required: true }]}
          name="deliveryFee"
          label={LangText({ id: "deliveryFee" })}
        >
          <InputNumber addonBefore="$" />
        </Form.Item>
        {/* <List>
          <BlockBox />
          <Button>New Building</Button>
        </List> */}
        <Form.Item>
          <Button htmlType="submit">{edit ? "Edit" : "Add"}</Button>
        </Form.Item>
      </Form>
      {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Sint iusto eius
      beatae quibusdam excepturi corrupti illum est quaerat molestias rem magnam
      magni, eaque, aperiam quidem. Hic sequi deserunt commodi laboriosam. */}
    </Drawer>
  );
}

export default AddressDrawer;
