import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  List,
  Popconfirm,
  Row,
  Tag,
  message,
} from "antd";
import L from "leaflet"; // Import Leaflet library
import React, { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
import { calcDist } from "../../utils/calcDist";
import { pointInPolygon } from "../../utils/map";
import {
  useAddAddress,
  useAdvertisingAddress,
  useAdvertisingLogs,
  useGetAddresses,
  useGetAreas,
  useGetMe,
} from "../../zustand/store";
import { getColoredIcon } from "../addressPage";
import CurrentLocation from "./currentLocation";
import { StartWrapper } from "./styles";

function MyComponent({ markerCoords, setMarkerCoords }) {
  // const me = useGetMe();
  const map = useMapEvents({
    click: (res) => {
      map.locate();
      setMarkerCoords(res?.latlng);
    },
  });
  // useEffect(() => {
  //   if (markerCoords && Object.keys(markerCoords).length) {
  //     map?.setView(markerCoords, map?.getZoom(), { animate: true });
  //   }
  //   const restaurant = me?.data?.restaurant;
  //   if (restaurant?.coords && !markerCoords) {
  //     setMarkerCoords(restaurant?.coords);
  //   }
  // }, []);
  return null;
}

function AdvertisersPage() {
  const [form] = Form.useForm();
  const address = useGetAddresses();
  const areas = useGetAreas();
  const addAddress = useAddAddress();
  const advertisingAddress = useAdvertisingAddress();
  const me = useGetMe();
  const [currentCoords, setCurrentCoords] = useState({});
  const [addressList, setAddressList] = useState([]);
  const advertisingLogs = useAdvertisingLogs();
  const [newAddress, setNewAddress] = useState(false);
  const [insideArea, setInsideArea] = useState(null);

  const customIcon = (name, color) => {
    const params = {
      // type: "material",
      // color: color,
      // size: "large",
      // iconType: "awesome",
      // iconSize: "large",
      // scaleFactor: 2,
      // apiKey: "de11088e1852491aa345dd9facc6b61d",

      type: "circle",
      color: color || "#0c0c0c",
      icon: color ? "none" : "circle",
      iconType: "material",
      textSize: "small",
      text: "You",
      scaleFactor: 2,
      apiKey: "de11088e1852491aa345dd9facc6b61d",
    };
    const urlSearchParams = new URLSearchParams(params);
    const url =
      "https://api.geoapify.com/v1/icon/?" + urlSearchParams.toString();

    return L.divIcon({
      // iconUrl: markerIconUrl,
      className: "custom-icon",
      html: `<img style="width:100%;height:100%;" src="https://api.geoapify.com/v1/icon/?${urlSearchParams.toString()}"/>${
        name ? `<div class="box">${name}</div>` : ""
      }`,
      // html: `<img style="width:100%;height:100%;" src="https://api.geoapify.com/v1/icon/?type=circle&color=%23${
      //   color || "0c0c0c"
      // }&icon=circle&iconType=material&textSize=small&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d"/>${
      //   name ? `<div class="box">${name}</div>` : ""
      // }`,
      iconSize: [25, 25], // size of the icon
      iconAnchor: [
        // 12, 25
        12.5, 12.5,
      ], // point of the icon which will correspond to marker's location
      popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
    });
  };
  const getCurrentCoords = () => {
    navigator.geolocation.getCurrentPosition(
      (res) => {
        const lat = res?.coords?.latitude;
        const lng = res?.coords?.longitude;

        setCurrentCoords({ lat, lng });
      },
      (err) => {}
      // options
    );
  };
  const [t, setT] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      getCurrentCoords();
      setT((prev) => prev + 1);
    }, 1000);
  }, [t]);

  useEffect(() => {
    // if (markerCoords && Object.keys(markerCoords).length) {
    //   // setMarkerCoords({ lat, lng });
    //   map?.setView(markerCoords, map?.getZoom(), { animate: true });
    // }
    // const restaurant = me?.data?.restaurant;
    // if (restaurant?.coords) {
    //   setMarkerCoords(restaurant?.coords);
    // }
    address.execute();
    areas.execute();
    advertisingLogs.execute();
    getCurrentCoords();
  }, []);

  useEffect(() => {
    if (Object.keys(currentCoords).length) {
      setAddressList(
        address?.data?.map((v) => ({
          ...v,
          distance: calcDist(
            currentCoords?.lat,
            currentCoords.lng,
            v.coords.lat,
            v.coords.lng
          ).toFixed(1),
        }))
      );
    }
  }, [Object.keys(currentCoords).length, address.data]);

  useEffect(() => {
    const singleArea = areas.data?.find(({ polygon }) =>
      pointInPolygon(currentCoords.lat, currentCoords.lng, polygon)
    );
    setInsideArea(singleArea || null);
    form.setFieldsValue({ deliveryFee: singleArea?.price });
  }, [currentCoords]);

  const makeAsAdvertisingAddress = (addressId) => {
    advertisingAddress.execute({ data: { state: "START", addressId } });
  };

  const startAdvertising = () => {
    advertisingAddress.execute({ data: { state: "START_ADVERTISING" } });
  };

  return !me?.data?.user?.inStore?.startedAdvertisingAt ? (
    <StartWrapper>
      <Button
        type="primary"
        onClick={() => {
          if (me.data?.user?.inStore?.startedDeliveryAt) {
            message.info("Please first stop Delivery");
          } else {
            startAdvertising();
          }
        }}
      >
        Start advertising
      </Button>
    </StartWrapper>
  ) : me?.data?.user?.inStore?.advertisingData?.addressId ? (
    <CurrentLocation />
  ) : (
    <div>
      {Object.keys(currentCoords).length ? (
        <MapContainer
          style={{ width: "100%", height: "200px" }}
          center={currentCoords}
          zoom={18}
          minZoom={0}
          maxZoom={18}
          scrollWheelZoom
        >
          <TileLayer
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker
            position={currentCoords}
            icon={customIcon("", insideArea?.color || null)}
          />
          {areas?.data?.map(({ polygon, color }) => (
            <Polygon positions={polygon} color={color} />
          ))}
          <MyComponent
            markerCoords={currentCoords}
            setMarkerCoords={setCurrentCoords}
          />
        </MapContainer>
      ) : (
        <></>
      )}
      {newAddress ? (
        <Card>
          <Form
            form={form}
            layout="vertical"
            onFinish={(v) => {
              if (Object.keys(currentCoords).length > 0) {
                addAddress.execute({
                  data: {
                    ...v,
                    coords: currentCoords,
                    areaId: insideArea?.id || undefined,
                  },
                  onSuccess: () => {
                    setNewAddress(false);
                  },
                });
              }
            }}
          >
            <Form.Item
              rules={[{ required: true }]}
              label={
                // LangText({ id:
                "Address name"
                //  })
                // ""
              }
              name={"name"}
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[{ required: true }]}
              label={
                // LangText({ id:
                "deliveryFee"
                //  })
                // ""
              }
              name={"deliveryFee"}
            >
              <InputNumber addonBefore="$" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <Row gutter={[10, 10]}>
                <Col span={12}>
                  <Button
                    block
                    onClick={() => {
                      setNewAddress(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col span={12}>
                  <Button block type="primary" htmlType="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      ) : (
        <div
          id="scrollableDiv"
          style={{
            height: "calc(100vh - 270px)",
            overflow: "auto",
            padding: "0 0px",
            border: "1px solid rgba(140, 140, 140, 0.35)",
            backgroundColor: "white",
          }}
        >
          <List size="small">
            <List.Item>
              <Button block onClick={() => setNewAddress(true)}>
                <PlusOutlined /> Add new address
              </Button>
            </List.Item>
            {addressList
              ?.sort((a, b) => a.distance - b.distance)
              ?.map(({ id, name, distance, area }, i) => (
                <List.Item key={i}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        style={{ width: "auto", height: "25px" }}
                        src={getColoredIcon(area?.[0]?.color || "white")}
                        alt=""
                      />{" "}
                      {name} <Tag>{distance} mi</Tag>
                    </div>
                    <div>
                      {advertisingLogs?.data?.find(
                        ({ hotelId }) => hotelId === id
                      ) ? (
                        <Tag color="green" style={{ margin: 0 }}>
                          Done <CheckOutlined />
                        </Tag>
                      ) : (
                        <Button onClick={() => makeAsAdvertisingAddress(id)}>
                          Start
                        </Button>
                      )}
                    </div>
                  </div>
                </List.Item>
              ))}
            <List.Item>
              <Popconfirm
                title="Finish advertising"
                description="Do you really want to finish advertising?"
                onConfirm={() => {
                  advertisingAddress.execute({
                    data: { state: "FINISH_ADVERTISING" },
                  });
                }}
              >
                <Button block type="primary">
                  Finish advertising
                </Button>
              </Popconfirm>
            </List.Item>
          </List>
        </div>
      )}
    </div>
  );
}

export default AdvertisersPage;
