import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const ImgPreview = styled.div`
  height: ${({ small }) => (small ? 60 : 100)}px;
  border-radius: 5px;
  border: ${({ small }) => (small ? 0 : 1)}px solid black;
  padding: ${({ small }) => (small ? 0 : 5)}px;
  position: relative;
  & > img {
    /* width: 100%; */
    height: 100%;
  }
  .deleteImg {
    width: 24px;
    height: 24px;
    background-color: white;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    cursor: pointer;
    &,
    svg {
      display: flex;
    }
    svg {
      width: 100%;
      height: 100%;
      path {
        stroke: red;
      }
    }
  }
`;

export const AddImgBox = styled.label`
  width: fit-content;
  height: 100px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 25px;
  img {
    /* width: 100%; */
    height: 100%;
  }
  input {
    display: none !important;
  }
`;
