import styled from "styled-components";

export const Wrapper = styled.div`
  
`;

export const InputWrapper = styled.div`
  span.ant-input-group-addon {
    background-color: lightgreen;
  }
`;
