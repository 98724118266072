import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Table,
  Tabs,
} from "antd";
import React from "react";
import {
  useGetCashOutDriverList,
  useGetSingleRestaurantUnpaid,
} from "../../../zustand/store";
import { useEffect } from "react";
import SingleCashOut from "../../cashOutDriverPage/singleCashOut";
import {
  DollarOutlined,
  MoneyCollectOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { SquareIcon } from "../../singleOrderOfDriver/styles";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Main from "./main";
import History from "./history";

const columns = [
  // "ID",
  "date",
  "driver",
  "Payment",
];

function AppFee() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const cashout = useGetSingleRestaurantUnpaid();

  const { id } = useParams();
  useEffect(() => {
    cashout.execute({ force: true, params: { id } });
  }, []);
  // const [form] = Form.useForm();

  const [totals, setTotals] = useState({
    sqr: 0,
    cash: 0,
    total: 0,
  });

  useEffect(() => {
    if (cashout.data) {
      const keys = cashout.data?.map(({ id }) => id);
      setSelectedRowKeys(keys);
      const totalData = cashout.data?.reduce(
        (t, v) => {
          const cash =
            t.cash + (keys.includes(v.id) ? v?.overallTotals?.appFee : 0);
          const sqr =
            t.sqr + (keys.includes(v.id) ? v?.overallTotals?.sqrAppFee : 0);

          return {
            sqr,
            cash,
            total: t.total + cash + sqr,
          };
        },
        { sqr: 0, cash: 0, total: 0 }
      );
      setTotals(totalData);
    }
  }, [cashout.data]);

  // const cashout = useGetCashOutDriverList();
  // useEffect(() => {
  //   cashout.execute();
  // }, []);
  // const [form] = Form.useForm();

  // const [totals, setTotals] = useState({
  //   sqr: 0,
  //   cash: 0,
  //   total: 0,
  // });

  // useEffect(() => {
  //   const totalData = cashout.data?.reduce(
  //     (t, v) => {
  //       const cash = t.cash + v?.overallTotals?.appFee;
  //       const sqr = t.sqr + v?.overallTotals?.sqrAppFee;

  //       return {
  //         sqr,
  //         cash,
  //         total: t.total + cash + sqr,
  //       };
  //     },
  //     { sqr: 0, cash: 0, total: 0 }
  //   );
  //   form.setFieldValue("total", totalData?.total);
  //   setTotals(totalData);
  // }, [cashout.data]);

  return (
    <>
      {/* <Row gutter={[10, 10]}>
      <Col span={24}>
        <Card size="small">
          <div
            style={{
              width: "100%",
              height: "100px",
              transform: "scale(2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row gutter={[10, 10]}>
              <Col>
                <DollarOutlined /> {totals?.cash}
              </Col>
              <Col>
                <PlusOutlined />
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <SquareIcon>
                    <div />
                  </SquareIcon>
                  <div>{totals?.sqr}</div>
                </div>
              </Col>
              <Col>=</Col>
              <Col>${totals?.total}</Col>
            </Row>
          </div>
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Table
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <SingleCashOut data={record} />
                </>
              ),
              //   rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            rowSelection={{ selectedRowKeys }}
            // size="small"
            columns={columns.map((column) => ({
              key: column,
              dataIndex: column,
              title: column,
            }))}
            dataSource={cashout.data?.map((v) => ({
              ...v,
              key: v.id,
              driver: v?.user?.[0]?.fullname,
              Payment: (
                <Row gutter={[20, 20]}>
                  <Col>
                    <DollarOutlined /> {v?.overallTotals?.appFee}
                  </Col>
                  <Col>
                    <PlusOutlined />
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <SquareIcon>
                        <div />
                      </SquareIcon>{" "}
                      <div>{v?.overallTotals?.sqrAppFee}</div>
                    </div>
                  </Col>
                  <Col>=</Col>
                  <Col>
                    {v?.overallTotals?.appFee + v?.overallTotals?.sqrAppFee}
                  </Col>
                </Row>
              ),
            }))}
          />
        </Card>
      </Col>
    </Row> */}
      <Tabs
        items={[
          {
            key: "1",
            label: "Main",
            children: <Main />,
          },
          {
            key: "2",
            label: "History",
            children: <History />,
          },
        ]}
      />
    </>
  );
}

export default AppFee;
