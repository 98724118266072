import { Button, Card, Col, Row, Table } from "antd";
import React from "react";
import { useGetSingleRestaurantCashoutHistory } from "../../../../zustand/store";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { DollarOutlined, PlusOutlined } from "@ant-design/icons";
import { thousandSeparator } from "../../../../utils/numberManager";
import { SquareIcon } from "../../../singleOrderOfDriver/styles";

function History() {
  const history = useGetSingleRestaurantCashoutHistory();
  const { id } = useParams();

  const columns = ["ID", "total", "payment", "date", 
  // "action"
];

  const navigate = useNavigate();

  useEffect(() => {
    history.execute({ params: { id }, force: true });
  }, []);
  return (
    <Card size="small">
      <Table
        pagination={false}
        columns={columns?.map((column) => ({
          key: column,
          dataIndex: column,
          title: column,
        }))}
        dataSource={history.data?.map((data) => {
          const totals = data?.overallTotals;
          return {
            ID: <>#{data?.id}</>,
            date: moment(data.createdAt).calendar(),
            payment: (
              <>
                <>${data?.payment[0]?.amount}</>
              </>
            ),
            total: (
              <>
                <div>
                  <Row
                    gutter={[10, 10]}
                    // align={"center"}
                  >
                    <Col>
                      <DollarOutlined /> {thousandSeparator(totals?.cash || 0)}
                    </Col>
                    <Col>
                      <PlusOutlined />
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <SquareIcon>
                          <div />
                        </SquareIcon>
                        <div>{thousandSeparator(totals?.sqr || 0)}</div>
                      </div>
                    </Col>
                    <Col>=</Col>
                    <Col>${thousandSeparator(totals?.total || 0)}</Col>
                  </Row>
                </div>
              </>
            ),
            action: (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    navigate(`/restaurants/${id}/cashout/${data?.id}`);
                  }}
                >
                  Visit
                </Button>
              </>
            ),
          };
        })}
        loading={history.loading}
      />
    </Card>
  );
}

export default History;
