let thousandSeparator = (num) => {
  try {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  } catch (err) {
    return 0;
  }
};

function numberToEmoji(number) {
  const numbers = ["0️⃣", "1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣", "8️⃣", "9️⃣"];
  let result = "";
  while (number > 0) {
      const remainder = number % 10;
      number = parseInt(number / 10);
      result = numbers[remainder] + result;
  }
  return result;
  // numbers[number%10]
}


export { thousandSeparator,numberToEmoji };
