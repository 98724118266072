import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Row,
  Table,
  Tag
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { paymentBtns } from "../../../constants/numbersBtns";
import { thousandSeparator } from "../../../utils/numberManager";
import {
  useSqrPayments,
  useSubmitOrderPayment
} from "../../../zustand/store";
import { AmountDisplay, ModalWrapper } from "./styles";

function PaymentModal({ open, onCancel, data, onSuccess, dineIn, loading }) {
  // const square = useGetDeviceSquare();
  const sqrPayments = useSqrPayments();
  const submit = useSubmitOrderPayment();
  const { orderId } = useParams();
  const total = Number(data?.total);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const sqrTotal = selectedRowKeys.reduce((t, sqrId) => {
    // const sqr = square.data?.find(({ data }) => data?.data?.id === sqrId);
    const sqr = sqrPayments.data?.list?.find(({ data }) => data?.data?.id === sqrId);
    const data = sqr?.data;
    const payment = data?.data?.object?.payment;
    const amount = payment?.amount_money?.amount / 100;
    return Number((t + amount).toFixed(2));
  }, 0);
  const sqrData = sqrPayments.data;
  const count = sqrData?.count;
  const page = sqrData?.page;
  const limit = sqrData?.limit;
  const [amount, setAmount] = useState("0");
  const fetchData = ({ limit = 5, page, force } = {}) => {
    sqrPayments.execute({ force, params: { limit, page, unused: true } })
  };

  useEffect(() => {
    if (open) {
      fetchData();
      // square.execute({ force: true });
      setSelectedRowKeys([]);
      setAmount("0");
    }
  }, [open]);
  return (
    <ModalWrapper
      destroyOnClose
      title=" "
      centered
      open={open}
      onCancel={onCancel}
      okButtonProps={{
        loading,
        disabled:
          !(Number((sqrTotal + amount / 100).toFixed(2)) >= total) || loading,
        // !Number(amount) && !selectedRowKeys.length
      }}
      onOk={() => {
        if (dineIn) {
          onSuccess({
            sqrIdList: selectedRowKeys,
            amount,
          });
        } else {
          submit.execute({
            data: {
              sqrIdList: selectedRowKeys,
              amount,
              orderId,
            },
            onSuccess,
          });
        }
      }}
    >
      {/* <Typography.Title>
        {sqrTotal} {Number((sqrTotal + amount / 100).toFixed(2))}
      </Typography.Title> */}
      <Table
        size="small"
        loading={sqrPayments.loading}
        pagination={{
          current: page,
          pageSize: limit,
          total: count,
          onChange: (page, limit) => {
            fetchData({ page, limit, force: true })
          },
        }}
        rowSelection={{
          selectedRowKeys,
          onChange: (e) => {
            setSelectedRowKeys(e);
          },
        }}
        onRow={(record, index) => ({
          onClick: (e) => {
            setSelectedRowKeys((prev) =>
              prev?.includes(record?.key)
                ? prev.filter((k) => k !== record?.key)
                : [...prev, record?.key]
            );
          },
        })}
        columns={[
          {
            title: (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div>Square</div>
                <div>
                  <ReloadOutlined
                    style={{ fontSize: "25px" }}
                    onClick={() => {
                      // square.execute({ force: true });
                      fetchData({ force: true });
                    }}
                  />
                </div>
              </div>
            ),
            dataIndex: "column",
          },
        ]}
        dataSource={
          // square
          sqrPayments
            ?.data
            ?.list
            ?.map(({ createdAt, data, orderId }) => {
              const payment = data?.data?.object?.payment;
              // return [Markup.button.callback(
              //     `Amount: $${payment?.amount_money?.amount / 100}${payment?.tip_money ? `\nTip: $${payment?.tip_money?.amount / 100}\nTotal: $${payment?.total_money?.amount / 100}` : ""}`
              //     ,
              //     `${callback}|${id}|${orderId}`
              // )]
              if (payment?.amount_money?.amount / 100 === data?.total) {
                setSelectedRowKeys([data?.data?.id]);
              }
              return {
                key: data?.data?.id,
                column: (
                  <div>
                    <Tag>Amount: ${payment?.amount_money?.amount / 100}</Tag>
                    {payment?.tip_money ? (
                      <>
                        <Tag>Tip: ${payment?.tip_money?.amount / 100}</Tag>{" "}
                        <Tag>Total: ${payment?.total_money?.amount / 100}</Tag>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    <div>{dayjs(createdAt).fromNow()} {orderId}</div>
                  </div>
                ),
              };
            }) || []
        }
      />
      <br />
      <AmountDisplay>
        {thousandSeparator(((Number(amount) || 0) / 100).toFixed(2))}
      </AmountDisplay>
      <Row gutter={[10, 10]}>
        {paymentBtns.map(({ label, value }, i) => (
          <Col span={8} key={i + 1}>
            <Button
              style={{ width: "100%", height: "auto", fontSize: "30px" }}
              size="large"
              onClick={() => {
                if (Number(value) || value === "0" || value === "00") {
                  setAmount((prev) => prev + value);
                } else if (value === "backspace") {
                  setAmount((prev) => prev.slice(0, -1));
                }
              }}
            >
              {label}
            </Button>
          </Col>
        ))}
        {!dineIn ? (
          [0, 1, 2].map((times) => {
            const result = Math.ceil(total / 5) * 5 + times * 5;
            return (
              <Col span={8} key={times * 20}>
                <Button
                  style={{ width: "100%", height: "auto", fontSize: "30px" }}
                  size="large"
                  onClick={() => {
                    setAmount(`${result * 100}`);
                  }}
                >
                  {result}.00
                </Button>
              </Col>
            );
          })
        ) : (
          <></>
        )}
        <Col span={24} key={"exact_amount"}>
          <Button
            style={{ width: "100%", height: "auto", fontSize: "30px" }}
            size="large"
            onClick={() => {
              setAmount(`${total * 100}`);
            }}
          >
            {total}
          </Button>
        </Col>
      </Row>
    </ModalWrapper>
  );
}

export default PaymentModal;
