import { PrivateComp } from "../../components/roleBasedAccesses";
import { p } from "../../constants/permissions";
import AddedGoods from "./addedGoods";

function WarehousePage() {
  return (
    <>
      <PrivateComp p={p.GET_GOODS}>
        <AddedGoods />
      </PrivateComp>
    </>
  );
}

export default WarehousePage;
