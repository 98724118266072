import { DollarOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Alert,
  Card,
  Col,
  Form,
  List,
  Popover,
  Row,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useMakeDriverCashOut } from "../../../zustand/store";
import { SquareIcon } from "../../singleOrderOfDriver/styles";
import HideTip from "../hideTip";
import moment from "moment";

function SingleCashOut({ data, showTip }) {
  const makeCashOut = useMakeDriverCashOut();
  const [form] = Form.useForm(null);

  useEffect(() => {
    // getPaymentsOfOrders.execute({
    //   params: { orderIds: JSON.stringify(data?.orders?.map(({ id }) => id)) },
    // });
    makeCashOut.clean();
  }, []);

  const allTotals = data?.orders?.reduce(
    (t, { order }) => {
      const totals = order?.totals || {};
      const { subTotal, tax, deliveryFee, total, serviceFee } = totals;

      return {
        deliveryCharge: Number(
          Number(t.deliveryCharge || 0) + Number(deliveryFee || 0)
        ),
        total: Number(t.total) + Number(total || 0),
      };
    },
    {
      deliveryCharge: 0,
      total: 0,
    }
  );

  const overallTotals = data?.overallTotals;

  useEffect(() => {
    const totalValue = (
      (overallTotals?.total || 0) -
      ((overallTotals?.sqrTip || 0) + (overallTotals?.sqrDeliveryFee || 0))
    )?.toFixed(2);
    form.setFieldValue("total", Number(totalValue));
  }, [overallTotals]);

  return (
    <Card size="small">
      {data?.user?.map(({ fullname }, i) => (
        <Row justify={"space-between"} align={"top"}>
          <Col>
            <Typography.Title level={2} key={i}>
              {fullname} {moment(data?.date, "YYYY/MM/DD").format("DD/MM/YYYY")}
            </Typography.Title>
          </Col>
          <Col>
            <Typography.Text code>
              {moment(data?.createdAt).calendar()}
            </Typography.Text>
          </Col>
        </Row>
      ))}

      {!makeCashOut.data ? (
        <Spin spinning={makeCashOut?.loading} tip="Loading..." size="large">
          <Table
            size="small"
            bordered
            pagination={false}
            columns={[
              { dataIndex: "address", key: "address", title: "Address" },
              {
                dataIndex: "deliveryCharge",
                key: "deliveryCharge",
                title: "DeliveryFee",
              },
              { dataIndex: "total", key: "total", title: "Total" },
              { dataIndex: "payment", key: "payment", title: "Payment" },
            ]}
            dataSource={data?.orders
              ?.map(({ order, payments }) => {
                const totals = order?.totals || {};
                const {
                  // subTotal, tax,
                  deliveryFee,
                  total,
                } = totals;

                const squareTotal =
                  payments
                    ?.filter(({ squareData }) => squareData)
                    ?.reduce(
                      (t, { amount, squareData }) => {
                        const payment = squareData?.data?.data?.object?.payment;
                        return {
                          total:
                            t.total +
                            (payment?.amount_money?.amount || 0) / 100,
                          tip: t.tip + (payment?.tip_money?.amount || 0) / 100,
                        };
                      },
                      { total: 0, tip: 0 }
                    ) || 0;
                const cashTotal =
                  payments
                    ?.filter(({ squareData }) => !squareData)
                    ?.reduce((t, { amount }) => t + amount, 0) || 0;

                return {
                  address: (
                    <>
                      {order?.userData?.address} 
                      {/* {payments?.length}  */}
                      {/* {order?.id} */}
                    </>
                  ),
                  deliveryCharge: <>${deliveryFee}</>,
                  total: <>${total}</>,
                  payment: (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {squareTotal?.total ? (
                        <Popover
                          content={
                            <div>
                              <List>
                                {payments
                                  ?.filter(({ squareData }) => squareData)
                                  ?.map(({ squareData }) => {
                                    const payment =
                                      squareData?.data?.data?.object?.payment;
                                    return (
                                      <List.Item>
                                        <Tag>
                                          Amount: $
                                          {payment?.amount_money?.amount / 100}
                                        </Tag>
                                        {payment?.tip_money ? (
                                          <>
                                            <Tag>
                                              Tip: $
                                              {payment?.tip_money?.amount / 100}
                                            </Tag>{" "}
                                            <Tag>
                                              Total: $
                                              {payment?.total_money?.amount /
                                                100}
                                            </Tag>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </List.Item>
                                    );
                                  })}
                              </List>
                              <Alert
                                type="error"
                                showIcon
                                message={
                                  <>
                                    Something went wrong. <br /> Driver didn't
                                    get exact amount of money from client
                                  </>
                                }
                              ></Alert>
                            </div>
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <SquareIcon>
                              <div />
                            </SquareIcon>{" "}
                            <div>
                              <div>
                                ${squareTotal?.total?.toFixed(2)}{" "}
                                {squareTotal?.total - total !== 0 ? (
                                  <>❌</>
                                ) : (
                                  <></>
                                )}{" "}
                              </div>
                              {squareTotal?.tip ? (
                                <div>tip: ${squareTotal?.tip}</div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </Popover>
                      ) : (
                        <></>
                      )}
                      {squareTotal?.total - total < 0 && cashTotal ? (
                        <>
                          <DollarOutlined />
                          {cashTotal - total >= 0 ? (
                            <div>
                              <div>${total}</div>
                              {cashTotal - total > 0 ? (
                                <div>
                                  tip: $
                                  {showTip
                                    ? (cashTotal - total).toFixed(2)
                                    : HideTip()}
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          ) : (
                            <Popover
                              content={
                                <Alert
                                  type="error"
                                  showIcon
                                  message={
                                    <>
                                      Something went wrong.
                                      <br />
                                      Driver didn't get exact amount of money
                                      from client
                                    </>
                                  }
                                />
                              }
                            >
                              ${cashTotal?.toFixed(2)} ❌
                            </Popover>
                          )}{" "}
                          {/* {cashTotal - total >= 0 ? <>Tip</> : <></>} */}
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ),
                };
              })
              .concat([
                {
                  deliveryCharge: (
                    <Typography.Text strong mark>
                      ${allTotals?.deliveryCharge}
                    </Typography.Text>
                  ),
                  total: (
                    <>
                      <Popover
                        content={
                          <>
                            Delivery charge also included in this total. <br />
                            It is{" "}
                            <Typography.Text mark>
                              ${allTotals?.total - allTotals?.deliveryCharge}
                            </Typography.Text>{" "}
                            without delivery charge
                          </>
                        }
                      >
                        <InfoCircleOutlined />
                      </Popover>{" "}
                      <Typography.Text strong mark>
                        ${allTotals?.total}
                      </Typography.Text>
                    </>
                  ),
                },
              ])}
          />
          <br />
          <div>
            <Typography.Title level={3}>
              Summary{" "}
              <Popover
                content={
                  <>
                    If you see ❌ somewhere above, don't believe in the below
                    calculation
                  </>
                }
              >
                <InfoCircleOutlined />
              </Popover>
            </Typography.Title>
            <Row gutter={[30, 10]}>
              <Col span={12}>
                <Table
                  showHeader={false}
                  bordered
                  title={() => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <SquareIcon>
                          <div />
                        </SquareIcon>{" "}
                        Square
                      </div>
                    </div>
                  )}
                  size="small"
                  pagination={false}
                  columns={[
                    { dataIndex: "key", key: "key", title: "Square" },
                    { dataIndex: "value", key: "value" },
                  ]}
                  dataSource={[
                    {
                      key: "Tip",
                      value: `$${
                        // showTip ? (
                        overallTotals?.sqrTip?.toFixed(2)
                        // ) : (
                        //   <del>0.00</del>
                        // )
                      }`,
                    },
                    {
                      key: "Delivery Fee",
                      value: `$${overallTotals?.sqrDeliveryFee?.toFixed(2)}`,
                    },
                    {
                      key: "Total",
                      value: `$${overallTotals?.sqrTotal?.toFixed(2)}`,
                    },
                    {
                      key: "AppFee",
                      value: `$${overallTotals?.sqrAppFee?.toFixed(2)}`,
                    },
                  ]}
                />
              </Col>
              <Col span={12}>
                <Table
                  showHeader={false}
                  bordered
                  title={() => <div style={{ textAlign: "center" }}>Cash</div>}
                  size="small"
                  pagination={false}
                  columns={[
                    { dataIndex: "key", key: "key", title: "Cash" },
                    { dataIndex: "value", key: "value" },
                  ]}
                  dataSource={[
                    {
                      key: "Tip",
                      value: `$${
                        showTip ? overallTotals.tip.toFixed(2) : HideTip()
                      }`,
                    },
                    {
                      key: "Delivery Fee",
                      value: `$${overallTotals.deliveryFee.toFixed(2)}`,
                    },
                    {
                      key: "Total",
                      value: `$${overallTotals?.total?.toFixed(2)}`,
                    },
                    {
                      key: "AppFee",
                      value: `$${overallTotals?.appFee?.toFixed(2)}`,
                    },
                  ]}
                />
              </Col>
            </Row>
            <br />
            <Alert
              message={
                <>
                  <div>
                    Cash total - (Square Tips + Square Delivery Charges)
                  </div>
                  <div>
                    ${overallTotals?.total?.toFixed(2)} - ($
                    {overallTotals?.sqrTip.toFixed(2)} + $
                    {overallTotals?.sqrDeliveryFee.toFixed(2)}) ={" "}
                    <Typography.Text strong>
                      $
                      {(
                        overallTotals.total -
                        (overallTotals?.sqrTip + overallTotals?.sqrDeliveryFee)
                      ).toFixed(2)}
                    </Typography.Text>
                  </div>

                  <div>
                    You have to get{" "}
                    <Typography.Text strong>
                      $
                      {(
                        overallTotals.total -
                        (overallTotals.sqrTip + overallTotals.sqrDeliveryFee)
                      ).toFixed(2)}
                    </Typography.Text>{" "}
                    from driver
                  </div>
                </>
              }
            />
            <br />
            <Row gutter={[10, 10]}>
              {data?.payment?.map(({ type, amount }, i) => (
                <Col key={i}>
                  <Card size="small">
                    <Tag color="#87d068">{type}</Tag> <br />${amount}
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        </Spin>
      ) : (
        <></>
      )}

      {/* </Table> */}
    </Card>
  );
}

export default SingleCashOut;
