import { ArrowLeftOutlined } from "@ant-design/icons";
import { Alert, Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import { useAssignOrderToDriver } from "../../../../zustand/store";
import { BoxFrame, GoBack, Wrapper } from "./styles";

function QRScanner() {
  const [data, setData] = useState("");
  // const assignOrder = useCheckOrderByQR();
  const assignOrder = useAssignOrderToDriver();
  const msg = assignOrder.data?.msg;
  const status = assignOrder.data?.status;
  const navigate = useNavigate();
  useEffect(() => {
    if (Number(data) && Number(data) > 0) {
      assignOrder.execute({
        params: { id: data },
        onSuccess: (res) => {
          if (res?.data?.status !== "ASSIGNED_SUCCESSFULLY") {
            navigate("/drivers-page");
          }
        },
      });
      // setData("");
    }
  }, [data]);
  return (
    <Wrapper>
      {assignOrder.loading ? (
        <Spin
          spinning={assignOrder.loading}
          style={{ width: "100vw", height: "100vh" }}
        />
      ) : assignOrder.data ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div>
            <Alert
              message={msg}
              type={
                status === "ASSIGNED_TO_ANOTHER_DRIVER" ? "info" : "success"
              }
              showIcon
            />
          </div>
          {status === "ASSIGNED_TO_ANOTHER_DRIVER" ? (
            <div>
              <Button
                type="primary"
                onClick={() => {
                  assignOrder.execute({ params: { id: data, force: true } });
                }}
              >
                Anyway assign this to me
              </Button>
            </div>
          ) : (
            <></>
          )}
          <div>
            <Button
              type="primary"
              onClick={() => {
                assignOrder.clean();
                setData("");
              }}
            >
              Scan another one
            </Button>
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                navigate("/drivers-page");
              }}
            >
              Orders list
            </Button>
          </div>
        </div>
      ) : assignOrder.error ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <div>
            <Alert
              message={
                assignOrder.errorData?.response?.data?.msg ||
                "Something went wrong"
              }
              // description="Something went wrong"
              type="error"
              showIcon
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                // window.location.reload(false);
                assignOrder.clean();
                setData("");
              }}
            >
              Try Again
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "relative",
          }}
        >
          <QrReader
            constraints={{
              facingMode: {
                exact: "environment",
              },
              // facingMode: "rear"
            }}
            videoStyle={{ width: "100%", height: "100%" }}
            videoContainerStyle={{ paddingTop: "100vh" }}
            onResult={(result, error) => {
              if (!!result) {
                setData(result?.text);
              }

              if (!!error) {
                // console.info(error);
              }
            }}
            style={{ height: "100%" }}
          />
          <BoxFrame />
          {/* <QrResult>{data || " "}</QrResult> */}
          <GoBack onClick={() => navigate("/drivers-page")}>
            <ArrowLeftOutlined />
          </GoBack>
        </div>
      )}
    </Wrapper>
  );
}

export default QRScanner;
