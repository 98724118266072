export const dict = {
  logout: "Chiqish",
  statistics: "Statistika",
  back: "orqaga",

  // ORDERS PAGE
  orders: "Buyurtmalar",
  plusOrder: "+ Buyurtma",
  addOrder: "Buyurtma qo'shish",
  inProcess: "Jarayonda",
  closed: "Yopilgan",
  edit: "Tahrirlash",
  closeEdit: "Tahrirlashni yopish",
  client: "Mijoz",
  fullname: "F.I.SH",
  phoneNumber: "Telefon raqam",
  address: "Manzil",
  update: "Yangilash",
  cancel: "Bekor qilish",
  pic: "Rasm",
  name: "Nom",
  price: "Narx",
  status: "Holati",
  created: "Yaratilgan",
  started: "Boshlangan",
  finished: "Tugatilgan",
  installed: "O'rnatilgan",
  done: "Bajarildi",

  description: "Tavsif",
  deadline: "Muddati",
  daysLeft: "kun qoldi",
  daysPassed: "kun o'tdi",
  action: "Amal",

  add: "Qo'shish",
  materials: "Xom ashyo(lar)",
  usedMaterial: "Ishlatilgan xom ashyo",
  amount: "Miqdor",
  addMaterial: "Xom ashyo qo'shish",
  currency: "Valyuta",
  waiting: "Kutilmoqda...",

  salary: "Maosh",
  paySalary: "Maosh to'lash",
  finAccount: "Moliyaviy hisob",

  pay: "To'lash",

  closeOrder: "Buyurtmani yopish",
  next: "Keyingisi",

  addPrice: "Narx qo'shish",

  save: "Saqlash",
  orderDetail: "Buyurtma ma'lumotlari",

  // WAREHOUSE PAGE
  warehouse: "Ombor",
  existInWarehouse: "Omborda mavjud",
  willBeUsedForBelowOrders: "Quyidagi buyurtmalarga ishlatilishi kutilmoqda",
  existsAfterUsing: "Ishlatgandan keyin qoladi",

  measurement: "Birlik",
  category: "Kategoriya",

  submit: "Jo'natish",

  goods: "Yuklar",
  singleGoods: "Yuk",
  addGoods: "Yuk qo'shish",

  staffList: "Ishchilar",
  staff: "Ishchi",
  addStaff: "Ishchi qo'shish",
  plusStaff: "+ Ishchi",
  id: "ID",

  role: "Rol",

  permissions: "Huquqlar",
  editPermissions: "Huquqlarni tahrirlash",
  list: "Ro'yxat",

  telegramChatId: "Telegram chatId",
  paySalaryOf: "Maosh to'lash: ",
  salaryOf: "Maosh: ",
  order: "Buyurtma",
  date: "Sana",

  finance: "Moliya",

  addAccount: "Hisob qo'shish",
  plusAccount: "+ Hisob",

  paymentMethod: "To'lov uslubi",

  more: "Yana",
  INCOME: "KIRIM",
  EXPENSE: "CHIQIM",

  ORDER: "BUYURTMA",
  GOODS: "YUK",
  SALARY: "MAOSH",

  last30Days: "Oxirgi 30 kun",
  thisWeek: "Bu hafta",
  prevWeek: "O'tgan hafta",
  thisMonth: "Bu oy",
  prevMonth: "O'tgan oy",

  stock: "Zaxira",

  materialName: "Xomashyo nomi",
  orderName: "Buyurtma nomi",

  // PLACEHOLDERS
  selectCurrency: "Iltimos valyutani tanlang",
  selectFinAccount: "Iltimos moliyaviy hisobni tanlang",
  selectStaff: "Iltimos ishchini tanlang",
  selectUsedMaterial: "Iltimos ishlatilgan xomashyoni tanlang",

  enterMaterialName: "Iltimos xomashyoni kiriting",
  enterOrderName: "Iltimos buyurtma nomini kiriting",
  enterName: "Iltimos nomni kiriting",
  enterDescription: "Iltimos tavsifni kiriting",
  enterPrice: "Iltimos narxni kiriting",
  enterFullname: "Iltimos F.I.SH-ni kiriting",
  enterPhoneNumber: "Iltimos telefon raqamni kiriting",
  enterAddress: "Iltimos manzilni kiriting",
  enterDeadline: "Iltimos oxirgi sanani kiriting",
  enterAmount: "Iltimos miqdorni kiriting",
  enterTgChatId: "Iltimos Telegram chatIdni kiriting",
  enterCode: "Iltimos Telegram bot bilan tashagan kodni kiriting",

  selectOrEnterCurrency: "Iltimos valyutani tanlang yoki kiriting",
  selectOrEnterPaymentMethod: "Iltimos to'lov usulini tanlang yoki kiriting",
  selectOrEnterCategory: "Iltimos kategoriyani tanlang yoki kiriting",
  selectOrEnterMeasurement: "Iltimos o'lchov birligini tanlang yoki kiriting",

  code: "Kod",

  all:"Hammasi",
  managers:"Menejerlar",
  seeDeleted:"Uchganlarniham kurish",

  products:"Mahsulotlar",

  // NOT FOUNDS
  orderNotFound: "Buyurtma topilmadi",
  createNow: "Yangisini yaratish",
  goodsNotFound: "Yuklar topilmadi",
  accountNotFound: "Hisob topilmadi",
};
