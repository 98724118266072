import styled from "styled-components"

export const Wrapper = styled.div`
  padding: 10px;
`;

export const ListItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const IconBox = styled.div`
  width: 14px;
  height: 14px;
  /* display: inline-block; */
  display: flex;
  margin-right: 10px;
`;

export const SquareIcon = styled.div`
  width: 14px;
  height: 14px;
  border: 3px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  /* display: inline-block; */
  div{
    width: 4px;
    height: 4px;
    background-color: black;
    margin: auto;
  }
`;