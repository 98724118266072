import { Card, Col, List, Row, Tag, Typography } from "antd";
import moment from "moment";
import React from "react";
import { PageContainer } from "../../components/container";
import { useGetMe, useGetUsersRestaurants } from "../../zustand/store";

function MePage() {
  const me = useGetMe();
  const restaurants = useGetUsersRestaurants();
  const user = me.data?.user?.inStore;
  const roles = user?.roles?.roles;
  const restaurant = me.data?.restaurant;
  return (
    <PageContainer title={"Account"}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Card size="small">
            <List>
              <List.Item>
                <Card.Meta
                  title={
                    <>
                      <div>
                        <span style={{ fontSize: "32px" }}>
                          {user?.fullname}
                        </span>{" "}
                        {user?.owner ? (
                          <Typography.Text underline>Owner</Typography.Text>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>{restaurant?.name}</div>
                    </>
                  }
                  description={
                    <>Registered: {moment(user?.created_at).calendar()}</>
                  }
                />
              </List.Item>
              {roles?.length ? (
                <List.Item>
                  <div>
                    {roles?.map((r) => (
                      <Tag>{r}</Tag>
                    ))}
                  </div>
                </List.Item>
              ) : (
                <></>
              )}
              <List.Item>RestaurantID: {restaurant?.id}</List.Item>
              <List.Item>TgChatID: {user?.chatID}</List.Item>
              <List.Item>SquareID: {user?.square_device_id}</List.Item>
            </List>
          </Card>
        </Col>
        {restaurants.data?.map(({ user, restaurant }) => {
          const roles = user?.roles?.roles;
          return (
            <Col span={24}>
              <Card size="small">
                <List>
                  <List.Item>
                    <Card.Meta
                      title={
                        <>
                          <div>
                            <span style={{ fontSize: "32px" }}>
                              {user?.fullname}
                            </span>{" "}
                            {user?.owner ? (
                              <Typography.Text underline>Owner</Typography.Text>
                            ) : (
                              ""
                            )}
                          </div>
                          <div>{restaurant?.name}</div>
                        </>
                      }
                      description={
                        <>Registered: {moment(user?.created_at).calendar()}</>
                      }
                    />
                  </List.Item>
                  {roles?.length ? (
                    <List.Item>
                      <div>
                        {roles?.map((r) => (
                          <Tag>{r}</Tag>
                        ))}
                      </div>
                    </List.Item>
                  ) : (
                    <></>
                  )}
                  <List.Item>RestaurantID: {restaurant?.id}</List.Item>
                  <List.Item>TgChatID: {user?.chatID}</List.Item>
                  <List.Item>SquareID: {user?.square_device_id}</List.Item>
                </List>
              </Card>
            </Col>
          );
        })}
      </Row>
    </PageContainer>
  );
}

export default MePage;
