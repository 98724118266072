import React, { useEffect, useState } from "react";
import { WS_BASE_URL } from "../constants/link";
// import notification from "../assets/audio/new_order_notification.mp3";
import notification from "../assets/audio/quarter-pounder-bell.mp3";
import { useDial, useGetBakerOrders } from "../zustand/store";
import { Alert } from "antd";
const audio = new Audio(notification);

function WS({ baker, orderTaker }) {
  const orders = useGetBakerOrders();
  const dial = useDial();
  const [connected, setConnected] = useState(false);

  const token = localStorage.getItem("hisob_new_token");
  const rId = localStorage.getItem("restaurantId");

  useEffect(() => {
    let socket = new WebSocket(`${WS_BASE_URL}/?token=${token}&rId=${rId}`);

    socket.addEventListener("open", (event) => {
      console.log("Connection Opened");
      setConnected(true);
    });

    socket.addEventListener("message", (event) => {
      if (event.data === "NEW_ORDER" && baker) {
        audio.play();
        orders.execute({ force: true });
      } else if (event.data === "HEARTBEAT") {
      } else {
        if (orderTaker) {
          try {
            const data = JSON.parse(event.data);
            const number = data.number;
            if (data.action === "RINGING") {
              dial.add(data);
            } else if (data.action === "IDLE") {
              dial.remove(number);
            }
          } catch (err) {
            // console.log(err);
          }
        }
      }
    });

    socket.addEventListener("error", (event) => {
      console.error("WebSocket error:", event);
    });

    socket.addEventListener("close", (event) => {
      console.log("Connection Closed");
      setConnected(false);
    });

    return () => {
      socket.close();
    };
  }, []);
  
  return (
    <>
      {!connected && (
        <div
          style={{
            position: "fixed",
            bottom: 10,
            right: 0,
            left: 0,
            width: "fit-content",
            margin: "auto",
          }}
        >
          <Alert
            type="error"
            showIcon
            // message={"Error"}
            message={"WebSocket Disconnected. Please restart page"}
          />
        </div>
      )}
    </>
  );
}

export default WS;
