import styled from "styled-components";

const firstFontSize = "18pt";
const secondFontSize = "17pt";

export const Wrapper = styled.div`
    /* @import url(https://themes.googleusercontent.com/fonts/css?kit=dpiI8CyVsrzWsJLBFKehGpLhv3qFjX7dUn1mYxfCXhI); */

    ul.lst-kix_u605up2ev8lg-0 {
        list-style-type: none;
    }

    .lst-kix_u605up2ev8lg-0 > li:before {
        content: "\0025cf   ";
    }

    .title{
        text-align: center;
        width: 100%;
        font-size: 20pt;
    }

    li.li-bullet-0:before {
        margin-left: -18pt;
        white-space: nowrap;
        display: inline-block;
        /* min-width: 18pt; */
    }

    .c1 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
        height: 11pt;
    }

    .c5 {
        color: #000000;
        font-weight: 400;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Roboto";
        font-style: normal;
    }

    .c0 {
        color: #000000;
        font-weight: 700;
        text-decoration: none;
        vertical-align: baseline;
        font-size: 12pt;
        font-family: "Roboto";
        font-style: normal;
    }

    .c4 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: justify;
        height: 11pt;
    }

    .c3 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: left;
    }

    .c11 {
        padding-top: 0pt;
        padding-bottom: 0pt;
        line-height: 1.15;
        orphans: 2;
        widows: 2;
        text-align: justify;
    }

    .c7 {
        font-size: 12pt;
        font-family: "Roboto";
        font-weight: 700;
    }

    .c9 {
        font-size: 12pt;
        font-family: "Roboto";
        font-weight: 400;
    }

    .c10 {
        background-color: #ffffff;
        /* max-width: 468pt; */
        padding: 72pt 72pt 72pt 72pt;
    }

    .c2 {
        padding: 0;
        margin: 0;
    }

    .c6 {
        margin-left: 36pt;
        padding-left: 0pt;
    }

    .c8 {
        margin-left: 36pt;
    }

    li {
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
    }

    p {
        margin: 0;
        color: #000000;
        font-size: 11pt;
        font-family: "Arial";
    }  
`;
