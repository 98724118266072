import { CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Radio,
  Row,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { LangText, text } from "../../../components/langManagement";
import { crust } from "../../../constants/crust";
import {
  FULL_SIDE,
  LEFT_SIDE,
  NO,
  RIGHT_SIDE,
  sideIcon,
} from "../../../constants/general";
import size from "../../../constants/size";
import { useGetOrderTemplate } from "../../../zustand/store";
import { ModalWrapper } from "./styles";

// const SideBtnIngredients = ({ingredient,setOnly,setNo})=>{
//   return <Button
//   type={
//     no.find((i) => i === ingredient) ? "primary" : "dashed"
//   }
//   danger={no.find((i) => i === ingredient)}
//   onClick={() => {
//     setOnly((prev) => prev.filter((i) => i !== ingredient));
//     if (no.find((i) => i === ingredient)) {
//       setNo((prev) => prev.filter((i) => i !== ingredient));
//     } else {
//       setNo((prev) => [...prev, ingredient]);
//     }
//   }}
// >
//   <CloseOutlined/>
// </Button>
// }

const SideBtn = ({ selectedExtra, setSelectedExtra, name, side, id }) => {
  const toggleExtra = ({ prev }) => {
    const found = prev?.find((p) => name === p?.name && id === p?.id);
    return found
      ? found?.side === side
        ? prev.filter((p) => !(p?.name === found?.name && p?.id === found?.id))
        : prev.map((p) =>
          p?.name === name && id === p?.id ? { ...p, side } : p
        )
      : [...prev, { name, side, id }];
  };

  const isActive = selectedExtra.find(
    (t) => t?.name === name && t?.side === side && (id ? t?.id === id : true)
  );

  return (
    <Button
      size="large"
      danger={side === NO && isActive}
      onClick={() => {
        setSelectedExtra((prev) => toggleExtra({ prev }));
      }}
      type={isActive ? "primary" : "dashed"}
    >
      {sideIcon?.[side]?.(isActive) || <>ADD</>}
    </Button>
  );
};

function CustomizationModal({
  open,
  onCancel,
  data,
  i,
  selectedFood,
  setSelectedFood,
  onOk,
  numberOfTopping,
  specialCustomization,
}) {
  const menu = useGetOrderTemplate();
  const [only, setOnly] = useState([]);
  const [selectedExtra, setSelectedExtra] = useState([]);
  const [selectedFlavors, setSelectedFlavors] = useState([]);
  const [crustS, setCrustS] = useState(crust.constants.HAND_TOSSED);
  const [form] = Form.useForm();
  const categoryList = menu?.data?.reduce(
    (t, { categories }) => t.concat(categories),
    []
  );
  const category = categoryList?.find((c) => c?.id === data?.menuCategoryId);
  const extra = category?.extra;
  const extraList =
    data?.extra?.map(({ id, amount, price }) => {
      id = Number(id);
      const fExtra = extra?.find((c) => c.id === id) || {};
      if (price) {
        fExtra.price = price;
      }
      const selectedAmount =
        selectedExtra?.filter((s) => s?.id === id).length || 0;
      let extraAmount = selectedAmount - amount;
      extraAmount = extraAmount > 0 ? extraAmount : 0;
      const extraPrice = extraAmount * fExtra?.price;
      const calculation = {
        selectedAmount,
        extraAmount,
        extraPrice,
      };
      return { ...fExtra, amount, calculation } || {};
    }) || [];

  const flavors = data?.flavors;

  useEffect(() => {
    setOnly(data?.only || []);
    setSelectedExtra(data?.selectedExtra || []);
    setCrustS(data?.crust || crust.constants.HAND_TOSSED);
    if (i === 0 || Boolean(i)) {
      setSelectedFlavors(
        data?.selectedFlavors || Array(data?.flavorAmount || 0).fill({}) || []
      );
    } else {
      if (data?.flavorAmount) {
        setSelectedFlavors(Array(data?.flavorAmount || 0).fill({}));
      } else {
        setSelectedFlavors([]);
      }
    }
    const desc = data?.desc;
    form.setFieldValue("description", desc || "");
    form.setFieldValue("price", data?.price);
  }, [data]);

  const extraCalc = extraList.reduce(
    (t, { calculation }) => ({
      sAmount: t.sAmount + (calculation?.selectedAmount || 0),
      eAmount: t.eAmount + (calculation?.extraAmount || 0),
      ePrice: t.ePrice + (calculation?.extraPrice || 0),
    }),
    {
      sAmount: 0,
      eAmount: 0,
      ePrice: 0,
    }
  );

  return (
    <ModalWrapper
      open={open}
      onCancel={onCancel}
      destroyOnClose
      onOk={() => {
        // if (data?.name === oneTopping && !(topping?.length > 0)) {
        //   message.error(text({ id: "chooseAtLeastOneTopping" }));
        //   return;
        // } else
        if (
          data.flavors?.length &&
          data.flavorAmount &&
          !(selectedFlavors?.length > 0)
        ) {
          message.error(text({ id: "chooseAtLeastOneFlavors" }));
          return;
        }

        const body = {
          ...data,
          only,
          selectedExtra,
          extraPrice: extraCalc?.ePrice,
          selectedFlavors: selectedFlavors?.filter(
            (f) => Object.keys(f).length > 0
          ),
          crust: crustS,
          price: form.getFieldValue("price") || data?.price,
          desc: form.getFieldValue("description"),
        };

        if (i === 0 || Boolean(i)) {
          setSelectedFood((prev) =>
            prev.map((v, index) => (index === i ? body : v))
          );
        } else {
          if (setSelectedFood) {
            setSelectedFood((prev) => [...prev, { ...body, amount: 1 }]);
          } else if (onOk) {
            onOk({ ...body, amount: 1 });
          }
        }
        onCancel();
      }}
      okButtonProps={{
        // disabled:
        // selectedFlavors.length > 0,
        // selectedFlavors?.find((f) => Object.keys(f).length === 0),
        // !selectedFlavors?.length
        //   ? true
        //   : !selectedFlavors?.find((f) => Object.keys(f).length > 0),
        size: "large",
      }}
      centered
      title={
        <>
          {data?.name}{" "}
          {data?.size ? (
            <Typography.Text keyboard>{size[data?.size]}</Typography.Text>
          ) : (
            <></>
          )}
        </>
      }
    >
      {data?.ingredients?.length ? (
        <>
          <Row gutter={[10, 10]} style={{ marginBottom: "20px" }}>
            <Col span={24} key={"ingredients_title"}>
              <Typography.Text strong>
                <LangText id="Ingredients" />
              </Typography.Text>
            </Col>
            {data?.ingredients?.map((ingredient, i) => (
              <Col span={24} key={i}>
                <Row gutter={10} align={"middle"}>
                  <Col>
                    <SideBtn
                      selectedExtra={only}
                      setSelectedExtra={setOnly}
                      name={ingredient}
                      side={NO}
                    />
                  </Col>
                  {category?.name === "Pizza" ? (
                    <>
                      <Col>
                        <SideBtn
                          selectedExtra={only}
                          setSelectedExtra={setOnly}
                          name={ingredient}
                          side={LEFT_SIDE}
                        />
                      </Col>
                      <Col>
                        <SideBtn
                          selectedExtra={only}
                          setSelectedExtra={setOnly}
                          name={ingredient}
                          side={RIGHT_SIDE}
                        />
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                  <Col>
                    <Typography.Text>{ingredient}</Typography.Text>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <></>
      )}
      {selectedFlavors?.length ? (
        <>
          <Typography.Text strong>
            <LangText id="Flavors" />
          </Typography.Text>
          <List>
            {selectedFlavors?.map((selectedFlavor, fIndex) => (
              <List.Item key={fIndex}>
                <Row gutter={[10, 10]}>
                  {
                    // selectedFlavor?.name ? (
                    //   <Button
                    //     size="large"
                    //     type={"primary"}
                    //     onClick={() => {
                    //       setSelectedFlavors((prev) =>
                    //         prev.map((p, i) => (fIndex === i ? {} : p))
                    //       );
                    //     }}
                    //   >
                    //     {selectedFlavor?.name} <CloseOutlined />
                    //   </Button>
                    // ) : (
                    flavors?.map((flavor, i) => (
                      <Col key={i}>
                        <Button
                          type={
                            selectedFlavor?.name === flavor
                              ? "primary"
                              : "default"
                          }
                          size="large"
                          onClick={() => {
                            setSelectedFlavors((prev) =>
                              prev.map((p, i) =>
                                fIndex === i ? { ...p, name: flavor } : p
                              )
                            );
                          }}
                        >
                          {flavor}
                        </Button>
                      </Col>
                    ))
                    // )
                  }
                </Row>
              </List.Item>
            ))}
          </List>
        </>
      ) : (
        <></>
      )}
      <Row gutter={[10, 10]}>
        {extraList?.map(
          ({ id, name, amount, items, sides, price, calculation }, i) => {
            const { selectedAmount, extraAmount, extraPrice } = calculation;
            return (
              <React.Fragment key={id}>
                <Col span={24}>
                  <Typography.Text strong>
                    <LangText id={name} /> {price}
                  </Typography.Text>
                  {amount ? <div>Free amount: {amount}</div> : <></>}
                  {
                    <div>
                      Extra:{" "}
                      {extraAmount ? (
                        amount ? (
                          <>
                            ({selectedAmount} - {amount})
                          </>
                        ) : (
                          <>{selectedAmount}</>
                        )
                      ) : (
                        0
                      )}{" "}
                      x ${price} = ${extraPrice}
                    </div>
                  }
                </Col>
                <Col key={id} span={24}>
                  <Row gutter={[10, 10]}>
                    {items?.map(({ name }) => (
                      <Col span={24} key={i}>
                        <Row gutter={10} align={"middle"}>
                          {sides ? (
                            sides?.map((side) => (
                              <Col>
                                <SideBtn
                                  selectedExtra={selectedExtra}
                                  setSelectedExtra={setSelectedExtra}
                                  numberOfTopping={numberOfTopping}
                                  name={name}
                                  side={side}
                                  id={id}
                                />
                              </Col>
                            ))
                          ) : (
                            <Col>
                              <SideBtn
                                selectedExtra={selectedExtra}
                                setSelectedExtra={setSelectedExtra}
                                numberOfTopping={numberOfTopping}
                                name={name}
                                side={FULL_SIDE}
                                id={id}
                              />
                            </Col>
                          )}
                          <Col>{name}</Col>
                        </Row>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </React.Fragment>
            );
          }
        )}
      </Row>
      {category?.name === "Pizza" ? (
        <>
          <Divider />
          <Typography.Text strong>
            <LangText id="crust" />
          </Typography.Text>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Radio.Group
                onChange={({ target }) => {
                  setCrustS(target.value);
                }}
                value={crustS}
              >
                <List size="small">
                  <List.Item>
                    <Radio value={crust.constants.BROOKLYN_STYLE}>
                      {crust.list.BROOKLYN_STYLE}
                    </Radio>
                  </List.Item>
                  <List.Item>
                    <Radio value={crust.constants.HAND_TOSSED}>
                      {crust.list.HAND_TOSSED}
                    </Radio>
                  </List.Item>
                  <List.Item>
                    <Radio value={crust.constants.CRUNCHY_THIN_CRUST}>
                      {crust.list.CRUNCHY_THIN_CRUST}
                    </Radio>
                  </List.Item>
                </List>
              </Radio.Group>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      <Divider />
      <Form form={form} layout="vertical">
        {!specialCustomization && (
          <Form.Item
            name={"price"}
            label={LangText({ id: "price" })}
            extra={
              extraCalc?.ePrice > 0 ? (
                <>
                  ${extraCalc?.ePrice} will be added for additional extra
                  {extraCalc.eAmount > 1 ? "s" : ""}
                </>
              ) : (
                <></>
              )
            }
          >
            <InputNumber addonBefore="$" style={{ width: "100%" }} />
          </Form.Item>
        )}
        <Form.Item name={"description"} label={LangText({ id: "description" })}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
}

export default CustomizationModal;
