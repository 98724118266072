import { Card, Col, Row, Table, Tag, Typography } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { thousandSeparator } from "../../../utils/numberManager";
import { useMakeDriverCashOut } from "../../../zustand/store";

function SingleCashOut({ data }) {
  const makeCashOut = useMakeDriverCashOut();
  const hourlyWage = data?.user[0]?.hourlyRate?.[data?.position] || 0;
  const total = data?.activeness?.reduce(
    (t, v) => {
      const { endedAt: e, id, payment_id, startedAt: s, userId } = v;
      const hours = Number((moment(e).diff(moment(s)) / 3_600_000).toFixed(2));
      const wage = hourlyWage;
      return {
        hours: t.hours + hours,
        wage: t.wage + Number((hours * wage).toFixed(2)),
      };
    },
    { hours: 0, wage: 0 }
  );
  useEffect(() => {
    makeCashOut.clean();
  }, []);

  return (
    <Card size="small">
      {data?.user?.map(({ fullname }, i) => (
        <Typography.Title key={i} level={2}>
          {fullname} {data?.position}
        </Typography.Title>
      ))}
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Table
            size="small"
            pagination={false}
            columns={[
              {
                key: "date",
                dataIndex: "date",
                title: "Date",
              },
              {
                key: "startedAt",
                dataIndex: "startedAt",
                title: "Start",
              },
              {
                key: "endedAt",
                dataIndex: "endedAt",
                title: "End",
              },
              {
                key: "hours",
                dataIndex: "hours",
                title: "Hours",
              },
              {
                key: "amount",
                dataIndex: "amount",
                title: "Amount",
              },
            ]}
            dataSource={data?.activeness?.map(
              ({ endedAt, id, payment_id, position, startedAt, userId }) => {
                const hours = (
                  moment(endedAt).diff(moment(startedAt)) / 3_600_000
                ).toFixed(2);
                return {
                  date: <>{moment(startedAt).format("MM/DD")}</>,
                  startedAt: <>{moment(startedAt).format("HH:mm")}</>,
                  endedAt: <>{moment(endedAt).format("HH:mm")}</>,
                  hours,
                  amount: `$${thousandSeparator(
                    ((hours || 0) * (hourlyWage || 0)).toFixed(2)
                  )}`,
                };
              }
            )}
            footer={() => {
              return (
                <>
                  {data?.user?.fullname} spent{" "}
                  <Typography.Text underline>
                    {total.hours} hours
                  </Typography.Text>{" "}
                  on advertising, so you have to pay{" "}
                  <Typography.Text mark strong>
                    ${thousandSeparator(total?.wage?.toFixed(2))}
                  </Typography.Text>
                </>
              );
            }}
          />
        </Col>
        <Col span={24}>
          <Row>
            {data?.payment?.map(({ type, amount }, i) => (
              <Col key={i}>
                <Card size="small">
                  <Tag color="#f50">{type}</Tag> <br />${amount.toFixed(2)}
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default SingleCashOut;
