import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, Input, Row } from "antd";
import { useNavigate } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import {
  useGetMe,
  useLogin,
  useRegisterBakerDevice,
  useTgLogin,
  useVerifyOTP,
} from "../../zustand/store";
import { BakerLoginWrapper } from "./styles";

function BakerLogin() {
  const registerDevice = useRegisterBakerDevice();
  const [form] = Form.useForm();
  const me = useGetMe();

  const navigate = useNavigate();

  return (
    <BakerLoginWrapper>
      <Card size="small">
        <Form
          form={form}
          layout="vertical"
          onFinish={({ code }) => {
            registerDevice.execute({
              params: { code },
              onSuccess: (res) => {
                const token = res?.data?.token;
                if (token) {
                  localStorage.setItem("hisob_new_token", token);
                  navigate("/pizzaiolo72");
                  setTimeout(() => {
                    me.execute();
                  }, 100);
                }
              },
            });
          }}
        >
          <Form.Item
            style={{ marginBottom: 10 }}
            label={<>Baker Device Registration Code</>}
            name={"code"}
            required
            rules={[
              {
                pattern: /^\d{6}$/,
                message: "Please enter exactly 6 numbers",
              },
              { required: true },
            ]}
          >
            <Input placeholder="Code" style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item style={{ margin: 0 }}>
            <Button htmlType="primary" block type="primary">
              Register Baker Device
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </BakerLoginWrapper>
  );
}

function TgLogin() {
  const tgLogin = useTgLogin();
  const navigate = useNavigate();
  const handleTelegramResponse = (data) => {
    tgLogin.execute({
      params: data,
      onSuccess: (res) => {
        const token = res?.data?.token;
        const tokens = res?.data?.tokens;
        const state = res?.data?.state;
        if (token) {
          localStorage.setItem("hisob_token", token);
        }
        if (tokens.length) {
          localStorage.setItem("token_list", JSON.stringify(tokens || []));
        }
        if (state === "REGISTER_RESTAURANT") {
          navigate("/register-restaurant");
        } else if (state === "WELCOME") {
          navigate("/");
        }
      },
    });
  };

  useEffect(() => {
    const paramsString = document.location.search;
    const urlParams = new URLSearchParams(paramsString);

    // Creating an object from the URLSearchParams object
    const paramsObject = {};
    for (const [key, value] of urlParams) {
      paramsObject[key] = value;
    }

    if (paramsObject.id && paramsObject.auth_date && paramsObject.hash) {
      handleTelegramResponse(paramsObject);
    }
  }, []);

  return (
    <>
      <TelegramLoginButton
        dataOnauth={handleTelegramResponse}
        botName="hisob_org_bot"
        requestAccess={"write"}
      />
    </>
  );
}

function PhoneNumberLogin() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [code, setCode] = useState("");

  const navigate = useNavigate();

  const login = useLogin();
  const verifyOTP = useVerifyOTP();

  const token = login?.data?.token;
  const submit = () => {
    console.log("ON FINISH", code, phoneNumber);
    if (token) {
      if (String(code).length === 6) {
        verifyOTP.execute({
          data: {
            code, token
          },
          onSuccess: (res) => {
            const data = res.data;
            const token = data.token;

            localStorage.setItem("hisob_new_token", token);
            const joinData = localStorage.getItem("join_data");
            navigate(joinData ? "/join" : '/');
          },
          onError: (res) => {
            if (res.response?.data !== "OTP_INCORRECT") {
              login.clean();
              setCode("");
            }
          }
        })
      }
    } else {
      if (String(phoneNumber).length === 10) {
        login.execute({
          data: {
            phoneNumber: (`+1${phoneNumber}`)
          }
        });
      }
    }
  }

  return <Card size="small">
    <Form layout="vertical" onFinish={submit}>
      <Form.Item label="Phone Number">
        <Input type="number" addonBefore="+1"
          value={phoneNumber}
          onChange={(e) => {
            let value = e.target.value;
            value = String(value);
            if (value.length <= 10) {
              console.log(value);
              setPhoneNumber(value);
            }
          }} />
      </Form.Item>{token &&
        <Form.Item label={"Code"}>
          <Input type="number"
            value={code}
            onChange={(e) => {
              let value = e.target.value;
              value = String(value);
              if (value.length <= 6) {
                console.log(value);
                setCode(value);
              }
            }}
          />
        </Form.Item>}
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Form.Item style={{ margin: 0 }}>
            <Button block type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Col>
        {token && <Col span={24}>
          <Form.Item style={{ margin: 0 }}>
            <Button block onClick={() => { login.clean(); setCode("") }}>
              Back
            </Button>
          </Form.Item>
        </Col>}
      </Row>
    </Form>
  </Card>
}

function LoginPage() {
  return (
    <div
      style={{
        margin: "auto",
        width: "100%",
        maxWidth: "300px",
        // border: "1px solid black",
        padding: "10px",
      }}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <PhoneNumberLogin />
        </Col>
        {/* <Col span={24}>
          <TgLogin />
        </Col> */}
        <Col span={24}>
          <BakerLogin />
        </Col>
      </Row>
    </div>
  );
}

export default LoginPage;
