import styled from "styled-components";

export const Wrapper = styled.div``;

export const TotalCalculationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: "space-between";
  gap: 10px;
  /* width: 100%; */
`;

export const IncomeExpenseBox = styled.div`
  width: fit-content;
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 16px;
`;
