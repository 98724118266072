import { AndroidOutlined, QrcodeOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  ColorPicker,
  Divider,
  Modal,
  Popconfirm,
  QRCode,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { PageContainer } from "../../../components/container";
import {
  useDeleteTelephony,
  useEditTelephony,
  useGetMe,
  useGetMenuName,
  useGetTelephony,
  useRequestTelephonyCode,
} from "../../../zustand/store";
import { ConnectData, ConnectedPhoneList, Wrapper } from "./styles";

import {
  blue,
  cyan,
  geekblue,
  gold,
  green,
  grey,
  lime,
  magenta,
  orange,
  // green,
  // genPresets,
  presetPalettes,
  purple,
  red,
  // red,
  volcano,
  yellow,
} from "@ant-design/colors";

function Timer({ telephony, remained, setRemained }) {
  const expiresAt = telephony?.expiresAt;
  useEffect(() => {
    setTimeout(() => {
      const currentTime = Date.now();
      setRemained((expiresAt - currentTime) / 1000);
    }, 1000);
  }, [expiresAt, remained]);

  return (
    <div>
      {Math.floor(remained / 60)}:
      {("0" + String(Math.floor(remained % 60))).slice(-2)}
    </div>
  );
}

function CodeGeneration() {
  const me = useGetMe();
  const [remained, setRemained] = useState(null);
  const [telephony, setTelephony] = useState(me.data?.restaurant?.telephony);
  const expiresAt = telephony?.expiresAt;

  const requestCode = useRequestTelephonyCode();
  const getTelephony = useGetTelephony();

  useEffect(() => {
    setTelephony(requestCode.data || me.data?.restaurant?.telephony);
    setTimeout(() => {
      const currentTime = Date.now();
      setRemained((expiresAt - currentTime) / 1000);
    }, 1000);
    getTelephony.execute();
  }, []);
  return (
    <>
      {telephony && remained > 0 && remained !== null ? (
        <div>
          <Typography.Text bold copyable>
            {telephony?.code}
          </Typography.Text>
          <Timer
            telephony={telephony}
            remained={remained}
            setRemained={setRemained}
          />
        </div>
      ) : (
        <>
          <Button
            onClick={() => {
              requestCode.execute({
                onSuccess: (res) => {
                  setTelephony(res.data || me.data?.restaurant?.telephony);

                  const currentTime = Date.now();
                  const expiresAt = res.data?.expiresAt;
                  setRemained((expiresAt - currentTime) / 1000);
                },
              });
            }}
          >
            Request code
          </Button>
        </>
      )}
    </>
  );
}

function AppCard() {

  const link = 
  // "https://drive.google.com/file/d/1tIL6cJ8XSpDEfsYOtaR3zC_UD4JDx3WW/view?usp=sharing";
  "https://drive.google.com/file/d/1hbh3UBKsB8tK3AT0PEvjpCdzBb0R_gdO/view?usp=drivesdk"

  return <>
    <Card
      style={{
        backgroundColor: "lightgreen",
        // cursor: "pointer"
      }}
      size="small"
    >
      <div style={{ fontSize: "30px", textAlign: "center" }}>
        <AndroidOutlined />
        Telephony
      </div>
      <div
        style={{
          // fontSize: "16px", 
          // textAlign: "center",
          width: "fit-content",
          display: "flex", margin: "auto",
          gap: "10px"
        }}
      >

        <Button
          onClick={() => window.open(link, "_blank", "noreferrer")}
          type="primary"
        >
          Download
        </Button>

        <Button style={{ padding: "5px" }}>
          <QrcodeOutlined
            // width={"30px"}
            style={{
              // width: "30px", height: "30px" 
              fontSize: "20px"
            }}
          />
        </Button>
      </div>
    </Card>
    <Modal 
    // open
     footer={false} >
      <div style={{ width: "fit-content", margin: "auto" }}>
        <QRCode size={300}
          value={link}
        />
      </div>
    </Modal>
  </>
}

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));

function TelephonySetting() {
  const getTelephony = useGetTelephony();
  const editTelephony = useEditTelephony();
  const deleteTelephony = useDeleteTelephony();

  const menuName = useGetMenuName();

  useEffect(() => {
    menuName.execute();
  }, []);

  const presets = genPresets({
    red,
    volcano,
    orange,
    gold,
    yellow,
    lime,
    green,
    cyan,
    blue,
    geekblue,
    purple,
    magenta,
    grey,
  });

  const customPanelRender = (_, { components: { Picker, Presets } }) => (
    <Row justify="space-between" wrap={false}>
      <Col span={13}>
        <Presets />
      </Col>
      <Divider
        type="vertical"
        style={{
          height: "auto",
        }}
      />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );

  return (
    <PageContainer title={"Telephony Setting"}>
      <Wrapper>
        <ConnectedPhoneList>
          <Card size="small">
            <Table
              loading={
                getTelephony.loading ||
                deleteTelephony.loading ||
                editTelephony.loading
              }
              pagination={false}
              columns={[
                "id",
                "name",
                "menu",
                "color",
                "created_at",
                "last_notification_at",
                "action",
              ].map((column) => ({
                key: column,
                dataIndex: column,
                title: column,
              }))}
              dataSource={
                getTelephony.data?.map((v) => ({
                  ...v,
                  name: v?.data?.name,
                  menu: (
                    <>
                      <Select
                        value={v?.menuId || "CHOOSE_MENU"}
                        onChange={(menuId) => {
                          editTelephony.execute({
                            params: { id: v.id },
                            data: { menuId },
                          });
                        }}
                        options={[
                          {
                            label: "NO_MENU",
                            value: null,
                          },
                          ...(menuName.data?.map(({ name, id }) => ({
                            label: name,
                            value: id,
                          })) || []),
                        ]}
                      />
                    </>
                  ),
                  color: (
                    <Row gutter={[10, 10]}>
                      <Col>
                        <ColorPicker
                          styles={{
                            popupOverlayInner: {
                              width: 480,
                            },
                          }}
                          panelRender={customPanelRender}
                          value={v.color}
                          onChange={(e) => {
                            const color = e.toHexString();
                            editTelephony.execute({
                              params: { id: v?.id },
                              data: { color },
                            });
                          }}
                          // showText
                          presets={presets}
                        />
                      </Col>
                      <Col>
                        <Button style={{ backgroundColor: v?.color }}>
                          +1 843 446 0396
                        </Button>
                      </Col>
                    </Row>
                  ),
                  created_at: moment(v.created_at).calendar(),
                  last_notification_at: moment(
                    v.last_notification_at
                  ).calendar(),
                  action: (
                    <Popconfirm
                      okText={"Yes"}
                      onConfirm={() => {
                        deleteTelephony.execute({ params: { id: v?.id } });
                      }}
                      description={<>Really want to delete it?</>}
                    >
                      <Button danger>Delete</Button>
                    </Popconfirm>
                  ),
                })) || []
              }
            />
          </Card>
        </ConnectedPhoneList>
        <ConnectData>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <AppCard />
            </Col>
            <Col span={24}>
              <Card size="small">
                <div>
                  <div>1) Download the app by clicking the button above.</div>
                  <div>2) Open the app on your device.</div>
                  <div>
                    3) Click the button below to get a registration code.
                  </div>
                  <div>4) Enter the registration code in the app.</div>
                </div>
                <br />
                <CodeGeneration />

                {/* <Typography.Text>{me.data?.restaurant?.id}</Typography.Text> */}
              </Card>
            </Col>
          </Row>
        </ConnectData>
      </Wrapper>
    </PageContainer>
  );
}

export default TelephonySetting;
