import {
  AppstoreOutlined,
  BorderOutlined
} from "@ant-design/icons";
import { Button, Form, Input, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import {
  useAddMenuSubCategory,
  useEditMenuSubCategory,
  useGetMenuSubCategory,
} from "../../../../zustand/store";
import { ItemCard, ItemsWrapper } from "./styles";

function SubCategory({ open, onCancel,
  // items, 
  edit, data, menuCategoryId }) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [form] = Form.useForm();
  const getSubCategory = useGetMenuSubCategory();
  const items = getSubCategory.data || [];
  const addSubCategory = useAddMenuSubCategory();
  const editSubCategory = useEditMenuSubCategory();

  useEffect(() => {
    if (open) {
      getSubCategory.execute({ force: true, params: { menuCategoryId } })
      if (edit) {
        const { display_type, name, selectedItems: si } = data;
        form.setFieldsValue({
          display_type,
          name,
        });
        setSelectedItems(si);
      } else {
        setSelectedItems([]);
        form.resetFields();
        form.setFieldsValue({ display_type: "GRID" });
      }
    }
  }, [open]);

  return (
    <Modal centered open={open} onCancel={onCancel} title=" " footer={null}>
      <Form
        form={form}
        layout="vertical"
        onFinish={(e) => {
          const name = e.name;
          const display_type = e.display_type;

          (edit ? editSubCategory : addSubCategory).execute({
            params: { id: data?.id },
            data: { name, display_type, selectedItems, menuCategoryId },
            onSuccess: () => {
              onCancel();
            },
          });
        }}
      >
        <Form.Item
          label="Name"
          name={"name"}
          required
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={"display_type"} label={"Display type"}>
          <Radio.Group>
            <Radio value={"GRID"}>
              <AppstoreOutlined /> Grid
            </Radio>

            <Radio value={"COLLECTED"}>
              <BorderOutlined /> Collected
            </Radio>
          </Radio.Group>
          {/* <Switch checkedChildren="Grid" unCheckedChildren="Collected" /> */}
        </Form.Item>
        <Form.Item>
          <ItemsWrapper>
            {(data?.id
              ? items?.filter(
                ({ id, subCategoryId }) =>
                  !subCategoryId || subCategoryId === data?.id
              )
              : items?.filter(({ id, subCategoryId }) => !subCategoryId)
            )?.map(
              ({
                category,
                createdAt,
                createdBy,
                id,
                ingredients,
                name,
                price,
                size: s,
                updatedAt,
                updatedBy,
                subCategoryId,
              }) => (
                <ItemCard
                  active={selectedItems.some((i) => i === id)}
                  onClick={() => {
                    setSelectedItems((prev) =>
                      prev.find((i) => i === id)
                        ? prev.filter((i) => i !== id)
                        : [...prev, id]
                    );
                  }}
                >
                  {name} <br />${price}
                </ItemCard>
              )
            )}
          </ItemsWrapper>
        </Form.Item>
        <Form.Item>
          <Button
            loading={addSubCategory.loading || editSubCategory.loading}
            disabled={selectedItems.length < 2}
            htmlType="submit"
          >
            {edit ? "Edit" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default SubCategory;
