import { CloudUploadOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { AddImgBox, ImgPreview, Wrapper } from "./styles";

import { BASE_URL } from "../../constants/link";

const { Text } = Typography;

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export function AddImage({ small, disabled, fileList, setFileList }) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    // if (!file.url && !file.preview) {
    //   file.preview = await getBase64(file.originFileObj);
    // }
    setPreviewImage(file.url || `${BASE_URL}/files/${file.uuid}`);
    setPreviewOpen(true);
    setPreviewTitle(file.name || "   ");
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );
  return (
    <Wrapper>
      {fileList.map(({ file, base64, uuid }, index) => (
        <ImgPreview small={small} key={index}>
          {!disabled && (
            <div
              className="deleteImg"
              onClick={() =>
                setFileList((prev) => prev.filter((v, i) => i !== index))
              }
            >
              X
            </div>
          )}
          <img
            onClick={() => {
              handlePreview({ url: base64, uuid });
            }}
            src={uuid ? `${BASE_URL}/files/${uuid}` : base64}
            alt=""
          />
        </ImgPreview>
      ))}
      {!disabled && (
        <AddImgBox>
          {/* <img src={uploadImg} alt="" /> */}
          <CloudUploadOutlined height={100} style={{ fontSize: "48px" }} />
          <input
            type="file"
            multiple
            accept="image/*"
            onChange={(e) => {
              const array = e.target.files;
              for (let index = 0; index < array.length; index++) {
                const file = array[index];

                getBase64(file).then((base64) => {
                  setFileList((prev) => [...prev, { file: null, base64 }]);
                });
              }
            }}
          />
        </AddImgBox>
      )}
      <Modal
        open={previewOpen}
        title={previewTitle}
        // title={null}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
            height: "472px",
            objectFit: "contain",
            objectPosition: "center",
          }}
          src={previewImage}
        />
      </Modal>
    </Wrapper>
  );
}

export function SelectOrEnter({
  value,
  options: optionList,
  onChange,
  placeholder,
  disabled,
}) {
  const [options, setOptions] = useState([
    // {
    //   value: "NO_ID",
    //   label: "",
    // },
    ...(optionList || []),
  ]);

  const onSearch = (e) => {
    if (options[0]?.value?.startsWith("NO_ID") && e) {
      setOptions((prev) =>
        prev.map((v, i) =>
          i === 0 ? { label: e || " ", value: "NO_ID|" + value?.label } : v
        )
      );
    } else if (options[0]?.value?.startsWith("NO_ID") && !e) {
      setOptions((prev) => prev.filter((v, i) => i !== 0));
    } else {
      setOptions((prev) => [
        { label: e || " ", value: "NO_ID|" + value?.label },
        ...prev,
      ]);
    }
  };

  useEffect(() => {
    if (
      value?.value?.startsWith("NO_ID") &&
      options?.[0]?.value?.startsWith("NO_ID")
    ) {
      setOptions((prev) =>
        prev.map((v, i) =>
          i === 0
            ? { label: value?.label || " ", value: "NO_ID|" + value?.label }
            : v
        )
      );
    } else if (
      value?.value?.startsWith("NO_ID") &&
      !options?.[0]?.value?.startsWith("NO_ID")
    ) {
      setOptions((prev) => [
        { label: value?.label || " ", value: "NO_ID|" + value?.label },
        ...prev,
      ]);
    }
  }, [value]);

  return (
    <Select
      allowClear
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      value={value?.value}
      onChange={(e) => onChange(options.find(({ value }) => value === e))}
      onSearch={onSearch}
      filterOption={(input, option) => {
        return option?.label?.trim()
          ? (option?.label || "").toLowerCase().includes(input.toLowerCase())
          : false;
      }}
      options={options}
      disabled={disabled}
    />
  );
}
