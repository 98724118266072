import { Modal } from "antd";
import React from "react";
import { SingleItem } from "..";
import { Wrapper } from "./styles";

function SubCategoryModal({
  open,
  onCancel,
  onClick,
  list,
  setSpecialCustomization,
  setCustomization,
  addFood,
  menuItems,
  subCategory,
  setSubCategory,
  selectedFood,
}) {
  return (
    <Modal
      width={680}
      centered
      title=" "
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      <Wrapper>
        {list?.map((data, i, a) => {
          return (
            <SingleItem
              data={data}
              setSpecialCustomization={setSpecialCustomization}
              setCustomization={setCustomization}
              addFood={addFood}
              menuItems={menuItems}
              subCategory={subCategory}
              setSubCategory={setSubCategory}
              selectedFood={selectedFood}
            />
          );
        })}
      </Wrapper>
    </Modal>
  );
}

export default SubCategoryModal;
