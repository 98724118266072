import styled from "styled-components";

export const OrderCardWrapper = styled.div`
  width: 290px;
  @media only screen and (max-width: 1250px) {
    width: calc((100% - 30px) / 4);
  }
  @media only screen and (max-width: 1100px) {
    width: calc((100% - 20px) / 3);
  }
  @media only screen and (max-width: 800px) {
    width: calc(50% - 5px);
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`;
