import { Modal } from "antd";
import styled from "styled-components"

export const ModalWrapper = styled(Modal)`
  @media only screen and (max-width: 580px) {
    max-width: 100vw;
    .ant-modal-content{
        padding: 20px 15px 20px;
    }
  }
`;
