import { Button, Col, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { AmountDisplay, Btn, BtnsWrapper } from "./styles";
import { LangText, text } from "../../../components/langManagement";
import { ArrowLeftOutlined, BackwardOutlined } from "@ant-design/icons";
import btns from "../../../constants/numbersBtns";

function AmountModal({ open, onCancel, data, onOk }) {
  const [amount, setAmount] = useState("");
  useEffect(() => {
    setAmount("");
  }, [data?.id]);
  return (
    <Modal
      centered
      title={LangText({ id: "enterAmount" })}
      open={open}
      onCancel={onCancel}
      //   onOk={}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            size="large"
            danger
            onClick={() =>
              onOk({
                amount: 0,
                id: data?.id,
              })
            }
          >
            Remove
          </Button>
          {/* <Button onClick={onCancel}>Cancel</Button> */}
          <Button
            size="large"
            onClick={() =>
              onOk({
                amount: amount === "" ? data?.amount : Number(amount),
                id: data?.id,
              })
            }
            type="primary"
          >
            Ok
          </Button>
        </div>
      }
    >
      <AmountDisplay>{amount === "" ? data?.amount : amount}</AmountDisplay>
      <Row gutter={[10, 10]}>
        {btns.map(({ label, value }, i) => (
          <Col span={8} key={i}>
            <Button
              style={{ width: "100%", height: "auto", fontSize: "30px" }}
              size="large"
              onClick={() => {
                if (Number(value) || value === "0") {
                  setAmount((prev) => prev + value);
                } else if (value === "backspace") {
                  setAmount((prev) => prev.slice(0, -1));
                } else if (value === ".") {
                  setAmount((prev) =>
                    prev.includes(".")
                      ? prev
                      : (amount.length === 0 ? data.amount : prev) + value
                  );
                }
              }}
            >
              {label}
            </Button>
          </Col>
        ))}
      </Row>
    </Modal>
  );
}

export default AmountModal;
