import { Button, Card, Form, Input, List, Popconfirm, Typography } from "antd";
import React from "react";
import {
  useAddMacAddress,
  useDeleteMacAddress,
  useGetMacAddress,
} from "../../../zustand/store";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useState } from "react";

const DeleteBtn = ({ id }) => {
  const deleteMacAddress = useDeleteMacAddress();
  const [popConfirm, setPopConfirm] = useState(false);
  return (
    <Popconfirm
      open={popConfirm}
      onOpenChange={(e) => setPopConfirm(e)}
      title="Delete"
      description="Do you really want to delete this?"
      okText="Yes"
      okButtonProps={{
        onClick: () => {
          deleteMacAddress.execute({
            params: { id },
            onSuccess: () => setPopConfirm(false),
          });
        },
        loading: deleteMacAddress.loading,
      }}
    >
      <Button danger size="small">
        <DeleteOutlined />
      </Button>
    </Popconfirm>
  );
};

function MacAddress() {
  const [form] = Form.useForm();
  const addMacAddress = useAddMacAddress();
  const getMacAddress = useGetMacAddress();
  const validateMacAddress = (rule, value, callback) => {
    const regex =
      /^(?:[0-9a-fA-F]{2}:){5}[0-9a-fA-F]{2}$|(?:[0-9a-fA-F]{2}\.){2}[0-9a-fA-F]{2}\.[0-9a-fA-F]{2}\.[0-9a-fA-F]{2}$/i;

    if (!regex.test(value)) {
      callback("Invalid MAC address");
    } else {
      callback();
    }
  };
  return (
    <Card size="small">
      <Form
        form={form}
        layout="vertical"
        onFinish={(e) => {
          addMacAddress.execute({ data: e });
          form.resetFields();
        }}
      >
        <Form.Item
          name="address"
          label={
            <div>
              MAC Address <InfoCircleOutlined />
            </div>
          }
          rules={[{ validator: validateMacAddress }]}
        >
          <Input
            type="text"
            //   value={macAddress}
            placeholder="xx:xx:xx:xx:xx:xx"
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit">Add</Button>
        </Form.Item>
      </Form>

      <List
        loading={getMacAddress.loading}
        size="small"
        bordered
        header={<Typography.Text strong>MAC Addresses</Typography.Text>}
      >
        {getMacAddress.data?.map(({ id, address }) => (
          <List.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography.Text>{address}</Typography.Text>
              <DeleteBtn id={id} />
            </div>
          </List.Item>
        ))}
      </List>
    </Card>
  );
}

export default MacAddress;
