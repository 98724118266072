import L from "leaflet"; // Import Leaflet library
import React, { useEffect } from "react";
import {
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  useMap,
} from "react-leaflet";
import { useGetAddresses, useGetAreas, useGetMe } from "../../../zustand/store";
import "./style.css";

// URL to the custom marker icon image
const markerIconUrl =
  "https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png";

// Create a custom icon using the image URL
// const customIcon = L.icon({
const customIcon = (name, color = "#237CC9") => {
  // ""
  // const name = urlSearchParams.get("name");
  const params = {
    type: "material",
    color: color,
    size: "large",
    iconType: "awesome",
    iconSize: "large",
    scaleFactor: 2,
    apiKey: "de11088e1852491aa345dd9facc6b61d",
  };
  const urlSearchParams = new URLSearchParams(params);
  // icon=home&

  //  urlSearchParams.toString();
  // const url = `https://api.geoapify.com/v1/icon/?type=material&color=${color}&size=large&icon=home&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d`;
  const url = "https://api.geoapify.com/v1/icon/?" + urlSearchParams.toString();

  return L.divIcon({
    // iconUrl: markerIconUrl,
    className: "custom-icon",
    // html: `<img style="width:100%;height:100%" src="https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png"/><div class="box">${name}</div>`,
    html: `<img style="width:100%;height:100%" src="${url}"/><div class="box">${name}</div>`,
    iconSize: [25, 41], // size of the icon
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  });
};
const redIcon = new L.Icon({
  iconUrl:
    "https://api.geoapify.com/v1/icon/?type=material&color=red&size=large&icon=home&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d",
  // shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

export function FitBoundsToMarkers({ markers }) {
  const map = useMap();

  useEffect(() => {
    if (markers.length === 0) return;

    const markerCoordinates = markers.map(({ coords: marker }) =>
      L.latLng(marker.lat, marker.lng)
    );
    const bounds = L.latLngBounds(markerCoordinates);

    map.fitBounds(bounds);
  }, [markers, map]);

  return null; // This component doesn't render anything
}

function AddressesInMap() {
  const addresses = useGetAddresses();
  const areas = useGetAreas();
  const me = useGetMe();
  const restaurant = me?.data?.restaurant;
  const addressList =
    (addresses?.data || [])?.concat(restaurant ? [restaurant] : []) || [];
  return (
    <>
      {/* <div>lat: {markerCoords?.lat}</div>
      <div>lng: {markerCoords?.lng}</div> */}
      {/* <div style={{ width: "100%", height: "100%" }}> */}
      {addressList && addressList?.length > 0 ? (
        <MapContainer
          style={{ width: "100%", height: "100%" }}
          zoom={13}
          scrollWheelZoom
        >
          <TileLayer
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {areas?.data?.map(({ polygon, color }) => (
            <Polygon positions={polygon} color={color} />
          ))}

          {addresses?.data?.map(({ name, coords, deliveryFee, area }, i) =>
            coords && Object.keys(coords)?.length ? (
              <Marker
                key={i}
                position={coords}
                icon={customIcon(name, area?.[0]?.color)}
              />
            ) : (
              <></>
            )
          )}
          {restaurant?.coords ? (
            <Marker position={restaurant?.coords} icon={redIcon} />
          ) : (
            <></>
          )}
          <FitBoundsToMarkers markers={addressList} />
        </MapContainer>
      ) : (
        <></>
      )}
      {/* </div> */}
    </>
  );
}

export default AddressesInMap;
