import { Tabs } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  /* background-color: blue; */
  display: flex;
  user-select: none;
  ${({ checkoutSide: side }) =>
    side === "right"
      ? "flex-direction: row;"
      : side === "left"
      ? "flex-direction: row-reverse;"
      : ""}
`;

export const ReceiptPart = styled.div`
  width: 350px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid black;
  /* border-right: 1px solid black; */
  background-color: white;
  @media only screen and (max-width: 850px) {
    transition-duration: 0.5s;
    width: 100%;
    position: fixed;
    top: ${({ open }) => (open ? "0vh" : "100vh")};
    left: 0;
    right: 0;
    border-left: 0px solid black;
  }
`;

export const CartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseCart = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: block;
    padding: 0 10px;
    font-size: 22px;
  }
`;

export const SmallCartBar = styled.div`
  display: none;
  width: 300px;
  position: fixed;
  bottom: 20px;
  top: auto;
  right: 0;
  left: 0;
  background-color: white;
  border: 1px solid black;
  margin: auto;
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-radius: 10px;
  @media only screen and (max-width: 850px) {
    display: flex;
  }
`;

export const MenuPart = styled.div`
  width: calc(100% - 350px);
  @media only screen and (max-width: 850px) {
    width: 100%;
  }
`;

export const ReceiptFooter = styled.div`
  li {
    border-color: black !important;
  }
`;

export const FoodList = styled.div`
  overflow: auto;
`;

export const MenuBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .icon {
    display: none;
  }
  @media only screen and (max-width: 850px) {
    .text {
      display: none;
    }
    .icon {
      display: block;
    }
  }
`;

export const SavedOrdersBtn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .icon {
    display: none;
  }
  @media only screen and (max-width: 850px) {
    .text {
      display: none;
    }
    .icon {
      display: block;
    }
  }
`;

export const SwipeSide = styled.div`
  width: 50px;
  position: absolute;
  ${({ side }) =>
    side === "left" ? "left: -0px;" : side === "right" ? "right: -0px;" : ""};
  opacity: ${({ opacity }) => (opacity ? 1 : 0)};

  top: 0;
  bottom: 0;
  height: 100%;
  display: ${({ visible }) => (visible ? "flex" : "none")};
  justify-content: center;
  transition-duration: 0.5s;
  /* background-color: lightgreen; */
  /* padding: 10px; */
`;

export const CustomTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-tab-active,
  .ant-tabs-tab {
    padding: 0 !important;
    border: 0 !important;
  }
`;

export const CustomMenuTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin: 0;
  }
  /* .ant-tabs-tab{
    color: #000000aa;
  } */
  .ant-tabs-tab-active
  /* ,.ant-tabs-tab  */ {
    /* font-weight: bold; */
    border-top-color: black !important;
    border-right-color: black !important;
    border-left-color: black !important;
    /* border: 1px solid black !important; */
    /* text-decoration: underline; */
    /* padding: 0 !important;
    border: 0 !important; */
  }
`;
