import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  InputNumber,
  List,
  Modal,
  Popover,
  Result,
  Row,
  Spin,
  Table,
  Tag,
  Typography,
} from "antd";
import React, { useEffect } from "react";
import { useMakeDriverCashOut } from "../../../../zustand/store";
// import { thousandSeparator } from "../../../utils/numberManager";
import { DollarOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import HideTip from "../../../cashOutDriverPage/hideTip";
import { SquareIcon } from "../../../singleOrderOfDriver/styles";

export function CashOutModalBody({ data, open, onCancel, showTip, driver }) {
  const orders = data?.orders || [];
  const makeCashOut = useMakeDriverCashOut();
  const [form] = Form.useForm(null);
  const navigate = useNavigate();

  useEffect(() => {
    makeCashOut.clean();
  }, []);

  const allTotals = orders?.reduce(
    (t, { order }) => {
      const totals = order?.totals || {};
      const { subTotal, tax, deliveryFee, total } = totals;

      return {
        deliveryCharge: Number(
          Number(t.deliveryCharge || 0) + Number(deliveryFee || 0)
        ),
        total: Number(t.total || 0) + Number(total || 0),
      };
    },
    {
      deliveryCharge: 0,
      total: 0,
    }
  );
  const overallTotals = orders?.reduce(
    (t, { order, payments }) => {
      const squareTotal =
        payments
          ?.filter(({ squareData }) => squareData)
          ?.reduce(
            (t, { amount, squareData }) => {
              const payment = squareData?.data?.data?.object?.payment;
              return {
                total: t.total + (payment?.amount_money?.amount || 0) / 100,
                tip: t.tip + (payment?.tip_money?.amount || 0) / 100,
                // deliveryFee: 0
              };
            },
            {
              total: 0,
              tip: 0,
              // , deliveryFee: 0
            }
          ) || 0;
      const cashTotal =
        payments
          ?.filter(({ squareData }) => !squareData)
          ?.reduce((t, { amount }) => t + amount, 0) || 0;

      // const delivery = order?.userData?.deliveryFee || 0;
      const totals = order.totals || {};
      const { subTotal, tax, deliveryFee, total, serviceFee = 0 } = totals;
      const bySqr = squareTotal.total >= cashTotal;
      const sqrAppFee = t?.sqrAppFee + (bySqr ? Number(serviceFee) : 0);
      // t?.sqrAppFee + (squareTotal.total ? Number(serviceFee || 0) : 0);
      const appFee = t?.appFee + (!bySqr && cashTotal ? Number(serviceFee) : 0);
      // t?.appFee + (cashTotal ? Number(serviceFee || 0) : 0);

      return {
        sqrTip: t?.sqrTip + (squareTotal.total ? squareTotal?.tip || 0 : 0),
        // .toFixed(2)
        sqrDeliveryFee:
          t?.sqrDeliveryFee +
          (squareTotal.total ? Number(deliveryFee || 0) : 0),
        // .toFixed(2)
        sqrTotal:
          t?.sqrTotal +
          (squareTotal?.total
            ? squareTotal?.total - Number(deliveryFee || 0)
            : 0),
        // .toFixed(2)
        sqrAppFee,
        // t?.sqrAppFee + (squareTotal.total ? Number(serviceFee || 0) : 0),

        tip: t.tip + (cashTotal ? cashTotal - total || 0 : 0),
        // .toFixed(2)
        deliveryFee: t.deliveryFee + (cashTotal ? Number(deliveryFee || 0) : 0),
        // .toFixed(2)
        total:
          t.total +
          (cashTotal ? Number(total || 0) - Number(deliveryFee || 0) : 0),
        // .toFixed(2)
        appFee,
        // : t?.appFee + (cashTotal ? Number(serviceFee || 0) : 0),
      };
    },
    {
      sqrTip: 0,
      sqrDeliveryFee: 0,
      sqrTotal: 0,
      sqrAppFee: 0,

      tip: 0,
      deliveryFee: 0,
      total: 0,
      appFee: 0,
    }
  );

  const driverEarned =
    overallTotals?.sqrTip +
    overallTotals?.sqrDeliveryFee +
    overallTotals?.tip +
    overallTotals?.deliveryFee;

  const cashAmount =
    overallTotals?.total -
    (overallTotals?.sqrTip + overallTotals?.sqrDeliveryFee);

  useEffect(() => {
    const totalValue = cashAmount
      // (overallTotals?.total || 0) - ((overallTotals?.sqrTip || 0) + (overallTotals?.sqrDeliveryFee || 0))
      ?.toFixed(2);
    form.setFieldValue("total", Number(totalValue));
  }, [overallTotals]);

  return (
    <>
      {!driver ? (
        <Typography.Title level={2}>
          {data?.user?.[0]?.fullname}
        </Typography.Title>
      ) : (
        <></>
      )}

      {!makeCashOut.data ? (
        <Spin spinning={makeCashOut?.loading} tip="Loading..." size="large">
          <div style={{ width: "100%", overflow: "auto" }}>
            <div style={{ minWidth: "550px" }}>
              <Table
                size="small"
                bordered
                pagination={false}
                columns={[
                  { dataIndex: "address", key: "address", title: "Address" },
                  {
                    dataIndex: "deliveryCharge",
                    key: "deliveryCharge",
                    title: "DeliveryFee",
                  },
                  { dataIndex: "total", key: "total", title: "Total" },
                  { dataIndex: "payment", key: "payment", title: "Payment" },
                ]}
                dataSource={orders
                  ?.map(({ order, payments }) => {
                    // const payments = getPaymentsOfOrders?.data?.filter(
                    //   ({ orderId }) => orderId === order?.id
                    // );
                    // const delivery = order?.userData?.deliveryFee || 0;
                    const totals = order?.totals || {};
                    const { subTotal, tax, deliveryFee, total } = totals;

                    const squareTotal =
                      payments
                        ?.filter(({ squareData }) => squareData)
                        ?.reduce(
                          (t, { amount, squareData }) => {
                            const payment =
                              squareData?.data?.data?.object?.payment;
                            return {
                              total:
                                t.total +
                                (payment?.amount_money?.amount || 0) / 100,
                              tip:
                                t?.tip +
                                (payment?.tip_money?.amount || 0) / 100,
                            };
                          },
                          { total: 0, tip: 0 }
                        ) || 0;
                    const cashTotal =
                      payments
                        ?.filter(({ squareData }) => !squareData)
                        ?.reduce((t, { amount }) => t + amount, 0) || 0;
                    return {
                      address: (
                        <>
                          {order?.userData?.address}{" "}
                          {order?.userData?.block
                            ? `${order?.userData?.block}-`
                            : ""}
                          {order?.userData?.roomNumber}
                          {/* {payments?.length} {order?.id} */}
                        </>
                      ),
                      deliveryCharge: <>${deliveryFee || 0}</>,
                      total: <>${total || 0}</>,
                      payment: (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          {/* {payments?.length} */}
                          {order?.isCanceled ? (
                            <Typography.Text>
                              <Tag color="red">Canceled</Tag>
                              <>{order.cancellationReason}</>
                            </Typography.Text>
                          ) : (
                            <></>
                          )}
                          {squareTotal?.total ? (
                            <Popover
                              content={
                                <div>
                                  <List>
                                    {payments
                                      ?.filter(({ squareData }) => squareData)
                                      ?.map(({ squareData }, i) => {
                                        const payment =
                                          squareData?.data?.data?.object
                                            ?.payment;
                                        return (
                                          <List.Item key={i}>
                                            <Tag>
                                              Amount: $
                                              {payment?.amount_money?.amount /
                                                100}
                                            </Tag>
                                            {payment?.tip_money ? (
                                              <>
                                                <Tag>
                                                  Tip: $
                                                  {
                                                    // showTip?
                                                    payment?.tip_money?.amount /
                                                      100
                                                    // :"*.**"
                                                  }
                                                </Tag>{" "}
                                                <Tag>
                                                  Total: $
                                                  {payment?.total_money
                                                    ?.amount / 100}
                                                </Tag>{" "}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                          </List.Item>
                                        );
                                      })}
                                  </List>
                                  <Alert
                                    type="error"
                                    showIcon
                                    message={
                                      <>
                                        Something went wrong. <br /> Driver
                                        didn't get exact amount of money from
                                        client
                                      </>
                                    }
                                  ></Alert>
                                </div>
                              }
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <SquareIcon>
                                  <div />
                                </SquareIcon>{" "}
                                <div>
                                  <div>
                                    ${squareTotal?.total?.toFixed(2)}{" "}
                                    {squareTotal?.total - total !== 0 ? (
                                      <>❌</>
                                    ) : (
                                      <></>
                                    )}{" "}
                                  </div>
                                  {squareTotal?.tip ? (
                                    <div>tip: ${squareTotal?.tip}</div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </Popover>
                          ) : (
                            <></>
                          )}
                          {squareTotal?.total - total < 0 && cashTotal ? (
                            <>
                              <DollarOutlined />
                              {cashTotal - total >= 0 ? (
                                <div>
                                  <div>${total}</div>
                                  {cashTotal - total > 0 ? (
                                    <div>
                                      tip: $
                                      {showTip
                                        ? (cashTotal - total).toFixed(2)
                                        : HideTip()}
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              ) : (
                                <Popover
                                  content={
                                    <Alert
                                      type="error"
                                      showIcon
                                      message={
                                        <>
                                          Something went wrong.
                                          <br />
                                          Driver didn't get exact amount of
                                          money from client
                                        </>
                                      }
                                    />
                                  }
                                >
                                  ${cashTotal?.toFixed(2)} ❌
                                </Popover>
                              )}{" "}
                              {/* {cashTotal - total >= 0 ? <>Tip</> : <></>} */}
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ),
                    };
                  })
                  .concat([
                    {
                      deliveryCharge: (
                        <Typography.Text strong mark>
                          ${allTotals?.deliveryCharge}
                        </Typography.Text>
                      ),
                      total: (
                        <>
                          <Popover
                            content={
                              <>
                                Delivery charge also included in this total.{" "}
                                <br />
                                It is{" "}
                                <Typography.Text mark>
                                  $
                                  {(
                                    allTotals?.total - allTotals?.deliveryCharge
                                  ).toFixed(2)}
                                </Typography.Text>{" "}
                                without delivery charge
                              </>
                            }
                          >
                            <InfoCircleOutlined />
                          </Popover>{" "}
                          <Typography.Text strong mark>
                            ${allTotals?.total}
                          </Typography.Text>
                        </>
                      ),
                    },
                  ])}
              />
            </div>
          </div>
          <br />
          <div>
            <Typography.Title level={3}>
              Summary{" "}
              <Popover
                content={
                  <>
                    If you see ❌ somewhere above, don't believe in the below
                    calculation
                  </>
                }
              >
                <InfoCircleOutlined />
              </Popover>
            </Typography.Title>
            <Row gutter={[20, 20]}>
              <Col sm={12} xs={24}>
                <Table
                  borderColor="rgb(0,0,0)"
                  showHeader={false}
                  bordered
                  title={() => (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <SquareIcon>
                          <div />
                        </SquareIcon>{" "}
                        Square
                      </div>
                    </div>
                  )}
                  size="small"
                  pagination={false}
                  columns={[
                    { dataIndex: "key", key: "key", title: "Square" },
                    { dataIndex: "value", key: "value" },
                  ]}
                  dataSource={[
                    {
                      key: "Tip",
                      value: `$${overallTotals?.sqrTip?.toFixed(2)}`,
                    },
                    {
                      key: "Delivery Fee",
                      value: `$${overallTotals?.sqrDeliveryFee?.toFixed(2)}`,
                    },
                    {
                      key: "Total",
                      value: `$${overallTotals?.sqrTotal?.toFixed(2)}`,
                    },
                  ].concat(
                    !showTip
                      ? [
                          {
                            key: "AppFee",
                            value: `$${overallTotals?.sqrAppFee?.toFixed(2)}`,
                          },
                        ]
                      : []
                  )}
                />
              </Col>
              <Col sm={12} xs={24}>
                <Table
                  showHeader={false}
                  bordered
                  title={() => <div style={{ textAlign: "center" }}>Cash</div>}
                  size="small"
                  pagination={false}
                  columns={[
                    { dataIndex: "key", key: "key", title: "Cash" },
                    { dataIndex: "value", key: "value" },
                  ]}
                  dataSource={[
                    {
                      key: "Tip",
                      value: `$${
                        showTip ? overallTotals.tip.toFixed(2) : HideTip()
                      }`,
                    },
                    {
                      key: "Delivery Fee",
                      value: `$${overallTotals.deliveryFee.toFixed(2)}`,
                    },
                    {
                      key: "Total",
                      value: `$${overallTotals?.total?.toFixed(2)}`,
                    },
                  ].concat(
                    !showTip
                      ? [
                          {
                            key: "AppFee",
                            value: `$${overallTotals?.appFee?.toFixed(2)}`,
                          },
                        ]
                      : []
                  )}
                />
              </Col>
            </Row>
            <br />
            <Alert
              message={
                <>
                  <div>
                    Cash total - (Square Tips + Square Delivery Charges)
                  </div>
                  <div>
                    ${overallTotals?.total?.toFixed(2)} - ($
                    {overallTotals?.sqrTip.toFixed(2)} + $
                    {overallTotals.sqrDeliveryFee.toFixed(2)}) ={" "}
                    <Typography.Text strong>
                      $
                      {cashAmount
                        // overallTotals.total - (overallTotals?.sqrTip + overallTotals.sqrDeliveryFee)
                        .toFixed(2)}
                    </Typography.Text>
                  </div>

                  {driver ? (
                    <>
                      <div>
                        You have to give{" "}
                        <Typography.Text strong>
                          $
                          {cashAmount
                            // overallTotals?.total - (overallTotals?.sqrTip + overallTotals?.sqrDeliveryFee)
                            .toFixed(2)}
                        </Typography.Text>{" "}
                        in cash out
                      </div>
                      <Divider />
                      <div>
                        (Square Tips + Square Delivery Charges) + (Tips +
                        Delivery Charges)
                      </div>
                      <div>
                        (${overallTotals?.sqrTip.toFixed(2)} + $
                        {overallTotals?.sqrDeliveryFee.toFixed(2)}) + ($
                        {overallTotals?.tip.toFixed(2)} + $
                        {overallTotals?.deliveryFee.toFixed(2)}) ={" "}
                        <Typography.Text strong>
                          ${driverEarned.toFixed(2)}
                        </Typography.Text>
                      </div>
                      <div>
                        You earned{" "}
                        <Typography.Title strong>
                          ${driverEarned.toFixed(2)}
                        </Typography.Title>
                      </div>
                    </>
                  ) : (
                    <div>
                      You have to get{" "}
                      <Typography.Text strong>
                        $
                        {cashAmount
                          // overallTotals?.total - (overallTotals?.sqrTip + overallTotals?.sqrDeliveryFee)
                          .toFixed(2)}
                      </Typography.Text>{" "}
                      from driver
                    </div>
                  )}
                </>
              }
            />
            {driver ? (
              <></>
            ) : (
              <>
                <br />
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={(e) => {
                    const formData = {
                      total: e.total,
                      overallTotals,
                      driver: data?.user?.[0]?.id,
                      // orders: orders?.map(({ order: { id } }) => id),
                      cashOutId: data?.id,
                    };
                    makeCashOut.execute({ data: formData });
                  }}
                >
                  <Form.Item label="Total" name={"total"}>
                    <InputNumber addonBefore={"$"} />
                  </Form.Item>
                  <Form.Item style={{ margin: 0 }}>
                    <Button
                      type="primary"
                      block
                      htmlType="submit"
                      loading={makeCashOut.loading}
                      disabled={
                        !orders?.length ||
                        orders?.length !==
                          orders?.filter(({ payments,order }) => payments?.length||order.isCanceled)
                            ?.length
                      }
                    >
                      Cash Out
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </div>
        </Spin>
      ) : (
        <Result
          status="success"
          title="Successfully Cashed Out"
          subTitle={
            <>
              Successfully cashed out.
              {/* You can check it in Cash Out page */}
            </>
          }
          extra={[
            // <Button
            //   type="primary"
            //   key="console"
            //   onClick={() => {
            //     navigate(`/cash-out/list/${makeCashOut?.data?.id}`);
            //   }}
            // >
            //   Visit
            // </Button>,
            <Button key="buy" onClick={() => onCancel?.()}>
              Close
            </Button>,
          ]}
        />
      )}
    </>
  );
}

function CashOutModal({ data, open, onCancel, showTip }) {
  return (
    <Modal
      centered
      width={800}
      open={open}
      title="Statistics"
      onCancel={onCancel}
      footer={null}
    >
      <CashOutModalBody data={data} showTip={showTip} onCancel={onCancel} />
    </Modal>
  );
}

export default CashOutModal;
