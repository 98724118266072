import { CloseOutlined, ReloadOutlined } from "@ant-design/icons";
import { Drawer, Space } from "antd";
import React from "react";
import OrdersListPage from "..";

function OrdersDrawer({ open, onClose, edit, refresh }) {
  // const innerWidth = useCallback(() => window.innerWidth, [open]);

  return (
    <Drawer
      width={"100%"}
      // destroyOnClose
      size="large"
      placement="left"
      open={open}
      onClose={onClose}
      // closeIcon={null}
      extra={
        <div style={{ display: "flex", gap: "15px", fontSize: "20px" }}>
          <div>
            <ReloadOutlined onClick={refresh} />
          </div>
          <div>
            <CloseOutlined onClick={onClose} />
          </div>
        </div>
      }
    >
      <OrdersListPage edit={edit} open={open} />
    </Drawer>
  );
}

export default OrdersDrawer;
