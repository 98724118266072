import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const SingleWrapper = styled.div`
  width: calc((100% - 10px) / 2);
`;
