import {
  Button,
  Card,
  Col,
  List,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { orderTypeColor } from "../../constants/orderType";
import WebSocket from "../../utils/ws";
import {
  useGetBakerOrders,
  useGetBakerOrdersHistory,
  useGetMe,
  useOrderServed,
} from "../../zustand/store";
import { ListItem } from "../makeOrderPage";
import BakerHistory from "./bakerHistory";
import {
  CardsCol,
  CardsRow,
  CardsWrapper,
  HistoryWrapper,
  Wrapper,
} from "./styles";
import { Seconds, Timer } from "./timer";
import {
  NEXT_HISTORY,
  NEXT_ORDER,
  PREV_HISTORY,
  PREV_ORDER,
  // REFRESH_HISTORY,
  REFRESH_ORDERS,
  REFRESH_PAGE,
  SERVE,
  ZOOM,
} from "../../utils/kbd";

export const SingleOrderCard = ({
  selectedFood,
  id,
  addedAt,
  order_type,
  // selectedOrder,
  // index,
  isSelected,
  historyCard,
}) => {
  const orderServed = useOrderServed();
  const bakerOrders = useGetBakerOrders();

  return (
    <Card
      loading={
        isSelected &&
        (orderServed.loading || bakerOrders.loading) &&
        !historyCard
      }
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* <div>{moment(addedAt).calendar()}</div> */}
          <Tag color={orderTypeColor[order_type] || ""}>{order_type}</Tag>

          <Seconds date={addedAt} />
        </div>
      }
      size="small"
      style={{
        backgroundColor: isSelected
          ? "rgba(144, 238, 144, 0.6)"
          : historyCard
          ? "#e8e8e8"
          : "",
      }}
      bodyStyle={{
        paddingTop: "0",
        paddingBottom: "0",
      }}
    >
      <List size="small">
        {selectedFood?.map((data, i) => (
          <ListItem key={i} data={data} inner readMode />
        ))}
      </List>
    </Card>
  );
};

function BakerPage() {
  const bakerOrder = useGetBakerOrders();
  const me = useGetMe();
  const kbd = me.data?.restaurant?.bakerSetting?.kbd;

  const orderServed = useOrderServed();
  const [history, setHistory] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const historyList = useGetBakerOrdersHistory();

  const baked = () => {
    const id = bakerOrder?.data?.[selectedOrder]?.id;
    if (id) {
      orderServed.execute({
        data: { id },
        onSuccess: () => {
          historyList.execute({ force: true });
        },
      });
    }
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (!e.repeat) {
        const checkKey = (action) => action === kbd[e.key];

        if (checkKey(NEXT_ORDER)) {
          const bMax = bakerOrder?.data?.length;
          setSelectedOrder((prev) => (prev + 1 < bMax ? prev + 1 : prev));
        } else if (checkKey(PREV_ORDER)) {
          setSelectedOrder((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
        } else if (checkKey(NEXT_HISTORY)) {
          const hMax = historyList?.data?.length;
          setHistoryIndex((prev) => (prev + 1 < hMax ? prev + 1 : prev));
        } else if (checkKey(PREV_HISTORY)) {
          setHistoryIndex((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
        } else if (checkKey(REFRESH_ORDERS)) {
          bakerOrder.execute({ force: true });
        } else if (checkKey(ZOOM)) {

        // } else if (checkKey(REFRESH_HISTORY)) {
        //   historyList.execute({ force: true });
        } else if (checkKey(REFRESH_PAGE)) {
          window.location.reload();
          historyList.execute({ force: true });
        } else if (
          !orderServed.loading &&
          !bakerOrder.loading &&
          checkKey(SERVE)
        ) {
          baked();
        }
      }
    },
    [bakerOrder?.data, selectedOrder]
  );

  useEffect(() => {
    // Add an event listener for the `keydown` event.
    document.addEventListener("keydown", handleKeyDown, true);
    // Return a function to remove the event listener when the component unmounts.
    return () => document.removeEventListener("keydown", handleKeyDown, true);
  }, [handleKeyDown]);
  // const [t, setT] = useState(0);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setT((prev) => prev + 1);
  //     bakerOrder.execute({ force: true });
  //   }, 15 * 1000);
  // }, [t]);

  useEffect(() => {
    bakerOrder.execute();
  }, []);

  useEffect(() => {
    document.querySelector(`.order_card_${selectedOrder}`)?.scrollIntoView({behavior:"smooth"});
  }, [selectedOrder]);
  useEffect(() => {
    document.querySelector(`.history_card_${historyIndex}`)?.scrollIntoView({behavior:"smooth"});
  }, [historyIndex]);

  return (
    <PageContainer
      title={
        <>
          <div>Pizzaiolo</div>
          {/* <Timer /> */}
        </>
      }
      rightPart={
        <>
          <Timer />
          {/* <Row gutter={[10, 10]}>
            <Col>
              <Button onClick={() => bakerOrder.execute({ force: true })}>
                Refresh
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => setHistory(true)}>
                History
              </Button>
            </Col>
          </Row> */}
        </>
      }
      // button={"Refresh"}
      // onClick={() => bakerOrder.execute()}
    >
      <Wrapper>
        <CardsWrapper>
          <Spin
            spinning={
              // bakerOrder.loading ||
              orderServed.loading
            }
            tip="Loading..."
            size="large"
            // style={{minHeight:"100%"}}
          >
            <CardsRow gutter={[10, 10]}>
              {bakerOrder?.data?.map((v, index) => (
                <>
                  <CardsCol
                    span={6}
                    key={index}
                    className={`order_card_${index}`}
                  >
                    <SingleOrderCard
                      {...v}
                      isSelected={selectedOrder === index}
                    />
                  </CardsCol>
                </>
              ))}
            </CardsRow>
          </Spin>
        </CardsWrapper>
        <HistoryWrapper>
          <Spin spinning={historyList.loading} size="large" tip="Loading...">
            <Typography.Title
              style={{
                // position: "sticky",
                // top: "-10px",
                backgroundColor: "white",
                zIndex: 5,
              }}
              level={4}
            >
              History
            </Typography.Title>
            {/* <div style={{ position: "relative", zIndex: 1 }}> */}
            <BakerHistory
              historyIndex={historyIndex}
              setHistory={setHistoryIndex}
              open={history}
              onCancel={() => setHistory(false)}
            />
          </Spin>
          {/* </div> */}
        </HistoryWrapper>
      </Wrapper>
      <WebSocket baker />
    </PageContainer>
  );
}

export default BakerPage;
