import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Statistic,
  Table,
  Tag,
  Typography,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PageContainer } from "../../components/container";
import { LangText, useLangText } from "../../components/langManagement";
import { PrivateComp } from "../../components/roleBasedAccesses";
import { dateOptions } from "../../constants/date";
import { p } from "../../constants/permissions";
import { categoryColor } from "../../utils/color";
import { thousandSeparator } from "../../utils/numberManager";
import {
  useDateRange,
  useGetPaymentCategories,
  useGetPayments,
} from "../../zustand/store";
import { SquareIcon } from "../singleOrderOfDriver/styles";
import PaymentForm from "./paymentForm";
import SquareDrawer from "./squareDrawer";
import { IncomeExpenseBox, TotalCalculationWrapper } from "./styles";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const tagRender = (props) => {
  const { label, value, closable, onClose } = props;
  const onPreventMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={categoryColor(value)}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
    >
      {label}
    </Tag>
  );
};

function FinancePage() {
  // const [accountForm, setAccountForm] = useState({ open: false });
  const [paymentForm, setPaymentForm] = useState({ open: false });
  const getPayments = useGetPayments();
  const getPaymentCategories = useGetPaymentCategories();
  const text = useLangText();

  // const [chosenAccount, setChosenAccount] = useState([]);
  const [paymentsList, setPaymentsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [chosenCategory, setChosenCategory] = useState([]);
  // const [dateRange, setDateRange] = useState([]);
  const { dateRange, setDateRange } = useDateRange();
  const [sqrDrawer, setSqrDrawer] = useState(false);

  useEffect(() => {
    getPaymentCategories.execute();
    // getAccounts.execute();
    // setDateRange(dateOptions[3]?.range);
  }, []);

  const getPaymentList = () => {
    getPayments.execute({ params: { dateRange }, force: true });
  };

  useEffect(() => {
    if (dateRange.length) {
      getPaymentList();
    }
  }, [dateRange]);

  useEffect(() => {
    if (getPayments?.data) {
      const categories = [
        ...new Set([
          ...getPayments?.data?.reduce(
            (t, { category }) => t.concat(category),
            []
          ),
        ]),
      ];
      setCategoryList(
        categories.map((v) => ({ label: <LangText id={v} />, value: v }))
      );
      setChosenCategory(categories);
    }
  }, [getPayments?.data]);

  useEffect(() => {
    setPaymentsList(
      getPayments?.data?.filter(
        ({ category }) => category.find((c) => chosenCategory.includes(c))
        // chosenCategory.includes(category)
      ) || []
    );
  }, [getPayments?.data, chosenCategory]);
  const { income, expense } = paymentsList?.reduce(
    ({ income, expense }, { type, amount }) => ({
      income: type === "INCOME" ? income + amount : income,
      expense: type === "EXPENSE" ? expense + amount : expense,
    }),
    {
      income: 0,
      expense: 0,
    }
  );

  return (
    <PageContainer
      title={<LangText id="finance" />}
      // onClick={() => setAccountForm({ open: true })}
      // button={"+ Account"}
      rightPart={
        <Row gutter={[10, 10]}>
          {/* <Col>
            <Card
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => setSqrDrawer(true)}
            >
              <a
                href="https://sandbox.dev.clover.com/appmarket/apps/X7GWACQ4KCTRJ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://sandbox.dev.clover.com/assets/images/clover-app-market-button.svg"
                  alt="Install From Clover App Market"
                />
              </a>
            </Card>
          </Col> */}
          <Col>
            <Card
              size="small"
              style={{ cursor: "pointer" }}
              onClick={() => setSqrDrawer(true)}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <div>
                  <SquareIcon>
                    <div />
                  </SquareIcon>
                </div>
                <div>
                  <b>Square</b>
                </div>
              </div>
            </Card>
          </Col>
          <Col>
            <Card size="small">
              <Row gutter={[10]}>
                <PrivateComp p={p.ADD_PAYMENTS}>
                  <Col>
                    <Button
                      style={{
                        borderColor: "#87d068",
                        backgroundColor: "#87d068",
                        color: "white",
                      }}
                      type="primary"
                      ghost
                      onClick={() =>
                        setPaymentForm({ open: true, type: "INCOME" })
                      }
                    >
                      <PlusOutlined />
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{
                        borderColor: "#f50",
                        backgroundColor: "#f50",
                        color: "white",
                      }}
                      type="primary"
                      danger
                      ghost
                      onClick={() =>
                        setPaymentForm({ open: true, type: "EXPENSE" })
                      }
                    >
                      <MinusOutlined />
                    </Button>
                  </Col>
                  {/* <Divider
                style={{ height: "unset" }}
                orientation="center"
                type="vertical"
              /> */}
                </PrivateComp>
                {/* <PrivateComp p={p.ADD_ACCOUNT}>
              <Col>
                <Button
                  onClick={() => setAccountForm({ open: true })}
                  type="primary"
                >
                  <LangText id="plusAccount" />
                </Button>
              </Col>
            </PrivateComp> */}
              </Row>
            </Card>
          </Col>
        </Row>
      }
    >
      <Card size="small">
        <TotalCalculationWrapper>
          <div style={{ display: "flex", gap: "10px" }}>
            <IncomeExpenseBox style={{ backgroundColor: "rgb(135, 208, 104)" }}>
              ${thousandSeparator(income.toFixed(2))}
            </IncomeExpenseBox>

            <IncomeExpenseBox style={{ backgroundColor: "rgb(255, 85, 0)" }}>
              ${thousandSeparator(expense.toFixed(2))}
            </IncomeExpenseBox>
          </div>
          <IncomeExpenseBox
            style={{
              border: `1px solid ${
                income - expense >= 0 ? "rgb(135, 208, 104)" : "rgb(255, 85, 0)"
              }`,
            }}
          >
            <Statistic
              // title="Income"
              value={`$${thousandSeparator(
                Math.abs((income - expense).toFixed(2))
              )}`}
              precision={2}
              valueStyle={{
                fontWeight: "bold",
                fontSize: "25px",
                color:
                  income - expense >= 0
                    ? "rgb(135, 208, 104)"
                    : "rgb(255, 85, 0)",
              }}
              prefix={
                income - expense >= 0 ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )
              }
              // suffix="%"
            />
          </IncomeExpenseBox>
        </TotalCalculationWrapper>
      </Card>
      <PrivateComp p={p.GET_PAYMENTS}>
        <br />
        <Card size="small">
          <Row gutter={10}>
            <Col span={6}>
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder={text({ id: "category" })}
                tagRender={tagRender}
                value={chosenCategory}
                onChange={(v) => {
                  setChosenCategory(v);
                }}
                options={categoryList}
              />
            </Col>
            <Col span={10}>
              <Row gutter={10}>
                <Col span={18}>
                  <RangePicker
                    format={"D/MM/YYYY"}
                    value={dateRange}
                    style={{ width: "100%" }}
                    onChange={(v) => {
                      if (v) {
                        const [start, end] = v;
                        const range = [start.startOf("day"), end.endOf("day")];
                        setDateRange(range);
                      }
                    }}
                  />
                </Col>
                <Col span={6}>
                  <Select
                    style={{ width: "150px" }}
                    onChange={(v, { range }) => {
                      setDateRange(range);
                    }}
                    value={
                      dateOptions?.find(({ range: [start, end] }) => {
                        const [s, e] = dateRange;
                        return start?.isSame(s) && end?.isSame(e);
                      })?.value
                    }
                    options={dateOptions.map((v) => ({
                      ...v,
                      label: <LangText id={v.label} />,
                    }))}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        <br />
        <Card size="small">
          <Table
            size="small"
            pagination={false}
            loading={getPayments?.loading}
            columns={[
              {
                title: <LangText id="category" />,
                dataIndex: "category",
                key: "category",
              },
              {
                title: <LangText id="amount" />,
                dataIndex: "amount",
                key: "amount",
              },
              // {
              //   title: <LangText id="finance" />,
              //   dataIndex: "fin",
              //   key: "fin",
              // },
              {
                title: <LangText id="description" />,
                dataIndex: "description",
                key: "description",
              },
              {
                title: <LangText id="date" />,
                dataIndex: "date",
                key: "date",
              },
              {
                title: <LangText id="more" />,
                dataIndex: "more",
                key: "more",
              },
            ]}
            dataSource={paymentsList?.map((v) => {
              const {
                amount,
                category,
                createdAt,
                description,
                goodId,
                id,
                paymentForOrderId,
                salaryForOrderId,
                staffId,
                type,
                addedAt,
                squareData,
                ownerId,
              } = v;

              return {
                category: (
                  <>
                    {squareData ? (
                      <>
                        {/* <Tag color={categoryColor("SQUARE")}>
                          <LangText id={"SQUARE"} />
                        </Tag> */}
                        <Tag color={categoryColor("SQUARE")}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <SquareIcon>
                              <div />
                            </SquareIcon>
                            <LangText
                              id={
                                squareData?.data?.data?.object?.payment
                                  ?.card_details?.entry_method
                              }
                            />
                          </div>
                        </Tag>
                      </>
                    ) : (
                      // <Tag color={categoryColor("SQUARE")}>
                      //   <LangText id={"CASH"} />
                      //   {/* <SquareIcon>
                      //     <div />
                      //   </SquareIcon> */}
                      // </Tag>
                      <></>
                    )}

                    {ownerId}
                    {category.map((c, i) => (
                      <Tag key={i} color={categoryColor(c)}>
                        <LangText id={c} />
                      </Tag>
                    ))}
                  </>
                ),
                amount: (
                  <Text strong>
                    <Tag
                      color={
                        type === "INCOME"
                          ? "#87d068"
                          : type === "EXPENSE"
                          ? "#f50"
                          : ""
                      }
                    >
                      {type === "INCOME" ? (
                        <>+ </>
                      ) : type === "EXPENSE" ? (
                        <>- </>
                      ) : (
                        ""
                      )}
                      ${thousandSeparator(amount)}
                      {/* {currency} */}
                    </Tag>
                  </Text>
                ),
                fin: (
                  <>
                    {/* <Text code>{name}</Text> <br /> */}
                    {/* <Text code>{paymentMethod}</Text> */}
                  </>
                ),
                description,
                // date: moment(createdAt).calendar(),
                date: (
                  <>
                    {moment(createdAt).format("ll")}
                    {/* <PrivateComp p={p.EDIT_PAYMENT}>
                      <EditAddedAt
                        id={id}
                        addedAt={addedAt}
                        getPaymentList={getPaymentList}
                      />
                    </PrivateComp> */}
                  </>
                ),
                more: (
                  <>
                    {salaryForOrderId ? (
                      <PrivateComp p={p.GET_ORDERS}>
                        <div>
                          <Link to={`/orders/${salaryForOrderId}`}>
                            <LangText id="order" /> #{salaryForOrderId}
                          </Link>
                        </div>
                      </PrivateComp>
                    ) : (
                      <></>
                    )}
                    {paymentForOrderId ? (
                      <PrivateComp p={p.GET_ORDERS}>
                        <div>
                          <Link to={`/orders/${paymentForOrderId}`}>
                            <LangText id="order" /> #{paymentForOrderId}
                          </Link>
                        </div>
                      </PrivateComp>
                    ) : (
                      <></>
                    )}
                    {staffId ? (
                      <PrivateComp p={p.GET_STAFF_LIST}>
                        <div>
                          <Link to={`/users?staff=${staffId}`}>
                            <LangText id="staff" />
                            {/* #{staffId} */}
                          </Link>
                        </div>
                      </PrivateComp>
                    ) : (
                      <></>
                    )}
                    {goodId ? (
                      <PrivateComp p={p.GET_GOODS}>
                        <div>
                          <Link to={`/warehouse/goods/${goodId}`}>
                            <LangText id="singleGoods" /> #{goodId}
                          </Link>
                        </div>
                      </PrivateComp>
                    ) : (
                      <></>
                    )}
                    {/* {!(
                      salaryForOrderId ||
                      paymentForOrderId ||
                      staffId ||
                      goodId
                    ) ? ( */}
                    <div>
                      {!category.includes("APP") &&
                      // !category.includes("SQUARE")
                      !squareData ? (
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => {
                            setPaymentForm({
                              open: true,
                              editData: v,
                            });
                          }}
                        >
                          Edit
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                    {/* ) : (
                      <></>
                    )} */}
                  </>
                ),
              };
            })}
          />

          <PrivateComp or={[p.ADD_PAYMENTS, p.EDIT_PAYMENT]}>
            <PaymentForm
              {...paymentForm}
              onClose={() => setPaymentForm({ open: false })}
              getPaymentsList={() =>
                getPayments.execute({
                  params: {
                    dateRange: dateOptions[3]?.range,
                    force: true,
                  },
                })
              }
            />
          </PrivateComp>

          {/* <PrivateComp p={p.ADD_ACCOUNT}>
            <FinanceForm
              open={accountForm.open}
              onClose={() => setAccountForm({ open: false })}
            />
          </PrivateComp> */}
        </Card>
      </PrivateComp>
      <SquareDrawer
        open={sqrDrawer}
        onClose={() => {
          setSqrDrawer(false);
        }}
      />
    </PageContainer>
  );
}

export default FinancePage;
