import { Card, Input, InputNumber } from "antd";
import React from "react";
import { useState } from "react";
import { PageContainer } from "../../components/container";

function Calculator() {
  const [value, setValue] = useState();
  return (
    <PageContainer title={"Calculator"}>
      <Card size="small">
        <div>
          <InputNumber value={value} onChange={setValue} />
        </div>
        <div>0.6%: {value * 0.006}</div>
        <div>Hisob: {value * 0.029 + 0.30}</div>
        {/* <div>Hisob: {value * 0.025 + 0.25}</div> */}
        <div>Square: {value * 0.026 + 0.1}</div>
        <div>Square: {value * 0.035 + 0.15}</div>
        <div>Clover: {value * 0.023 + 0.1}</div>
      </Card>
    </PageContainer>
  );
}

export default Calculator;
