import { Col, DatePicker, Drawer, Row, Select, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { LangText } from "../../../../components/langManagement";
import { dateOptions } from "../../../../constants/date";
import { thousandSeparator } from "../../../../utils/numberManager";
import {
  useDateRange,
  useGetStaffList,
  useGetStaffSalaries,
} from "../../../../zustand/store";

const { RangePicker } = DatePicker;

function PaidSalaries({ open, staffId, onClose }) {
  const staffList = useGetStaffList();
  const getSalaries = useGetStaffSalaries();

  // const [dateRange, setDateRange] = useState(dateOptions[3]?.range);
  const { dateRange, setDateRange } = useDateRange();

  // useEffect(() => {
  //   setDateRange(dateOptions[3]?.range);
  // }, []);

  useEffect(() => {
    if (staffId) {
      getSalaries.execute({ params: { staffId, dateRange } });
    }
  }, [staffId, dateRange]);

  return (
    <Drawer
      width={"40%"}
      title={
        <>
          <LangText id="salaryOf" />{" "}
          {staffList?.data?.find(({ id }) => id === staffId)?.fullname}
        </>
      }
      open={open}
      onClose={onClose}
    >
      <Row
        gutter={10}
        style={{
          // width: "100%",
          marginBottom: "20px",
        }}
      >
        <Col span={15}>
          <RangePicker
            format={"D/MM/YYYY"}
            value={dateRange}
            style={{ width: "100%" }}
            onChange={(v) => {
              if (v) {
                const [start, end] = v;
                const range = [start.startOf("day"), end.endOf("day")];
                setDateRange(range);
              }
            }}
          />
        </Col>
        <Col span={9}>
          <Select
            style={{ width: "100%" }}
            onChange={(v, { range }) => {
              setDateRange(range);
            }}
            value={
              dateOptions?.find(({ range: [start, end] }) => {
                const [s, e] = dateRange;
                return start?.isSame(s) && end?.isSame(e);
              })?.value
            }
            options={dateOptions.map((v) => ({
              ...v,
              label: <LangText id={v.label} />,
            }))}
          />
        </Col>
      </Row>

      <Table
        size="small"
        loading={getSalaries?.loading}
        pagination={false}
        columns={[
          {
            width: "50px",
            title: <LangText id="id" />,
            key: "id",
            dataIndex: "id",
          },
          {
            title: <LangText id="amount" />,
            key: "amount",
            dataIndex: "amount",
          },
          // {
          //   title: <LangText id="order" />,
          //   key: "order",
          //   dataIndex: "order",
          // },
          {
            title: <LangText id="description" />,
            key: "description",
            dataIndex: "description",
          },
          {
            title: <LangText id="date" />,
            key: "addedAt",
            dataIndex: "addedAt",
          },
        ]}
        dataSource={getSalaries?.data?.map(
          ({
            amount,
            createdAt,
            description,
            id,
            salaryForOrderId,
          }) => ({
            id: `${id}`,
            amount: <>${thousandSeparator(amount)}</>,
            description,
            // addedAt: moment(addedAt).format("ll"),
            addedAt: moment(createdAt).format("ll"),
            order: salaryForOrderId ? (
              <Link to={`/orders/${salaryForOrderId}`}>
                #{salaryForOrderId}
              </Link>
            ) : (
              <></>
            ),
          })
        )}
      />
    </Drawer>
  );
}

export default PaidSalaries;
