import React, { useState } from "react";

import { Col, Row, Spin, Typography } from "antd";
import { useEffect } from "react";
import { PageContainer } from "../../../components/container";
import EmptyBox from "../../../components/emptyBox";
import { LangText } from "../../../components/langManagement";
import { useGetGoods } from "../../../zustand/store";
import SingleAddedGoods from "./singleAddedGoods";
const { Title } = Typography;

function AddedGoods() {
  const [addGoodsOpen, setAddGoodsOpen] = useState(false);
  const getGoods = useGetGoods((state) => state);

  useEffect(() => {
    getGoods.execute();
  }, []);
  return (
    <PageContainer
      title={<LangText id="products" />}
      // rightPart={
      //   <PrivateComp p={p.ADD_GOODS}>
      //     <Button onClick={() => setAddGoodsOpen(true)} type="primary">
      //       <LangText id="addGoods" />
      //     </Button>
      //   </PrivateComp>
      // }
    >
      {getGoods?.data?.length === 0 ? (
        <EmptyBox
          text={<LangText id="goodsNotFound" />}
          create={() => setAddGoodsOpen(true)}
        />
      ) : (
        <Spin spinning={getGoods.loading}>
          <Row gutter={[10, 10]}>
            {getGoods?.data?.map((v, i) => (
              <Col key={i}>
                <SingleAddedGoods data={v} />
              </Col>
            ))}
          </Row>
        </Spin>
      )}
      {/* <PrivateComp p={p.ADD_GOODS}>
        <AddGoods open={addGoodsOpen} onClose={() => setAddGoodsOpen(false)} />
      </PrivateComp> */}
    </PageContainer>
  );
}

export default AddedGoods;
