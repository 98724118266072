import { Button, Card, Col, Form, InputNumber, Row } from "antd";
import React, { useEffect } from "react";
import {
  LangText,
  useLangText,
} from "../../../../../components/langManagement";
import {
  useAddWarehousePayment,
  useEditPayment,
  useGetGoods,
} from "../../../../../zustand/store";

function SinglePaymentCard({ amount, id, warehouseId }) {
  const [form] = Form.useForm();
  const editPayment = useEditPayment();
  const addWarehousePayment = useAddWarehousePayment();
  const getGoods = useGetGoods();
  const text = useLangText();

  useEffect(() => {
    form.setFieldValue("price", amount || 0);
  }, [amount]);

  return (
    <Col
    // span={24}
    >
      <Form
        onFinish={(e) => {
          // setEdit(false);
          (warehouseId ? addWarehousePayment : editPayment).execute({
            params: {
              ...e,
              id,
              warehouseId,
            },
            onSuccess: () => {
              getGoods.execute({ force: true });
            },
          });
        }}
        form={form}
        layout="vertical"
        hideRequiredMark
      >
        <Card size="small">
          <Row gutter={[10, 10]}>
            <Col span={16}>
              <Form.Item
                style={{ margin: 0 }}
                name={`price`}
                label={<LangText id="totalAmount" />}
                rules={[
                  {
                    required: true,
                    message: text({ id: "enterPrice" }),
                  },
                ]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  placeholder={text({ id: "enterPrice" })}
                  min={0}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                  }
                  parser={(value) => value.replace(/\$\s?|( *)/g, "")}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label=" " style={{ margin: 0 }}>
                <Button type="primary" htmlType="submit" block>
                  {warehouseId ? (
                    <LangText id="save" />
                  ) : (
                    <LangText id="update" />
                  )}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Col>
  );
}

export default SinglePaymentCard;
