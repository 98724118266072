import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Row, Spin, Tabs } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/container";
import EmptyBox from "../../components/emptyBox";
import { Text } from "../../components/langManagement";
import { PrivateComp } from "../../components/roleBasedAccesses";
import { p } from "../../constants/permissions";
import { useGetAddresses, useGetOrdersList } from "../../zustand/store";
import OrdersStat from "./ordersStat";
import SingleOrderRow from "./singleOrderRow";
import { OrdersListWrapper } from "./styles";
import CalendarHeatmap from "./calHeatmap";

const OrdersInProcess = ({ addOrderOpen, setAddOrderOpen, data }) => {
  // const getOrders = useGetOrdersList();

  return (
    <>
      {
        // getOrders?.
        data?.length === 0 ? (
          <EmptyBox
            text={<Text id="orderNotFound" />}
            create={() => setAddOrderOpen(true)}
          />
        ) : (
          <Card size="small">
            <OrdersListWrapper>
              {/* <Row gutter={[10, 10]}> */}
              {
                // getOrders?.
                data?.map((v, i) => (
                  <SingleOrderRow {...v} key={i} />
                ))
              }
              {/* </Row> */}
            </OrdersListWrapper>
          </Card>
        )
      }
      {/* <AddOrder open={addOrderOpen} onClose={() => setAddOrderOpen(false)} /> */}
    </>
  );
};

function OrdersPage() {
  const [date, setDate] = useState(null);
  const getOrders = useGetOrdersList();
  const addresses = useGetAddresses();

  const navigate = useNavigate();

  useEffect(() => {
    addresses.execute();
    const dateTime = dayjs().subtract(6, "hours");
    handleDate(dateTime);
  }, []);

  const handleDate = (e) => {
    let from = e.startOf("date").add(6, "hours").format();
    let to = e.endOf("date").add(6, "hours").format();
    setDate(e);
    getOrders.execute({ params: { from, to }, force: true });
  };

  let orders = getOrders.data?.reduce((t, v) => ({
    ...t,
    [v.order_type]: [...(t[v.order_type] || []), v],
  }), {});

  orders = orders || {};

  return (
    <PageContainer
      title={<Text id="orders" />}
      rightPart={
        <PrivateComp p={p.ADD_ORDER}>
          <Button
            onClick={() => {
              // setAddOrderOpen(true);
              navigate("/add-order");
            }}
            type="primary"
          >
            <Text id="plusOrder" />
          </Button>
        </PrivateComp>
      }
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Row gutter={[5, 5]}>
            <Col>
              <Button onClick={() => handleDate(date.subtract(1, "days"))}>
                <LeftOutlined />
              </Button>
            </Col>
            <Col>
              <DatePicker
                format={"DD/MM/YYYY"}
                value={date}
                onChange={handleDate}
                disabledDate={(current) => current > dayjs()}
                allowClear={false}
              />
            </Col>
            <Col>
              <Button onClick={() => handleDate(date.add(1, "days"))}>
                <RightOutlined />
              </Button>
            </Col>
          </Row>
        </Col>
        {/* <Col span={24}>
          <CalendarHeatmap />
        </Col> */}
        <Col span={24}>
          <Spin spinning={getOrders.loading}>
            <OrdersStat orders={orders} />
          </Spin>
        </Col>

        <Col span={24}>
          <Spin spinning={getOrders.loading}>
            <Tabs
              // type="card"
              items={[
                {
                  key: "ALL",
                  label: "ALL",
                  children: <OrdersInProcess data={getOrders.data || []} />,
                },
              ].concat(
                Object.entries(orders).map(([name, order]) => ({
                  label: name,
                  key: name,
                  children: <OrdersInProcess data={order} />,
                }))
              )}
            />
          </Spin>
        </Col>
      </Row>
    </PageContainer>
  );
}

export default OrdersPage;
