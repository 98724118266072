import styled from "styled-components"

export const AmountDisplay = styled.div`
  width: 100%;
  height: 85px;
  border: 1px solid lightgrey;
  font-size: 50px;
  text-align: center;
  margin-bottom: 10px;
  border-radius: 10px;
`;

export const BtnsWrapper = styled.div`
  
`;

export const Btn = styled.div`
  border: 1px solid black;
`;