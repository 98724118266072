import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  /* padding: 10px 20px 10px 0px; */
  border-right: 1px solid black;
`;

export const NavListWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px 10px 10px 0;
`;

export const NavItem = styled(Link)`
  padding: 15px 15px;
  cursor: pointer;
  display: block;
  color: ${({ active }) => (active ? "#6045e2" : "#545661")};
  text-decoration: none;
  /* border-left: 3px solid transparent; */
  /* ${({ active }) => (active ? "#6045e2" : "transparent")}; */
  background-color: ${({ active }) => (active ? "#6045e250" : "transparent")};
  border-radius: 0 10px 10px 0;
  position: relative;
  /* font-size: 20px; */
  &::after {
    content: "";
    width: 4px;
    height: calc(100% + 4px);
    position: absolute;
    right: auto;
    top: 0;
    left: 0;
    bottom: 0;

    background-color: ${({ active }) => (active ? "#6045e2" : "transparent")};
    border-radius: 0 10px 10px 0;
    margin: auto;
  }
  &:hover {
    /* background-color: #00000050; */
    /* background-color: #00000050; */
    color: ${({ active }) => (active ? "#6045e2" : "rgb(84, 86, 97)")};
    background-color: ${({ active }) => (active ? "#6045e250" : "#6045e230")};
  }
`;
