import React from "react";
import { Wrapper } from "./styles";
import { Card } from "antd";
import { useGetCashOutDriverList } from "../../../zustand/store";
import { SquareIcon } from "../../singleOrderOfDriver/styles";

function Stat() {
  const cashOut = useGetCashOutDriverList();
  const data = cashOut?.data || [];
  const total = data?.reduce(
    (t, { overallTotals }) => ({
      appFee: t.appFee + (overallTotals?.appFee || 0),
      sqrAppFee: t.sqrAppFee + (overallTotals?.sqrAppFee || 0),
    }),
    { appFee: 0, sqrAppFee: 0 }
  );

  return (
    <Wrapper>
      <Card size="small">
        Cash: <b>${total?.appFee}</b>
        <br />
        Square: <b>${total?.sqrAppFee}</b>
      </Card>
    </Wrapper>
  );
}

export default Stat;
