import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid black;
`;

export const CardsWrapper = styled.div`
  width: calc(100% - 350px);
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
  /* border-left: 1px solid black; */
  padding: 10px 15px 10px 0;
  .ant-spin-nested-loading {
    height: 100%;
  }
`;

export const HistoryWrapper = styled.div`
  width: 350px;
  height: calc(100vh - 70px);
  overflow-y: auto;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  border-left: 1px solid black;
  padding: 10px 0 0px 15px;
  /* position: relative; */
  .ant-spin-nested-loading {
    height: 100%;
  }
`;

export const CardsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: calc(100vh - 80px);
  overflow-y: auto;
  align-content: start;
`;

export const CardsCol = styled.div`
  /* width: calc((100% - 30px) / 4); */
  width: 300px;
`;
