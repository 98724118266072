import { Spin, Tag, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { orderTypeColor } from "../../constants/orderType";
import {
  NEXT_HISTORY,
  NEXT_ORDER,
  PREV_HISTORY,
  PREV_ORDER,
  PRINT,
  REFRESH_PAGE,
  SERVE,
  ZOOM
} from "../../utils/kbd";
import WebSocket from "../../utils/ws";
import {
  useAddOrderPrintRequest,
  useGetBakerOrders,
  useGetBakerOrdersHistory,
  useGetMe,
  useOrderServed,
} from "../../zustand/store";
// import { ListItem } from "../makeOrderPage";
import { SettingOutlined } from "@ant-design/icons";
import { crustList } from "../../constants/crust";
import { NO } from "../../constants/general";
import size from "../../constants/size";
import { extraPriceCalc } from "../../utils/calculate";
import BakerHistory from "./bakerHistory";
import {
  CardsCol,
  CardsRow,
  CardsWrapper,
  CustomTag,
  HistoryWrapper,
  SingleOrderWrapper,
  Wrapper,
} from "./styles";
import { Seconds, Timer } from "./timer";

export const ListItem = ({
  data,
  i,
  selectedFood,
  setSelectedFood,
  inner,
  readMode,
}) => {
  let {
    category,
    createdAt,
    createdBy,
    id,
    ingredients,
    name,
    price,
    size: s,
    updatedAt,
    updatedBy,
    amount,
    only,
    no,
    // toppings,
    // toppingPrice,
    half,
    flavors,
    selectedFlavors,
    desc,
    selectedOption,
    options,
    crust,
    extra,
    extraPrice,
    selectedExtra,
  } = data;
  extraPrice = extraPrice || extraPriceCalc(selectedOption) || 0;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [transition, setTransition] = useState(0.5);
  const [amountPopUp, setAmountPopUp] = useState(false);
  const [customizationPopUp, setCustomizationPopUp] = useState(false);
  const [specialCustomization, setSpecialCustomization] = useState(null);

  const minSwipeDistance = 50;
  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
    setTransition(0.5);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isRightSwipe) {
      setCustomizationPopUp(true);
    } else if (
      isLeftSwipe
      // || isRightSwipe
    ) {
      setTouchStart(350);
      setTouchEnd(0);
      setTimeout(() => {
        setSelectedFood((prev) => prev.filter((pf, j) => j !== i));
        setTouchStart(null);
        setTouchEnd(null);
        setTransition(0);
      }, 500);
    } else {
      setTouchStart(null);
      setTouchEnd(null);
    }
  };

  return (
    <div key={i}>
      <div
        style={{
          transform: `translate(${-(touchStart !== null &&
            touchEnd !== null &&
            touchStart - touchEnd > 0
            ? touchStart - touchEnd
            : 0)}px)`,
          transitionDuration: `${transition}s`,
          userSelect: "none",
          paddingRight: "0",
          paddingLeft: "0",
        }}
        onTouchStart={!inner && !readMode ? onTouchStart : () => { }}
        onTouchMove={!inner && !readMode ? onTouchMove : () => { }}
        onTouchEnd={!inner && !readMode ? onTouchEnd : () => { }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  backgroundColor: "yellow",
                }}
              >
                <div
                  onClick={() => {
                    if (!inner && !readMode) {
                      setAmountPopUp({
                        popUp: true,
                        data: {
                          category,
                          createdAt,
                          createdBy,
                          id,
                          ingredients,
                          name,
                          price,
                          size: s,
                          updatedAt,
                          updatedBy,
                          amount,
                        },
                      });
                    }
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {amount > 1 ? (
                      // <Badge color="#52c41a" count={amount} />
                      <div
                        style={{
                          marginRight: "5px",
                          // fontWeight: "bold",
                          backgroundColor: "lightgreen",
                          padding: "0 7px",
                          borderRadius: "10px",
                        }}
                      >
                        {amount}
                      </div>
                    ) : (
                      <></>
                    )}
                    <div style={{ marginRight: "5px" }}>{name}</div>
                    {s ? <CustomTag>{size?.[s]}</CustomTag> : ""}
                    {!inner && !half && amount === 1 ? (
                      <>
                        ${price}
                        {extraPrice ? ` + $${extraPrice}` : ""}
                      </>
                    ) : (
                      <></>
                    )}{" "}
                    {category === "Pizza" && crust ? (
                      <div>{crustList[crust]}</div>
                    ) : (
                      <></>
                    )}
                    {/* ${price}{" "} */}
                  </div>
                  {!inner && amount > 1 && !half ? (
                    <div>
                      {extraPrice ? "(" : ""}${price}
                      {extraPrice ? ` + $${extraPrice})` : ""} x {amount} = $
                      {((price + (extraPrice || 0)) * amount).toFixed(2)}
                    </div>
                  ) : !inner && Boolean(half) ? (
                    <div>
                      {extraPrice ? "(" : ""}${price}
                      {extraPrice ? ` + $${extraPrice})` : ""} x {0.5 * amount}{" "}
                      = ${((price + extraPrice) * 0.5 * amount).toFixed(2)}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {!inner && !readMode && (
                <div style={{ width: "30px", height: "30px", display: "flex" }}>
                  <SettingOutlined
                    onClick={() => {
                      if (options && options?.length) {
                        setSpecialCustomization(data);
                      } else {
                        setCustomizationPopUp(true);
                      }
                    }}
                    style={{ fontSize: "26px", margin: "auto" }}
                  />
                </div>
              )}
            </div>
            <div>
              {no?.length ||
                only?.length ||
                selectedExtra?.length ||
                selectedFlavors?.length ||
                desc ||
                selectedOption ? (
                <div
                  style={{ width: "fit-content", padding: "0 10px 5px" }}
                  size="small"
                  onClick={() => {
                    if (!inner && !readMode) {
                      if (options && options?.length) {
                        setSpecialCustomization(data);
                      } else {
                        setCustomizationPopUp(true);
                      }
                      // setCustomizationPopUp(true);
                    }
                  }}
                >
                  {no?.map((v, i) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginBottom: "5px",
                      }}
                      key={"NO" + i}
                    >
                      <CustomTag type="danger">NO</CustomTag> {v}
                    </div>
                  ))}
                  {only?.map((v, i) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginBottom: "5px",
                      }}
                      key={"ONLY" + i}
                    >
                      <CustomTag
                        color={v?.side === NO ? "red" : "yellow"}
                        type={v?.side === NO ? "danger" : "yellow"}
                      >
                        {v?.side || "ONLY"}
                      </CustomTag>
                      {v?.name || v}
                    </div>
                  ))}
                  {selectedExtra?.map((v, i) => (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginBottom: "5px",
                      }}
                      key={"TOPPING" + i}
                    >
                      <CustomTag type="success" color="green">
                        {v?.side || "ADD"}
                      </CustomTag>
                      {v?.name || v}
                    </div>
                  ))}
                  {selectedFlavors?.length ? (
                    <div key={"FLAVOR"}>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          // gap: "10px 0",
                          marginBottom: "5px",
                        }}
                      >
                        {[...new Set(selectedFlavors?.map(({ name }) => name))]
                          ?.length === 1 ? (
                          <CustomTag
                            // style={{fontSize:"20px"}}
                            key={`FLAVOR_TAG_${i}`}
                            color="green"
                            type="success"
                          >
                            {selectedFlavors?.[0]?.name}
                          </CustomTag>
                        ) : (
                          selectedFlavors?.map((v, i) => (
                            <CustomTag
                              type="success"
                              key={`FLAVOR_TAG_${i}`}
                              color="green"
                            >
                              {v?.name}
                            </CustomTag>
                          ))
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                  {desc ? (
                    <div key={"DESCRIPTION"}>
                      <CustomTag color="blue">{desc}</CustomTag>
                    </div>
                  ) : (
                    <></>
                  )}
                  {selectedOption?.map((sOption, i) => (
                    <div
                      style={{
                        paddingTop: 0,
                        paddingBottom: 0,
                        marginBottom: "5px",
                      }}
                      key={"OPTION" + i}
                    >
                      {sOption.length ? (
                        <div style={{ padding: "0 10px 10px" }}>
                          <div size="small" style={{ paddingBottom: 0 }}>
                            {sOption?.map((data, i) => (
                              <ListItem
                                key={i}
                                inner
                                data={{
                                  ...data,
                                  name: data?.name,
                                  // ||menu?.data?.find(
                                  //   ({ id: mId }) => mId === data?.id
                                  // )?.name,
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <AmountModal
        onOk={({ amount }) => {
          if (amount === 0) {
            setSelectedFood((prev) => prev.filter((pf, j) => j !== i));
          } else {
            setSelectedFood((prev) =>
              prev.map((sf, j) => (j === i ? { ...sf, amount } : sf))
            );
          }
          setAmountPopUp(false);
        }}
        data={data}
        open={amountPopUp}
        onCancel={() => setAmountPopUp(false)}
      />
      <CustomizationModal
        i={i}
        open={customizationPopUp}
        data={data}
        onCancel={() => {
          setCustomizationPopUp(false);
        }}
        selectedFood={selectedFood}
        setSelectedFood={setSelectedFood}
      />
      <SpecialCustomizationModal
        open={Boolean(specialCustomization)}
        data={specialCustomization}
        onCancel={() => setSpecialCustomization(null)}
        onOk={(data) => {
          setSelectedFood((prev) =>
            prev.map((v, index) => (index === i ? data : v))
          );
          // addFood(data);
          setSpecialCustomization(null);
        }}
      /> */}
    </div>
  );
};

export const SingleOrderCard = ({
  selectedFood,
  id,
  addedAt,
  order_type,
  // selectedOrder,
  // index,
  isSelected,
  historyCard,
  currentSection,
}) => {
  const orderServed = useOrderServed();
  const bakerOrders = useGetBakerOrders();

  return (
    <SingleOrderWrapper
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* <div>{moment(addedAt).calendar()}</div> */}
          <Tag color={orderTypeColor[order_type] || ""}>{order_type}</Tag>
          <Seconds date={addedAt} />
        </div>
      }
      size="small"
      style={{
        backgroundColor: isSelected
          ? `rgba(144, 238, 144, ${currentSection ? 0.7 : 0.3})`
          : historyCard
            ? "#e8e8e8"
            : "",
      }}
      bodyStyle={{
        paddingTop: "0",
        paddingBottom: "0",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          padding: "10px 10px",
        }}
      >
        <CustomTag order_type={order_type}>{order_type}</CustomTag>
        <Seconds date={addedAt} />
      </div>
      <div style={{ padding: "0 10px 0px" }}>
        {selectedFood?.map((data, i) => (
          <ListItem key={i} data={data} inner readMode />
        ))}
      </div>
    </SingleOrderWrapper>
  );
};

function BakerPage72() {
  const zooms = [0.8, 1, 1.25];
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentSection, setCurrentSection] = useState("order");
  const bakerOrder = useGetBakerOrders();
  const me = useGetMe();
  const kbd = me.data?.restaurant?.bakerSetting?.kbd;

  const printRequest = useAddOrderPrintRequest();

  const orderServed = useOrderServed();
  const [history, setHistory] = useState(false);
  const [historyIndex, setHistoryIndex] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState(0);
  const historyList = useGetBakerOrdersHistory();

  const baked = () => {
    const id = bakerOrder?.data?.[selectedOrder]?.id;
    if (id) {
      orderServed.execute({
        data: { id },
        onSuccess: () => {
          historyList.execute({ force: true });
        },
      });
    }
  };

  const print = () => {
    if (currentSection === "order") {
      const id = bakerOrder?.data?.[selectedOrder]?.id;
      if (id) {
        printRequest.execute({
          params: { orderId: id },
        });
      }
    } else if (currentSection === "history") {
      const id = historyList?.data?.[historyIndex]?.id;
      if (id) {
        printRequest.execute({
          params: { orderId: id },
        });
      }
    }
  };

  const handleKeyDown = useCallback(
    (e) => {
      if (!e.repeat) {
        const checkKey = (action) => action === kbd[e.key];

        if (checkKey(NEXT_ORDER)) {
          setCurrentSection("order");
          const bMax = bakerOrder?.data?.length;
          setSelectedOrder((prev) => (prev + 1 < bMax ? prev + 1 : prev));
        } else if (checkKey(PREV_ORDER)) {
          setCurrentSection("order");
          setSelectedOrder((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
        } else if (checkKey(NEXT_HISTORY)) {
          setCurrentSection("history");
          const hMax = historyList?.data?.length;
          setHistoryIndex((prev) => (prev + 1 < hMax ? prev + 1 : prev));
        } else if (checkKey(PREV_HISTORY)) {
          setCurrentSection("history");
          setHistoryIndex((prev) => (prev - 1 >= 0 ? prev - 1 : prev));
        } else if (
          !orderServed.loading &&
          !bakerOrder.loading &&
          checkKey(PRINT)
        ) {
          print();
          // } else if (checkKey(REFRESH_ORDERS)) {
          //   bakerOrder.execute({ force: true });
        } else if (checkKey(ZOOM)) {
          setZoomLevel((prev) => (zooms.length > prev + 1 ? prev + 1 : 0));
          // } else if (checkKey(REFRESH_HISTORY)) {
          //   historyList.execute({ force: true });
        } else if (checkKey(REFRESH_PAGE)) {
          window.location.reload();
          historyList.execute({ force: true });
        } else if (
          !orderServed.loading &&
          !bakerOrder.loading &&
          checkKey(SERVE)
        ) {
          baked();
        }
      }
    },
    [bakerOrder?.data, selectedOrder, currentSection, historyIndex]
  );

  useEffect(() => {
    // Add an event listener for the `keydown` event.
    document.addEventListener("keydown", handleKeyDown, true);
    // Return a function to remove the event listener when the component unmounts.
    return () => document.removeEventListener("keydown", handleKeyDown, true);
  }, [handleKeyDown]);
  // const [t, setT] = useState(0);
  // useEffect(() => {
  //   setTimeout(() => {
  //     setT((prev) => prev + 1);
  //     bakerOrder.execute({ force: true });
  //   }, 15 * 1000);
  // }, [t]);

  useEffect(() => {
    bakerOrder.execute();
  }, []);

  useEffect(() => {
    document
      .querySelector(`.order_card_${selectedOrder}`)
      ?.scrollIntoView({ behavior: "smooth" });
  }, [selectedOrder]);
  useEffect(() => {
    document
      .querySelector(`.history_card_${historyIndex}`)
      ?.scrollIntoView({ behavior: "smooth" });
  }, [historyIndex]);
  const zoom = zooms[zoomLevel];
  return (
    <PageContainer
      style={{ zoom, transitionDuration: "0.5s" }}
      title={
        <>
          <div>{me.data?.restaurant?.name || "Pizzaiolo"} </div>
          {/* <Timer /> */}
        </>
      }
      rightPart={
        <>
          <Timer />
          {/* <Row gutter={[10, 10]}>
            <Col>
              <Button onClick={() => bakerOrder.execute({ force: true })}>
                Refresh
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => setHistory(true)}>
                History
              </Button>
            </Col>
          </Row> */}
        </>
      }
    // button={"Refresh"}
    // onClick={() => bakerOrder.execute()}
    >
      <Wrapper>
        <CardsWrapper
          zoom={zoom}
        // style={{
        //   height: `calc((100vh - 70px) / ${zoom})`,
        // }}
        >
          <Spin
            spinning={
              // bakerOrder.loading ||
              orderServed.loading
            }
            tip="Loading..."
            size="large"
          // style={{minHeight:"100%"}}
          >
            <CardsRow zoom={zoom} gutter={[10, 10]}>
              {bakerOrder?.data?.map((v, index) => (
                <CardsCol
                  span={6}
                  key={index}
                  className={`order_card_${index}`}
                >
                  <SingleOrderCard
                    {...v}
                    isSelected={selectedOrder === index}
                    currentSection={currentSection === "order"}
                  />
                </CardsCol>
              ))}
            </CardsRow>
          </Spin>
        </CardsWrapper>
        <HistoryWrapper
          zoom={zoom}
        // style={{
        //   height: `calc((100vh - 70px) / ${zoom})`,
        // }}
        // zoom={2 - zooms[zoomLevel]}
        >
          <Spin spinning={historyList.loading} size="large" tip="Loading...">
            <Typography.Title
              style={{
                // position: "sticky",
                // top: "-10px",
                backgroundColor: "white",
                zIndex: 5,
              }}
              level={4}
            >
              History
            </Typography.Title>
            {/* <div style={{ position: "relative", zIndex: 1 }}> */}
            <BakerHistory
              zoom={zoom}
              historyIndex={historyIndex}
              setHistory={setHistoryIndex}
              open={history}
              onCancel={() => setHistory(false)}
              currentSection={currentSection === "history"}
            />
          </Spin>
          {/* </div> */}
        </HistoryWrapper>
      </Wrapper>
      <WebSocket baker />
    </PageContainer>
  );
}

export default BakerPage72;
