import { LoadingOutlined, PrinterOutlined } from "@ant-design/icons";
import { Card, Col, List, Row, Tag, Typography, Watermark } from "antd";
import moment from "moment";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { Receipt, receipt } from "../../../components/receipt";
import { orderTypeColor, orderTypes } from "../../../constants/orderType";
import { useAddOrderPrintRequest } from "../../../zustand/store";
import { ListItem } from "../../makeOrderPage";
import { handlePrint } from "../../ordersListPage";
import { Wrapper } from "./styles";

function SingleOrderRow(props) {
  let {
    accepted_by_driver,
    addedAt,
    cancellationReason,
    cashAmount,
    cash_out,
    closedAt,
    created_at,
    driver,
    id,
    isCanceled,
    canceledAt,
    isClosed,
    madeByBaker,
    madeByBakerAt,
    msgId,
    orderId,
    order_type,
    selectedFood,
    updated_at,
    userData,
    payment,
    createdAt,
    totals,
  } = props;
  driver = driver?.[0];

  const { subTotal, tax, deliveryFee, total, serviceFee } = totals || {};
  const addPrintRequest = useAddOrderPrintRequest();

  return (
    <Wrapper>
      <Watermark
        font={{ color: "#ff000050" }}
        gap={[25, 42]}
        rotate={-45}
        content={isCanceled ? ["ORDER", "CANCELED"] : []}
      >
        <Card
          actions={[
            addPrintRequest.loading ? (
              <LoadingOutlined />
            ) : (
              <PrinterOutlined
                onClick={() =>
                  addPrintRequest.execute({
                    params: { orderId: id },
                  })
                }
              />
            ),
          ]}
          size="small"
        >
          <List
            header={
              <div>
                <Tag color={orderTypeColor[order_type] || ""}>
                  {order_type || "DELIVERY"}
                </Tag>
                {moment(addedAt).calendar()}
              </div>
            }
          >
            <List.Item>
              <div style={{ width: "100%" }}>
                <List
                  footer={
                    <div>
                      SubTotal: ${subTotal} <br />
                      {order_type === orderTypes.DELIVERY ? (
                        <>
                          Delivery and Service Fee: ${deliveryFee + serviceFee}
                          <br />
                        </>
                      ) : (
                        <></>
                      )}
                      Tax: ${tax} <br />
                      <hr />
                      <Typography.Text strong>Total: ${total}</Typography.Text>
                    </div>
                  }
                >
                  {selectedFood.map((data, i) => (
                    <ListItem
                      key={i}
                      data={data}
                      readMode
                      //  inner
                    />
                  ))}
                </List>
              </div>
            </List.Item>
            {order_type === orderTypes.DELIVERY || userData?.desc ? (
              <List.Item>
                <Row>
                  {order_type === orderTypes.DELIVERY ? (
                    <>
                      <Col span={24}>
                        <Typography.Text keyboard>
                          {formatPhoneNumberIntl(userData?.phoneNumber) ||
                            "UNKNOWN PHONE NUMBER"}
                        </Typography.Text>
                      </Col>
                      <Col>
                        <Typography.Text keyboard>
                          {userData?.address || "UNKNOWN ADDRESS"}
                        </Typography.Text>{" "}
                      </Col>
                      <Col>
                        <Typography.Text keyboard>
                          {userData?.block || ""}{" "}
                          {userData?.roomNumber || "UNKNOWN ROOM NUMBER"}
                        </Typography.Text>
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                  {userData?.desc ? (
                    <Col span={24}>
                      <Typography.Text keyboard>
                        {userData?.desc}
                      </Typography.Text>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {userData?.call ? (
                    <Col span={24}>
                      <Typography.Text keyboard>CALL</Typography.Text>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </List.Item>
            ) : (
              <></>
            )}

            {order_type === orderTypes.DELIVERY ? (
              <List.Item>
                {driver ? (
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      Driver: <Tag color="green">{driver?.fullname}</Tag>
                    </Col>
                  </Row>
                ) : (
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Tag>NO DRIVER</Tag>
                    </Col>
                  </Row>
                )}
              </List.Item>
            ) : (
              <></>
            )}
            <List.Item>
              <div>
                <Tag color={madeByBaker || madeByBakerAt ? "green" : ""}>
                  🧑‍🍳{madeByBaker || madeByBakerAt ? "Yes" : "No"}
                </Tag>
                {order_type === orderTypes.DELIVERY ? (
                  <Tag color={accepted_by_driver ? "green" : ""}>
                    🚛{accepted_by_driver ? "Yes" : "No"}
                  </Tag>
                ) : (
                  <></>
                )}
                <Tag color={Object.keys(payment || {})?.length ? "green" : ""}>
                  💰{Object.keys(payment || {})?.length ? "Yes" : "No"}
                </Tag>
              </div>
            </List.Item>
          </List>
        </Card>
      </Watermark>
    </Wrapper>
  );
}

export default SingleOrderRow;
