import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
  SettingOutlined
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Input,
  List,
  Radio,
  Row,
  Switch,
  Tabs,
  Tag,
  Typography
} from "antd";
import React, { useEffect, useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import "swiper/css";
import { LangText } from "../../components/langManagement";
import { crustList } from "../../constants/crust";
import { NO } from "../../constants/general";
import { orderTypes } from "../../constants/orderType";
import size from "../../constants/size";
import { calcTotals, extraPriceCalc } from "../../utils/calculate";
import {
  useAddOrder,
  useEditOrder,
  useGetDrivers,
  useGetMe,
  useGetMenuSubCategory,
  useGetOrderTemplate,
  useGetOrders,
} from "../../zustand/store";
import OrdersDrawer from "../ordersListPage/ordersDrawer";
import PaymentModal from "../singleOrderOfDriver/paymentModal";
import { checkOTS, ot_s } from "../usersPage/staffSection/staffForm";
import AmountModal from "./amountModal";
import AssignDriver from "./assignDriver";
import CustomizationModal from "./customizationModal";
import ItemsOfCategory from "./itemsOfCategory";
import SettingModal from "./settingModal";
import SpecialCustomizationModal from "./specialCustomizationModal";
import {
  CartHeader,
  CloseCart,
  CustomMenuTabs,
  CustomTabs,
  FoodList,
  MenuPart,
  ReceiptFooter,
  ReceiptPart,
  SavedOrdersBtn,
  SmallCartBar,
  SwipeSide,
  Wrapper
} from "./styles";
import UserDataModal from "./userDataModal";

const SwipeToDelete = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "red",
        color: "white",
      }}
    >
      <DeleteOutlined />
    </div>
  );
};

const SwipeToEdit = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "lightgreen",
        color: "white",
      }}
    >
      <EditOutlined />
    </div>
  );
};

export const ListItem = ({
  data,
  i,
  selectedFood,
  setSelectedFood,
  inner,
  readMode,
}) => {
  let {
    category,
    createdAt,
    createdBy,
    id,
    ingredients,
    name,
    price,
    size: s,
    updatedAt,
    updatedBy,
    amount,
    only,
    no,
    // toppings,
    // toppingPrice,
    half,
    flavors,
    selectedFlavors,
    desc,
    selectedOption,
    options,
    crust,
    extra,
    extraPrice,
    selectedExtra,
  } = data;
  extraPrice = extraPrice || extraPriceCalc(selectedOption) || 0;
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [transition, setTransition] = useState(0.5);
  const [amountPopUp, setAmountPopUp] = useState(false);
  const [customizationPopUp, setCustomizationPopUp] = useState(false);
  const [specialCustomization, setSpecialCustomization] = useState(null);
  const distance =
    touchStart !== null && touchEnd !== null ? touchStart - touchEnd : 0;
  let swipeSetting = localStorage.getItem("swipe_selected_food");
  const [left, right] = swipeSetting?.split("_") || ["ls", "rd"];

  const minSwipeDistance = 50;
  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
    // setTransition(0.5);
    setTransition(0);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientX);

  const openCustomization = () => {
    setCustomizationPopUp(true);
    setTouchStart(null);
    setTouchEnd(null);
  };
  const deleteItem = (side) => {
    if (side === "left") {
      setTouchStart(375);
      setTouchEnd(0);
    } else if (side === "right") {
      setTouchStart(-375);
      setTouchEnd(0);
    }
    setTimeout(() => {
      setSelectedFood((prev) => prev.filter((pf, j) => j !== i));
      setTouchStart(null);
      setTouchEnd(null);
      setTransition(0);
    }, 500);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) {
      setTouchStart(null);
      setTouchEnd(null);
      return;
    }
    setTransition(0.5);

    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if ((isRightSwipe && right === "rs") || (isLeftSwipe && left === "ls")) {
      openCustomization();
      // setCustomizationPopUp(true);
      // setTouchStart(null);
      // setTouchEnd(null);
    } else if (
      (isRightSwipe && right === "rd") ||
      (isLeftSwipe && left === "ld")
      // isLeftSwipe
      // || isRightSwipe
    ) {
      deleteItem(
        isRightSwipe && right === "rd"
          ? "right"
          : isLeftSwipe && left === "ld"
            ? "left"
            : ""
      );
      // setTouchStart(350);
      // setTouchEnd(0);
      // setTimeout(() => {
      //   setSelectedFood((prev) => prev.filter((pf, j) => j !== i));
      //   setTouchStart(null);
      //   setTouchEnd(null);
      //   setTransition(0);
      // }, 500);
    } else {
      setTouchStart(null);
      setTouchEnd(null);
    }
  };

  return (
    <React.Fragment key={i}>
      <List.Item
        style={{
          transform: `translate(${
            //   -(touchStart !== null &&
            // touchEnd !== null &&
            // touchStart - touchEnd > 0
            //   ? touchStart - touchEnd
            //   : 0)
            touchStart !== null && touchEnd !== null ? touchEnd - touchStart : 0
            // -(touchStart !== null &&
            // touchEnd !== null &&
            // touchStart - touchEnd > 0
            //   ? touchStart - touchEnd
            //   : 0)
            }px)`,
          transitionDuration: `${transition}s`,
          userSelect: "none",
          paddingRight: "0",
          paddingLeft: "0",
          position: "relative",
        }}
        onTouchStart={!inner && !readMode ? onTouchStart : () => { }}
        onTouchMove={!inner && !readMode ? onTouchMove : () => { }}
        onTouchEnd={!inner && !readMode ? onTouchEnd : () => { }}
      >
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ backgroundColor: "yellow" }}>
                <div
                  onClick={() => {
                    if (!inner && !readMode) {
                      setAmountPopUp({
                        popUp: true,
                        data: {
                          category,
                          createdAt,
                          createdBy,
                          id,
                          ingredients,
                          name,
                          price,
                          size: s,
                          updatedAt,
                          updatedBy,
                          amount,
                        },
                      });
                    }
                  }}
                >
                  <div>
                    {amount > 1 ? (
                      <Badge color="#52c41a" count={amount} />
                    ) : (
                      <></>
                    )}
                    {name} {s ? <Tag>{size?.[s]}</Tag> : ""}
                    {!inner && !half && amount === 1 ? (
                      <>
                        ${price}
                        {extraPrice ? ` + $${extraPrice}` : ""}
                      </>
                    ) : (
                      <></>
                    )}{" "}
                    {category === "Pizza" && crust ? (
                      <div>{crustList[crust]}</div>
                    ) : (
                      <></>
                    )}
                    {/* ${price}{" "} */}
                  </div>
                  {!inner && amount > 1 && !half ? (
                    <div>
                      {extraPrice ? "(" : ""}${price}
                      {extraPrice ? ` + $${extraPrice})` : ""} x {amount} = $
                      {((price + (extraPrice || 0)) * amount).toFixed(2)}
                    </div>
                  ) : !inner && Boolean(half) ? (
                    <div>
                      {extraPrice ? "(" : ""}${price}
                      {extraPrice ? ` + $${extraPrice})` : ""} x {0.5 * amount}{" "}
                      = ${((price + extraPrice) * 0.5 * amount).toFixed(2)}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              {!inner && !readMode && (
                <div style={{ width: "30px", height: "30px", display: "flex" }}>
                  <SettingOutlined
                    onClick={() => {
                      if (options && options?.length) {
                        setSpecialCustomization(data);
                      } else {
                        setCustomizationPopUp(true);
                      }
                    }}
                    style={{ fontSize: "26px", margin: "auto" }}
                  />
                </div>
              )}
            </div>
            <div>
              <List
                style={{ width: "fit-content" }}
                size="small"
                onClick={() => {
                  if (!inner && !readMode) {
                    if (options && options?.length) {
                      setSpecialCustomization(data);
                    } else {
                      setCustomizationPopUp(true);
                    }
                    // setCustomizationPopUp(true);
                  }
                }}
              >
                {no?.map((v, i) => (
                  <List.Item key={"NO" + i}>
                    <Tag color="red">NO</Tag> {v}
                  </List.Item>
                ))}
                {only?.map((v, i) => (
                  <List.Item key={"ONLY" + i}>
                    <Tag color={v?.side === NO ? "red" : "yellow"}>
                      {v?.side || "ONLY"}
                    </Tag>
                    {v?.name || v}
                  </List.Item>
                ))}
                {selectedExtra?.map((v, i) => (
                  <List.Item key={"TOPPING" + i}>
                    <Tag color="green">{v?.side || "ADD"}</Tag>
                    {v?.name || v}
                  </List.Item>
                ))}
                {selectedFlavors?.length ? (
                  <List.Item key={"FLAVOR"}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px 0",
                      }}
                    >
                      {[...new Set(selectedFlavors?.map(({ name }) => name))]
                        ?.length === 1 ? (
                        <Tag
                          // style={{fontSize:"20px"}}
                          key={`FLAVOR_TAG_${i}`}
                          color="green"
                        >
                          {selectedFlavors?.[0]?.name}
                        </Tag>
                      ) : (
                        selectedFlavors?.map((v, i) => (
                          <>
                            <Tag key={`FLAVOR_TAG_${i}`} color="green">
                              {v?.name}
                            </Tag>
                            {/* <Tag key={`FLAVOR_TAG_${i}`} color="green">
                            {v?.name}
                          </Tag>
                          <Tag key={`FLAVOR_TAG_${i}`} color="green">
                            {v?.name}
                          </Tag> */}
                          </>
                        ))
                      )}
                    </div>
                  </List.Item>
                ) : (
                  <></>
                )}
                {desc ? (
                  <List.Item key={"DESCRIPTION"}>
                    <Tag color="blue">{desc}</Tag>
                  </List.Item>
                ) : (
                  <></>
                )}
                {selectedOption?.map((sOption, i) => (
                  <List.Item
                    style={{ paddingTop: 0, paddingBottom: 0 }}
                    key={"OPTION" + i}
                  >
                    <div>
                      <List size="small" style={{ paddingBottom: 0 }}>
                        {sOption?.map((data, i) => (
                          <ListItem
                            key={i}
                            inner
                            data={{
                              ...data,
                              name: data?.name,
                              // ||menu?.data?.find(
                              //   ({ id: mId }) => mId === data?.id
                              // )?.name,
                            }}
                          />
                        ))}
                      </List>
                    </div>
                  </List.Item>
                ))}
              </List>
            </div>
            {!inner && !readMode ? (
              <div
                style={{
                  marginTop: "10px",
                }}
              >
                <Row>
                  <Col>
                    {amount === 1 ? (
                      <Button
                        size="large"
                        danger
                        onClick={() => {
                          setSelectedFood((prev) =>
                            prev.filter((pf, j) => j !== i)
                          );
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        ghost
                        size="large"
                        onClick={() => {
                          setSelectedFood((prev) =>
                            prev.map((sf, j) =>
                              j === i ? { ...sf, amount: amount - 1 } : sf
                            )
                          );
                        }}
                      >
                        <MinusOutlined />
                      </Button>
                    )}
                  </Col>
                  <Col>
                    <div
                      style={{
                        padding: "0 20px",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      onClick={() => {
                        if (!inner && !readMode) {
                          setAmountPopUp({
                            popUp: true,
                            data: {
                              category,
                              createdAt,
                              createdBy,
                              id,
                              ingredients,
                              name,
                              price,
                              size: s,
                              updatedAt,
                              updatedBy,
                              amount,
                            },
                          });
                        }
                      }}
                    >
                      <b>{amount}</b>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      ghost
                      size="large"
                      onClick={() => {
                        // if (amount === 0) {
                        //   setSelectedFood((prev) =>
                        //     prev.filter((pf, j) => j !== i)
                        //   );
                        // } else {
                        setSelectedFood((prev) =>
                          prev.map((sf, j) =>
                            j === i ? { ...sf, amount: amount + 1 } : sf
                          )
                        );
                        // }
                      }}
                    >
                      <PlusOutlined />
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <SwipeSide
          opacity={distance < -minSwipeDistance}
          visible={distance < -10}
          side="left"
        >
          {right === "rd" ? (
            <SwipeToDelete />
          ) : right === "rs" ? (
            <SwipeToEdit />
          ) : (
            <></>
          )}
        </SwipeSide>

        <SwipeSide
          opacity={distance > minSwipeDistance}
          visible={distance > 10}
          side="right"
        >
          {left === "ld" ? (
            <SwipeToDelete />
          ) : left === "ls" ? (
            <SwipeToEdit />
          ) : (
            <></>
          )}
        </SwipeSide>
      </List.Item>
      <AmountModal
        onOk={({ amount }) => {
          if (amount === 0) {
            setSelectedFood((prev) => prev.filter((pf, j) => j !== i));
          } else {
            setSelectedFood((prev) =>
              prev.map((sf, j) => (j === i ? { ...sf, amount } : sf))
            );
          }
          setAmountPopUp(false);
        }}
        data={data}
        open={amountPopUp}
        onCancel={() => setAmountPopUp(false)}
      />
      <CustomizationModal
        i={i}
        open={customizationPopUp}
        data={data}
        onCancel={() => {
          setCustomizationPopUp(false);
        }}
        selectedFood={selectedFood}
        setSelectedFood={setSelectedFood}
      />
      <SpecialCustomizationModal
        open={Boolean(specialCustomization)}
        data={specialCustomization}
        onCancel={() => setSpecialCustomization(null)}
        onOk={(data) => {
          setSelectedFood((prev) =>
            prev.map((v, index) => (index === i ? data : v))
          );
          // addFood(data);
          setSpecialCustomization(null);
        }}
      />
    </React.Fragment>
  );
};

function AddOrderPage() {
  const addOrder = useAddOrder();
  const editOrder = useEditOrder();
  const getOrders = useGetOrders();
  const getMenu = useGetOrderTemplate();
  const menuSubCategory = useGetMenuSubCategory();
  const drivers = useGetDrivers();
  const me = useGetMe();
  const [selectedFood, setSelectedFood] = useState([]);
  const [menuId, setMenuId] = useState();
  const [userDataPopUp, setUserDataPopUp] = useState(false);
  const [settingPopUp, setSettingPopUp] = useState(false);
  const [userData, setUserData] = useState({});
  const [assignDriver, setAssignDriver] = useState({ open: false });
  const [driver, setDriver] = useState(null);
  const [savedOrders, setSavedOrders] = useState(false);
  const [editId, setEditId] = useState(null);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [orderType, setOrderType] = useState("");
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [withTax, setWithTax] = useState(true);
  const [menuType, setMenuType] = useState("");
  const [checkoutSide, setCheckoutSide] = useState("");

  const { subTotal, tax, deliveryFee, total, serviceFee } = calcTotals(
    selectedFood,
    userData?.deliveryFee,
    me?.data?.restaurant?.serviceFee,
    orderType,
    withTax
  );

  const changeOrderType = () => {
    const savedOrderType = localStorage.getItem("orderType");
    setOrderType(savedOrderType || orderTypes.DELIVERY);
  };

  useEffect(() => {
    if (orderType === orderTypes.DELIVERY) {
      setUserDataPopUp(true);
    }
  }, [orderType]);

  useEffect(() => {
    getMenu.execute();
    getOrders.execute();
    drivers.execute();
    menuSubCategory.execute();

    changeOrderType();

    let mType = localStorage.getItem("menuType");
    if (!mType) {
      mType = "horizontal";
      localStorage.setItem("menuType", mType);
    }
    setMenuType(mType);

    let chSide = localStorage.getItem("checkoutSide");
    if (!chSide) {
      chSide = "right";
      localStorage.setItem("checkoutSide", chSide);
    }
    setCheckoutSide(chSide);

    let swipeSetting = localStorage.getItem("swipe_selected_food");
    if (!swipeSetting) {
      swipeSetting = "ls_rd";
      localStorage.setItem("swipe_selected_food", swipeSetting);
    }
  }, []);

  useEffect(() => {
    if (editId) {
      const order = getOrders?.data.find(({ id }) => id === editId);

      setOrderType(order?.order_type);
      setSelectedFood(order?.selectedFood || []);
      setUserData(order?.userData || {});
      setWithTax(true);
      setDriver(order?.driver?.[0]?.id);
    }
  }, [editId]);

  const selectMenuById = (mId) => {
    mId = Number(mId);
    setMenuId(mId);
    localStorage.setItem("menuId", mId);
  };

  useEffect(() => {
    if (getMenu.data) {
      let mId = localStorage.getItem("menuId");
      mId = Number(mId);
      mId = mId || getMenu.data?.[0]?.id;

      selectMenuById(mId)
    }
  }, [getMenu.data]);

  const saveOrder = (payment, orderType) => {
    (editId ? editOrder : addOrder).execute({
      data: {
        selectedFood,
        userData,
        driver,
        payment,
        orderType,
        totals: { subTotal, tax, deliveryFee, total, serviceFee },
      },
      params: { id: editId },
      onSuccess: () => {
        setSelectedFood([]);
        setUserData({});
        setWithTax(true);
        setDriver(null);
        setEditId(null);
        setPaymentOpen(false);
        setIsCartOpen(false);
        if (orderType === orderTypes.DELIVERY) {
          setUserDataPopUp(true);
        }
      },
    });
  };

  const ots = checkOTS(me);

  // const categoryList =
  //   selectedMenu?.menu?.map(({ category }) => category) || [];

  return (
    <Wrapper checkoutSide={checkoutSide}>
      <MenuPart id="main-part">
        <CustomMenuTabs
          tabBarGutter={0}
          activeKey={menuId}
          onChange={selectMenuById}
          type="card"
          tabBarExtraContent={
            <Row gutter={[5, 5]}>
              <Col>
                <Button
                  size="large"
                  onClick={() => setSettingPopUp(true)}>
                  <SettingOutlined />
                </Button>
              </Col>
              {ots(ot_s.see_saved_orders) ? (
                <Col>
                  <Button
                    type="primary"
                    size="large"
                    // style={{ marginLeft: "auto", display: "block" }}
                    onClick={() => {
                      setSavedOrders(true);
                    }}
                  >
                    <SavedOrdersBtn>
                      <div className="text">Orders</div>
                      <div className="icon">
                        <RightOutlined />
                      </div>
                    </SavedOrdersBtn>
                  </Button>
                </Col>
              ) : (
                <></>
              )}
            </Row>
          }
          items={
            getMenu.data?.map(({ id, name, categories }) => ({
              label: name,
              key: id,
              children: (<>
                {menuType === "horizontal" ? (
                  <CustomTabs
                    tabBarGutter={0}
                    // defaultActiveKey="1"
                    // tabBarStyle={{color:"red"}}
                    type="card"
                    // tabBarStyle={{backgroundColor:"red"}}
                    // tabBarExtraContent={
                    //   <Row gutter={[5, 5]}>
                    //     <Col>
                    //       <MenuDropDown
                    //         checkoutSide={checkoutSide}
                    //         setCheckoutSide={setCheckoutSide}
                    //         menuType={menuType}
                    //         setMenuType={setMenuType}
                    //         selectedMenu={selectedMenu}
                    //         setSelectedMenu={setSelectedMenu}
                    //         settingPopUp={settingPopUp}
                    //         setSettingPopUp={setSettingPopUp}
                    //       />
                    //     </Col>
                    //     {ots(ot_s.see_saved_orders) ? (
                    //       <Col>
                    //         <Button
                    //           type="primary"
                    //           size="large"
                    //           onClick={() => {
                    //             setSavedOrders(true);
                    //           }}
                    //         >
                    //           <SavedOrdersBtn>
                    //             <div className="text">Orders</div>
                    //             <div className="icon">
                    //               <RightOutlined />
                    //             </div>
                    //           </SavedOrdersBtn>
                    //         </Button>
                    //       </Col>
                    //     ) : (
                    //       <></>
                    //     )}
                    //   </Row>
                    // }
                    // tabPosition="left"
                    style={{
                      height: "100vh",
                      // backgroundColor: "lightblue"
                    }}
                  >
                    {
                      // selectedMenu?.
                      categories
                        ?.filter(({ pinned }) => !pinned)
                        ?.map((category, i) => (
                          // {
                          // label: category.name,
                          // key: i,
                          // style: { backgroundColor: category.color },
                          // color: category.color,
                          // children: (
                          <Tabs.TabPane
                            buttonStyle={{
                              backgroundColor: category?.color,
                              // color: category?.color,
                            }}
                            className="something"
                            style={{
                              backgroundColor: category?.color,
                              // color: category?.color,
                            }}
                            tab={
                              <div
                                style={{
                                  backgroundColor: category?.color,
                                  // padding: "8px 16px",
                                  display: "inline-block",
                                  border: 0,
                                  padding: "9px 16px",
                                  borderRadius: "10px 10px 0 0",
                                }}
                              >
                                {category?.name}
                              </div>
                            }
                            key={category?.id}
                          >
                            <div
                              style={{
                                height: "calc(100vh - 40px)",
                                overflow: "auto",
                                // position: "relative",
                                // backgroundColor: category?.color,
                                paddingTop: "13px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                              key={i}
                            >
                              {/* <div> */}
                              <div style={{ overflow: "auto" }}>
                                <ItemsOfCategory
                                  lastOne
                                  addFood={(food) => {
                                    const foundSelectedFood = selectedFood.find(
                                      ({ id, no, only, toppings, half }) =>
                                        id === food.id &&
                                        (!no || no?.length === 0) &&
                                        (!only || only?.length === 0) &&
                                        (!toppings || toppings?.length === 0) &&
                                        (!half || Object.keys(half).length === 0)
                                    );
                                    if (!foundSelectedFood) {
                                      setSelectedFood((prev) => [
                                        ...prev,
                                        { ...food, amount: 1 },
                                      ]);
                                    } else {
                                      setSelectedFood((prev) =>
                                        prev.map((sf) =>
                                          JSON.stringify(foundSelectedFood) ===
                                            JSON.stringify(sf)
                                            ? // sf.id === food.id
                                            { ...sf, amount: sf.amount + 1 }
                                            : sf
                                        )
                                      );
                                    }
                                  }}
                                  selectedFood={selectedFood}
                                  setSelectedFood={setSelectedFood}
                                  menuItems={
                                    // selectedMenu?.menu?.filter(
                                    //   ({ category: c, name }) =>
                                    //     category === c && name !== additionalTopping
                                    // )
                                    category?.items
                                  }
                                />
                              </div>

                              <div
                                style={{
                                  height: "fit-content",
                                  borderTop: "1px solid black",
                                }}
                              >
                                {
                                  // selectedMenu?.
                                  categories
                                    ?.filter(({ pinned }) => pinned)
                                    ?.map((category, i, a) => (
                                      <div>
                                        <div
                                          style={{
                                            // height: "calc(100vh - 56px)",
                                            overflow: "auto",
                                            position: "relative",
                                            backgroundColor: category.color,
                                            // border:"1px solid black"
                                          }}
                                          key={i}
                                        >
                                          <div
                                            style={{
                                              // fontSize: "20px",
                                              // fontWeight: "bold",
                                              padding: "0 10px 10px",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "end",
                                            }}
                                          >
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                              }}
                                            >
                                              <Typography.Text
                                                // type="success"
                                                // keyboard
                                                underline
                                                style={{
                                                  fontSize: "20px",
                                                  fontWeight: "bold",
                                                  // color: "white",
                                                  // padding: "0 13px 10px",
                                                }}
                                              // mark
                                              >
                                                {category.name}
                                              </Typography.Text>
                                            </div>
                                          </div>
                                          <ItemsOfCategory
                                            pinned
                                            lastOne={a.length - 1 === i}
                                            addFood={(food) => {
                                              const foundSelectedFood = selectedFood.find(
                                                ({ id, no, only, toppings, half }) =>
                                                  id === food.id &&
                                                  (!no || no?.length === 0) &&
                                                  (!only || only?.length === 0) &&
                                                  (!toppings || toppings?.length === 0) &&
                                                  (!half || Object.keys(half).length === 0)
                                              );
                                              if (!foundSelectedFood) {
                                                setSelectedFood((prev) => [
                                                  ...prev,
                                                  { ...food, amount: 1 },
                                                ]);
                                              } else {
                                                setSelectedFood((prev) =>
                                                  prev.map((sf) =>
                                                    JSON.stringify(foundSelectedFood) ===
                                                      JSON.stringify(sf)
                                                      ? // sf.id === food.id
                                                      { ...sf, amount: sf.amount + 1 }
                                                      : sf
                                                  )
                                                );
                                              }
                                            }}
                                            selectedFood={selectedFood}
                                            setSelectedFood={setSelectedFood}
                                            menuItems={
                                              // selectedMenu?.menu?.filter(
                                              //   ({ category: c, name }) =>
                                              //     category === c && name !== additionalTopping
                                              // )
                                              category?.items
                                            }
                                          />
                                        </div>
                                      </div>
                                    ))}
                              </div>
                              {/* </div> */}
                            </div>
                          </Tabs.TabPane>
                          //   ),
                          // }
                        ))}
                  </CustomTabs>
                ) : menuType === "vertical" ? (
                  <div
                    style={{
                      height: "calc(100vh - 40px)",
                      overflow: "auto",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        height: "calc(100vh - 40px)",
                      }}
                    >
                      <div
                        style={{
                          overflow: "auto",
                          // borderTop: "1px solid black",
                          // borderLeft: "1px solid black",
                          // borderRight: "1px solid black",
                          // borderBottom: "1px solid black",
                        }}
                      >
                        {
                          // selectedMenu?.
                          categories
                            ?.filter(({ pinned }) => !pinned)
                            ?.map((category, i, a) => (
                              <>
                                <div
                                  style={{
                                    // height: "calc(100vh - 56px)",
                                    overflow: "auto",
                                    position: "relative",
                                    backgroundColor: category.color,
                                    // ...(i > 0 ? { 
                                    borderTop: "1px solid black"
                                    // } : {}),
                                  }}
                                  key={i}
                                >
                                  <div
                                    style={{
                                      // fontSize: "20px",
                                      // fontWeight: "bold",
                                      padding: "0 10px 10px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        // fontSize: "30px",
                                        fontWeight: "bold",
                                        // padding: "0 13px 10px",
                                      }}
                                    >
                                      <Typography.Text
                                        // type="success"
                                        // keyboard
                                        underline
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          // color: "white",
                                          // padding: "0 13px 10px",
                                        }}
                                      // mark
                                      >
                                        {category.name}
                                      </Typography.Text>
                                    </div>
                                    {/* {i === 0 ? (
                                      <div style={{ marginTop: "10px" }}>
                                        <Row gutter={[5, 5]}>
                                          <Col>
                                            <MenuDropDown
                                              checkoutSide={checkoutSide}
                                              setCheckoutSide={setCheckoutSide}
                                              menuType={menuType}
                                              setMenuType={setMenuType}
                                              selectedMenu={selectedMenu}
                                              setSelectedMenu={setSelectedMenu}
                                              settingPopUp={settingPopUp}
                                              setSettingPopUp={setSettingPopUp}
                                            />
                                          </Col>
                                          {ots(ot_s.see_saved_orders) ? (
                                            <Col>
                                              <Button
                                                type="primary"
                                                size="large"
                                                // style={{ marginLeft: "auto", display: "block" }}
                                                onClick={() => {
                                                  setSavedOrders(true);
                                                }}
                                              >
                                                <SavedOrdersBtn>
                                                  <div className="text">Orders</div>
                                                  <div className="icon">
                                                    <RightOutlined />
                                                  </div>
                                                </SavedOrdersBtn>
                                              </Button>
                                            </Col>
                                          ) : (
                                            <></>
                                          )}
                                        </Row>
                                      </div>
                                    ) : (
                                      <></>
                                    )} */}
                                  </div>
                                  <ItemsOfCategory
                                    lastOne={
                                      // selectedMenu?.
                                      categories?.length !== a?.length &&
                                      // selectedMenu?.
                                      categories.length - 1 === i
                                    }
                                    addFood={(food) => {
                                      const foundSelectedFood = selectedFood.find(
                                        ({ id, no, only, toppings, half }) =>
                                          id === food.id &&
                                          (!no || no?.length === 0) &&
                                          (!only || only?.length === 0) &&
                                          (!toppings || toppings?.length === 0) &&
                                          (!half || Object.keys(half).length === 0)
                                      );
                                      if (!foundSelectedFood) {
                                        setSelectedFood((prev) => [
                                          ...prev,
                                          { ...food, amount: 1 },
                                        ]);
                                      } else {
                                        setSelectedFood((prev) =>
                                          prev.map((sf) =>
                                            JSON.stringify(foundSelectedFood) ===
                                              JSON.stringify(sf)
                                              ? // sf.id === food.id
                                              { ...sf, amount: sf.amount + 1 }
                                              : sf
                                          )
                                        );
                                      }
                                    }}
                                    selectedFood={selectedFood}
                                    setSelectedFood={setSelectedFood}
                                    menuItems={
                                      // selectedMenu?.menu?.filter(
                                      //   ({ category: c, name }) =>
                                      //     category === c && name !== additionalTopping
                                      // )
                                      category?.items
                                    }
                                  />
                                </div>
                              </>
                            ))}
                      </div>
                      <div
                        style={{
                          height: "fit-content",
                          borderTop: "1px solid black",
                          // borderBottom: "1px solid black",
                          // borderLeft: "1px solid black",
                        }}
                      >
                        {
                          // selectedMenu?.
                          categories
                            ?.filter(({ pinned }) => pinned)
                            ?.map((category, i, a) => (
                              <>
                                <div
                                  style={{
                                    // height: "calc(100vh - 56px)",
                                    overflow: "auto",
                                    position: "relative",
                                    backgroundColor: category.color,
                                    // border:"1px solid black"
                                  }}
                                  key={i}
                                >
                                  <div
                                    style={{
                                      // fontSize: "20px",
                                      // fontWeight: "bold",
                                      padding: "0 10px 10px",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "end",
                                    }}
                                  >
                                    <div
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <Typography.Text
                                        // type="success"
                                        // keyboard
                                        underline
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                          // color: "white",
                                          // padding: "0 13px 10px",
                                        }}
                                      // mark
                                      >
                                        {category.name}
                                      </Typography.Text>
                                    </div>
                                  </div>
                                  <ItemsOfCategory
                                    pinned
                                    lastOne={a.length - 1 === i}
                                    addFood={(food) => {
                                      const foundSelectedFood = selectedFood.find(
                                        ({ id, no, only, toppings, half }) =>
                                          id === food.id &&
                                          (!no || no?.length === 0) &&
                                          (!only || only?.length === 0) &&
                                          (!toppings || toppings?.length === 0) &&
                                          (!half || Object.keys(half).length === 0)
                                      );
                                      if (!foundSelectedFood) {
                                        setSelectedFood((prev) => [
                                          ...prev,
                                          { ...food, amount: 1 },
                                        ]);
                                      } else {
                                        setSelectedFood((prev) =>
                                          prev.map((sf) =>
                                            JSON.stringify(foundSelectedFood) ===
                                              JSON.stringify(sf)
                                              ? // sf.id === food.id
                                              { ...sf, amount: sf.amount + 1 }
                                              : sf
                                          )
                                        );
                                      }
                                    }}
                                    selectedFood={selectedFood}
                                    setSelectedFood={setSelectedFood}
                                    menuItems={
                                      // selectedMenu?.menu?.filter(
                                      //   ({ category: c, name }) =>
                                      //     category === c && name !== additionalTopping
                                      // )
                                      category?.items
                                    }
                                  />
                                </div>
                              </>
                            ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>)
            }))
          }
        />

      </MenuPart>
      <SmallCartBar
        onClick={() => {
          setIsCartOpen(true);
        }}
      >
        <div>
          <div style={{ fontSize: "20px" }}>
            <b>View Order</b>
          </div>
          <div style={{ fontSize: "16px" }}>${total}</div>
        </div>
        <Badge
          size="large"
          color="#6045e2"
          count={selectedFood?.length || 0}
          showZero
        />
      </SmallCartBar>
      <ReceiptPart open={isCartOpen}>
        <FoodList>
          <Card
            style={{
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 9,
              border: 0,
              borderRadius: 0,
              borderBottom: "1px solid black",
            }}
            size="small"
          >
            <CartHeader>
              <Radio.Group
                style={{ width: "100%" }}
                value={orderType}
                buttonStyle="solid"
                onChange={(e) => {
                  const type = e.target.value;
                  localStorage.setItem("orderType", type);
                  changeOrderType();
                  // setOrderType(type);
                }}
              >
                {ots(ot_s.types)?.map((order_type) => (
                  <Radio.Button value={order_type}>{order_type}</Radio.Button>
                ))}
              </Radio.Group>
              <CloseCart onClick={() => setIsCartOpen(false)}>
                <DownOutlined />
              </CloseCart>
            </CartHeader>
            {/* {LangText({ id: "selectedFood" })} */}
          </Card>
          <div style={{ padding: "0 13px" }}>
            <List
              size="small"
              style={{ overflow: "auto" }}
            // bordered
            >
              {selectedFood?.map((data, i) => (
                <ListItem
                  data={data}
                  i={i}
                  selectedFood={selectedFood}
                  setSelectedFood={setSelectedFood}
                // setAmountPopUp={setAmountPopUp}
                // amountPopUp={amountPopUp}
                />
              ))}
            </List>
          </div>
        </FoodList>
        <ReceiptFooter>
          <List
            //  bordered
            style={{ borderTop: "1px solid black" }}
            size="small"
          >
            <List.Item style={{ padding: "10px" }}>
              <div>
                <div style={{ fontSize: "15px", lineHeight: "1.4" }}>
                  <div>SubTotal: ${subTotal}</div>
                  {orderType === orderTypes.DELIVERY ? (
                    <div>
                      Delivery and Service Fee: ${deliveryFee + serviceFee}
                    </div>
                  ) : (
                    <></>
                  )}
                  <div>
                    <Row gutter={[10, 10]}>
                      <Col>Tax: ${tax}</Col>
                      <Col>
                        <Switch checked={withTax} onChange={setWithTax} />
                      </Col>
                    </Row>
                  </div>
                </div>

                <hr />
                <div>
                  <Typography.Text style={{ fontSize: "18px" }} strong>
                    Total: ${total}
                  </Typography.Text>
                </div>
              </div>
            </List.Item>
            <List.Item
              style={{
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {orderType === orderTypes.DELIVERY ? (
                <div>
                  <div
                    style={{}}
                    onClick={() => {
                      setUserDataPopUp(true);
                    }}
                  >
                    <Typography.Text keyboard>
                      {formatPhoneNumberIntl(userData?.phoneNumber) ||
                        "UNKNOWN PHONE NUMBER"}
                    </Typography.Text>
                    <br />
                    <Typography.Text keyboard>
                      {userData?.address || "UNKNOWN ADDRESS"}
                    </Typography.Text>{" "}
                    <Typography.Text keyboard>
                      {userData?.block || ""}{" "}
                      {userData?.roomNumber || "UNKNOWN ROOM NUMBER"}
                    </Typography.Text>
                    {userData?.desc ? (
                      <div>
                        <Typography.Text keyboard>
                          {userData?.desc}
                        </Typography.Text>
                      </div>
                    ) : (
                      <></>
                    )}
                    {userData?.call ? (
                      <div>
                        <Typography.Text keyboard>CALL</Typography.Text>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {driver ? (
                    <div>
                      <Typography.Text keyboard>
                        Driver:{" "}
                        <Typography.Text underline strong>
                          {drivers?.data?.find(({ id }) => id === driver)
                            ?.fullname || driver}
                        </Typography.Text>
                      </Typography.Text>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div style={{ width: "100%" }}>
                  <div>
                    <LangText id="noteForKitchen" />
                  </div>
                  <Input.TextArea
                    value={userData?.desc}
                    onChange={(e) => {
                      setUserData((prev) => ({
                        ...prev,
                        desc: e.target.value,
                      }));
                    }}
                  />
                </div>
              )}
            </List.Item>
            <List.Item style={{ padding: "10px" }}>
              <Row
                gutter={[10, 10]}
                style={{ width: "100%" }}
                justify={"space-between"}
              // alignItems={"center"}
              >
                {orderType === orderTypes.DINE_IN ||
                  orderType === orderTypes.TO_GO ||
                  orderType === orderTypes.PICK_UP ? (
                  <Col>
                    <Button
                      size="large"
                      disabled={!selectedFood?.length}
                      onClick={() => {
                        setPaymentOpen(true);
                      }}
                    >
                      <LangText id="dineInTotal" />
                    </Button>
                  </Col>
                ) : (
                  <>
                    {orderType === orderTypes.DELIVERY &&
                      ots(ot_s.assign_driver) ? (
                      <>
                        <Col>
                          <Button
                            size="large"
                            disabled={
                              !userData.address || !selectedFood?.length
                            }
                            onClick={() => {
                              setAssignDriver({ open: true });
                            }}
                          >
                            <LangText id="assignDriver" />
                          </Button>
                        </Col>
                        <Col>
                          <Switch
                            size="large"
                            fontSize="20px"
                            checkedChildren="Call"
                            unCheckedChildren="Call"
                            checked={Boolean(userData.call)}
                            // value={Boolean(userData.call)}
                            onChange={(call) =>
                              setUserData((prev) => ({ ...prev, call }))
                            }
                          />
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
                <Col span={24}>
                  <Button
                    block
                    disabled={
                      !ots(ot_s.types)?.length ||
                      !selectedFood?.length ||
                      addOrder.loading ||
                      editOrder.loading
                    }
                    size="large"
                    type="primary"
                    loading={addOrder.loading || editOrder.loading}
                    onClick={() => {
                      saveOrder(null, orderType);
                    }}
                  >
                    <LangText id={editId ? "edit" : "save"} />
                  </Button>
                </Col>
                {editId && (
                  <Col>
                    <Button
                      size="large"
                      onClick={() => {
                        setSelectedFood([]);
                        setUserData({});
                        setWithTax(true);
                        setDriver(null);
                        setEditId(null);
                      }}
                    >
                      <LangText id="cancelEdit" />
                    </Button>
                  </Col>
                )}
              </Row>
            </List.Item>
          </List>
        </ReceiptFooter>
      </ReceiptPart>
      <UserDataModal
        selectMenuById={selectMenuById}
        open={userDataPopUp}
        onCancel={() => setUserDataPopUp(false)}
        data={userData}
        onOk={(e) => {
          setUserDataPopUp(false);
          setUserData(e);
        }}
      />
      <PaymentModal
        loading={editOrder.loading || addOrder.loading}
        open={paymentOpen}
        onCancel={() => setPaymentOpen(false)}
        onSuccess={({ sqrIdList, amount }) => {
          saveOrder({ sqrIdList, amount }, orderType);
        }}
        data={{ total }}
        dineIn
      />
      <AssignDriver
        open={assignDriver?.open}
        selectedDriver={driver}
        onOk={(driver) => {
          setDriver(driver?.id);
          setAssignDriver({ open: false });
        }}
        onCancel={() => {
          setAssignDriver({ open: false });
        }}
      />
      <OrdersDrawer
        open={savedOrders}
        onClose={() => {
          setSavedOrders(false);
          changeOrderType();
        }}
        edit={(id) => {
          setEditId(id);
          setSavedOrders(false);
        }}
        refresh={() => {
          getOrders.execute({ force: true });
        }}
      />
      <SettingModal
        menuType={menuType}
        setMenuType={setMenuType}
        checkoutSide={checkoutSide}
        setCheckoutSide={setCheckoutSide}
        open={settingPopUp}
        onCancel={() => {
          setSettingPopUp(false);
        }}
      />
    </Wrapper>
  );
}

export default AddOrderPage;
