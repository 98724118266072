import { Tabs, message } from "antd";
import React, { useEffect, useState } from "react";
import { orderTypes } from "../../constants/orderType";
import { useEditOrderDriver, useGetOrders } from "../../zustand/store";
import AssignDriver from "../makeOrderPage/assignDriver";
import SingleOrderCard from "./singleOrderCard";
import { OrdersListWrapper } from "./styles";

export const handlePrint = async (request, id) => {
  // const { origin, pathname } = window.location;
  // const href = `${origin}${pathname}`;
  // let passPRNT_uri = "starpassprnt://v1/print/nopreview?";
  // passPRNT_uri += "back=" + encodeURIComponent(href);
  // passPRNT_uri += "&html=" + encodeURIComponent(html);

  try {
    await request.execute({ params: { orderId: id } });
  } catch (err) {
    message.error("Something went wrong with printing");
  }
};

function OrdersListPage({ edit, open }) {
  const getOrders = useGetOrders();
  const editDriver = useEditOrderDriver();
  const [orderType, setOrderType] = useState("");
  const [assignDriver, setAssignDriver] = useState({
    open: false,
    driverId: null,
    orderId: null,
  });

  useEffect(() => {
    getOrders.execute();
  }, []);

  const changeOrderType = () => {
    const type = localStorage.getItem("orderType");
    setOrderType(type || orderTypes.ALL);
  };

  useEffect(() => {
    if (open) {
      changeOrderType();
    }
  }, [open]);

  return (
    <>
      <Tabs
        onChange={(e) => {
          if (e === orderTypes.ALL) {
            setOrderType(e);
          } else {
            localStorage.setItem("orderType", e);
            changeOrderType();
          }
        }}
        activeKey={orderType}
        items={[
          orderTypes.ALL,
          orderTypes.DELIVERY,
          orderTypes.DINE_IN,
          orderTypes.TO_GO,
          orderTypes.PICK_UP,
        ].map((tab, index) => {
          const all = tab === orderTypes.ALL;
          return {
            key: tab,
            label: tab,
            children: (
              <OrdersListWrapper key={index}>
                {getOrders?.error ? "Something went wrong" : ""}
                {getOrders?.data
                  ?.filter(({ order_type }) => all || tab === order_type)
                  .map((data) => {
                    return (
                      <SingleOrderCard
                        {...data}
                        edit={edit}
                        setAssignDriver={setAssignDriver}
                      />
                    );
                  })}
              </OrdersListWrapper>
            ),
          };
        })}
      />

      {/* </Masonry> */}

      <AssignDriver
        open={assignDriver?.open}
        selectedDriver={assignDriver?.driverId}
        onOk={(driver) => {
          editDriver.execute({
            data: {
              driverId: driver?.id,
              orderId: assignDriver?.orderId,
            },
            onSuccess: () => {
              getOrders.execute({ force: true });
              setAssignDriver({ open: false });
            },
          });
        }}
        onCancel={() => {
          setAssignDriver({ open: false });
        }}
      />
    </>
  );
}

export default OrdersListPage;
