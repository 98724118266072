import styled from "styled-components";
import { mainColor } from "../../../components/styles/styles";

export const LeftHalfWrapper = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 1px solid ${({ reverse }) => (reverse ? "white" : mainColor)};
  position: relative;
  overflow: hidden;
`;

export const LeftHalfCircle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
  width: 50%;
  height: 100%;
  background-color: ${({ reverse }) => (reverse ? "white" : mainColor)};
`;

export const FullCircle = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${({ reverse }) => (reverse ? "white" : mainColor)};
  border-radius: 100%;
`;

export const RightHalfWrapper = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 100%;
  border: 1px solid ${({ reverse }) => (reverse ? "white" : mainColor)};
  position: relative;
  overflow: hidden;
`;

export const RightHalfCircle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: auto;
  width: 50%;
  height: 100%;
  background-color: ${({ reverse }) => (reverse ? "white" : mainColor)};
`;
