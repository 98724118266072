import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { LangText, useLangText } from "../../../components/langManagement";
import {
  useAddPayment,
  useEditPayment,
  useGetPaymentCategories,
} from "../../../zustand/store";
const { TextArea } = Input;

function PaymentForm({
  type,
  open,
  editData,
  onClose,
  getPaymentsList,
}) {
  type = type || editData?.type;
  const getPaymentCategories = useGetPaymentCategories();
  const addPayment = useAddPayment();
  const editPayment = useEditPayment();
  const [form] = Form.useForm();
  const text = useLangText();

  useEffect(() => {
    if (type) {
      form.setFieldValue("type", type);
    }
  }, [type]);

  useEffect(() => {
    if (editData && getPaymentCategories?.data) {
      const {
        amount,
        category,
        description,
        addedAt,
      } = editData;
      form.setFieldsValue({
        // type,
        category,
        amount,
        description,
        addedAt: dayjs(addedAt),
      });
    }
  }, [editData, getPaymentCategories?.data]);

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      title={
        <Tag
          color={
            type === "INCOME" ? "#87d068" : type === "EXPENSE" ? "#f50" : ""
          }
          style={{ fontSize: "18px" }}
        >
          <span style={{ textTransform: "capitalize" }}>
            <LangText id={type} />
          </span>
        </Tag>
      }
    >
      <Form
        form={form}
        onFinish={(e) => {
          if (editData) {
            const data = {
              id: editData?.id,
              price: e?.amount,
              category: e?.category,
              description: e?.description,
            };
            editPayment.execute({
              params: data,
              onSuccess: () => {
                getPaymentsList();
                // accounts.execute();
                form.resetFields();
                onClose();
              },
            });
          } else {
            const data = { ...e, type };
            addPayment.execute({
              data,
              onSuccess: () => {
                getPaymentsList();
                // accounts.execute();
                form.resetFields();
                onClose();
              },
            });
          }
        }}
        layout="vertical"
        hideRequiredMark
      >
        <Row>
          <Col span={24}>
            <Form.Item
              name="category"
              label={<LangText id="category" />}
              rules={[
                {
                  required: true,
                  message: text({ id: "selectOrEnterCategory" }),
                },
              ]}
            >
              <Select
                mode="tags"
                options={[
                  ...new Set([
                    ...(getPaymentCategories?.data || [])?.reduce(
                      (t, { category }) => t.concat(category),
                      []
                    ),
                  ]),
                ]?.map((category) => ({
                  label: category,
                  value: category,
                }))}
                placeholder={text({ id: "selectOrEnterCategory" })}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="amount"
              label={<LangText id="price" />}
              rules={[{ required: true, message: text({ id: "enterPrice" }) }]}
            >
              <InputNumber
                min={0}
                addonBefore={"$"}
                style={{ width: "100%" }}
                placeholder={text({ id: "enterPrice" })}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                }
                parser={(value) => value.replace(/\$\s?|( *)/g, "")}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" label={<LangText id="description" />}>
              <TextArea
                rows={5}
                style={{ width: "100%" }}
                placeholder={text({ id: "enterDescription" })}
              />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item name="addedAt" label={<LangText id="addedAt" />}>
              <DatePicker
                allowClear={false}
                // defaultValue={dayjs()}
                placeholder={text({ id: "enterAddedAt" })}
              />
            </Form.Item>
          </Col> */}
          <Col span={24}>
            <Form.Item label="">
              <div style={{ display: "flex", gap: "10px" }}>
                <Button type="primary" htmlType="submit">
                  <LangText id="add" />
                </Button>
                <Button onClick={onClose} type="primary" ghost>
                  <LangText id="cancel" />
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}

export default PaymentForm;
