const list = {
  BROOKLYN_STYLE: "BROOKLYN STYLE",
  HAND_TOSSED: "HAND TOSSED",
  CRUNCHY_THIN_CRUST: "CRUNCHY THIN CRUST",
};
const constants = {
  BROOKLYN_STYLE: "BROOKLYN_STYLE",
  HAND_TOSSED: "HAND_TOSSED",
  CRUNCHY_THIN_CRUST: "CRUNCHY_THIN_CRUST",
};
export const crust = {
    list,
    constants,
};

export const crustList=list;
export const crustConst=constants
