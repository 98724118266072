import React, { useEffect, useState } from "react";
import {
  ImageOverlay,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  // useMapEvents,
} from "react-leaflet";
import { useMapEvents } from "react-leaflet";
import { FullScreen, Wrapper } from "./styles";
import "./style.css";
import L, { LatLngBounds } from "leaflet"; // Import Leaflet library
import { FullscreenOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { useGetAreas, useGetMe } from "../../../../zustand/store";
import { pointInPolygon } from "../../../../utils/map";

// URL to the custom marker icon image
const markerIconUrl =
  "https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png";

// Create a custom icon using the image URL
// const customIcon = L.icon({

const customIcon = (name, color = "#237CC9") => {
  // ""
  // const name = urlSearchParams.get("name");
  const params = {
    type: "material",
    color: color,
    size: "large",
    iconType: "awesome",
    iconSize: "large",
    scaleFactor: 2,
    apiKey: "de11088e1852491aa345dd9facc6b61d",
  };
  const urlSearchParams = new URLSearchParams(params);
  // icon=home&

  //  urlSearchParams.toString();
  // const url = `https://api.geoapify.com/v1/icon/?type=material&color=${color}&size=large&icon=home&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d`;
  const url = "https://api.geoapify.com/v1/icon/?" + urlSearchParams.toString();

  return L.divIcon({
    // iconUrl: markerIconUrl,
    className: "custom-icon",
    // html: `<img style="width:100%;height:100%" src="https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png"/><div class="box">${name}</div>`,
    html: `<img style="width:100%;height:100%" src="${url}"/><div class="box">${name}</div>`,
    iconSize: [25, 41], // size of the icon
    iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
    popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
  });
};

// const customIcon = (name) =>
//   L.divIcon({
//     // iconUrl: markerIconUrl,
//     className: "custom-icon",
//     html: `<img style="width:100%;height:100%" src="https://unpkg.com/leaflet@1.0.1/dist/images/marker-icon-2x.png"/>${
//       name ? `<div class="box">${name}</div>` : ""
//     }`,
//     iconSize: [25, 41], // size of the icon
//     iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
//     popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
//   });

const redIcon = new L.Icon({
  iconUrl:
    "https://api.geoapify.com/v1/icon/?type=material&color=red&size=large&icon=home&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d",
  // shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

function MyComponent({
  currentCoords,
  markerCoords,
  setMarkerCoords,
  setCurrentCoords,
}) {
  const me = useGetMe();
  const map = useMapEvents({
    click: (res) => {
      map.locate();
      setCurrentCoords(res?.latlng);
      setMarkerCoords(res?.latlng);
    },
  });
  useEffect(() => {
    if (markerCoords && Object.keys(markerCoords).length) {
      // setMarkerCoords({ lat, lng });
      map?.setView(markerCoords, map?.getZoom(), { animate: true });
    }
    const restaurant = me?.data?.restaurant;
    if (restaurant?.coords && !markerCoords) {
      setMarkerCoords(restaurant?.coords);
    }
  }, []);
  return null;
}

function MapBox({
  markerCoords,
  setMarkerCoords,
  name,
  form,
  insideArea,
  setInsideArea,
}) {
  const [currentCoords, setCurrentCoords] = useState({
    lat: 33.7111401,
    lng: -78.8920569,
    // lat: 40.712216,
    // lng: -74.22655,
  });
  const me = useGetMe();
  const areas = useGetAreas();
  const restaurantCoords = me?.data?.restaurant?.coords;

  useEffect(() => {
    markerCoords && setCurrentCoords(markerCoords);
  }, []);

  useEffect(() => {
    const singleArea =
      areas.data?.find(({ polygon }) =>
        pointInPolygon(markerCoords.lat, markerCoords.lng, polygon)
      ) || null;
    setInsideArea?.(singleArea);
    if (singleArea?.price) {
      form.setFieldsValue({ deliveryFee: singleArea?.price });
    }
  }, [markerCoords]);

  // const [markerCoords, setMarkerCoords] = useState({
  //   // lat: 33.7111401,
  //   // lng: -78.8920569,
  //   // lat: 33.7120805,
  //   // lng: -78.8939265,
  // });
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <Wrapper
    // fullscreen={fullscreen}
    // style={{ width: "100%",height:"100px" }}
    >
      <Row gutter={[20, 20]}>
        {insideArea?.name ? <Col>{insideArea?.name}</Col> : <></>}
        <Col>lat: {markerCoords?.lat || "0.00"}</Col>
        <Col>lng: {markerCoords?.lng || "0.00"}</Col>
      </Row>
      <div style={{ width: "100%", height: "300px" }}>
        <MapContainer
          // width={"100%"}
          // height={100}
          style={{ width: "100%", height: "100%" }}
          center={currentCoords}
          zoom={13}
          scrollWheelZoom
        >
          <TileLayer
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {areas?.data?.map(({ polygon, color }) => (
            <Polygon positions={polygon} color={color} />
          ))}
          {restaurantCoords ? (
            <Marker position={restaurantCoords} icon={redIcon}>
              {/* <Popup>You are here</Popup> */}
            </Marker>
          ) : (
            <></>
          )}
          {Object.keys(markerCoords)?.length ? (
            <Marker
              draggable
              position={markerCoords}
              icon={customIcon(name, insideArea?.color || undefined)}
              eventHandlers={{
                dragend: (res) => {
                  setMarkerCoords(res.target.getLatLng());
                },
              }}
            >
              {/* <Popup>You are here</Popup> */}
            </Marker>
          ) : (
            <></>
          )}

          {/* {currentCoords ? (
          <Marker position={currentCoords}>
            <Popup>You are here</Popup>
          </Marker>
        ) : (
          <></>
        )} */}
          <MyComponent
            currentCoords={currentCoords}
            setCurrentCoords={setCurrentCoords}
            markerCoords={markerCoords}
            setMarkerCoords={setMarkerCoords}
          />
        </MapContainer>
      </div>
    </Wrapper>
  );
}

export default MapBox;
