import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/container";
import { useGetCashOutDriverList } from "../../zustand/store";
import { SingleWrapper, Wrapper } from "./styles";
import SingleCashOut from "../cashOutDriverPage/singleCashOut";
import Stat from "./stat";

function CashOutAppPage() {
  const navigate = useNavigate();
  const getCashOutList = useGetCashOutDriverList();

  useEffect(() => {
    getCashOutList.execute({ params: { app: true } });
  }, []);

  return (
    <PageContainer
      title={
        <>
          <Button onClick={() => navigate("/cash-out/drivers")}>Go back</Button>{" "}
          Cash Out App
        </>
      }
    >
      <Wrapper>
        <Stat />
        {getCashOutList?.data?.map((cashOut, i) => (
          <SingleWrapper>
            <SingleCashOut data={cashOut} key={i} />
          </SingleWrapper>
        ))}
      </Wrapper>
    </PageContainer>
  );
}

export default CashOutAppPage;
