import React from "react";
import { Wrapper } from "./styles";
import { Table } from "antd";
import { useGetDriverReport } from "../../../../zustand/store";
import { useEffect } from "react";
import moment from "moment";

const columns = ["date", "deliveryFee", "tip", "total"];

function Report() {
  const report = useGetDriverReport();
  useEffect(() => {
    report.execute();
  }, []);
  return (
    <Wrapper>
      <Table
        loading={report.loading}
        pagination={false}
        size="small"
        columns={columns.map((c) => ({ ket: c, dataIndex: c, title: c }))}
        dataSource={report.data?.map(
          ({
            app_fee_payment,
            createdAt,
            date,
            driver,
            id,
            overallTotals: {
              tip,
              total,
              appFee,
              deliveryFee,
              sqrTip,
              sqrTotal,
              sqrAppFee,
              sqrDeliveryFee,
            },
            payment_id,
            position,
            user,
          }) => ({
            date: moment(date, "YYYY/MM/DD").format("DD/MM/YYYY"),
            deliveryFee: <>${(deliveryFee + sqrDeliveryFee).toFixed(2)}</>,
            tip: <>${(tip + sqrTip).toFixed(2)}</>,
            total: deliveryFee + sqrDeliveryFee + tip + sqrTip,
          })
        )}
      />
    </Wrapper>
  );
}

export default Report;
