import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: calc((100vh / ${({ zoom }) => zoom || 1} - 125px));
  overflow-y: auto;
  /* align-items: first baseline; */
  align-content: start;
`;

export const SingleCardWrapper = styled.div`
  width: 100%;
  height: fit-content;
  margin-bottom: 15px;
`;
