import { Input, Modal } from "antd";
import PhoneInput from "react-phone-number-input/input";
import styled from "styled-components";

export const ModalWrapper = styled(Modal)`
  @media only screen and (max-width: 580px) {
    max-width: 100vw;
    .ant-modal-content {
      padding: 20px 15px 1px;
    }
  }
`;

export const JustBlurBackground = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 100px;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.35);
  display: none;
  @media only screen and (max-width: 580px) {
    display: block;
  }
`;

export const PhoneNumberPadWrapper = styled.div`
  /* display: none; */
  @media only screen and (max-width: 580px) {
    /* position: fixed; */
    position: ${({ active }) => (active ? "fixed" : "responsive")};
    bottom: 0;
    background-color: white;
    right: 0;
    left: 0;
    /* padding: 10px; */
    padding: ${({ active }) => (active ? "10px" : "0")};
    z-index: 3;
  }
`;

export const PhoneNumberInput = styled(PhoneInput)`
  width: 100%;
  font-size: 30px;
  padding: 0 10px;
  display: none;
  @media only screen and (max-width: 580px) {
    display: block;
  }
`;

export const AddressPadWrapper = styled.div`
  @media only screen and (max-width: 580px) {
    position: fixed;
    top: 0;
    bottom: 0;
    background-color: white;
    right: 0;
    left: 0;
    padding: 10px;
    z-index: 4;
    overflow: auto;
  }
`;

export const AddressInputBox = styled.div`
  display: none;
  @media only screen and (max-width: 580px) {
    display: block;
  }
`;

export const RoomNumberPadWrapper = styled.div`
  @media only screen and (max-width: 580px) {
    position: ${({ active }) => (active ? "fixed" : "responsive")};
    /* top: 0; */
    bottom: 0;
    background-color: white;
    right: 0;
    left: 0;
    padding: ${({ active }) => (active ? "10px" : "0")};
    z-index: 4;
    overflow: auto;
  }
`;
