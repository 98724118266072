import React, { useState } from "react";
import { FormWrapper, Wrapper } from "./styles";
import { Button, Form, Input } from "antd";
import MapBox from "../addressPage/addressDrawer/mapBox";
import { useRegisterRestaurant } from "../../zustand/store";
import { useNavigate } from "react-router-dom";

function RegisterRestaurant() {
  const [markerCoords, setMarkerCoords] = useState({});
  const [name, setName] = useState("");
  const [form] = Form.useForm();
  const registerRestaurant = useRegisterRestaurant();
  const navigate = useNavigate();

  return (
    <Wrapper>
      <FormWrapper>
        <Form
          layout="vertical"
          form={form}
          onValuesChange={(e) => {
            setName(e?.name);
          }}
          onFinish={(e) => {
            const data = {
              ...e,
              coords: markerCoords,
            };

            registerRestaurant.execute({
              params: { data: JSON.stringify(data) },
              onSuccess: (res) => {
                const data = res?.data
                if(data.state==="WELCOME"){
                  localStorage.setItem("hisob_new_token", data?.token);
                  navigate("/");
                }else if(data.state === "LOGIN"){
                  navigate("/login");
                }
              },
            });
          }}
        >
          <Form.Item name="name" label="Restaurant name">
            <Input />
          </Form.Item>
          <Form.Item label="Location">
            <MapBox
              markerCoords={markerCoords}
              setMarkerCoords={setMarkerCoords}
              name={name || ""}
            />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" disabled={!name}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </FormWrapper>
    </Wrapper>
  );
}

export default RegisterRestaurant;
