import React from "react";
import BakerSetting from "./bakerSetting";
import TelephonySetting from "./telephonySetting";
import { Divider } from "antd";
import MenuSetting from "./menuSetting";

function SettingPage() {
  return (
    <>
      {/* <MenuSetting /> */}
      {/* <Divider /> */}
      <TelephonySetting />
      <Divider />
      <BakerSetting />
    </>
  );
}

export default SettingPage;
