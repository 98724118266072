import React, { useEffect } from "react";
import { Wrapper } from "./styles";
import StaffSection from "./staffSection";
import { useGetStaffList } from "../../zustand/store";


function UsersPage() {
  const getStaffList = useGetStaffList();

  useEffect(() => {
    getStaffList.execute();
  }, []);
  return (
    <Wrapper>
      <StaffSection />
    </Wrapper>
  );
}

export default UsersPage;
