import {
  EditOutlined,
  LoadingOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, List, Row, Tag, Typography } from "antd";
import moment from "moment";
import React, { memo } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { handlePrint } from "..";
import { Receipt } from "../../../components/receipt";
import { orderTypeColor, orderTypes } from "../../../constants/orderType";
import { useAddOrderPrintRequest, useGetMe } from "../../../zustand/store";
import { ListItem } from "../../makeOrderPage";
import { OrderCardWrapper } from "./styles";
import { checkOTS, ot_s } from "../../usersPage/staffSection/staffForm";

const SingleOrderCard = memo(function SingleOrderCard({
  id,
  selectedFood,
  driver,
  order_type,
  userData,
  createdAt,
  addedAt,
  totals,
  edit,
  setAssignDriver,
}) {
  driver = driver?.[0];
  const { subTotal, tax, deliveryFee, total, serviceFee } = totals || {};
  const addPrintRequest = useAddOrderPrintRequest();
  const me = useGetMe();
  // const user = me.data?.user;
  // const extra = user?.extra;
  // const ots = extra?.ots;
  const ots = checkOTS(me);
  return (
    <>
      <OrderCardWrapper key={id}>
        <Card
          className={`saved_order_card_${id}`}
          size="small"
          actions={(ots(ot_s.ability_to_edit)
            ? [
                <EditOutlined
                  onClick={() => {
                    edit(id);
                  }}
                />,
              ]
            : []
          ).concat([
            addPrintRequest.loading ? (
              <LoadingOutlined />
            ) : (
              <PrinterOutlined
                onClick={() =>
                  addPrintRequest.execute({
                    params: { orderId: id },
                  })
                }
              />
            ),
          ])}
        >
          <List
            header={
              <div>
                <Tag color={orderTypeColor[order_type] || ""}>
                  {order_type || "DELIVERY"}
                </Tag>
                {moment(addedAt).calendar()}
              </div>
            }
          >
            <List.Item>
              <List
                style={{ width: "100%" }}
                //   bordered
                footer={
                  <div>
                    SubTotal: ${subTotal} <br />
                    {order_type === orderTypes.DELIVERY ? (
                      <>
                        Delivery And Service Fee: ${deliveryFee + serviceFee}{" "}
                        <br />
                      </>
                    ) : (
                      <></>
                    )}
                    Tax: ${tax}
                    <hr />
                    <Typography.Text strong>Total: ${total}</Typography.Text>
                  </div>
                }
              >
                {selectedFood.map((data, i) => (
                  <ListItem
                    key={i}
                    data={data}
                    readMode
                    // inner
                  />
                ))}
              </List>
            </List.Item>
            {order_type === orderTypes.DELIVERY || userData?.desc ? (
              <List.Item>
                <div>
                  {order_type === orderTypes.DELIVERY ? (
                    <div>
                      <Typography.Text keyboard>
                        {formatPhoneNumberIntl(userData?.phoneNumber) ||
                          "UNKNOWN PHONE NUMBER"}
                      </Typography.Text>
                      <br />
                      <Typography.Text keyboard>
                        {userData?.address || "UNKNOWN ADDRESS"}
                      </Typography.Text>{" "}
                      <Typography.Text keyboard>
                        {userData?.block || ""}{" "}
                        {userData?.roomNumber || "UNKNOWN ROOM NUMBER"}
                      </Typography.Text>
                    </div>
                  ) : (
                    <></>
                  )}
                  {userData?.desc ? (
                    <div>
                      <Typography.Text keyboard>
                        {userData?.desc}
                      </Typography.Text>
                    </div>
                  ) : (
                    <></>
                  )}
                  {userData?.call ? (
                    <div>
                      <Typography.Text keyboard>CALL</Typography.Text>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </List.Item>
            ) : (
              <></>
            )}

            {order_type === orderTypes.DELIVERY ? (
              <List.Item>
                {driver ? (
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Tag color="green">{driver?.fullname}</Tag>
                    </Col>
                    {ots(ot_s.ability_to_edit) ? (
                      <Col span={24}>
                        <Button
                          type="primary"
                          ghost
                          onClick={() => {
                            setAssignDriver({
                              open: true,
                              orderId: id,
                              driverId: driver?.id,
                            });
                          }}
                        >
                          change driver
                        </Button>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                ) : (
                  <Row gutter={[10, 10]}>
                    <Col span={24}>
                      <Tag>NO DRIVER</Tag>
                    </Col>
                    {ots(ot_s.ability_to_edit) ? (
                      <Col span={24}>
                        <Button
                          type="primary"
                          ghost
                          onClick={() => {
                            setAssignDriver({
                              open: true,
                              orderId: id,
                              driverId: driver?.id,
                            });
                          }}
                        >
                          Assign Driver
                        </Button>
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                )}
              </List.Item>
            ) : (
              <></>
            )}
          </List>
        </Card>
      </OrderCardWrapper>
    </>
  );
});

export default SingleOrderCard;
