import { Button, Card, Col, Row, Table, Tag } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "../../components/container";
import {
  useGetRestaurantsList,
  useGetSingleCashOutOfRestaurant,
} from "../../zustand/store";
import SingleCashOut from "../cashOutDriverPage/singleCashOut";
import { DollarOutlined, PlusOutlined } from "@ant-design/icons";
import { SquareIcon } from "../singleOrderOfDriver/styles";
import { thousandSeparator } from "../../utils/numberManager";
import moment from "moment";

const columns = [
  // "ID",
  "date",
  "driver",
  "Payment",
];

function SingleRestaurantCashoutPage() {
  const restaurants = useGetRestaurantsList();
  const cashout = useGetSingleCashOutOfRestaurant();

  const navigate = useNavigate();
  const { id, cashoutId } = useParams();

  const main = cashout.data?.main;
  const totals = main?.overallTotals;

  useEffect(() => {
    restaurants.execute();
    cashout.execute({ params: { id, cashoutId } });
  }, []);
  return (
    <PageContainer
      title={
        <>
          <Button
            onClick={() => {
              navigate(`/restaurants/${id}`);
            }}
          >
            GoBack
          </Button>{" "}
          {restaurants.data?.find(({ id: rID }) => rID === id)?.name}/CashOut
        </>
      }
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Card size="small">
            {moment(main?.createdAt).calendar()}

            <div
              style={{
                width: "100%",
                height: "100px",
                transform: "scale(2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Row gutter={[10, 10]} align={"center"}>
                <Col>
                  <DollarOutlined /> {thousandSeparator(totals?.cash || 0)}
                </Col>
                <Col>
                  <PlusOutlined />
                </Col>
                <Col>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <SquareIcon>
                      <div />
                    </SquareIcon>
                    <div>{thousandSeparator(totals?.sqr || 0)}</div>
                  </div>
                </Col>
                <Col>=</Col>
                <Col>${thousandSeparator(totals?.total || 0)}</Col>
              </Row>
            </div>

            <Row gutter={[10, 10]}>
              {main?.payment?.map(({ type, amount }, i) => (
                <Col key={i}>
                  <Card size="small">
                    <Tag color="#87d068">{type}</Tag> <br />${amount}
                  </Card>
                </Col>
              ))}
            </Row>
            {/* Lorem ipsum dolor, sit amet consectetur adipisicing elit. Dolor
            doloribus inventore ullam nam perferendis sit, sapiente culpa ut
            ipsum. At ea voluptas voluptatum architecto, quo debitis porro
            aliquid itaque quis. */}
          </Card>
        </Col>
        <Col span={24}>
          <Card size="small">
            <Table
              loading={cashout.loading}
              pagination={false}
              expandable={{
                expandedRowRender: (record) => (
                  <>
                    <SingleCashOut data={record} />
                  </>
                ),
                //   rowExpandable: (record) => record.name !== "Not Expandable",
              }}
              // rowSelection={{
              //   selectedRowKeys,
              //   onChange: (keys) => {
              //     setSelectedRowKeys(keys);
              //     const totalData = cashout.data?.reduce(
              //       (t, v) => {
              //         const cash =
              //           t.cash +
              //           (keys.includes(v.id) ? v?.overallTotals?.appFee : 0);
              //         const sqr =
              //           t.sqr +
              //           (keys.includes(v.id) ? v?.overallTotals?.sqrAppFee : 0);

              //         return {
              //           sqr,
              //           cash,
              //           total: t.total + cash + sqr,
              //         };
              //       },
              //       { sqr: 0, cash: 0, total: 0 }
              //     );
              //     form.setFieldValue("total", totalData?.total);
              //     setTotals(totalData);
              //   },
              // }}
              // size="small"
              columns={columns.map((column) => ({
                key: column,
                dataIndex: column,
                title: column,
              }))}
              dataSource={cashout.data?.cashOuts?.map((v) => ({
                ...v,
                key: v.id,
                driver: v?.user?.[0]?.fullname,
                Payment: (
                  <Row gutter={[20, 20]}>
                    <Col>
                      <DollarOutlined /> {v?.overallTotals?.appFee}
                    </Col>
                    <Col>
                      <PlusOutlined />
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <SquareIcon>
                          <div />
                        </SquareIcon>{" "}
                        <div>{v?.overallTotals?.sqrAppFee}</div>
                      </div>
                    </Col>
                    <Col>=</Col>
                    <Col>
                      {v?.overallTotals?.appFee + v?.overallTotals?.sqrAppFee}
                    </Col>
                  </Row>
                ),
              }))}
            />
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
}

export default SingleRestaurantCashoutPage;
