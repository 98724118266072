import React, { useEffect, useState } from "react";
import { Wrapper } from "./styles";
import { Card, Statistic } from "antd";
import { useGetOrdersList } from "../../../zustand/store";
import { orderTypes } from "../../../constants/orderType";
import OrderByHour from "./charts/ordersByHour";
import OrderByAddress from "./charts/ordersByAddress";
import OrderByAddressMap from "./charts/ordersByAddressMap";

const orderTypeColor = {
  [orderTypes.DELIVERY]: "#4096FF",
  [orderTypes.DINE_IN]: "#73D13D",
  [orderTypes.TO_GO]: "#9254DE",
  [orderTypes.PICK_UP]: "#FF4D4F",
};

function OrdersStat({ orders }) {
  orders = orders || {};
  const getOrders = useGetOrdersList();
  const [open, setOpen] = useState(false);

  const cancelledAmount =
    getOrders?.data?.filter(
      ({ canceledAt, isCanceled }) => isCanceled || canceledAt
    )?.length || 0;

  useEffect(() => {
    setTimeout(() => {
      setOpen(true);
    }, 5000);
  }, []);

  return (
    <Wrapper>
      <Card size="small" style={{ backgroundColor: `#e245819a` }}>
        <Statistic title="Total Orders" value={getOrders?.data?.length || 0} />
      </Card>
      {Object.entries(orders).map(([name, order], i) => (
        <Card
          key={i}
          size="small"
          style={{ backgroundColor: `${orderTypeColor[name]}7a` }}
        >
          <Statistic title={name} value={order?.length || 0} />
        </Card>
      ))}
      {cancelledAmount ? (
        <Card size="small" style={{ backgroundColor: `#ff00008a` }}>
          <Statistic title="Canceled" value={cancelledAmount} />
        </Card>
      ) : (
        <></>
      )}
      <div style={{ width: "100%" }}></div>
      <Card style={{ width: "100%" }}>
        <div style={{ height: "300px" }}>
          <OrderByHour orders={orders} orderTypeColor={orderTypeColor} />
        </div>
      </Card>
      <Card style={{ width: "100%" }}>
        <div style={{ height: "300px" }}>
          <OrderByAddress />
        </div>
      </Card>
      {/* <Card style={{ width: "100%" }}>
        <div style={{ height: "500px" }}>
          <OrderByAddressMap />
        </div>
      </Card> */}
    </Wrapper>
  );
}

export default OrdersStat;
