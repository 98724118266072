import { DollarOutlined, PlusOutlined } from "@ant-design/icons";
import { Card, Col, Modal, Row, Typography } from "antd";
import React from "react";
import { SquareIcon } from "../../../singleOrderOfDriver/styles";
import { thousandSeparator } from "../../../../utils/numberManager";
import {
  useCashRestaurantOut,
  useGetSingleRestaurantUnpaid,
} from "../../../../zustand/store";
import { useParams } from "react-router-dom";

function Conformation({ data, onCancel, cashouts }) {
  const { id } = useParams();
  const totals = data;
  const cashoutRestaurantOut = useCashRestaurantOut();
  const cashout = useGetSingleRestaurantUnpaid();

  return (
    <Modal
      okText="Yes"
      okButtonProps={{ size: "large" }}
      onOk={() => {
        cashoutRestaurantOut.execute({
          params: { id },
          data: { cashouts, totals },
          onSuccess: () => {
            cashout.execute({ force: true, params: { id } });
            onCancel();
          },
        });
      }}
      title=" "
      open={data}
      onCancel={onCancel}
      centered
    >
      <div
        style={{
          width: "100%",
          height: "100px",
          transform: "scale(2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row gutter={[10, 10]} align={"center"}>
          <Col>
            <DollarOutlined /> {thousandSeparator(totals?.cash || 0)}
          </Col>
          <Col>
            <PlusOutlined />
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <SquareIcon>
                <div />
              </SquareIcon>
              <div>{thousandSeparator(totals?.sqr || 0)}</div>
            </div>
          </Col>
          <Col>=</Col>
          <Col>${thousandSeparator(totals?.total || 0)}</Col>
        </Row>
      </div>
      <div style={{ fontSize: "25px" }}>
        Did you really get <b>${thousandSeparator(totals?.total)}</b> from
        restaurant
      </div>
    </Modal>
  );
}

export default Conformation;
