import styled from "styled-components";

export const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const ItemCard = styled.div`
  width: 150px;
  border: 1px solid black;
  padding: 10px;
  border-radius: 8px;
  background-color: ${({ active }) => (active ? "lightgreen" : "white")};
`;
