import { Col, Row, Spin } from "antd";
import React, { useEffect } from "react";
import { SingleOrderCard } from "..";
import { useGetBakerOrdersHistory } from "../../../zustand/store";
import { SingleCardWrapper, Wrapper } from "./styles";

function BakerHistory({
  currentSection,
  zoom,
  open,
  onCancel,
  historyIndex,
  setHistoryIndex,
}) {
  const historyList = useGetBakerOrdersHistory();

  useEffect(() => {
    // if (open) {
    historyList.execute();
    // }
  }, []);

  return (
    // <Drawer
    //   title="History"
    //   open={open}
    //   onClose={onCancel}
    //   extra={<ReloadOutlined onClick={() => historyList.execute()} />}
    // >
    // <Spin spinning={historyList.loading} size="large" tip="Loading...">
    <Wrapper zoom={zoom}>
      {historyList?.data?.map((v, i) => (
        <SingleCardWrapper key={i} className={`history_card_${i}`}>
          <SingleOrderCard
            {...v}
            historyCard
            isSelected={historyIndex === i}
            currentSection={currentSection}
          />
        </SingleCardWrapper>
      ))}
    </Wrapper>
    // </Spin>
    // </Drawer>
  );
}

export default BakerHistory;
