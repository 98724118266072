export const dict = {
  logout: "Выйти",
  statistics: "Статистика",
  back:"назад",

  // ORDERS PAGE
  orders: "Заказы",
  addOrder: "Добавить заказ",
  plusOrder: "+ Заказ",
  inProcess: "В процессе",
  closed: "Закрыто",
  edit: "Редактировать",
  closeEdit: "Закрыть редактирование",
  client: "Клиент",
  fullname: "Ф.И.О.",
  phoneNumber: "Номер телефона",
  address: "Адрес",
  update: "Обновить",
  cancel: "Отмена",
  pic: "Фото",
  name: "Название",
  price: "Цена",
  status: "Статус",
  created: "Создан",
  started: "Начат",
  finished: "Завершен",
  installed: "Установлен",
  done: "Выполнен",

  description: "Описание",
  deadline: "Крайний срок",
  daysLeft: "дней осталось",
  daysPassed: "дней прошло",
  action: "Действие",

  add: "Добавить",
  materials: "Материалы",
  usedMaterial: "Использованные материалы",
  amount: "Количество",
  addMaterial: "Добавить материал",
  currency: "Валюта",
  waiting: "Ожидание...",

  salary: "Зарплата",
  paySalary: "Выплатить зарплату",
  finAccount: "Финансовый счет",

  pay: "Оплатить",

  closeOrder: "Закрыть заказ",
  next: "Далее",

  addPrice: "Добавить цену",

  save: "Сохранить",
  orderDetail: "Детали заказа",

  // WAREHOUSE PAGE
  warehouse: "Склад",
  existInWarehouse: "Наличие на складе",
  willBeUsedForBelowOrders: "Будет использовано для нижеследующих заказов",
  existsAfterUsing: "Останется после использования",

  measurement: "Единица измерения",
  category: "Категория",

  submit: "Отправить",

  goods: "Товары",
  singleGoods: "Товар",
  addGoods: "Добавить товар",

  staffList: "Сотрудники",
  staff: "Сотрудник",
  addStaff: "Добавить сотрудника",
  plusStaff: "+ Сотрудник",
  id: "ID",

  role: "Роль",

  permissions: "Права доступа",
  editPermissions: "Редактировать права",
  list: "Список",

  telegramChatId: "Telegram ChatId",
  paySalaryOf: "Выплатить зарплату: ",
  salaryOf: "Зарплата: ",
  order: "Заказ",
  date: "Дата",

  finance: "Финансы",

  addAccount: "Добавить счет",
  plusAccount: "+ Счет",

  paymentMethod: "Способ оплаты",

  more: "Еще",
  INCOME: "Доход",
  EXPENSE: "Расход",

  ORDER: "ЗАКАЗ",
  GOODS: "ТОВАР",
  SALARY: "ЗАРПЛАТА",

  last30Days: "Последние 30 дней",
  thisWeek: "На этой неделе",
  prevWeek: "На прошлой неделе",
  thisMonth: "В этом месяце",
  prevMonth: "В прошлом месяце",

  stock: "Запас",

  materialName: "Название материала",
  orderName: "Название заказа",

  // PLACEHOLDERS
  selectCurrency: "Пожалуйста, выберите валюту",
  selectFinAccount: "Пожалуйста, выберите финансовый аккаунт",
  selectStaff: "Пожалуйста, выберите сотрудника",
  selectUsedMaterial: "Пожалуйста, выберите использованный материал",

  enterMaterialName: "Пожалуйста, введите название материала",
  enterOrderName: "Пожалуйста, введите название заказа",
  enterName: "Пожалуйста, введите имя",
  enterDescription: "Пожалуйста, введите описание",
  enterPrice: "Пожалуйста, введите цену",
  enterFullname: "Пожалуйста, введите ФИО",
  enterPhoneNumber: "Пожалуйста, введите номер телефона",
  enterAddress: "Пожалуйста, введите адрес",
  enterDeadline: "Пожалуйста, введите крайний срок",
  enterAmount: "Пожалуйста, введите количество",
  enterTgChatId: "Пожалуйста, введите идентификатор чата в Telegram",
  enterCode: "Пожалуйста, введите код, полученный от Telegram бота",

  selectOrEnterCurrency: "Пожалуйста, выберите или введите валюту",
  selectOrEnterPaymentMethod: "Пожалуйста, выберите или введите метод оплаты",
  selectOrEnterCategory: "Пожалуйста, выберите или введите категорию",
  selectOrEnterMeasurement: "Пожалуйста, выберите или введите единицу измерения",

  code: "Код",

  all:"Все",
  managers:"Менеджеры",
  seeDeleted:"см. удалено",

  products:"Продукты",

  // НЕ НАЙДЕНО
  orderNotFound: "Заказ не найден",
  createNow: "Создать сейчас",
  goodsNotFound: "Товары не найдены",
  accountNotFound: "Счет не найден",
};
