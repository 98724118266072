import { Button } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/container";
import { useGetCashOutDriverList } from "../../zustand/store";
import SingleCashOut from "./singleCashOut";
import { SingleWrapper, Wrapper } from "./styles";

function CashOutDriverPage() {
  const navigate = useNavigate();
  const getCashOutList = useGetCashOutDriverList();

  useEffect(() => {
    getCashOutList.execute();
  }, []);

  return (
    <PageContainer
      title={
        <>
          <Button onClick={() => navigate("/cash-out")}>Go back</Button> Cash
          Out List
        </>
      }
      // rightPart={
      //   <>
      //     <Button
      //       onClick={() => {
      //         navigate("/cash-out/app");
      //       }}
      //     >
      //       Cash out AppFee
      //     </Button>
      //   </>
      // }
    >
      <Wrapper>
        {getCashOutList?.data?.map((cashOut, i) => (
          <SingleWrapper>
            <SingleCashOut data={cashOut} key={i} />
          </SingleWrapper>
        ))}
      </Wrapper>
    </PageContainer>
  );
}

export default CashOutDriverPage;
