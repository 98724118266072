// import { DualAxes } from "@ant-design/plots";
import { useEffect, useState } from "react";
import MarkerClusterGroup from "react-leaflet-cluster";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { orderTypes } from "../../../../../constants/orderType";
import {
  useGetAddresses,
  useGetOrdersList,
} from "../../../../../zustand/store";
import { MapContainer, Marker, TileLayer, useMap } from "react-leaflet";
import L from "leaflet"; // Import Leaflet library

function FitBoundsToMarkers({ markers }) {
  const map = useMap();

  useEffect(() => {
    if (markers?.length === 0) return;

    const markerCoordinates = markers.map(({ coords: marker }) =>
      L.latLng(marker.lat, marker.lng)
    );
    const bounds = L.latLngBounds(markerCoordinates);

    map.fitBounds(bounds);
  }, [markers, map]);

  return null; // This component doesn't render anything
}

function OrderByAddressMap() {
  const orders = useGetOrdersList();
  const addresses = useGetAddresses();
  const [data, setData] = useState([]);
  const customIcon = (name) =>
    L.divIcon({
      // iconUrl: markerIconUrl,
      className: "custom-icon",
      html: `<img style="width:100%;height:100%;" src="https://api.geoapify.com/v1/icon/?type=circle&color=%230c0c0c&icon=circle&iconType=material&textSize=small&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d"/>${
        name ? `<div class="box">${name}</div>` : ""
      }`,
      iconSize: [25, 25], // size of the icon
      iconAnchor: [12, 25], // point of the icon which will correspond to marker's location
      popupAnchor: [1, -34], // point from which the popup should open relative to the iconAnchor
    });

  useEffect(() => {
    if (orders?.data && addresses?.data) {
      setData([]);
      let addressData = addresses?.data?.reduce(
        (t, { name }) => ({ ...t, [name]: 0 }),
        {}
      );
      addressData["other"] = 0;

      const dataObj = orders?.data?.reduce((t, order) => {
        const address = order?.userData?.address;
        if (address) {
          const a = t[address];
          if (a || a === 0) {
            return { ...t, [address]: a + 1 };
          } else {
            return { ...t, other: t.other + 1 };
          }
        } else {
          return t;
        }
      }, addressData);

      const dataList = Object.entries(dataObj).reduce(
        (t, [name, amount]) => (amount ? [...t, { name, amount }] : t),
        []
      );

      setData(dataList);
    }
  }, [orders.data, addresses?.data]);

  const markers = orders?.data
    ?.map((order, index) => {
      const userData = order?.userData;
      const addressData = userData?.addressData;
      // const coords = addressData?.coords;
      return addressData;
    })
    .filter((addressData) => addressData?.coords);
  
  return (
    <>
      {markers?.length ? (
        <MapContainer
          style={{ height: "100%", width: "100%" }}
          // center={[38.9637, 35.2433]}
          // zoom={6}
          zoom={13}
          scrollWheelZoom
          // scrollWheelZoom={true}
        >
          <TileLayer
            // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup chunkedLoading>
            {markers?.map(({ name, coords }, index) => {
              return (
                <Marker
                  key={index}
                  position={[coords.lat, coords.lng]}
                  title={name}
                  icon={customIcon(name)}
                ></Marker>
              );
            })}
          </MarkerClusterGroup>

          <FitBoundsToMarkers markers={markers} />
        </MapContainer>
      ) : (
        <></>
      )}
    </>
  );
}

export default OrderByAddressMap;
