import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GoBack = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  svg{
    /* width: 25px; */
  }
`;

export const BoxFrame = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top: calc((100vh - 200px) * 1 / 3) solid #00000066;
  border-right: calc((100vw - 200px) / 2) solid #00000066;
  border-bottom: calc((100vh - 200px) * 2 / 3) solid #00000066;
  border-left: calc((100vw - 200px) / 2) solid #00000066;
`;

export const QrResult = styled.div`
  width: fit-content;
  min-width: 100px;
  max-width: 100%;
  background-color: white;
  /* height: 50px; */
  padding: 10px;
  position: absolute;
  /* bottom: -200px; */
  bottom: calc((100vh - 200px) * (1 / 3));
  /* top: 0; */
  right: 0;
  left: 0;
  margin: auto;
  font-size: 15px;
  text-align: center;
  border-radius: 5px;
  font-family: Futura;
`;
