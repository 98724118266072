import styled from "styled-components";

export const Wrapper = styled.div`
  /* background-color: white; */
  /* padding: 10px; */
  /* border-radius: 10px; */
  /* margin-bottom: 10px; */
  /* cursor: pointer; */
  width: calc((100% - 20px) / 3);
  max-width: 300px;
  @media only screen and (max-width: 1100px) {
    width: calc((100% - 10px) / 2);
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const MaterialImgBox = styled.div`
  width: 32px;
  height: 32px;
  margin: auto;
  position: relative;
  cursor: pointer;
  .badge {
    width: fit-content;
    height: fit-content;
    padding: 0 5px;
    color: white;
    background-color: red;
    font-size: 10px;
    position: absolute;
    top: -6px;
    right: -6px;
    border-radius: 10px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;

export const PaymentWrapper = styled.div`
  background-color: white;
`;
