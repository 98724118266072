const colors = {
  GOODS: "green",
  SALARY: "cyan",
  ORDER: "purple",
  CASH_OUT: "blue",
  SQUARE: "lightgray",
  DINE_IN:"geekblue",
  DELIVERY:"gold",
};

export const categoryColor = (category) => {
  return colors[category] || "";
  // category === "GOODS"
  //   ? "green"
  //   : category === "SALARY"
  //   ? "cyan"
  //   : category === "ORDER"
  //   ? "purple"
  //   : "";
};
