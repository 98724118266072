export function pointInPolygon(lat, lon, polygon) {
  let isInside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const lati = polygon[i][0];
    const loni = polygon[i][1];
    const latj = polygon[j][0];
    const lonj = polygon[j][1];

    const intersect =
      loni > lon !== lonj > lon &&
      lat < ((latj - lati) * (lon - loni)) / (lonj - loni) + lati;

    if (intersect) {
      isInside = !isInside;
    }
  }

  return isInside;
}

// export function pointInPolygon(lat, lon, polygon) {
//   let isInside = false;

//   for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
//     const lati = polygon[i][0];
//     const loni = polygon[i][1];
//     const latj = polygon[j][0];
//     const lonj = polygon[j][1];

//     // Check if the point is at the same latitude as the current vertex
//     const sameLatitude = lati === latj;

//     // Check if the point is within the latitude range of the edge
//     const withinLatitudeRange =
//       (lati < lat && latj >= lat) || (latj < lat && lati >= lat);

//     // Check if the point is to the left of the edge (based on longitude)
//     const isLeft = loni + ((lat - lati) / (latj - lati)) * (lonj - loni) < lon;

//     // Check if the edge crosses the ray to the right of the point and is not horizontal
//     if (!sameLatitude && withinLatitudeRange && isLeft) {
//       isInside = !isInside;
//     }
//   }

//   return isInside;
// }
