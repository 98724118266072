import { create } from "zustand";
import { httpRequest } from "../utils/httpRequest";
import { message } from "antd";
import { dateOptions } from "../constants/date";
const initialState = {
  loading: false,
  success: false,
  error: false,
  data: null,
  errorData: null,
};

export const logout = () => {
  localStorage.removeItem("hisob_new_token");
  window.location.reload();
};
export const useLangStore = create((set) => ({
  lang: "EN",
  changeLang: (l) => {
    set({ lang: l });
  },
}));

export const useSidebar = create((set, get) => ({
  status: false,
  open: () => {
    set({ status: true });
  },
  close: () => {
    set({ status: false });
  },
  toggle: () => {
    set({ status: !get().status });
  },
}));

export const useDial = create((set, get) => ({
  phoneNumbers: [],
  add: (data) => {
    data.phoneNumber = data.number;
    if (!get().phoneNumbers.some((n) => n.phoneNumber === data.phoneNumber)) {
      set({
        phoneNumbers: [...get().phoneNumbers, data],
        // [...new Set([...get().phoneNumbers, number])]
      });
    }
  },
  remove: (number) => {
    set({
      phoneNumbers:
        get().phoneNumbers?.filter((n) => n.phoneNumber !== number) || [],
    });
  },
  setNumbers: (list) => {
    set({ phoneNumbers: list });
  },
}));

export const useDateRange = create((set) => ({
  dateRange: dateOptions[3]?.range,
  setDateRange: (date) => {
    set({ dateRange: date });
  },
}));

const request = (props, option) =>
  create((set, get) => ({
    ...initialState,
    execute: (p) => {
      if (props.method === "GET" && !p?.force && Boolean(get().data)) {
        return;
      }
      set({ ...initialState, loading: true });
      httpRequest({
        ...props,
        data: { ...props?.data, ...p?.data },
        params: { ...props?.params, ...p?.params },
      })
        .then((res) => {
          set({ ...initialState, success: true, data: res.data });
          p?.onSuccess?.(res);
          option?.onSuccess?.(res);
          // console.log("%cSuccess request: ", "color:green;", res.data);
        })
        .catch((err) => {
          set({ ...initialState, error: true, errorData: err });
          p?.onError?.(err);
          option?.onError?.(err);
          // console.log("%Error request: ", "color:green;", err);
          if (
            // !p?.onError &&
            // !option?.onError &&
            typeof err?.response?.data === "string"
          ) {
            message.error(err?.response?.data);
          }
        })
        .finally(() => {
          p?.onFinal?.();
          option?.onFinal?.();
        });
      // }
    },
    clean: () => {
      set(initialState);
    },
  }));

const requestList = (props, option) =>
  create((set, get) => ({
    ...initialState,
    execute: (p) => {
      if (props.method === "GET" && !p?.force && Boolean(get().data)) {
        return;
      }
      set({ ...initialState, data: get().data, loading: true });
      httpRequest({
        ...props,
        data: { ...props?.data, ...p?.data },
        params: { ...props?.params, ...p?.params },
      })
        .then((res) => {
          set({ ...initialState, success: true, data: res.data });
          p?.onSuccess?.(res);
          option?.onSuccess?.(res);
          // console.log("%cSuccess request: ", "color:green;", res.data);
        })
        .catch((err) => {
          set({ ...initialState, error: true, errorData: err });
          p?.onError?.(err);
          option?.onError?.(err);
          // console.log("%Error request: ", "color:green;", err);
        })
        .finally(() => {
          p?.onFinal?.();
          option?.onFinal?.();
        });
      // }
    },
    clean: () => {
      set(initialState);
    },
  }));

export const useLogin = request({
  method: "POST",
  path: "login",
});

export const useVerifyOTP = request({
  method: "POST",
  path: "login/verify-otp",
});

export const useTgLogin = request(
  {
    method: "GET",
    path: "login/tg-callback",
  },
  {
    onSuccess: (res) => {
      if (res?.data?.state === "WELCOME") {
        useGetMe.getState().execute({ force: true });
      }
    },
  }
);

export const useRegisterRestaurant = request(
  {
    method: "GET",
    path: "register-restaurant",
  },
  {
    onSuccess: (res) => {
      if (res?.data?.state === "WELCOME") {
        useGetMe.getState().execute({ force: true });
      }
    },
  }
);

export const useGetGoods = requestList({
  method: "GET",
  path: "warehouse/goods",
});

export const useDeleteGoods = request(
  {
    method: "DELETE",
    path: "warehouse/goods",
  },
  {
    onSuccess: () => useGetGoods.getState().execute({ force: true }),
  }
);

export const useAddWarehousePayment = request({
  method: "POST",
  path: "warehouse/goods/payment",
});

export const useGetPayments = requestList({
  method: "GET",
  path: "payments",
});

export const useAddPayment = request(
  {
    method: "POST",
    path: "payment",
  },
  {
    onSuccess: () => {
      useGetPaymentCategories.getState().execute({ force: true });
    },
  }
);

export const useDeletePayment = request({
  method: "DELETE",
  path: "payment",
});
export const useEditPayment = request({
  method: "PUT",
  path: "payment",
});

export const useGetOrdersList = requestList({
  method: "GET",
  path: "order/list",
});

export const useGetOrders = requestList({
  method: "GET",
  path: "order",
});

export const useAddOrder = request(
  {
    method: "POST",
    path: "order",
  },
  {
    onSuccess: () => useGetOrders.getState().execute({ force: true }),
  }
);

export const useEditOrder = request(
  {
    method: "PUT",
    path: "order",
  },
  {
    onSuccess: () => useGetOrders.getState().execute({ force: true }),
  }
);

export const useEditOrderDriver = request({
  method: "PUT",
  path: "order/driver",
});

export const useCancelOrder = request(
  {
    method: "PUT",
    path: "order/cancel",
  },
  {
    onSuccess: () => {
      useOrderOfDelivery.getState().execute({ force: true });
    },
  }
);

export const useAddStaff = request({
  method: "POST",
  path: "staff",
});

export const useEditStaff = request({
  method: "PUT",
  path: "staff",
});

export const useGetStaffList = requestList({
  method: "GET",
  path: "staff",
});

export const useChangeStaffActivation = request({
  method: "PUT",
  path: "staff/activation",
});

export const useGetDrivers = requestList({
  method: "GET",
  path: "staff/drivers",
});

export const usePaySalary = request({
  method: "POST",
  path: "payment/pay-salary",
});

export const useGetPaymentCategories = request({
  method: "GET",
  path: "payment/categories",
});

export const useGetStaffSalaries = request({
  method: "GET",
  path: "payment/salaries",
});

export const useJoinRestaurant = request({
  method: "POST",
  path: "join",
});

export const useGetMe = requestList({
  method: "GET",
  path: "me",
});

// export const useGetMe = requestList({
//   method: "GET",
//   path: "staff/me",
// });

export const useGetUsersRestaurants = requestList({
  method: "GET",
  path: "staff/restaurants",
});

export const useGetOrderTemplate = requestList({
  method: "GET",
  path: "order/template",
});

export const useAddOrderTemplate = request({
  method: "POST",
  path: "order/template",
});

export const useEditOrderTemplate = request({
  method: "PUT",
  path: "order/template",
});

export const useEditOrderTemplateSort = request(
  {
    method: "PATCH",
    path: "order/template/sort",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useDeleteOrderTemplate = request(
  {
    method: "DELETE",
    path: "order/template",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

// export const useGetToppings = requestList({
//   method: "GET",
//   path: "order/topping",
// });

// export const useAddTopping = request(
//   {
//     method: "POST",
//     path: "order/topping",
//   },
//   {
//     onSuccess: () => {
//       useGetToppings.getState().execute({ force: true });
//     },
//   }
// );

// export const useDeleteTopping = request(
//   {
//     method: "DELETE",
//     path: "order/topping",
//   },
//   {
//     onSuccess: () => {
//       useGetToppings.getState().execute({ force: true });
//     },
//   }
// );

export const useGetExtra = requestList({
  method: "GET",
  path: "menu/extra",
});

export const useAddExtra = request(
  {
    method: "POST",
    path: "menu/extra",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useEditExtra = request(
  {
    method: "PUT",
    path: "menu/extra",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useDeleteExtra = request(
  {
    method: "DELETE",
    path: "menu/extra",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useGetAreas = requestList({
  method: "GET",
  path: "area/all",
});

export const useAddArea = request(
  {
    method: "POST",
    path: "area",
  },
  {
    onSuccess: () => {
      useGetAreas.getState().execute({ force: true });
    },
  }
);

export const useDeleteArea = request(
  {
    method: "DELETE",
    path: "area",
  },
  {
    onSuccess: () => {
      useGetAreas.getState().execute({ force: true });
    },
  }
);

export const useEditArea = request(
  {
    method: "PUT",
    path: "area",
  },
  {
    onSuccess: () => {
      useGetAreas.getState().execute({ force: true });
      message.success("Updated");
    },
  }
);

export const useGetAddresses = requestList({
  method: "GET",
  path: "address/all",
});

export const useGetAreaAndAddresses = requestList({
  method: "GET",
  path: "area/address",
});

export const useAddAddress = request(
  {
    method: "POST",
    path: "address",
  },
  {
    onSuccess: () => {
      useGetAddresses.getState().execute({ force: true });
    },
  }
);
export const useEditAddress = request({
  method: "PUT",
  path: "address",
});
export const useDeleteAddress = request({
  method: "DELETE",
  path: "address",
});

export const useAdvertisingAddress = request(
  {
    method: "PUT",
    path: "advertising/synchronize-action",
  },
  {
    onSuccess: () => {
      useGetMe.getState().execute({ force: true });
    },
  }
);

export const useAdvertisingLogs = request({
  method: "GET",
  path: "advertising/logs",
});

export const useGetUnPaidHourlyWorker = requestList({
  method: "GET",
  path: "hourly-workers/un-paid",
});

export const useStartDelivery = request(
  {
    method: "PUT",
    path: "delivery/start",
  },
  {
    onSuccess: () => {
      useGetMe.getState().execute({ force: true });
    },
  }
);
export const useStopDelivery = request(
  {
    method: "PUT",
    path: "delivery/stop",
  },
  {
    onSuccess: () => {
      useGetMe.getState().execute({ force: true });
    },
  }
);

export const useOrderOfDelivery = requestList({
  method: "GET",
  path: "delivery/orders",
});

export const useGetDeliverySingleOrder = request({
  method: "GET",
  path: "delivery/single-order",
});

export const useSubmitOrderPayment = request(
  {
    method: "POST",
    path: "order/payment",
  },
  {
    onSuccess: () => {
      useOrderOfDelivery.getState().execute({ force: true });
      useGetDriverCalculation.getState().execute({ force: true });
    },
  }
);

export const useGetDeviceSquare = requestList({
  method: "GET",
  path: "square/device",
});

export const useSqrPayments = requestList({
  method: "GET",
  path: "square/payments",
});

export const useSqrWebhookList = requestList({
  method: "GET",
  path: "square/webhook-list",
});

export const useConnectSquareWebhook = request(
  {
    method: "PUT",
    path: "square/connect-webhook",
  },
  {
    onSuccess: () => {
      useGetMe.getState().execute({ force: true });
      useCheckSquareWebhook.getState().execute({ force: true });
    },
  }
);

export const useCheckSquareWebhook = requestList({
  method: "GET",
  path: "square/check-webhook",
});

export const useAcceptOrder = request(
  {
    method: "PUT",
    path: "delivery/single-order/accept-order",
  },
  {
    onSuccess: () => {
      useOrderOfDelivery.getState().execute({ force: true });
    },
  }
);

export const useGetActiveDrivers = requestList({
  method: "GET",
  path: "staff/active-drivers",
});

export const useMakeDriverCashOut = request(
  {
    method: "POST",
    path: "cash-out/driver",
  },
  {
    onSuccess: () => {
      useGetUnCashedOutDriverList.getState().execute({ force: true });
      // useGetActiveDrivers.getState().execute({ force: true });
    },
  }
);

export const useMakeHourlyWorkerCashOut = request(
  {
    method: "POST",
    path: "cash-out/hourly-worker",
  },
  {
    onSuccess: () => {
      useGetUnPaidHourlyWorker.getState().execute({ force: true });
    },
  }
);

export const useGetCashOutDriverList = request({
  method: "GET",
  path: "cash-out/driver/list",
});

export const useGetUnCashedOutDriverList = request({
  method: "GET",
  path: "uncashed-out/driver/list",
});

export const useGetCashOutHourlyWorkerList = request({
  method: "GET",
  path: "cash-out/hourly-worker/list",
});

export const useChangeActivenessDate = request(
  {
    method: "PUT",
    path: "cash-out/advertiser/date",
  },
  {
    onSuccess: () =>
      useGetUnPaidHourlyWorker.getState().execute({ force: true }),
  }
);

export const useGetBakerOrders = requestList({
  method: "GET",
  path: "baker/order/list",
});

export const useGetBakerOrdersHistory = requestList({
  method: "GET",
  path: "baker/order/history/list",
});

export const useOrderServed = request(
  {
    method: "PUT",
    path: "baker/order/served",
  },
  {
    onSuccess: () => {
      useGetBakerOrders.getState().execute({ force: true });
    },
  }
);
export const useEditBakerKbd = request(
  {
    method: "PUT",
    path: "baker/kbd",
  },
  {
    onSuccess: () => {
      useGetMe.getState().execute({ force: true });
    },
  }
);

export const useGetDriverCalculation = requestList({
  method: "GET",
  path: "delivery/calculation",
});

export const useGetDriverReport = requestList({
  method: "GET",
  path: "delivery/report",
});

export const useCheckOrderByQR = request({
  method: "GET",
  path: "delivery/check-order-by-qr",
});

export const useAssignOrderToDriver = request(
  {
    method: "PUT",
    path: "delivery/assign-order-to-driver-by-qr",
  },
  {
    onSuccess: () => {
      useOrderOfDelivery.getState().execute({ force: true });
    },
  }
);

export const useGetReceiptList = requestList({
  method: "GET",
  path: "printer/receipt/list",
});

export const useDeleteReceipt = requestList(
  {
    method: "DELETE",
    path: "printer/receipt",
  },
  {
    onSuccess: () => {
      useGetReceiptList.getState().execute({ force: true });
    },
  }
);

export const useGetMacAddress = requestList({
  method: "GET",
  path: "printer-mac",
});

export const useAddMacAddress = request(
  {
    method: "POST",
    path: "printer-mac",
  },
  {
    onSuccess: () => {
      useGetMacAddress.getState().execute({ force: true });
    },
  }
);

export const useDeleteMacAddress = request(
  {
    method: "DELETE",
    path: "printer-mac",
  },
  {
    onSuccess: () => {
      useGetMacAddress.getState().execute({ force: true });
    },
  }
);

export const useGetMenuName = request({
  method: "GET",
  path: "menu-name",
});

export const useAddMenuName = request(
  {
    method: "POST",
    path: "menu-name",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useGetMenuSubCategory = requestList({
  method: "GET",
  path: "order/template/subcategory",
});
export const useAddMenuSubCategory = request(
  {
    method: "POST",
    path: "order/template/subcategory",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);
export const useEditMenuSubCategory = request(
  {
    method: "PUT",
    path: "order/template/subcategory",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useDeleteMenuName = request(
  {
    method: "DELETE",
    path: "menu-name",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useAddMenuCategoryName = request(
  {
    method: "POST",
    path: "menu/category",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useEditMenuCategory = request(
  {
    method: "PUT",
    path: "menu/category",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useUpdateMenuCategoryNameSort = request(
  {
    method: "PATCH",
    path: "menu/category/sort",
  },
  {
    onSuccess: () => {
      useGetOrderTemplate.getState().execute({ force: true });
    },
  }
);

export const useAddPrintRequest = request({
  method: "POST",
  path: "print/request",
});

export const useAddOrderPrintRequest = request({
  method: "POST",
  path: "print/order",
});

export const useReceiptMarkUp = requestList({
  method: "POST",
  path: "print/markup",
});

export const useUpdateReceiptSetting = requestList(
  {
    method: "PUT",
    path: "print/receipt/setting",
  },
  {
    onSuccess: () => {
      useGetMe.getState().execute({ force: true });
    },
  }
);

export const useRequestTelephonyCode = request({
  method: "POST",
  path: "telephony/request-code",
});

export const useGetTelephony = requestList({
  method: "GET",
  path: "telephony",
});

export const useEditTelephony = request(
  {
    method: "PUT",
    path: "telephony",
  },
  {
    onSuccess: () => {
      useGetTelephony.getState().execute({ force: true });
    },
  }
);

export const useDeleteTelephony = request(
  {
    method: "DELETE",
    path: "telephony/delete",
  },
  {
    onSuccess: () => {
      useGetTelephony.getState().execute({ force: true });
    },
  }
);

export const useGetDial = request(
  {
    method: "GET",
    path: "telephony/dial",
  },
  {
    onSuccess: (res) => {
      // dial.setNumbers(res.data?.map(({ phoneNumber }) => phoneNumber));
      useDial.getState().setNumbers(res.data || []);
    },
  }
);

export const useDeleteDial = request(
  {
    method: "DELETE",
    path: "telephony/dial",
  },
  {
    onSuccess: () => {
      useGetDial.getState().execute({ force: true });
    },
  }
);

export const useRequestBakerCode = request({
  method: "POST",
  path: "baker/request-code",
});

export const useRegisterBakerDevice = request({
  method: "POST",
  path: "baker/register",
});

export const useGetBaker = request({
  method: "GET",
  path: "baker",
});

export const useDeleteBaker = request(
  {
    method: "DELETE",
    path: "baker/delete",
  },
  {
    onSuccess: () => {
      useGetBaker.getState().execute({ force: true });
    },
  }
);

export const useGetRestaurantsList = requestList({
  method: "GET",
  path: "project_owner/restaurants",
});

export const useUpdateRestaurant = request(
  {
    method: "PUT",
    path: "project_owner/restaurants",
  },
  {
    onSuccess: () => {
      useGetRestaurantsList.getState().execute({ force: true });
    },
  }
);

export const useGetSingleRestaurantUnpaid = request({
  method: "GET",
  path: "project_owner/single-restaurant/unpaid",
});

export const useCashRestaurantOut = request({
  method: "POST",
  path: "project_owner/single-restaurant/cashout",
});

export const useGetSingleCashOutOfRestaurant = request({
  method: "GET",
  path: "project_owner/single-restaurant/cashout",
});

export const useGetSingleRestaurantCashoutHistory = request({
  method: "GET",
  path: "project_owner/single-restaurant/cashout-history",
});
