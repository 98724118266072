import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const DocPart = styled.div`
  width: calc(100% - (${({ macWidth }) => macWidth || 0}px + 10px));
  max-width: 100%;
`;

export const MacAddressPart = styled.div`
  width: 300px;
`;
export const ReceiptWrapper = styled.div`
  width: 100%;
  svg {
    width: 100%;
    height: auto;
  }
`;
