export const orderTypes = {
  DINE_IN: "DINE_IN",
  DELIVERY: "DELIVERY",
  PICK_UP: "PICK_UP",
  TO_GO: "TO_GO",
  ALL: "ALL",
};

export const orderTypeColor = {
  [orderTypes.DINE_IN]: "green",
  [orderTypes.TO_GO]: "black",
  [orderTypes.DELIVERY]: "blue",
};
