import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
`;

export const Logo = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    height: 100%;
  }
  span {
    cursor: pointer;
    /* color: white; */
    /* font-size: 25px; */
    /* font-family: "Futura"; */
  }
`;

export const UserBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  height: fit-content;
  cursor: pointer;
  /* padding: 5px 15px; */
  border-radius: 10px;
  img {
    width: 50px;
    height: 50px;
  }
`;

export const SidebarBtn = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: block;
  }
`;
