import {
  ArrowDownOutlined,
  CopyOutlined,
  DeleteOutlined,
  DoubleLeftOutlined,
  DownCircleOutlined,
  DownOutlined,
  EditOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Row,
  Tag,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { LangText } from "../../../components/langManagement";
import size from "../../../constants/size";
import { extraPriceCalc } from "../../../utils/calculate";
import { useGetMenuSubCategory, useGetOrderTemplate } from "../../../zustand/store";
import CustomizationModal from "../customizationModal";
import { thousandSeparator } from "../../../utils/numberManager";
import { mainColor } from "../../../components/styles/styles";
import { ModalWrapper } from "./styles";

function SpecialCustomizationModal({ open, onCancel, data, onOk }) {
  const menu = useGetOrderTemplate();
  const menuSubCategory = useGetMenuSubCategory();
  const [selectedOption, setSelectedOption] = useState([]);
  // const extraTopping = extraToppingPrice(selectedOption);
  const extraPrice = extraPriceCalc(selectedOption);
  // = selectedOption?.reduce(
  //   (ti, vi) =>
  //     ti + (vi?.reduce((tj, vj) => tj + (vj?.toppingPrice || 0), 0) || 0),
  //   0
  // );
  const [customization, setCustomization] = useState(null);
  const [form] = Form.useForm();
  // const menuList = menu?.data?.reduce(
  //   (t, { categories }) =>
  //     t.concat(categories?.reduce((ct, c) => ct.concat(c?.items || []), [])),
  //   []
  // );
  const menuList = menuSubCategory.data || []
  useEffect(() => {
    setSelectedOption(
      data?.options?.map((option, i) =>
        Array(option?.length)
          .fill("")
          .map((v, j) => {
            if (
              option?.foods?.length === 1 &&
              !data?.selectedOption?.[i]?.[j]
            ) {
              const firstFood = option?.foods?.[0];
              const id = firstFood?.id;

              const food = {
                ...menuList?.find(({ id: fId }) => id === fId),
                extra: firstFood?.extra,
              };
              const requiredExtra = food?.extra?.find(
                ({ amount }) => amount > 0
              );
              if (
                !requiredExtra &&
                !(food.flavors?.length > 0) &&
                !(food.flavorAmount > 0)
              ) {
                return food;
              }
              return {};
            }
            return data?.selectedOption?.[i]?.[j] || {};
          })
      )
    );

    form.setFieldValue("desc", data?.desc);
    form.setFieldValue("price", data?.price);
  }, [open]);

  return (
    <ModalWrapper
      centered
      title={data?.name}
      open={open}
      onCancel={onCancel}
      okButtonProps={{
        disabled: selectedOption?.some((v) =>
          v?.some((v) => !Object.keys(v)?.length)
        ),
        size: "large",
      }}
      onOk={() => {
        const desc = form.getFieldValue("desc");
        const price = form.getFieldValue("price");
        form.resetFields();
        onOk({ ...data, selectedOption, desc, price });
      }}
    >
      <Row gutter={[10, 10]}>
        {data?.options.map((option, index, a) => (
          <>
            <Col span={24}>Option #{index + 1}</Col>
            <Col span={24}>
              <Row gutter={[20, 20]}>
                {Array(option?.length)
                  .fill("")
                  .map((v, i) => (
                    <Col span={24} key={i}>

                      <Row gutter={[10, 10]}>
                        {option?.foods
                          ?.filter(({ id }) =>
                            selectedOption?.[index]?.[i].id
                              ? selectedOption?.[index]?.[i].id === id
                              : true
                          )
                          ?.map(({ id, extra }, j) => {
                            const food = {
                              ...menuList?.find(({ id: fId }) => id === fId),
                              extra,
                            };

                            const onClick = () => {
                              const ind = i;
                              if (
                                food?.extra?.find(
                                  ({ amount }) => amount > 0
                                ) ||
                                (food.flavors?.length > 0 &&
                                  food.flavorAmount > 0)
                              ) {
                                if (!selectedOption?.[index]?.[i].id)
                                  setCustomization({
                                    ...food,
                                    index,
                                    ind,
                                    numberOfTopping: option?.numberOfTopping,
                                  });
                              } else {
                                setSelectedOption((prev) =>
                                  prev?.map((v, i) =>
                                    i === index
                                      ? v.map((v, i) =>
                                        i === ind ? food /**{ id }**/ : v
                                      )
                                      : v
                                  )
                                );
                              }
                            };

                            const onContextMenu = (e) => {
                              e.preventDefault();
                              const ind = i;
                              if (!selectedOption?.[index]?.[i].id)
                                setCustomization({
                                  ...food,
                                  index,
                                  ind,
                                });
                            };

                            return (
                              <>
                                <Col
                                  span={
                                    selectedOption?.[index]?.[i].id === id
                                      ? 12
                                      : 8
                                  }
                                  key={id}
                                >
                                  <Card
                                    style={{
                                      cursor: "pointer",
                                      backgroundColor:
                                        selectedOption?.[index]?.[i].id === id
                                          ? "#90ee9059"
                                          : null,
                                    }}
                                    size="small"
                                    bordered
                                    {...(!selectedOption?.[index]?.[i].id
                                      ? { onContextMenu, onClick }
                                      : {})}
                                    actionsBg={"#90ee9059"}
                                    actions={
                                      selectedOption?.[index]?.[i].id
                                        ? [
                                          <DeleteOutlined
                                            onClick={() => {
                                              setSelectedOption((prev) =>
                                                prev?.map((v, j) =>
                                                  j === index
                                                    ? v.map((v, j) =>
                                                      j === i ? {} : v
                                                    )
                                                    : v
                                                )
                                              );
                                            }}
                                          />,
                                        ]
                                        : []
                                    }
                                  >
                                    <div>
                                      <Typography.Text
                                        strong
                                        style={{
                                          marginRight: "10px",
                                          fontSize: "16px",
                                          lineHeight: 1,
                                        }}
                                      >
                                        {food?.name}
                                      </Typography.Text>

                                      {food?.size ? (
                                        <Tag
                                          style={{
                                            margin: 0,
                                          }}
                                          color={mainColor}
                                        >
                                          {size[food?.size]}
                                        </Tag>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div>
                                      {selectedOption?.[index]?.[i]?.id &&
                                        // selectedOption?.[index]?.[i]?.toppings ? (
                                        selectedOption?.[index]?.[i]
                                          ?.selectedExtra ? (
                                        <Row>
                                          {selectedOption?.[index]?.[
                                            i
                                          ]?.selectedExtra?.map((t, i) => {
                                            return (
                                              <Col span={24} key={i}>
                                                <Tag
                                                  color="green"
                                                  size="small"
                                                >
                                                  {t?.side || "ADD"}
                                                </Tag>
                                                {t?.name}
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      ) : (
                                        <></>
                                      )}
                                      {selectedOption?.[index]?.[i]?.id &&
                                        selectedOption?.[index]?.[i]
                                          ?.selectedFlavors ? (
                                        <Row>
                                          {selectedOption?.[index]?.[
                                            i
                                          ]?.selectedFlavors?.map((t, i) => (
                                            <Col key={i}>
                                              <Tag color="green" size="small">
                                                {t?.name}
                                              </Tag>
                                            </Col>
                                          ))}
                                        </Row>
                                      ) : (
                                        <></>
                                      )}
                                      {selectedOption?.[index]?.[i]?.desc ? (
                                        <Tag color="blue">
                                          {selectedOption?.[index]?.[i]?.desc}
                                        </Tag>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </Card>
                                </Col>
                                {selectedOption?.[index]?.[i].id === id &&
                                  option.length > 1 &&
                                  selectedOption?.[index]?.some(
                                    (v) => !Object.values(v || {})?.length
                                  ) ? (
                                  <Col>
                                    <Button
                                      size="large"
                                      onClick={() => {
                                        let empty = false;
                                        const data =
                                          selectedOption?.[index]?.[i];
                                        const dataIndex = index;
                                        setSelectedOption((prev) =>
                                          prev?.map((v, i) =>
                                            i === dataIndex
                                              ? v.map((v) => {
                                                if (
                                                  !empty &&
                                                  !Object.values(v || {})
                                                    ?.length
                                                ) {
                                                  empty = true;
                                                  return data;
                                                } else {
                                                  return v;
                                                }

                                                // return i === data?.ind
                                                //   ? { ...data }
                                                //   : v;
                                              })
                                              : v
                                          )
                                        );
                                      }}
                                    >
                                      <CopyOutlined />
                                      {/* <DownOutlined /> */}
                                    </Button>
                                  </Col>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })}
                      </Row>

                    </Col>
                  ))}
              </Row>
            </Col>
            {a.length - 1 !== index ? (
              <Col span={24}>
                <div style={{ borderTop: "1px solid black" }} />
              </Col>
            ) : (
              <></>
            )}
          </>
        ))}
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Form layout="vertical" form={form}>
            <Form.Item
              name="price"
              label={LangText({ id: "price" })}
              extra={
                extraPrice > 0 ? (
                  <>${extraPrice} will be added for extra topping(s)</>
                ) : null
              }
            >
              <InputNumber style={{ width: "100%" }} addonBefore={"$"} />
            </Form.Item>
            <Form.Item name="desc" label={LangText({ id: "description" })}>
              <Input.TextArea />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <CustomizationModal
        open={Boolean(customization)}
        onCancel={() => setCustomization(null)}
        data={customization}
        onOk={(data) => {
          setSelectedOption((prev) =>
            prev?.map((v, i) =>
              i === data?.index
                ? v.map((v, i) => (i === data?.ind ? { ...data } : v))
                : v
            )
          );
        }}
        numberOfTopping={customization?.numberOfTopping}
        specialCustomization
      //   selectedFood={selectedFood}
      //   setSelectedFood={setSelectedFood}
      />
    </ModalWrapper>
  );
}

export default SpecialCustomizationModal;
