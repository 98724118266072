import { DollarOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, InputNumber, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { thousandSeparator } from "../../../utils/numberManager";
import {
  useCashRestaurantOut,
  useGetSingleRestaurantUnpaid,
} from "../../../zustand/store";
import SingleCashOut from "../../cashOutDriverPage/singleCashOut";
import { SquareIcon } from "../../singleOrderOfDriver/styles";
import Conformation from "./confirmation";

import {
  ApplePay,
  GooglePay,
  CashAppPay,
  CreditCard,
  PaymentForm,
} from "react-square-web-payments-sdk";

const columns = [
  // "ID",
  "date",
  "driver",
  "Payment",
];

function AppFee() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [confirmation, setConformation] = useState(null);

  // const cashout = useGetCashOutDriverList();
  const cashout = useGetSingleRestaurantUnpaid();

  const { id } = useParams();
  useEffect(() => {
    cashout.execute({ force: true, params: { id } });
  }, []);
  const [form] = Form.useForm();

  const [totals, setTotals] = useState({
    sqr: 0,
    cash: 0,
    total: 0,
  });

  useEffect(() => {
    if (cashout.data) {
      const keys = cashout.data?.map(({ id }) => id);
      setSelectedRowKeys(keys);
      const totalData = cashout.data?.reduce(
        (t, v) => {
          const cash =
            t.cash + (keys.includes(v.id) ? v?.overallTotals?.appFee : 0);
          const sqr =
            t.sqr + (keys.includes(v.id) ? v?.overallTotals?.sqrAppFee : 0);

          return {
            sqr,
            cash,
            total: t.total + cash + sqr,
          };
        },
        { sqr: 0, cash: 0, total: 0 }
      );
      form.setFieldValue("total", totalData?.total);
      setTotals(totalData);
    }
  }, [cashout.data]);

  return (
    <Row gutter={[10, 10]}>
      <Col span={24}>
        <Card size="small">
          <div
            style={{
              width: "100%",
              height: "100px",
              transform: "scale(2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row gutter={[10, 10]} align={"center"}>
              <Col>
                <DollarOutlined /> {thousandSeparator(totals?.cash || 0)}
              </Col>
              <Col>
                <PlusOutlined />
              </Col>
              <Col>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <SquareIcon>
                    <div />
                  </SquareIcon>
                  <div>{thousandSeparator(totals?.sqr || 0)}</div>
                </div>
              </Col>
              <Col>=</Col>
              <Col>${thousandSeparator(totals?.total || 0)}</Col>
            </Row>
          </div>
          <div style={{ width: "300px", margin: "auto" }}>
            <Form layout="vertical" form={form}>
              <Form.Item label="Total" name={"total"}>
                <InputNumber
                  style={{ width: "100%" }}
                  min={0}
                  size="large"
                  value={totals.total}
                  onChange={(e) => {
                    setTotals((prev) => ({ ...prev, total: e }));
                  }}
                />
              </Form.Item>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  disabled={totals.total <= 0 || !cashout.data?.length}
                  onClick={() => {
                    setConformation(totals);
                  }}
                  htmlType="submit"
                  size="large"
                  type="primary"
                  style={{ margin: "auto", display: "block" }}
                >
                  Cash Out
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div>
            {/* <PaymentForm>
              <CashAppPay />
            </PaymentForm> */}
          </div>
        </Card>
      </Col>
      <Col span={24}>
        <Card>
          <PaymentForm
            createPaymentRequest={() => ({
              countryCode: "US",
              currencyCode: "USD",
              lineItems: [
                {
                  amount: "22.15",
                  label: "Item to be purchased",
                  id: "SKU-12345",
                  imageUrl: "https://url-cdn.com/123ABC",
                  pending: true,
                  productUrl: "https://my-company.com/product-123ABC",
                },
              ],
              taxLineItems: [
                {
                  label: "State Tax",
                  amount: "8.95",
                  pending: true,
                },
              ],
              discounts: [
                {
                  label: "Holiday Discount",
                  amount: "5.00",
                  pending: true,
                },
              ],
              requestBillingContact: false,
              requestShippingContact: false,
              shippingOptions: [
                {
                  label: "Next Day",
                  amount: "15.69",
                  id: "1",
                },
                {
                  label: "Three Day",
                  amount: "2.00",
                  id: "2",
                },
              ],
              // pending is only required if it's true.
              total: {
                amount: "41.79",
                label: "Total",
              },
            })}
            locationId="LID"
            cardTokenizeResponseReceived={(token, verifiedBuyer) => {
              console.info("Token:", token);
              console.info("Verified Buyer:", verifiedBuyer);
            }}
            applicationId="sq0idp-Y0QZQ-Xx-Xx-Xx-Xx"
          >
            <GooglePay />
            <ApplePay />
            <CashAppPay />
            <CreditCard />
          </PaymentForm>
        </Card>
      </Col>
      <Col span={24}>
        <Card size="small">
          <Table
            loading={cashout.loading}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <>
                  <SingleCashOut data={record} />
                </>
              ),
              //   rowExpandable: (record) => record.name !== "Not Expandable",
            }}
            rowSelection={{
              selectedRowKeys,
              onChange: (keys) => {
                setSelectedRowKeys(keys);
                const totalData = cashout.data?.reduce(
                  (t, v) => {
                    const cash =
                      t.cash +
                      (keys.includes(v.id) ? v?.overallTotals?.appFee : 0);
                    const sqr =
                      t.sqr +
                      (keys.includes(v.id) ? v?.overallTotals?.sqrAppFee : 0);

                    return {
                      sqr,
                      cash,
                      total: t.total + cash + sqr,
                    };
                  },
                  { sqr: 0, cash: 0, total: 0 }
                );
                form.setFieldValue("total", totalData?.total);
                setTotals(totalData);
              },
            }}
            // size="small"
            columns={columns.map((column) => ({
              key: column,
              dataIndex: column,
              title: column,
            }))}
            dataSource={cashout.data?.map((v) => ({
              ...v,
              key: v.id,
              driver: v?.user?.[0]?.fullname,
              Payment: (
                <Row gutter={[20, 20]}>
                  <Col>
                    <DollarOutlined /> {v?.overallTotals?.appFee}
                  </Col>
                  <Col>
                    <PlusOutlined />
                  </Col>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <SquareIcon>
                        <div />
                      </SquareIcon>{" "}
                      <div>{v?.overallTotals?.sqrAppFee}</div>
                    </div>
                  </Col>
                  <Col>=</Col>
                  <Col>
                    {v?.overallTotals?.appFee + v?.overallTotals?.sqrAppFee}
                  </Col>
                </Row>
              ),
            }))}
          />
        </Card>
      </Col>
      <Conformation
        data={confirmation}
        cashouts={selectedRowKeys}
        onCancel={() => {
          setConformation(null);
        }}
      />
    </Row>
  );
}

export default AppFee;
