import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 10px;
`;

export const Title = styled.div`
  font-size: 32px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    font-family: "Futura";
  }
`;

export const BodyPart = styled.div``;
