import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  height: calc(100vh - 125px);
  overflow-y: auto;
  /* align-items: first baseline; */
  align-content: start;
`;

export const SingleCardWrapper = styled.div`
  width: 100%;
  height: fit-content;
`;
