import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Col, List, Popover, Row, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/container";
import { LangText } from "../../components/langManagement";
import {
  useDeleteAddress,
  useEditAddress,
  useEditArea,
  useGetAddresses,
  useGetAreaAndAddresses,
  useGetAreas,
} from "../../zustand/store";
import AddressDrawer from "./addressDrawer";
import AddressesInMap from "./addressesInMap";
import { AddressListWrapper, AreaWrapper, MapWrapper, Wrapper } from "./styles";

export const getColoredIcon = (color, text) => {
  const params = {
    type: "material",
    color,
    size: "large",
    iconType: "awesome",
    iconSize: "large",
    scaleFactor: 2,
    apiKey: "de11088e1852491aa345dd9facc6b61d",
    ...(text ? { text } : {}),
  };
  const urlSearchParams = new URLSearchParams(params);
  // icon=home&

  //  urlSearchParams.toString();
  // const url = `https://api.geoapify.com/v1/icon/?type=material&color=${color}&size=large&icon=home&iconType=awesome&iconSize=large&scaleFactor=2&apiKey=de11088e1852491aa345dd9facc6b61d`;
  const url = "https://api.geoapify.com/v1/icon/?" + urlSearchParams.toString();
  return url;
};

export const AreaListItem = ({ bordered, size }) => {
  const areas = useGetAreas();
  const areaAndAddress = useGetAreaAndAddresses();
  const editArea = useEditArea();

  const move = ({ id, index, move }) => {
    const l = areas.data;
    const s = [...l.slice(0, index), ...l.slice(index + 1)];
    s.splice(index + move, 0, { id });
    const list = s.map(({ id }, i) => ({ id, sort: i + 1 }));
    editArea.execute({ data: { list } });
  };

  useEffect(() => {
    areas.execute();
  }, []);

  return <List
    bordered={bordered}
    size={size}
    loading={areas.loading}
    dataSource={areas?.data || []}
    renderItem={
      ({ id, name, price, address_count, color, visible }, index) => (
        <List.Item>
          <Row align={"middle"} style={{ width: "100%" }}>
            <Col span={4}>
              <img
                style={{ width: "auto", height: "35px" }}
                src={getColoredIcon(color, address_count)}
                alt=""
              />
            </Col>
            {/* <Col
            span={5}
            // style={{ textAlign: "center" }}
          >
              {address_count}
          </Col> */}
            <Col span={13}>
              {name}
              <div>
                <Switch
                  loading={editArea.loading || areas.loading}
                  checked={visible}
                  onChange={(visible) => {
                    editArea.execute({
                      data: { visible },
                      params: { id },
                      onSuccess: () => {
                        areaAndAddress.execute({ force: true });
                      },
                    });
                  }}
                />
              </div>
            </Col>
            <Col span={2}>${price}</Col>
            <Col span={5}>
              <div style={{ marginLeft: "auto", width: "fit-content" }}>
                <div>
                  <Button
                    disabled={index <= 0}
                    size="small"
                    onClick={() => {
                      index > 0 && move({ id, index, move: -1 });
                    }}
                    loading={areas.loading}
                  >
                    <UpOutlined />
                  </Button>
                </div>
                <div>
                  <Button
                    disabled={index + 1 >= areas.data?.length}
                    onClick={() => {
                      index + 1 < areas.data?.length && move({ id, index, move: 1 });
                    }}
                    size="small"
                    loading={areas.loading}
                  >
                    <DownOutlined />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </List.Item>
      )
    }
  />

  // areas?.data?.map(
  //   ({ id, name, price, address_count, color, visible }, index, a) => (
  //     <List.Item>
  //       <Row align={"middle"} style={{ width: "100%" }}>
  //         <Col span={4}>
  //           <img
  //             style={{ width: "auto", height: "35px" }}
  //             src={getColoredIcon(color, address_count)}
  //             alt=""
  //           />
  //         </Col>
  //         {/* <Col
  //           span={5}
  //           // style={{ textAlign: "center" }}
  //         >
  //             {address_count}
  //         </Col> */}
  //         <Col span={13}>
  //           {name}
  //           <div>
  //             <Switch
  //               loading={editArea.loading || areas.loading}
  //               checked={visible}
  //               onChange={(visible) => {
  //                 editArea.execute({
  //                   data: { visible },
  //                   params: { id },
  //                   onSuccess: () => {
  //                     areaAndAddress.execute({ force: true });
  //                   },
  //                 });
  //               }}
  //             />
  //           </div>
  //         </Col>
  //         <Col span={2}>${price}</Col>
  //         <Col span={5}>
  //           <div style={{ marginLeft: "auto", width: "fit-content" }}>
  //             <div>
  //               <Button
  //                 disabled={index <= 0}
  //                 size="small"
  //                 onClick={() => {
  //                   index > 0 && move({ id, index, move: -1 });
  //                 }}
  //                 loading={areas.loading}
  //               >
  //                 <UpOutlined />
  //               </Button>
  //             </div>
  //             <div>
  //               <Button
  //                 disabled={index + 1 >= a.length}
  //                 onClick={() => {
  //                   index + 1 < a.length && move({ id, index, move: 1 });
  //                 }}
  //                 size="small"
  //                 loading={areas.loading}
  //               >
  //                 <DownOutlined />
  //               </Button>
  //             </div>
  //           </div>
  //         </Col>
  //       </Row>
  //     </List.Item>
  //   )
  // );
};

function AddressPage() {
  const [addressDrawer, setAddressDrawer] = useState({ open: false });
  const addresses = useGetAddresses();
  const areaAndAddress = useGetAreaAndAddresses();
  const areas = useGetAreas();
  const deleteAddress = useDeleteAddress();
  const navigate = useNavigate();

  useEffect(() => {
    addresses.execute();
    areas.execute();
    areaAndAddress.execute();
  }, []);

  return (
    <PageContainer
      title={LangText({ id: "address" })}
      button={"addAddress"}
      onClick={() => {
        setAddressDrawer({ open: true });
      }}
    >
      <Wrapper>
        <AddressListWrapper size="small"
          title={"Addresses"}
        >
          {/* <Card size="small"> */}
          <List
            loading={areaAndAddress.loading}
            dataSource={areaAndAddress.data || []}
            renderItem={({ address, name, color, visible }) => (
              address?.map((data) => {
                const { name, deliveryFee, id, coords, areaId } = data;
                // const area = areas?.data?.find(({ polygon }) =>
                //   pointInPolygon(coords.lat, coords.lng, polygon)
                // );
                const icon = getColoredIcon(color || "white");
                return (
                  <List.Item key={id} style={{ position: "relative" }}>
                    <Row style={{ width: "100%" }}>
                      {/* <Badge color="blue" count={`$${deliveryFee}`} />  */}
                      <Col span={2}>
                        <img
                          style={{ width: "auto", height: "25px" }}
                          src={icon}
                          alt=""
                        />
                        {/* <Tag color={color}> </Tag> */}
                      </Col>
                      <Col span={12}>{name}</Col>
                      <Col span={4} style={{ textAlign: "center" }}>
                        ${deliveryFee}
                      </Col>
                      <Col span={6}>
                        <div style={{ marginLeft: "auto" }}>
                          <Row gutter={10}>
                            <Col>
                              <EditOutlined
                                onClick={() => {
                                  setAddressDrawer({
                                    open: true,
                                    edit: true,
                                    data,
                                  });
                                }}
                              />
                            </Col>
                            <Col>
                              <Popover
                                content={
                                  <div>
                                    Do you really want to delete this address?{" "}
                                    <br />
                                    <Button
                                      loading={deleteAddress.loading}
                                      size="small"
                                      onClick={() => {
                                        deleteAddress.execute({
                                          params: { id },
                                          onSuccess: () => {
                                            addresses.execute({ force: true });
                                            areas.execute({ force: true });
                                          },
                                        });
                                      }}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                }
                              >
                                <DeleteOutlined />
                              </Popover>
                            </Col>
                            <Col>
                              <CopyOutlined
                                onClick={() => {
                                  setAddressDrawer({ open: true, data });
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {!visible && (
                      <div
                        style={{
                          width: "100%",
                          position: "absolute",
                          backgroundColor: "lightgrey",
                          height: "100%",
                          opacity: 0.5,
                        }}
                      />
                    )}
                  </List.Item>
                );
              })
            )}
          />
        </AddressListWrapper>
        <MapWrapper size="small">
          <AddressesInMap />
        </MapWrapper>
        <AreaWrapper
          extra={
            <Row>
              <Col>
                <Button
                  size="small"
                  onClick={() => {
                    navigate("/address-area");
                  }}
                >
                  Control
                </Button>
              </Col>
            </Row>
          }
          title={<>Areas</>}
          size="small">
          <AreaListItem />
        </AreaWrapper>
      </Wrapper>
      <AddressDrawer
        data={addressDrawer?.data}
        edit={addressDrawer?.edit}
        open={addressDrawer?.open}
        onClose={() => {
          setAddressDrawer({ open: false });
        }}
      />
      {/* <MapBox/> */}
    </PageContainer>
  );
}

export default AddressPage;
