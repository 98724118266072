import React from "react";
import { useLangStore } from "../../zustand/store";
import { dict as uzDict } from "../../constants/dictionary/uz";
import { dict as ruDict } from "../../constants/dictionary/ru";
import { dict as enDict } from "../../constants/dictionary/en";

export function Text({ id }) {
  const lang = useLangStore((state) => state.lang);
  return text({ lang, id });
}

export const LangText = Text;

export const text = ({ lang, id }) => {
  return (
    {
      UZ: uzDict[id],
      RU: ruDict[id],
      EN: enDict[id],
    }?.[lang] || id
  );
};

export const useLangText = () => {
  const lang = useLangStore((state) => state.lang);
  return ({ id }) => text({ lang, id });
};
