const size = {
  "12_md": '12" MD',
  "14_lg": '14" LG',
  "16_x-lg": '16" X-LG',
  "sm": 'SM',
  "md": 'MD',
  "lg": 'LG',
  "x-lg": 'X-LG',
};



export default size;