import React, { useEffect } from "react";
import { Wrapper } from "./styles";
import { useGetMe } from "../../zustand/store";
import { Button, Card, List } from "antd";
import { useNavigate } from "react-router-dom";
import {
  ADVERTISER,
  DRIVER,
  ORDER_TAKER,
  PIZZA_MAKER,
} from "../../constants/roles";

function RolesPage() {
  const me = useGetMe();

  const navigate = useNavigate();

  const navigationObj = {
    [ORDER_TAKER]: "add-order",
    [PIZZA_MAKER]: "pizzaiolo",
  };

  const redirect = (role) => {
    navigate("/" + (navigationObj[role] || `${role.toLowerCase()}s-page`));
  };

  useEffect(() => {
    if (me?.data) {
      if (me.data?.user?.inStore?.startedDeliveryAt && !me.data?.user?.inStore?.owner) {
        redirect(DRIVER);
      } else if (me.data?.user?.startedAdvertisingAt && !me.data?.user?.inStore?.owner) {
        redirect(ADVERTISER);
      } else if (
        !me.data?.user?.inStore?.owner &&
        me.data?.user?.inStore?.roles?.roles?.length === 1
      ) {
        const role = me.data?.user?.inStore?.roles?.roles[0];
        redirect(role);
      }
    }
  }, [me.data]);

  return (
    <Wrapper>
      <Card>
        <List style={{ width: "400px" }}>
          {me.data?.user?.inStore?.roles?.roles?.map((role, i) => (
            <List.Item key={i}>
              <Button
                onClick={() => {
                  redirect(role);
                }}
                block
              >
                {role}
              </Button>
            </List.Item>
          ))}
        </List>
      </Card>
    </Wrapper>
  );
}

export default RolesPage;
