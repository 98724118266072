import { Button, Card, Col, Drawer, Form, Input, List, Row, Table, Tag, Tooltip, Typography, message } from "antd";
import React, { useEffect } from "react";
import {
  useCheckSquareWebhook,
  useConnectSquareWebhook,
  useGetMe,
  useSqrPayments,
  useSqrWebhookList,
} from "../../../zustand/store";
import { InputWrapper, Wrapper } from "./styles";
import dayjs from "dayjs";

const Payments = () => {
  const payments = useSqrPayments();

  const fetchData = ({ page, limit = 10, force } = {}) => {
    payments.execute({ force, params: { page, limit } });
  }

  useEffect(() => {
    fetchData();
  }, [])

  const data = payments.data;
  const limit = data?.limit;
  const page = data?.page;
  const count = data?.count;
  return <>
    <Table
      size="small"
      columns={[
        {
          title: "id",
          key: "_id",
          dataIndex: "_id",
          render: (id) => <div style={{ width: "50px" }}><Typography.Text ellipsis>{id}</Typography.Text></div>
        },
        {
          title: "device",
          key: "device",
          dataIndex: ["data", "data", "object", "payment",
            // "device_details", "device_name"
          ],
          render: (payment) => {
            return <div>
              <div>
                {payment?.device_details?.device_name}
              </div>
              <div>
                {payment?.card_details?.entry_method}
              </div>

            </div>
          }
        },
        {
          title: "payment",
          key: "payment",
          dataIndex: ["data", "data", "object", "payment"],
          render: (payment, { createdAt }) => <div>
            <Tag>Amount: ${payment?.amount_money?.amount / 100}</Tag>
            {payment?.tip_money ? (
              <>
                <Tag>Tip: ${payment?.tip_money?.amount / 100}</Tag>{" "}
                <Tag>Total: ${payment?.total_money?.amount / 100}</Tag>{" "}
              </>
            ) : (
              ""
            )}
            <div>{dayjs(createdAt).fromNow()}</div>
          </div>
        },
        {
          title: "order",
          key: "order",
          dataIndex: "orderId",
        }
      ]}
      pagination={{
        current: page,
        pageSize: limit,
        total: count,
        onChange: (page, limit) => {
          fetchData({ page, limit, force: true })
        },
      }}
      dataSource={payments.data?.list || []}
    /></>
}

function SquareDrawer({ open, onClose }) {
  const webhookList = useSqrWebhookList();
  const connectSqrWebhook = useConnectSquareWebhook();
  const checkSqrWebhook = useCheckSquareWebhook();
  const me = useGetMe();

  const [form] = Form.useForm();


  useEffect(() => {
    if (open) {
      checkSqrWebhook.execute();
      webhookList.execute();
    }
  }, [open]);

  useEffect(() => {
    const sqr_token = me?.data?.restaurant?.sqr_token;
    if (sqr_token) {
      form.setFieldValue("sqr_token", sqr_token);
    }
  }, [me.data]);

  return (
    <Wrapper>
      <Drawer width={"620px"} open={open} onClose={onClose} title="Square">
        To connect to Square, paste your{" "}
        <a href="https://developer.squareup.com/docs/build-basics/access-tokens#get-a-personal-access-token" target="_blank">
          Square Access Token
        </a>{" "}
        below. Choose <b>Production</b>, Not Sandbox. <br /> <br />
        <Form
          form={form}
          layout="vertical"
          onFinish={(e) => {
            connectSqrWebhook.execute({
              data: e,
              onSuccess: (res) => {
                message.success(res.data);
              },
            });
          }}
        >
          <InputWrapper>
            <Form.Item label="Access Token" name="sqr_token">
              <Input
                addonAfter={
                  checkSqrWebhook.data ? (
                    <Tooltip title={<>{webhookList.data?.url}</>}>
                      {/* <Tag color="green"> */}
                      Already Connected ✅{/* </Tag> */}
                    </Tooltip>
                  ) : null
                }
              />
            </Form.Item>
          </InputWrapper>
          <Form.Item>
            <Button loading={checkSqrWebhook.loading || connectSqrWebhook.loading} htmlType="submit">Connect</Button>
          </Form.Item>
        </Form>

        <Table
          bordered
          pagination={false}
          size="small"
          columns={[
            {
              title: "name",
              dataIndex: "name",
            },
            {
              title: "url",
              dataIndex: "notificationUrl",
              render: (url) => (
                <Typography.Text type={url === webhookList.data?.url && "success"}>
                  {url}
                </Typography.Text>
              )
            },
            {
              width: "10px",
              title: "events",
              dataIndex: "eventTypes",
              render: (types) =>
                <>
                  {types.map((v) => <div><Tag>{v}</Tag></div>)}
                </>

            },
            // {
            //   title: "Date",
            //   dataIndex: "createdAt"
            // },
          ]}
          dataSource={webhookList.data?.list || []}
        />
        <Payments />
      </Drawer>
    </Wrapper>
  );
}

export default SquareDrawer;
