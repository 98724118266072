import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { LeftHalf, RightHalf } from "../assets/icons/components";
import { FullCircle } from "../assets/icons/components/styles";

const additionalTopping = "add'l_topping";
// const oneTopping = "1 Topping";
const wings = "Wings";

const botUsername = "hisob_org_bot";

const LEFT_SIDE = "LEFT";
const FULL_SIDE = "FULL";
const RIGHT_SIDE = "RIGHT";
const NO = "NO";
const ADD_FULLY = "ADD_FULLY";

const emoji = {
  [LEFT_SIDE]: "◀",
  [FULL_SIDE]: "+",
  [RIGHT_SIDE]: "▶",
  [NO]: "-",
  [ADD_FULLY]: "+",
};

const sideIcon = {
  [LEFT_SIDE]: (isActive) => <LeftHalf reverse={isActive} />,
  [FULL_SIDE]: (isActive) => <FullCircle reverse={isActive} />,
  [RIGHT_SIDE]: (isActive) => <RightHalf reverse={isActive} />,
  [NO]: () => <CloseOutlined />,
  [ADD_FULLY]: () => <PlusOutlined />,
};

export {
  additionalTopping,
  // oneTopping,
  wings,
  botUsername,
  LEFT_SIDE,
  FULL_SIDE,
  RIGHT_SIDE,
  NO,
  emoji,
  sideIcon,
};
