import { DeleteOutlined } from "@ant-design/icons";
import { Card, Col, Modal, Popconfirm, Row, Typography } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { BASE_URL } from "../../../../constants/link";
import { useDeleteGoods } from "../../../../zustand/store";
import SinglePaymentCard from "./singlePaymentCard";

const { Text } = Typography;

function SingleAddedGoods({ data }) {
  const {
    createdAt,
    description,
    goods_material_amounts,
    id,
    payments,
    provider,
    // updatedAt
    addedAt,
    images,
    files,
    created_at,
  } = data;
  const [preview, setPreview] = useState({ open: false });

  const deleteGoods = useDeleteGoods();
  const [popConform, setPopConfirm] = useState(false);

  return (
    <>
      <Card
        extra={
          <Popconfirm
            open={popConform}
            onOpenChange={(e) => setPopConfirm(e)}
            title="Delete"
            description="Do you really want to delete this?"
            okText="Yes"
            okButtonProps={{
              onClick: () => {
                deleteGoods.execute({
                  params: { id },
                  onSuccess: () => setPopConfirm(false),
                });
              },
              loading: deleteGoods.loading,
            }}
          >
            <div
              style={{
                width: "25px",
                height: "25px",
                border: "1px solid black",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
              }}
            >
              <DeleteOutlined />
            </div>
          </Popconfirm>
        }
        size="small"
        title={moment(created_at).calendar()}
      >
        <Row gutter={[10, 10]}>
          {files?.map((file, i) => {
            // {
            //   created_at,
            //   file_id,
            //   id,
            //   img_base64,
            //   warehouse_id
            // }
            return (
              <Col key={i}>
                <img
                  onClick={() =>
                    setPreview({ open: true, file: file?.img_base64 })
                  }
                  key={file?.file_id}
                  style={{ width: "100px" }}
                  // src={`data:image/png;base64, ${file?.img_base64}`}
                  src={`${BASE_URL}/file/${file?.file_id}`}
                  alt="img"
                />
              </Col>
            );
          })}
        </Row>
        <br />
        <Row>
          {payments?.length ? (
            payments?.map((payment, i) => (
              <SinglePaymentCard key={i} {...payment} />
            ))
          ) : (
            <SinglePaymentCard warehouseId={id} />
          )}
        </Row>

        <Text>{description}</Text>
      </Card>
      <Modal
        centered
        open={preview.open}
        title={" "}
        // title={null}
        footer={null}
        onCancel={() => setPreview({ open: false })}
      >
        <img
          alt="example"
          style={{
            width: "100%",
            height: "472px",
            objectFit: "contain",
            objectPosition: "center",
          }}
          src={`data:image/png;base64,${preview.file}`}
        />
      </Modal>
    </>
  );
}

export default SingleAddedGoods;
