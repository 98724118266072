import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/container";
import { useGetMe } from "../../zustand/store";
import RolesPage from "../rolesPage";

function DashboardPage() {
  const me = useGetMe();
  const navigate = useNavigate();

  const owner = me?.data?.user?.inStore?.owner;

  // useEffect(() => {
  //   if (me.data && !owner) {
  //     navigate("/roles");
  //   }
  // }, [owner]);

  return (
    <PageContainer title={"Statistika"}>
      <RolesPage />
    </PageContainer>
  );
}

export default DashboardPage;
