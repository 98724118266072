import React, { useEffect, useState } from "react";
import { useGetActiveDrivers, useGetDrivers } from "../../../zustand/store";
import { List, Modal } from "antd";

function AssignDriver({ open, onCancel, onOk, selectedDriver: sd }) {
  const drivers = useGetDrivers();
  // const drivers = useGetActiveDrivers();
  const [selectedDriver, setSelectedDriver] = useState(null);
  useEffect(() => {
    drivers.execute({ force: true });
    if (sd) {
      setSelectedDriver({ id: sd });
    } else {
      setSelectedDriver(null);
    }
  }, [open]);
  return (
    <Modal
      title=" "
      open={open}
      onCancel={onCancel}
      okButtonProps={{ disabled: selectedDriver === null, size: "large" }}
      onOk={() => {
        onOk(selectedDriver);
      }}
    >
      <List
        style={{ overflow: "hidden" }}
        bordered
        //   size="small"
      >
        {drivers?.data?.map((data, i) => {
          const { fullname } = data;
          return (
            <List.Item
              key={i}
              onClick={() => {
                if (selectedDriver?.id === data?.id) {
                  setSelectedDriver(null);
                } else {
                  setSelectedDriver(data);
                }
              }}
              style={{
                backgroundColor:
                  data?.id === selectedDriver?.id ? "lightblue" : "unset",
              }}
            >
              {fullname}
            </List.Item>
          );
        })}
      </List>
    </Modal>
  );
}

export default AssignDriver;
