import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  border-top: 1px solid black;
`;

export const CardsWrapper = styled.div`
  width: calc(100% - 350px);
  height: calc((100vh / ${({ zoom }) => zoom || 1} - 70px));
  overflow-y: auto;
  overflow-x: hidden;
  /* border-left: 1px solid black; */
  padding: 10px 15px 10px 0;
  .ant-spin-nested-loading {
    height: 100%;
  }
`;

export const HistoryWrapper = styled.div`
  width: 350px;
  height: calc((100vh / ${({ zoom }) => zoom || 1} - 70px));
  overflow-y: auto;
  /* overflow-y: hidden; */
  overflow-x: hidden;
  border-left: 1px solid black;
  padding: 10px 0 0px 15px;
  /* position: relative; */
  .ant-spin-nested-loading {
    height: 100%;
  }
`;

export const CardsRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  height: calc((100vh / ${({ zoom }) => zoom || 1} - 80px));
  overflow-y: auto;
  align-content: start;
`;

export const CardsCol = styled.div`
  /* width: calc((100% - 30px) / 4); */
  width: 300px;
  margin: 0 15px 15px 0;
`;

export const SingleOrderWrapper = styled.div`
  border: 1px solid black;
`;

const oTypeColor = {
  DELIVERY: {
    color: "#0958d9",
    border: "#91caff",
    bg: "#e6f4ff",
  },
  DINE_IN: {
    color: "#389e0d",
    border: "#b7eb8f",
    bg: "#f6ffed",
  },
  TO_GO: {
    color: "white",
    border: "black",
    bg: "black",
  },
};

const typeColor = {
  danger: {
    color: "#cf1322",
    border: "#ffa39e",
    bg: "#fff1f0",
  },
  success: {
    color: "#389e0d",
    border: "#b7eb8f",
    bg: "#f6ffed",
  },
};
export const CustomTag = styled.div`
  color: ${({ order_type, type }) =>
    oTypeColor[order_type]?.color || typeColor[type]?.color || "#d4b106"};
  border: 1px solid
    ${({ order_type, type }) =>
      oTypeColor[order_type]?.border || typeColor[type]?.border || "#fffb8f"};
  background-color: ${({ order_type, type }) =>
    oTypeColor[order_type]?.bg || typeColor[type]?.bg || "#feffe6"};
  padding: 1px 5px;
  border-radius: 5px;
  width: fit-content;
  margin-right: 10px;
`;
