import {
  DollarOutlined,
  LoadingOutlined,
  MenuOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Col,
  Dropdown,
  List,
  Row,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate, useParams } from "react-router-dom";
import { Receipt } from "../../components/receipt";
import {
  useAcceptOrder,
  useAddOrderPrintRequest,
  useGetAddresses,
  useGetDeliverySingleOrder,
  useGetMe,
  useGetOrderTemplate,
} from "../../zustand/store";
import { addCoords } from "../driversPage/activeDriver";
import { ListItem } from "../makeOrderPage";
import { handlePrint } from "../ordersListPage";
import CancelModal from "./cancelModal";
import PaymentModal from "./paymentModal";
import { IconBox, ListItemWrapper, SquareIcon, Wrapper } from "./styles";

function SingleOrderOfDriver() {
  const [cancelPopUp, setCancelPopUp] = useState(false);
  const navigate = useNavigate();
  const { orderId } = useParams();
  const [payment, setPayment] = useState(false);
  const getSingleOrder = useGetDeliverySingleOrder();
  const menu = useGetOrderTemplate();
  const me = useGetMe();
  const addresses = useGetAddresses();
  const acceptOrder = useAcceptOrder();
  const order = getSingleOrder?.data;

  const totals = order?.totals || {};
  const { subTotal, tax, deliveryFee, total, serviceFee } = totals;
  const userData = order?.userData;
  const addressData = userData?.addressData;
  const addPrintRequest = useAddOrderPrintRequest();

  useEffect(() => {
    getSingleOrder.execute({ params: { orderId }, force: true });
    menu.execute();
    addresses.execute();
  }, []);
  return (
    <Wrapper>
      <div>
        <Button onClick={() => navigate("/drivers-page")}>GoBack</Button>
      </div>
      <br />
      {getSingleOrder?.loading ? (
        <>Loading...</>
      ) : !getSingleOrder?.loading && !order?.driver ? (
        <>Order not found</>
      ) : order?.isCanceled ? (
        <>
          Order is Canceled
          <br />
          {order?.cancellationReason}
        </>
      ) : order?.driver === me?.data?.user?.inStore?.id ? (
        <>
          <Card size="small">
            <List
              key={"unique"}
              size="small"
              header={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ width: "calc(100% - 18px)" }}>
                    <div>
                      {addCoords(userData?.address, addressData?.coords)}{" "}
                      <b>
                        <u>
                          {userData?.block || ""} {userData?.roomNumber}
                        </u>
                      </b>
                    </div>
                    {userData?.desc ? (
                      <div>
                        <b>{userData?.desc}</b>
                      </div>
                    ) : (
                      <></>
                    )}
                    {userData?.call ? (
                      <div>
                        <b>CALL</b>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div
                    style={{
                      width: "18px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <div onClick={() => setCancelPopUp(true)}>
                                Order Canceled
                              </div>
                            ),
                          },
                        ],
                      }}
                      placement="bottomRight"
                    >
                      <MenuOutlined />
                    </Dropdown>
                  </div>
                </div>
              }
              footer={
                <div>
                  SubTotal: ${subTotal || 0} <br />
                  Delivery and Service Fee: $
                  {deliveryFee || 0 + serviceFee || 0} <br />
                  Tax: ${tax || 0} <br />
                  <Typography.Text strong>Total: ${total || 0}</Typography.Text>
                </div>
              }
            >
              {order?.selectedFood?.map((data, i) => (
                <ListItem
                  key={i}
                  inner
                  readMode
                  data={{
                    ...data,
                    // name:
                    //   data?.name
                    //  ||menu?.data?.find(({ id: mId }) => mId === data?.id)?.name,
                  }}
                />
              ))}
            </List>
          </Card>
          {!order?.payments?.length ? (
            <Row gutter={[10, 10]}>
              <Col span={24} />
              {/* <Col span={12}>
                <Button
                  disabled={addPrintRequest.loading}
                  block
                  onClick={() =>
                    order && handlePrint(addPrintRequest, order.id)
                  }
                >
                  {addPrintRequest.loading ? (
                    <LoadingOutlined />
                  ) : (
                    <PrinterOutlined />
                  )}
                </Button>
              </Col> */}
              <Col span={24}>
                {order?.accepted_by_driver ? (
                  <Button block onClick={() => setPayment(true)}>
                    Payment
                  </Button>
                ) : (
                  <Button
                    block
                    onClick={() =>
                      acceptOrder.execute({
                        data: { orderId },
                        onSuccess: () => {
                          getSingleOrder.execute({
                            params: { orderId },
                            force: true,
                          });
                        },
                      })
                    }
                  >
                    Accept an order
                  </Button>
                )}
              </Col>
            </Row>
          ) : (
            <>
              <br />
              <Alert showIcon message="Successfully finished" type="success" />
              <br />
              <List size="small" header={"Payments"} bordered>
                {order?.payments?.map(({ squareData, amount }, i) => (
                  <List.Item key={i}>
                    <ListItemWrapper>
                      <IconBox>
                        {squareData ? (
                          <SquareIcon>
                            <div />
                          </SquareIcon>
                        ) : (
                          <DollarOutlined />
                        )}
                      </IconBox>
                      ${amount}
                    </ListItemWrapper>
                  </List.Item>
                ))}
              </List>
            </>
          )}
        </>
      ) : (
        <>This is not your order</>
      )}
      <PaymentModal
        data={{
          subTotal: subTotal || 0,
          tax: tax || 0,
          deliveryFee: deliveryFee || 0,
          total: total || 0,
          serviceFee: serviceFee || 0,
        }}
        open={payment}
        onCancel={() => setPayment(false)}
        onSuccess={() => {
          setPayment(false);
          message.success("Payment successfully added");
          navigate("/drivers-page");
        }}
      />
      <CancelModal
        open={cancelPopUp}
        onCancel={() => setCancelPopUp(false)}
        closePage={() => navigate("/drivers-page")}
      />
    </Wrapper>
  );
}

export default SingleOrderOfDriver;
