import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;

export const HeaderPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const LeftSideHeader = styled.div`
  width: fit-content;
`;

export const BtnsWrapper = styled.div`
  /* width: 100%; */
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: end;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`;

export const SingleItemWrapper = styled.div`
  width: 250px;
`;

export const SubCategoryWrapper = styled.div`
  width: ${({ display_type }) =>
    display_type === "GRID"
      ? "100%"
      : display_type === "COLLECTED"
      ? "250px"
      : "100%"};
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`;
