import React from "react";
import { useLocation } from "react-router-dom";
import { p } from "../../constants/permissions";
import { LangText } from "../langManagement";
import { PrivateComp } from "../roleBasedAccesses";
import { NavItem, NavListWrapper, Wrapper } from "./styles";
import { useSidebar } from "../../zustand/store";
import {
  ADVERTISER,
  DRIVER,
  ORDER_TAKER,
  PIZZA_MAKER,
} from "../../constants/roles";
import { CarOutlined, CreditCardOutlined, DashboardOutlined, DollarOutlined, FileImageOutlined, GlobalOutlined, LineChartOutlined, PartitionOutlined, PlusSquareOutlined, PrinterOutlined, ProductOutlined, RestOutlined, SettingOutlined, TeamOutlined, UnorderedListOutlined, UsergroupAddOutlined } from "@ant-design/icons";

function Sidebar() {
  const navList = [
    {
      icon: <DashboardOutlined />,
      name: <LangText id="Main" />,
      path: "/",
    },
    {
      icon: <><LineChartOutlined /></>,
      name: <LangText id="orders" />,
      path: "/orders",
      // p: p.GET_ORDERS,
    },
    {
      icon: <><PlusSquareOutlined /></>,
      name: <LangText id="addOrder" />,
      path: "/add-order",
      p: ORDER_TAKER,
    },
    {
      icon: <><UnorderedListOutlined /></>,
      name: <LangText id="menu" />,
      path: "/menu",
      // p: p.GET_ORDERS,
    },
    {
      icon: <><ProductOutlined /></>,
      name: <LangText id="warehouse" />,
      path: "/warehouse",
      // or: [p.GET_MATERIALS, p.GET_GOODS],
    },
    {
      icon: <><TeamOutlined /></>,
      name: <LangText id="staffList" />,
      path: "/users",
      // p: p.GET_STAFF_LIST,
    },
    {
      icon: <><DollarOutlined /></>,
      name: <LangText id="finance" />,
      path: "/finance",
      // and: [p.GET_PAYMENTS, p.GET_ACCOUNTS],
    },
    {
      icon: <><GlobalOutlined /></>,
      name: <LangText id="address" />,
      path: "/address",
      // and: [p.GET_PAYMENTS, p.GET_ACCOUNTS],
    },
    {
      icon: <><CreditCardOutlined /></>,
      name: <LangText id="cashOut" />,
      path: "/cash-out",
      // and: [p.GET_PAYMENTS, p.GET_ACCOUNTS],
    },
    {
      icon: <><PartitionOutlined /></>,
      name: <LangText id="Restaurants" />,
      path: "/restaurants",
      // and: [p.GET_PAYMENTS, p.GET_ACCOUNTS],
      project_owner: true,
    },
    {
      icon: <><RestOutlined /></>,
      name: <LangText id="Pizzaiolo" />,
      path: "/pizzaiolo",
      p: PIZZA_MAKER,
    },
    {
      icon: <><RestOutlined /></>,
      name: <LangText id="Pizzaiolo 72" />,
      path: "/pizzaiolo72",
      p: PIZZA_MAKER,
    },
    {
      icon: <><FileImageOutlined /></>,
      name: <LangText id="Advertiser" />,
      path: "/advertisers-page",
      p: ADVERTISER,
    },
    {
      icon: <><CarOutlined /></>,
      name: <LangText id="Driver" />,
      path: "/drivers-page",
      p: DRIVER,
    },
    {
      icon: <><PrinterOutlined /></>,
      name: <LangText id="Printer" />,
      path: "/printer",
    },
    // {
    //   name: <LangText id="Telephone" />,
    //   path: "/telephony",
    // },
    {
      icon: <><SettingOutlined /></>,
      name: <LangText id="Setting" />,
      path: "/setting",
    },
  ];

  const sidebar = useSidebar();
  const location = useLocation();

  return (
    <Wrapper>
      <NavListWrapper>
        {navList.map(({ name, icon, path, p, and, or, project_owner }, i) => (
          <PrivateComp
            key={i}
            p={p}
            and={and}
            or={or}
            project_owner={project_owner}
          >
            <NavItem
              key={i}
              to={path}
              active={
                path === "/"
                  ? location.pathname === "/" ? "active" : ""
                  // ? "true"
                  // : undefined
                  : location.pathname.startsWith(path) ? "active" : ""
                // ? "true"
                // : undefined
              }
              onClick={() => sidebar.close()}
            >
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <div style={{ width: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  {icon}
                </div>
                <div>
                  {name}
                </div>
              </div>
            </NavItem>
          </PrivateComp>
        ))}
      </NavListWrapper>
    </Wrapper>
  );
}

export default Sidebar;
