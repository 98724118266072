import React from "react";
import { Wrapper } from "./styles";
import { Button, message } from "antd";
import { useGetMe, useStartDelivery } from "../../../zustand/store";

function StartDelivery() {
  const startDelivery = useStartDelivery();
  const me = useGetMe();
  return (
    <Wrapper>
      <Button
        type="primary"
        onClick={() => {
          if (me.data?.user?.inStore?.startedAdvertisingAt) {
            message.info("Please first stop Advertising");
          } else {
            startDelivery.execute();
          }
        }}
      >
        StartDelivery
      </Button>
    </Wrapper>
  );
}

export default StartDelivery;
