import {
  Badge,
  Button,
  Card,
  Col,
  Popconfirm,
  Row,
  Tag,
  Typography,
  ColorPicker,
  theme,
  Divider,
  Switch,
  Radio,
} from "antd";
import React from "react";
import { thousandSeparator } from "../../../utils/numberManager";
import size from "../../../constants/size";
import {
  generate,
  // green,
  // genPresets,
  presetPalettes,
  red,
  // red,
  volcano,
  orange,
  gold,
  yellow,
  lime,
  green,
  cyan,
  blue,
  geekblue,
  purple,
  magenta,
  grey,
  gray,
} from "@ant-design/colors";
import {
  AppstoreOutlined,
  BorderOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  LeftOutlined,
  PushpinOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  useDeleteOrderTemplate,
  useEditMenuCategory,
  useEditMenuSubCategory,
  useEditOrderTemplateSort,
  useGetOrderTemplate,
} from "../../../zustand/store";
import { useState } from "react";
import CreateNew from "./createNew";
import Extra from "./extra";
import {
  BtnsWrapper,
  HeaderPart,
  ItemsWrapper,
  LeftSideHeader,
  SingleItemWrapper,
  SubCategoryWrapper,
  Wrapper,
} from "./styles";
import SubCategory from "./subCategory";

const list = [
  {
    "name": "Tuscan Countryside",
    "colors": ["#5C832F", "#CB6843", "#F2D649", "#A3A948", "#EAE0D5", "#D4B483", "#8B4513", "#C2B280", "#87CEEB"]
  },
  {
    "name": "Venetian Sunset",
    "colors": ["#56A3A6", "#FFD700", "#C04000", "#D4A5A5", "#8A9A5B", "#FFFACD", "#800000", "#FFE5B4", "#4682B4"]
  },
  {
    "name": "Mediterranean Coast",
    "colors": ["#2A93D5", "#F4E1A4", "#FF7F50", "#1A2C5B", "#EAD3BF", "#40E0D0", "#FFFFF0", "#FFDAB9", "#0047AB"]
  },
  {
    "name": "Roman Architecture",
    "colors": ["#F0EFEA", "#9D2A2B", "#4E3B31", "#B5B5B5", "#708238", "#676767", "#5F8575", "#8B0000", "#F5F5DC"]
  },
  {
    "name": "Sicilian Market",
    "colors": ["#F7E81E", "#E94F37", "#7D9A69", "#4B2843", "#F1F1F1", "#FF6347", "#808000", "#FF4500", "#E2725B"]
  },
  {
    "name": "Florentine Elegance",
    "colors": ["#E97451", "#D4AF37", "#4A5A31", "#F1E3C6", "#2C3E50", "#4169E1", "#50C878", "#F7E7CE", "#C04000"]
  },
  {
    "name": "Italian Riviera",
    "colors": ["#7FDBFF", "#FF851B", "#0074D9", "#2ECC40", "#FFDC00", "#F88379", "#FFF44F", "#002366", "#FBCEB1"]
  },
  {
    "name": "Piedmont Harvest",
    "colors": ["#8A2B0D", "#F5DEB3", "#9C9C76", "#E6E6FA", "#191970", "#DAA520", "#228B22", "#DDA0DD", "#954535"]
  },
  {
    "name": "Amalfi Coast",
    "colors": ["#007FFF", "#FFE135", "#FF6F61", "#9FE2BF", "#E2725B", "#005B5C", "#FFD700", "#FF4040", "#6B8E23"]
  },

  {
    "name": "Classic Italian Flag",
    "colors": ["#009246", "#FFFFFF", "#CE2B37", "#00632A", "#F0F0F0", "#B22222", "#50C878", "#FFFFF0", "#B03060"]
  },
  {
    "name": "Modern Italian Flag",
    "colors": ["#009246", "#FFFFFF", "#CE2B37", "#228B22", "#D3D3D3", "#DC143C", "#98FF98", "#F8F8FF", "#800000"]
  },
  {
    "name": "Vintage Italian Flag",
    "colors": ["#009246", "#FFFFFF", "#CE2B37", "#556B2F", "#FAEBD7", "#8B0000", "#B2AC88", "#FFFDD0", "#722F37"]
  },
  {
    "name": "Italian Riviera",
    "colors": ["#2E8B57", "#FFFFFF", "#FF4040", "#40E0D0", "#FFFFF0", "#E97451", "#98FB98", "#FFEBCD", "#DE3163"]
  },
  {
    "name": "Tuscan Fields",
    "colors": ["#01796F", "#FFFFFF", "#D2691E", "#6B8E23", "#FFF5EE", "#B22222", "#8A9A5B", "#F7E7CE", "#8B0000"]
  },
  {
    "name": "Venetian Elegance",
    "colors": ["#50C878", "#FFFFFF", "#DC143C", "#228B22", "#F8F8FF", "#C41E3A", "#00A86B", "#F5F5DC", "#990000"]
  },
  {
    "name": "Mediterranean Coast",
    "colors": ["#008080", "#FFFFFF", "#B03060", "#9FE2BF", "#FFFAF0", "#D40000", "#98FB98", "#FAF0E6", "#FF2400"]
  },
  {
    "name": "Sicilian Sunrise",
    "colors": ["#32CD32", "#FFFFFF", "#FA8072", "#00FF7F", "#FFFAFA", "#FF6347", "#E0F8D0", "#FFE4E1", "#E9967A"]
  },
  {
    "name": "Roman Holiday",
    "colors": ["#355E3B", "#FFFFFF", "#CE2029", "#4F7942", "#FFFFF0", "#B31B1B", "#F5FFFA", "#F08080", "#850000"]
  }

]

function ColorPalettes() {
  return (
    <div style={{ width: "100%" }}>
      <Row gutter={[10, 10]}>
        {list.map(({ name, colors }) => <Col span={4}>
          <Card title={name}>
            {colors?.map((color) => (
              <div style={{
                backgroundColor: color,
                height: "40px",
                width: "100%",
              }}>{color}</div>
            ))}
          </Card>
        </Col>
        )}
      </Row>
    </div>
  )
}

function SingleItem({
  data,
  index,
  setEdit,
  move,
  array,
  // items,
  setSubCategory,
}) {
  const deleteMenu = useDeleteOrderTemplate();

  const editSubCategory = useEditMenuSubCategory();

  const {
    category,
    createdAt,
    createdBy,
    id,
    ingredients,
    name,
    price,
    size: s,
    updatedAt,
    updatedBy,
    display_type,
    subCategoryId,
    sub_items
  } = data;
  if (display_type) {
    // items = items.filter(({ subCategoryId }) => subCategoryId === id);
    return (
      <SubCategoryWrapper display_type={display_type}>
        <Card
          // style={{ width: "100%" }}
          title={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{name}</div>
              <div>
                <Radio.Group
                  loading={editSubCategory.loading}
                  value={display_type}
                  onChange={(e) => {
                    editSubCategory.execute({
                      params: { id: data?.id },
                      data: { display_type: e.target.value },
                    });
                  }}
                >
                  <Radio value={"GRID"}>
                    <AppstoreOutlined /> Grid
                  </Radio>

                  <Radio value={"COLLECTED"}>
                    <BorderOutlined /> Collected
                  </Radio>
                </Radio.Group>
              </div>
            </div>
          }
          extra={
            <Row gutter={[10, 10]}>
              <Col>
                <Button size="small" disabled={!(index > 0)}>
                  {/* <LeftOutlined disabled={index > 1} onClick={() => move({ id, index, move: -1 })} /> */}
                  <UpOutlined disabled={index > 1} onClick={() => move({ id, index, move: -1 })} />
                </Button>
              </Col>
              <Col>
                <Button size="small" disabled={!(array?.length - 1 > index)}>
                  {/* <RightOutlined onClick={() => move({ id, index, move: +1 })} /> */}
                  <DownOutlined onClick={() => move({ id, index, move: +1 })} />
                </Button>
              </Col>
              <Col>
                <Button size="small" type="primary" ghost>
                  <EditOutlined
                    onClick={() => {
                      setSubCategory({
                        open: true,
                        edit: true,
                        data: { ...data, selectedItems: sub_items.map(({ id }) => id) },
                      });
                    }}
                  />
                </Button>
              </Col>
              <Col>
                <Button size="small" danger>
                  <Popconfirm
                    title="Delete"
                    description="Do you really want to delete this?"
                    okText="Yes"
                    onConfirm={() => {
                      deleteMenu.execute({
                        data: { orderId: id },
                      });
                    }}
                  >
                    <DeleteOutlined />
                  </Popconfirm>
                </Button>
              </Col>
            </Row>
          }
          size="small"
        >
          <SubCategoryWrapper>
            {sub_items?.map((v, i, a) => (
              <SingleItem
                data={v}
                index={i}
                setEdit={setEdit}
                move={move}
                array={a}
              />
            ))}
          </SubCategoryWrapper>
        </Card>
      </SubCategoryWrapper>
    );
  } else {
    return (
      <SingleItemWrapper key={id}>
        <Badge.Ribbon
          text={size[s]||s}
          style={{ visibility: s ? "visible" : "hidden" }}
        >
          <Card
            title={name}
            size="small"
            actions={[
              index > 0 ? (
                <LeftOutlined
                  disabled={index > 1}
                  onClick={() => move({ id, index, move: -1 })}
                />
              ) : undefined,
              <EditOutlined
                onClick={() =>
                  setEdit({
                    edit: true,
                    data,
                  })
                }
              />,
              <Popconfirm
                title="Delete"
                description="Do you really want to delete this?"
                okText="Yes"
                onConfirm={() => {
                  deleteMenu.execute({
                    data: { orderId: id },
                  });
                }}
              >
                <DeleteOutlined />
              </Popconfirm>,
              array?.length - 1 > index ? (
                <RightOutlined onClick={() => move({ id, index, move: +1 })} />
              ) : undefined,
            ]}
          >
            <Typography.Text strong mark>
              ${thousandSeparator(price)}
            </Typography.Text>
            <br />
            {ingredients?.length ? (
              <Row style={{ marginTop: "10px" }} gutter={[0, 5]}>
                {ingredients?.sort().map((ingredient, i) => (
                  <Col key={i}>
                    <Tag size="small">{ingredient}</Tag>
                  </Col>
                ))}
              </Row>
            ) : (
              ""
            )}
          </Card>
        </Badge.Ribbon>
      </SingleItemWrapper>
    );
  }
}

const genPresets = (presets = presetPalettes) =>
  Object.entries(presets).map(([label, colors]) => ({
    label,
    colors,
  }));

function MenuItems({ category, menu }) {
  const [newOrder, setNewOrder] = useState(false);
  const [extra, setExtra] = useState(false);
  const [edit, setEdit] = useState({ edit: false, data: null });
  const [subCategory, setSubCategory] = useState({ open: false, edit: false });
  const editMenuCategory = useEditMenuCategory();
  const getMenu = useGetOrderTemplate();

  const editSort = useEditOrderTemplateSort();
  const menuList = menu?.categories?.reduce((ct, c) => ct.concat(c?.items), []);
  const move = ({ id, index, move }) => {
    const items = category.items;
    let s = [...items.slice(0, index), ...items.slice(index + 1)];
    s.splice(index + move, 0, { id });
    s = s.map(({ id }, i) => ({ id, sort: i + 1 }));
    editSort.execute({ data: { data: s } });
  };

  // const move = ({ id, name, index, move }) => {
  //   const s = [
  //     ...sortCategory.slice(0, index),
  //     ...sortCategory.slice(index + 1),
  //   ];
  //   s.splice(index + move, 0, { id, name });
  //   setSortCategory(s.map(({ id, name }, i) => ({ id, name, sort: i + 1 })));
  // };
  const items = category?.items;

  const presets = genPresets({
    red,
    volcano,
    orange,
    gold,
    yellow,
    lime,
    green,
    cyan,
    blue,
    geekblue,
    purple,
    magenta,
    grey,
  });

  const customPanelRender = (_, { components: { Picker, Presets } }) => (
    <Row justify="space-between" wrap={false}>
      <Col span={13}>
        <Presets />
      </Col>
      <Divider
        type="vertical"
        style={{
          height: "auto",
        }}
      />
      <Col flex="auto">
        <Picker />
      </Col>
    </Row>
  );

  return (
    <div>
      <Wrapper key={category?.id} gutter={[10, 10]} slot="15">
        {/* <ColorPalettes /> */}
        <HeaderPart>
          <LeftSideHeader>
            <Card size="small">
              <Row gutter={[10, 10]}>
                <Col>
                  <Switch
                    checked={category?.pinned}
                    loading={editMenuCategory.loading || getMenu.loading}
                    onChange={(pinned) => {
                      editMenuCategory.execute({
                        params: { id: category?.id },
                        data: { pinned },
                      });
                    }}
                  />
                </Col>
                <Col>
                  <PushpinOutlined />
                </Col>
              </Row>
            </Card>
          </LeftSideHeader>
          <BtnsWrapper>
            <ColorPicker
              styles={{
                popupOverlayInner: {
                  width: 480,
                },
              }}
              panelRender={customPanelRender}
              value={category.color}
              onChange={(e) => {
                const color = e.toHexString();
                editMenuCategory.execute({
                  data: { color },
                  params: { id: category?.id },
                });
              }}
              showText
              presets={presets}
            />

            <Button
              // block
              ghost
              type="primary"
              onClick={() => {
                setSubCategory({ open: true });
              }}
            >
              subCategory
            </Button>
            <Button
              // block
              type="primary"
              onClick={() => {
                setNewOrder(true);
              }}
            >
              addNew
            </Button>
            <Button
              // block
              // type="primary"
              onClick={() => {
                setExtra(true);
              }}
            >
              Extra
            </Button>
          </BtnsWrapper>
        </HeaderPart>
        <ItemsWrapper>
          {items
            ?.filter(({ subCategoryId }) => !subCategoryId)
            ?.map((data, index, a) => {
              return (
                <SingleItem
                  data={data}
                  index={index}
                  setEdit={setEdit}
                  move={move}
                  array={a}
                  items={items}
                  setSubCategory={setSubCategory}
                />
              );
            })}
        </ItemsWrapper>
      </Wrapper>
      <CreateNew
        menuList={menuList}
        extraList={category?.extra}
        menu={menu}
        data={category}
        edit={edit}
        setEdit={setEdit}
        onClose={() => {
          setNewOrder(false);
          setEdit({ edit: false, data: null });
        }}
        open={newOrder || edit?.edit}
      />
      <Extra
        extraList={category?.extra}
        open={extra}
        onClose={() => setExtra(false)}
        menuCategoryId={category?.id}
      />
      <SubCategory
        items={
          items?.filter(({ display_type }) => !display_type)
        }
        {...subCategory}
        onCancel={() => setSubCategory({ open: false })}
        menuCategoryId={category?.id}
      />
    </div>
  );
}

export default MenuItems;
