import styled from "styled-components";

export const NavbarWrapper = styled.div`
  background-color: white;
  border-bottom: 1px solid black;
  padding: 0px 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 20px 0;
`;

export const Body = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: center;
  max-width: 1000px;
  margin: auto;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 470px;
  padding: 50px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* margin: 20px; */
`;
export const FooterWrapper = styled.div`
  background-color: white;
  /* border-bottom: 1px solid black; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 20px 20px;
  /* width: 100%; */
  /* max-width: 450px; */
  /* padding: 50px; */
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
