import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Space,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import PNInput from "react-phone-number-input/input";
import { LangText, text } from "../../../components/langManagement";
import btns from "../../../constants/numbersBtns";
import WebSocket from "../../../utils/ws";
import {
  useDeleteDial,
  useDial,
  useGetAddresses,
  useGetAreaAndAddresses,
  useGetDial,
} from "../../../zustand/store";
import {
  AddressInputBox,
  AddressPadWrapper,
  JustBlurBackground,
  ModalWrapper,
  PhoneNumberPadWrapper,
  RoomNumberPadWrapper,
} from "./styles";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { CloseOutlined } from "@ant-design/icons";

function UserDataModal({ selectMenuById, open, onCancel, data, onOk }) {
  const [form] = Form.useForm();
  const [kbd, setKbd] = useState(null);
  const [pnValue, setPnValue] = useState("");
  const address = useGetAddresses();
  const areaAddress = useGetAreaAndAddresses();
  const addressList = areaAddress.data?.reduce(
    (t, v) => (v.address ? t.concat(v.address) : t),
    []
  );
  const dial = useDial();
  const getDial = useGetDial();
  const deleteDial = useDeleteDial();

  const blocks = addressList?.find(
    ({ name }) => name === form.getFieldValue("address")
  )?.blocks;

  useEffect(() => {
    if (kbd === "address") {
      document
        .querySelector("#address_list_wrapper")
        .scrollIntoView({ behavior: "smooth" });
    }
  }, [kbd]);

  useEffect(() => {
    if (open && Object.keys(data)?.length) {
      form.setFieldsValue(data);
      setPnValue(data?.phoneNumber || "");
    } else if (open && !Object.keys(data)?.length) {
      form.resetFields();
    }
    if (open) {
      setKbd("pn");
      getDial.execute({
        // onSuccess: (res) => {
        //   dial.setNumbers(res.data?.map(({ phoneNumber }) => phoneNumber));
        // },
        force: true,
      });
    }
    // address.execute();
    areaAddress.execute();
  }, [open]);

  return (
    <ModalWrapper
      title={LangText({ id: "userData" })}
      open={open}
      onCancel={onCancel}
      // data={data}
      footer={null}
      centered
      destroyOnClose
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={(e) => {
          if (e.address) {
            // const addressList = areaAddress.data?.reduce(
            //   (t, v) => (v.address ? t.concat(v.address) : t),
            //   []
            // );
            const addressData = addressList.find(
              ({ name }) => name === e.address
            );
            e.addressData = addressData;
          }
          onOk(e);
        }}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        {kbd === "pn" || kbd === "rn" ? (
          <JustBlurBackground onClick={() => setKbd("")} />
        ) : (
          <></>
        )}

        <PhoneNumberPadWrapper active={kbd === "pn"}>
          <Form.Item
            label={LangText({ id: "phoneNumber" })}
            name="phoneNumber"
            rules={[{ required: true, message: "Please input phoneNumber!" }]}
          >
            <PNInput
              autoComplete={false}
              onClick={() => setKbd("pn")}
              // international
              withCountryCallingCode
              country="US"
              className="ant-input css-dev-only-do-not-override-1e3x2xa"
              // inputComponent={Input}
              defaultValue="+1"
              placeholder={text({ id: "enterPhoneNumber" })}
              style={{ width: "100%" }}
            />
          </Form.Item>
          {kbd === "pn" ? (
            <Row gutter={[10, 10]} style={{ marginBottom: "15px" }}>
              {/* <Col span={24}>
                  <PhoneNumberInput
                    autoComplete={false}
                    withCountryCallingCode
                    country="US"
                    className="ant-input css-dev-only-do-not-override-1e3x2xa"
                    defaultValue="+1"
                    placeholder={text({ id: "enterPhoneNumber" })}
                    size="large"
                    onChange={(e) => {
                      setPnValue(e);
                      form.setFieldValue("phoneNumber", e);
                    }}
                    value={pnValue}
                  />
                </Col> */}
              {btns.map(({ label, value }, i) => (
                <Col span={8} key={i}>
                  <Button
                    style={{
                      width: "100%",
                      height: "auto",
                      fontSize: "30px",
                    }}
                    size="large"
                    onClick={() => {
                      const phoneInput =
                        form.getFieldValue("phoneNumber") || "";
                      if (Number(value) || value === "0") {
                        const phoneNumber =
                          String(phoneInput?.startsWith("+1") ? "" : "+1") +
                          phoneInput +
                          value;

                        form.setFieldValue("phoneNumber", phoneNumber);
                        setPnValue(phoneNumber);
                      } else if (value === "backspace") {
                        form.setFieldValue(
                          "phoneNumber",
                          phoneInput.slice(0, -1)
                        );
                        setPnValue(phoneInput.slice(0, -1));
                      } else if (value === ".") {
                        form.setFieldValue("phoneNumber", "");
                        setPnValue("");
                      }
                    }}
                  >
                    {value === "." ? "C" : label}
                  </Button>
                  {/* ) : (
                <></>
              )} */}
                </Col>
              ))}

              {dial?.phoneNumbers?.map(({ phoneNumber, menuId, color }) => (
                <Col span={12}>
                  <Space.Compact>
                    <Button
                      size="large"
                      style={{
                        width: "100%",
                        height: "auto",
                        fontSize: "20px",
                        backgroundColor: color || undefined,
                      }}
                      onClick={() => {
                        deleteDial.execute({ params: { number: phoneNumber } });
                      }}
                    >
                      <CloseOutlined />
                    </Button>
                    <Button
                      onClick={() => {
                        form.setFieldValue("phoneNumber", phoneNumber);
                        setKbd("address");
                        if (menuId) {
                          selectMenuById(menuId);
                        }
                      }}
                      style={{
                        width: "100%",
                        height: "auto",
                        fontSize: "20px",
                        backgroundColor: color || undefined,
                      }}
                      size="large"
                    >
                      {
                        formatPhoneNumberIntl(phoneNumber) || phoneNumber
                        //  ||"+1 843 446 03 96"
                      }
                    </Button>
                  </Space.Compact>
                </Col>
              ))}
              {/* <Col span={12}>
                <Space.Compact block>
                  <Button
                    size="large"
                    style={{ width: "100%", height: "auto", fontSize: "20px" }}
                    onClick={() => {
                      deleteDial.execute({
                        params: { number: "+18434460396" },
                      });
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                  <Button
                    onClick={() => {
                      form.setFieldValue("phoneNumber", "+1 843 446 03 96");
                      setKbd("address");
                    }}
                    style={{ width: "100%", height: "auto", fontSize: "20px" }}
                    size="large"
                  >
                    +1 843 446 03 96
                  </Button>
                </Space.Compact>
              </Col> */}

              <Col span={24}>
                <Button
                  type="primary"
                  style={{ width: "100%", height: "auto", fontSize: "30px" }}
                  size="large"
                  onClick={
                    () => setKbd("address")
                    // setKbd(null)
                  }
                >
                  Done
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </PhoneNumberPadWrapper>

        <Form.Item
          label={LangText({ id: "address" })}
          name="address"
          rules={[{ required: true, message: "Please input address!" }]}
        >
          <Input onClick={() => setKbd("address")} />
        </Form.Item>

        {kbd === "address" ? (
          <>
            <JustBlurBackground onClick={() => setKbd("")} />
            <AddressPadWrapper id="address_list_wrapper">
              <Row style={{ marginBottom: "20px" }} gutter={[15, 15]}>
                {/* <Col span={24} /> */}
                {/* <Col span={24} /> */}
                <Col span={24}>
                  <AddressInputBox>
                    <Row gutter={[10, 10]}>
                      <Col span={16}>
                        <Form.Item label="Address" name={"padAddress"}>
                          <Input placeholder="Address" />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label=" "
                          onClick={() => {
                            const address = form.getFieldValue("padAddress");
                            form.setFieldValue("address", address);
                            setKbd("rn");
                          }}
                        >
                          <Button type="primary" size="large">
                            Save
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </AddressInputBox>
                </Col>
                {areaAddress.data
                  // ?.map(({ visible }) =>visible)
                  ?.map(({ name, address, color, visible }) =>
                    address && visible ? (
                      <Col span={24}>
                        <div
                          style={{
                            backgroundColor: color || "lightgrey",
                            padding: "0 10px 10px",
                            borderRadius: "8px",
                            border: `1px solid ${color || "black"}`,
                          }}
                        >
                          <div>
                            <Typography.Text strong style={{ fontSize: 20 }}>
                              {name}
                            </Typography.Text>
                          </div>
                          <Row gutter={[8, 8]}>
                            {address
                              ?.sort((a, b) => a.name.localeCompare(b.name))
                              ?.map((data, i) => {
                                const { name, deliveryFee } = data;
                                return (
                                  <Col key={i}>
                                    <Button
                                      color={color}
                                      onClick={() => {
                                        form.setFieldValue("padAddress", name);
                                        form.setFieldValue("address", name);
                                        form.setFieldValue(
                                          "deliveryFee",
                                          deliveryFee
                                        );
                                        setKbd("rn");
                                      }}
                                      size="large"
                                      // style={{ fontSize: "20px" }}
                                    >
                                      {name}
                                    </Button>
                                  </Col>
                                );
                              })}
                          </Row>
                        </div>
                      </Col>
                    ) : (
                      <></>
                    )
                  )}
              </Row>
            </AddressPadWrapper>
          </>
        ) : (
          ""
        )}

        <RoomNumberPadWrapper active={kbd === "rn"}>
          {blocks?.length ? (
            <Form.Item
              label={"block"}
              name={"block"}
              rules={[{ required: true, message: "Block is required" }]}
            >
              <Radio.Group defaultValue="a" buttonStyle="solid">
                {blocks.map((b) => (
                  // <Select.Option value={b}>{b}</Select.Option>
                  <Radio.Button value={b}>{b}</Radio.Button>
                ))}
              </Radio.Group>
              {/* <Select placeholder="Select block">
                  {blocks.map((b) => (
                    <Select.Option value={b}>{b}</Select.Option>
                  ))}
                </Select> */}
            </Form.Item>
          ) : (
            <></>
          )}
          <Form.Item
            label={LangText({ id: "roomNumber" })}
            name="roomNumber"
            rules={[{ required: true, message: "Please input room number!" }]}
          >
            <Input
              onClick={() => setKbd("rn")}
              // style={{ width: "50%" }}
              placeholder="Room Number"
            />
          </Form.Item>
          {kbd === "rn" ? (
            <Row gutter={[10, 10]} style={{ marginBottom: "15px" }}>
              {btns.map(({ label, value }, i) => (
                <Col span={8} key={i}>
                  <Button
                    style={{ width: "100%", height: "auto", fontSize: "30px" }}
                    size="large"
                    onClick={() => {
                      if (Number(value) || value === "0") {
                        form.setFieldValue(
                          "roomNumber",
                          (Boolean(form.getFieldValue("roomNumber"))
                            ? form.getFieldValue("roomNumber")
                            : "") + value
                        );
                      } else if (value === "backspace") {
                        form.setFieldValue(
                          "roomNumber",
                          form.getFieldValue("roomNumber").slice(0, -1)
                        );
                      } else if (value === ".") {
                        form.setFieldValue("roomNumber", "");
                      }
                    }}
                  >
                    {value === "." ? "C" : label}
                  </Button>
                </Col>
              ))}
              <Col span={24}>
                <Button
                  type="primary"
                  style={{ width: "100%", height: "auto", fontSize: "30px" }}
                  size="large"
                  onClick={() => setKbd(null)}
                >
                  Done
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </RoomNumberPadWrapper>

        <Form.Item label={LangText({ id: "deliveryFee" })} name="deliveryFee">
          <InputNumber addonBefore="$" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label={LangText({ id: "description" })}
          name="desc"
          // rules={[{ required: true, message: "Please input address!" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24 }} style={{ marginBottom: "0" }}>
          <Button
            size="large"
            style={{
              width: "100%",
              height: "auto",
              fontSize: "25px",
            }}
            type="primary"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>
      </Form>
      {open ? <WebSocket orderTaker /> : <></>}
    </ModalWrapper>
  );
}

export default UserDataModal;
