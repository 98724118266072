import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGetMe, useJoinRestaurant } from '../../zustand/store';

function JoinPage() {
    const [searchParams] = useSearchParams();
    const me = useGetMe();
    const joinRestaurant = useJoinRestaurant();
    const navigate = useNavigate();

    let userId = (searchParams.get("userId"));
    let rId = (searchParams.get("rId"));

    useEffect(() => {
        const token = localStorage.getItem("hisob_new_token");
        let join_data = localStorage.getItem("join_data");
        join_data = JSON.parse(join_data || "{}") || {};

        userId = (userId || join_data?.userId);
        rId = (rId || join_data?.rId);

        if (token) {
            joinRestaurant.execute({
                data: { userId, rId },
                onSuccess: () => {
                    localStorage.setItem("restaurantId", rId);
                    localStorage.removeItem("join_data");
                    navigate('/')
                    window.location.reload(false);
                }
            })
        } else {
            localStorage.setItem("join_data", JSON.stringify({ rId, userId }))
            navigate("/login");
        }

    }, [])

    useEffect(() => {
        me.execute();
    }, [])

    return (
        <div>
            JoinPage
            <div>
                userId: {userId}
            </div>
            <div>
                restaurantId: {rId}
            </div>
        </div>
    )
}

export default JoinPage