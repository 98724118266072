import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  List,
  Popover,
  QRCode,
  Row,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useState } from "react";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useSearchParams } from "react-router-dom";
import { PageContainer } from "../../../components/container";
import { LangText } from "../../../components/langManagement";
import { PrivateComp } from "../../../components/roleBasedAccesses";
import { botUsername } from "../../../constants/general";
import { p, permissions_with_data } from "../../../constants/permissions";
import { ADVERTISER, DRIVER } from "../../../constants/roles";
import { copy } from "../../../utils/general";
import {
  useChangeStaffActivation,
  useGetMe,
  useGetStaffList,
} from "../../../zustand/store";
import PaidSalaries from "./paidSalaries";
import PaySalary from "./paySalary";
import StaffForm from "./staffForm";
import { BASE_URL } from "../../../constants/link";

const { Text, Link } = Typography;

function StaffList({ setStaffForm, setSalaryForm, setSalaryList, staffList }) {
  // const [staffForm, setStaffForm] = useState({});
  // const [salaryForm, setSalaryForm] = useState({ open: false, staffId: null });
  // const [salaryList, setSalaryList] = useState({ open: false, staffId: null });
  // const [staffListType, setStaffListType] = useState("all");
  const getStaffList = useGetStaffList();
  const me = useGetMe();
  const data = me.data;
  const restaurant = data.restaurant;
  const changeActivation = useChangeStaffActivation();
  const [searchParams] = useSearchParams();
  const staffId = searchParams.get("staff");


  const origin = (window?.location?.origin);

  return (
    <List bordered size="small">
      <Table
        loading={getStaffList.loading}
        size="small"
        pagination={false}
        columns={[
          // {
          //   width: "40px",
          //   title: <LangText id="id" />,
          //   dataIndex: "id",
          //   key: "id",
          // },
          {
            title: <LangText id="fullname" />,
            dataIndex: "fullname",
            key: "fullname",
          },
          {
            title: <LangText id="phoneNumber" />,
            dataIndex: "phone_number",
            key: "phone_number",
            width: "180px",
          },
          {
            title: <LangText id="role" />,
            dataIndex: "role",
            key: "role",
            width: "200px",
          },
          {
            width: "150px",
            title: <LangText id="permissions" />,
            dataIndex: "permissions",
            key: "permissions",
          },
          {
            width: "150px",
            title: <LangText id="description" />,
            dataIndex: "description",
            key: "description",
          },
          {
            width: "100px",
            title: <LangText id="action" />,
            dataIndex: "action",
            key: "action",
          },
        ]}
        dataSource={staffList
          // getStaffList?.data
          // ?.filter(({ tg_chat_id }) =>
          //   staffListType === "managers" ? Boolean(tg_chat_id) : true
          // )
          ?.map((data) => {
            const {
              id,
              fullname,
              phone_number,
              tg_chat_id,
              chatID,
              roles,
              permissions,
              description,
              is_active,
              owner,
              userId
            } = data;
            const role = roles?.roles;
            // const shareLink = `https://t.me/${botUsername}?start=${me?.data?.restaurant?.id
            //   }_${id.slice(0, 27)}`;

            const shareLink = `${origin}/join?userId=${id}&rId=${restaurant.id}`

            return {
              id: <>#{id}</>,
              fullname:
                id === staffId ? (
                  <Tag color="#87d068">{fullname}</Tag>
                ) : (
                  fullname
                ),
              phone_number: (
                <div>
                  <Link href={`tel:${phone_number}`}>
                    {formatPhoneNumberIntl(phone_number)}
                  </Link>
                  {
                    // chatID ? (
                    //   <a
                    //     style={{ display: "block" }}
                    //     href={`tg://user?id=${chatID}`}
                    //   >
                    //     tg:{chatID}
                    //   </a>
                    // ) : 

                    !userId ? (
                      <div>
                        <Row gutter={[10, 10]}>
                          <Col>
                            <Popover
                              content={
                                <div>
                                  <div style={{
                                    marginBottom: "15px",
                                    width: "fit-content",
                                    margin: "auto"
                                  }}>
                                    <QRCode
                                      size={200}
                                      value={shareLink} />
                                  </div>
                                  <div
                                  // onClick={() => copy(shareLink)}
                                  >
                                    <Tooltip title={shareLink}>
                                      <Typography.Text
                                        style={{ maxWidth: "250px" }}
                                        ellipsis
                                        copyable
                                      >
                                        {shareLink}
                                      </Typography.Text>
                                    </Tooltip>
                                  </div>
                                  {/* <Button
                                    style={{
                                      marginLeft: "auto",
                                      display: "block",
                                      marginTop: "10px",
                                    }}
                                    onClick={() => copy(shareLink)}
                                  >
                                    Copy
                                  </Button> */}
                                </div>
                              }
                            >
                              <Button onClick={() => { }}>Share Link</Button>
                            </Popover>
                          </Col>
                          <Col></Col>
                        </Row>
                      </div>
                    ) : (
                      <></>
                    )}
                </div>
              ),
              role: (
                <>
                  {owner ? (
                    <Text code key={"owner"}>
                      Owner
                    </Text>
                  ) : (
                    <></>
                  )}
                  {Array.isArray(role) ? (
                    role.map((r, i) => (
                      <Text key={i} code>
                        {r}
                      </Text>
                    ))
                  ) : role ? (
                    <Text code>{role}</Text>
                  ) : (
                    <></>
                  )}
                </>
              ),
              permissions: permissions?.length ? (
                <Tooltip
                  overlay={
                    <div style={{ maxHeight: "210px", overflow: "auto" }}>
                      {permissions?.map((p, i) => (
                        <div key={i}>{permissions_with_data[p]?.label}</div>
                      ))}
                    </div>
                  }
                >
                  <Text
                    style={{ maxWidth: "150px" }}
                    ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                  >
                    {permissions
                      ?.map((p) =>
                        permissions_with_data[p]?.label?.toLowerCase()
                      )
                      ?.join(", ")}
                  </Text>
                </Tooltip>
              ) : (
                <></>
              ),
              description: (
                <Text
                  style={{ width: "150px" }}
                  ellipsis={{ rows: 2, expandable: true, symbol: "more" }}
                >
                  {description}
                </Text>
              ),
              action: (
                <div style={{ display: "flex", gap: "10px" }}>
                  <PrivateComp p={p.EDIT_STAFF}>
                    <Button
                      type="primary"
                      ghost
                      onClick={() =>
                        setStaffForm({ open: true, edit: true, data })
                      }
                    >
                      <EditOutlined />
                    </Button>

                    <Button
                      disabled={changeActivation.loading}
                      {...(is_active
                        ? { danger: true }
                        : { type: "primary", ghost: true })}
                      onClick={() =>
                        changeActivation.execute({
                          params: { id },
                          data: { is_active: !is_active },
                          onSuccess: () => {
                            getStaffList.execute({ force: true });
                          },
                        })
                      }
                    >
                      {is_active ? <CloseOutlined /> : <CheckOutlined />}
                    </Button>
                  </PrivateComp>

                  {/* <PrivateComp or={[p.PAY_SALARY, p.GET_STAFF_SALARY]}>
                    <Button.Group>
                      <PrivateComp p={p.PAY_SALARY}>
                        <Button
                          onClick={() =>
                            setSalaryForm({ open: true, staffId: id })
                          }
                        >
                          <LangText id="pay" />
                        </Button>
                      </PrivateComp>
                      <PrivateComp p={p.GET_STAFF_SALARY}>
                        <Button
                          onClick={() =>
                            setSalaryList({ open: true, staffId: id })
                          }
                        >
                          <LangText id="list" />
                        </Button>
                      </PrivateComp>
                    </Button.Group>
                  </PrivateComp> */}
                </div>
              ),
            };
          })}
      />
    </List>
  );
}

function StaffSection() {
  const [staffForm, setStaffForm] = useState({});
  const [salaryForm, setSalaryForm] = useState({ open: false, staffId: null });
  const [salaryList, setSalaryList] = useState({ open: false, staffId: null });
  const getStaffList = useGetStaffList();
  const [searchParams] = useSearchParams();
  const staffId = searchParams.get("staff");

  return (
    <PageContainer
      title={
        <div style={{ alignItems: "center", display: "flex", gap: "10px" }}>
          {staffId ? (
            <>
              <Button
                onClick={() => {
                  window.history.back();
                }}
              >
                <ArrowLeftOutlined />
              </Button>{" "}
            </>
          ) : (
            <></>
          )}
          <LangText id="staffList" />
        </div>
      }
      rightPart={
        <PrivateComp p={p.ADD_STAFF}>
          <Button
            onClick={() =>
              setStaffForm({ open: true, edit: false, data: null })
            }
            type="primary"
          >
            <LangText id="plusStaff" />
          </Button>
        </PrivateComp>
      }
    >
      <Card size="small">
        <Tabs
          // onChange={(type) => setStaffListType(type)}
          type="card"
          items={[
            {
              label: LangText({ id: "all" }),
              key: "all",
              children: (
                <StaffList
                  setStaffForm={setStaffForm}
                  setSalaryForm={setSalaryForm}
                  setSalaryList={setSalaryList}
                  staffList={getStaffList?.data || []}
                />
              ),
            },
            ...[
              ...new Set(
                [DRIVER, ADVERTISER]
                  ?.concat(
                    getStaffList?.data?.reduce(
                      (t, { roles }) => t.concat(roles?.roles),
                      []
                    ) || []
                  )
                  .filter((r) => r)
              ),
            ].map((r) => ({
              label: r,
              // LangText({ id: r }),
              key: r,
              children: (
                <StaffList
                  setStaffForm={setStaffForm}
                  setSalaryForm={setSalaryForm}
                  setSalaryList={setSalaryList}
                  staffList={getStaffList?.data?.filter(({ roles }) =>
                    roles?.roles?.includes(r)
                  )}
                />
              ),

              // `Content of Tab Pane ${id}`,
            })),
          ]}
        />
      </Card>
      <StaffForm
        open={staffForm.open}
        edit={staffForm.edit}
        data={staffForm.data}
        onClose={() => setStaffForm({ open: false, edit: false, data: null })}
      />
      <PaySalary
        {...salaryForm}
        onClose={() => setSalaryForm({ open: false, staffId: null })}
      />
      <PaidSalaries
        {...salaryList}
        onClose={() => setSalaryList({ open: false, staffId: null })}
      />
    </PageContainer>
  );
}

export default StaffSection;
