import {
  Button,
  Card,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import PNInput from "react-phone-number-input/input";
import { LangText, useLangText } from "../../../../components/langManagement";
import {
  ADVERTISER,
  DRIVER,
  ORDER_TAKER,
  PIZZA_MAKER,
} from "../../../../constants/roles";
import {
  useAddStaff,
  useEditStaff,
  useGetStaffList,
} from "../../../../zustand/store";
import { orderTypes } from "../../../../constants/orderType";

const { TextArea } = Input;

export const ot_s = {
  types: "types",
  ability_to_edit: "ability_to_edit",
  see_saved_orders: "see_saved_orders",
  assign_driver: "assign_driver",
};

export const checkOTS = (me) => (ot_set) => {
  const user = me.data?.user?.inStore;
  const extra = user?.extra;
  const ots = extra?.ots;

  if (ot_s.types === ot_set) {
    return (
      ots?.[ot_s.types] ||
      (user.owner
        ? [
            orderTypes.DELIVERY,
            orderTypes.DINE_IN,
            orderTypes.TO_GO,
            orderTypes.PICK_UP,
          ]
        : [])
    );
  } else if (
    ot_s.ability_to_edit === ot_set ||
    ot_s.see_saved_orders === ot_set ||
    ot_s.assign_driver === ot_set
  ) {
    const r = ots?.[ot_set];
    return typeof r === "boolean" ? r : user?.owner ? true : false;
  }
};

function StaffForm({ open, edit, data, onClose }) {
  const [form] = Form.useForm();
  const [permissionList, setPermissionList] = useState([]);
  const [selectedRole, setSelectedRole] = useState([]);
  const addStaff = useAddStaff();
  const editStaff = useEditStaff();
  const getStaffList = useGetStaffList();
  const text = useLangText();

  useEffect(() => {
    if (edit && data) {
      const {
        fullname,
        phone_number,
        roles,
        tg_chat_id,
        description,
        permissions,
        hourlyRate,
        extra,
      } = data;
      setSelectedRole(roles?.roles || []);
      const ots = Object.entries(extra?.ots || {}).reduce(
        (t, [key, value]) => ({
          ...t,
          [`ots___${key}`]: value,
        }),
        {}
      );

      form.setFieldsValue({
        fullname,
        phone_number,
        roles: roles?.roles || [],
        description,
        tg_chat_id,
        advertisingHourlyWage: hourlyRate?.advertising || 0,
        ...ots,
      });
      setPermissionList(permissions || []);
    } else {
      form.resetFields();
    }
  }, [open, data]);

  return (
    <>
      <Drawer open={open} onClose={onClose}>
        <Form
          autoComplete="off"
          form={form}
          onFinish={(e) => {
            (edit ? editStaff : addStaff).execute({
              params: { id: data?.id },
              data: { ...e, permissions: permissionList },
              onSuccess: () => {
                getStaffList.execute({ force: true });
                onClose();
                form.resetFields();
              },
            });
          }}
          layout="vertical"
          hideRequiredMark
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="fullname"
                label={<LangText id="fullname" />}
                rules={[
                  {
                    required: true,
                    message: text({ id: "enterFullname" }),
                  },
                ]}
              >
                <Input
                  style={{ width: "100%" }}
                  placeholder={text({ id: "enterFullname" })}
                />
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                name="phone_number"
                label={<LangText id="phoneNumber" />}
                // rules={[
                //   { required: true, message: text({ id: "enterPhoneNumber" }) },
                // ]}
              >
                <PNInput
                  className="ant-input css-dev-only-do-not-override-1e3x2xa"
                  // inputComponent={Input}
                  defaultValue="+998"
                  placeholder={text({ id: "enterPhoneNumber" })}
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={24}>
              <Form.Item
                name="tg_chat_id"
                label={<LangText id="telegramChatId" />}
              >
                <InputNumber
                  min={0}
                  style={{ width: "100%" }}
                  placeholder={text({ id: "enterTgChatId" })}
                />
              </Form.Item>
            </Col> */}

            <Col span={24}>
              <Form.Item
                name="roles"
                label={<LangText id="role" />}
                rules={[
                  {
                    required: true,
                    message: text({ id: "selectOrEnterRole" }),
                  },
                ]}
              >
                <Select
                  mode="tags"
                  onChange={(e) => setSelectedRole(e)}
                  options={[
                    ...new Set(
                      [DRIVER, ADVERTISER, ORDER_TAKER, PIZZA_MAKER]
                        ?.concat(
                          getStaffList?.data?.reduce(
                            (t, { roles }) => t.concat(roles?.roles),
                            []
                          ) || []
                        )
                        .filter((r) => r)
                    ),
                  ]?.map((role) => ({
                    label: role,
                    value: role,
                  }))}
                  placeholder={text({ id: "selectOrEnterRole" })}
                />
              </Form.Item>
            </Col>
            {selectedRole
              ?.filter((r) => r !== DRIVER)
              ?.map((r, i) => (
                <Col span={24} key={i}>
                  <Form.Item
                    label={`${r} Hourly Wage`}
                    name={`hourlyWage___${r}`}
                  >
                    <InputNumber
                      style={{ width: "100%" }}
                      addonBefore="$"
                      min={0}
                    />
                  </Form.Item>
                </Col>
              ))}
            {selectedRole.includes(ORDER_TAKER) ? (
              <Col span={24} key={"ots"}>
                <Form.Item
                  label={
                    <Typography.Text strong>
                      Order Taker Setting
                    </Typography.Text>
                  }
                >
                  <Card size="small" style={{ width: "100%" }}>
                    <Form.Item
                      label={`Order Types`}
                      name={`ots___${ot_s.types}`}
                    >
                      <Checkbox.Group style={{ width: "100%" }}>
                        <Row gutter={[10, 10]}>
                          {[
                            orderTypes.DELIVERY,
                            orderTypes.DINE_IN,
                            orderTypes.PICK_UP,
                            orderTypes.TO_GO,
                          ].map((orderType) => (
                            <Col>
                              <Checkbox value={orderType}>{orderType}</Checkbox>
                            </Col>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                    <Form.Item
                      label={`Ability to edit saved order`}
                      name={`ots___${ot_s.ability_to_edit}`}
                      valuePropName="checked"
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      label={`Ability to see saved orders`}
                      name={`ots___${ot_s.see_saved_orders}`}
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      label={`Ability to assign driver`}
                      name={`ots___${ot_s.assign_driver}`}
                      valuePropName="checked"
                      style={{ marginBottom: 0 }}
                    >
                      <Switch />
                    </Form.Item>
                  </Card>
                </Form.Item>
              </Col>
            ) : (
              <></>
            )}
            <Col span={24}>
              <Form.Item
                name="description"
                label={<LangText id="description" />}
                rules={[
                  {
                    required: false,
                    message: text({ id: "enterDescription" }),
                  },
                ]}
              >
                <TextArea
                  //   disabled={disabled}
                  rows={5}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  <LangText id={edit ? "update" : "add"} />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}

export default StaffForm;
