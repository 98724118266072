import { EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Popover,
  Result,
  Row,
  Table,
  Typography,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { thousandSeparator } from "../../../../utils/numberManager";
import {
  useChangeActivenessDate,
  useMakeHourlyWorkerCashOut,
} from "../../../../zustand/store";

export const TimeBox = ({ date, onFinish, loading }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm(null);
  useEffect(() => {
    form.setFieldValue("date", dayjs(date));
    setOpen(false);
  }, [date]);
  return (
    <div>
      {moment(date).format("HH:mm")}{" "}
      <Popover
        open={open}
        onOpenChange={setOpen}
        trigger={"click"}
        content={
          <Form
            form={form}
            onFinish={(e) => {
              onFinish(e.date);
            }}
          >
            <Row gutter={10}>
              <Col>
                <Form.Item name={"date"} style={{ margin: 0 }}>
                  <DatePicker
                    // defaultValue={dayjs(date)}
                    format="YYYY-MM-DD HH:mm"
                    showTime
                  />
                </Form.Item>
              </Col>
              <Form.Item style={{ margin: 0 }}>
                <Button loading={loading} type="primary" htmlType="submit">
                  Edit
                </Button>
              </Form.Item>
            </Row>
          </Form>
        }
      >
        <EditOutlined />
      </Popover>
    </div>
  );
};

export function CashOutModalBody({ open, onCancel, data: { position, data } }) {
  const changeDate = useChangeActivenessDate();
  const makeCashOut = useMakeHourlyWorkerCashOut();
  const hourlyWage = data?.user?.hourlyRate?.[position] || 0;
  const [form] = Form.useForm(null);
  const total = data?.activeness?.reduce(
    (t, v) => {
      const { endedAt: e, id, payment_id, startedAt: s, userId } = v;
      const hours = Number((moment(e).diff(moment(s)) / 3_600_000).toFixed(2));
      const wage = hourlyWage;
      return {
        hours: t.hours + hours,
        wage: t.wage + Number((hours * wage).toFixed(2)),
      };
    },
    { hours: 0, wage: 0 }
  );

  useEffect(() => {
    if (total) {
      form.setFieldValue("total", total?.wage?.toFixed(2));
    }
  }, [total, open]);
  useEffect(() => {
    if (open) {
      makeCashOut.clean();
    }
  }, [open]);
  return (
    <>
      {!makeCashOut.data ? (
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Table
              size="small"
              pagination={false}
              columns={[
                {
                  key: "startedAt",
                  dataIndex: "startedAt",
                  title: "Start",
                },
                {
                  key: "endedAt",
                  dataIndex: "endedAt",
                  title: "End",
                },
                {
                  key: "hours",
                  dataIndex: "hours",
                  title: "Hours",
                },
                {
                  key: "amount",
                  dataIndex: "amount",
                  title: "Amount",
                },
              ]}
              dataSource={data?.activeness?.map(
                ({ endedAt, id, payment_id, position, startedAt, userId }) => {
                  const hours = (
                    moment(endedAt).diff(moment(startedAt)) / 3_600_000
                  ).toFixed(2);
                  return {
                    startedAt: (
                      <TimeBox
                        loading={changeDate.loading}
                        date={startedAt}
                        onFinish={(startedAt) =>
                          changeDate.execute({
                            data: { startedAt },
                            params: { id },
                          })
                        }
                      />
                    ),
                    endedAt: (
                      <TimeBox
                        loading={changeDate.loading}
                        date={endedAt}
                        onFinish={(endedAt) =>
                          changeDate.execute({
                            data: { endedAt },
                            params: { id },
                          })
                        }
                      />
                    ),
                    hours,
                    amount: `$${thousandSeparator(
                      (
                        hours * (data?.user?.hourlyRate?.advertising || 0) || 0
                      ).toFixed(2)
                    )}`,
                  };
                }
              )}
              footer={() => {
                return (
                  <>
                    {data?.user?.fullname} spent{" "}
                    <Typography.Text underline>
                      {total.hours} hours
                    </Typography.Text>{" "}
                    on advertising, so you have to pay{" "}
                    <Typography.Text mark strong>
                      ${thousandSeparator(total?.wage?.toFixed(2))}
                    </Typography.Text>
                  </>
                );
              }}
            />
          </Col>
          <Form
            onFinish={(e) => {
              const body = {
                ...e,
                activeness: data?.activeness?.map(({ id }) => id),
                user: data?.user?.id,
                position,
              };
              makeCashOut.execute({ data: body });
            }}
            layout="vertical"
            form={form}
          >
            <Form.Item label="Total" name={"total"}>
              <InputNumber addonBefore="$" min={0} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item>
              <Button
                loading={makeCashOut.loading}
                block
                type="primary"
                htmlType="submit"
              >
                CashOut
              </Button>
            </Form.Item>
          </Form>
        </Row>
      ) : (
        <>
          <Result
            status="success"
            title="Successfully Cashed Out"
            subTitle={
              <>
                You successfully cashed out. You can check it in Cash Out page
              </>
            }
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => {
                  // navigate(`/cash-out/list/${makeCashOut?.data?.id}`);
                }}
              >
                Visit
              </Button>,
              <Button key="buy" onClick={() => onCancel?.()}>
                Close
              </Button>,
            ]}
          />
        </>
      )}
    </>
  );
}

function CashOutModal({ open, onCancel, data: { position, data } }) {
  const hourlyWage = data?.user?.hourlyRate?.[position] || 0;
  return (
    <Modal
      title={`${data?.user?.fullname} ${position} $${hourlyWage || 0}/hour`}
      open={open}
      onCancel={onCancel}
      centered
      footer
    >
      <CashOutModalBody
        open={open}
        onCancel={onCancel}
        data={{ position, data }}
      />
    </Modal>
  );
}

export default CashOutModal;
