export const permissions = {
  //  WAREHOUSE
  GET_MATERIALS: "GET_MATERIALS",
  ADD_MATERIALS: "ADD_MATERIALS",
  EDIT_MATERIAL: "EDIT_MATERIAL",
  // EDIT_MATERIAL_ACTIVENESS: "EDIT_MATERIAL_ACTIVENESS",

  GET_GOODS: "GET_GOODS",
  // GET_SINGLE_GOODS: "GET_SINGLE_GOODS",
  // GET_PROVIDERS: "GET_PROVIDERS",
  ADD_GOODS: "ADD_GOODS",
  EDIT_GOODS: "EDIT_GOODS",
  PAY_FOR_GOODS: "PAY_FOR_GOODS",

  // ADD_MATERIAL_TO_GOODS: "ADD_MATERIAL_TO_GOODS",
  // EDIT_MATERIAL_OF_GOODS: "EDIT_MATERIAL_OF_GOODS",
  // EDIT_PROVIDER_OF_GOODS: "EDIT_PROVIDER_OF_GOODS",
  // DELETE_MATERIAL_OF_GOODS: "DELETE_MATERIAL_OF_GOODS",

  //   PAYMENT
  GET_PAYMENTS: "GET_PAYMENTS",
  ADD_PAYMENTS: "ADD_PAYMENTS",
  EDIT_PAYMENT: "EDIT_PAYMENT",
  DELETE_PAYMENT: "DELETE_PAYMENT",
  // GET_PAYMENT_CATEGORY: "GET_PAYMENT_CATEGORY",

  //   FIN_ACCOUNT
  GET_ACCOUNTS: "GET_ACCOUNTS",
  ADD_ACCOUNT: "ADD_ACCOUNT",
  EDIT_ACCOUNT: "EDIT_ACCOUNT",
  // EDIT_ACCOUNT_ACTIVENESS: "EDIT_ACCOUNT_ACTIVENESS",

  //   ORDERS
  GET_ORDERS: "GET_ORDERS",
  // GET_SINGLE_ORDER: "GET_SINGLE_ORDER",
  ADD_ORDER: "ADD_ORDER",
  EDIT_ORDER: "EDIT_ORDER",

  ADD_ORDER_DETAIL: "ADD_ORDER_DETAIL",
  EDIT_ORDER_DETAIL: "EDIT_ORDER_DETAIL",
  ADD_PAYMENT_TO_ORDER: "ADD_PAYMENT_TO_ORDER",
  PAY_SALARY_FOR_ORDER: "PAY_SALARY_FOR_ORDER",

  // EDIT_ORDER_DETAIL_PRICE: "EDIT_ORDER_DETAIL_PRICE",
  // EDIT_ORDER_DETAIL_STATUS: "EDIT_ORDER_DETAIL_STATUS",
  // EDIT_ORDER_CLIENT: "EDIT_ORDER_CLIENT",
  // EDIT_ORDER_DESCRIPTION: "EDIT_ORDER_DESCRIPTION",

  //   STAFF_LIST
  GET_STAFF_LIST: "GET_STAFF_LIST",
  ADD_STAFF: "ADD_STAFF",
  EDIT_STAFF: "EDIT_STAFF",
  PAY_SALARY: "PAY_SALARY",
  GET_STAFF_SALARY: "GET_STAFF_SALARY",
  // EDIT_STAFF_ACTIVENESS: "EDIT_STAFF_ACTIVENESS",

  GET_FILE_BY_ID: "GET_FILE_BY_ID",
};

export const p = permissions;

export const permissions_with_data = {
  [p.GET_MATERIALS]: {
    label: "GET_MATERIALS",
    description: "",
    belongsTo: [],
    belongedBy: [],
    defaultValue: true,
  },
  [p.ADD_MATERIALS]: {
    label: "ADD_MATERIALS",
    description: "",
    belongsTo: [p.GET_MATERIALS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.EDIT_MATERIAL]: {
    label: "EDIT_MATERIAL",
    description: "",
    belongsTo: [p.GET_MATERIALS],
    belongedBy: [],
    defaultValue: false,
  },

  [p.GET_GOODS]: {
    label: "GET_GOODS",
    description: "",
    belongsTo: [],
    belongedBy: [],
    defaultValue: false,
  },
  [p.ADD_GOODS]: {
    label: "ADD_GOODS",
    description: "",
    belongsTo: [p.GET_GOODS, p.GET_MATERIALS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.EDIT_GOODS]: {
    label: "EDIT_GOODS",
    description: "",
    belongsTo: [p.GET_GOODS, p.GET_MATERIALS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.PAY_FOR_GOODS]: {
    label: "PAY_FOR_GOODS",
    description: "",
    belongsTo: [p.GET_GOODS, p.GET_MATERIALS, p.GET_ACCOUNTS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.GET_PAYMENTS]: {
    label: "GET_PAYMENTS",
    description: "",
    belongsTo: [],
    belongedBy: [],
    defaultValue: false,
  },
  [p.ADD_PAYMENTS]: {
    label: "ADD_PAYMENTS",
    description: "",
    belongsTo: [p.GET_PAYMENTS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.EDIT_PAYMENT]: {
    label: "EDIT_PAYMENT",
    description: "",
    belongsTo: [p.GET_GOODS, p.GET_MATERIALS, p.GET_PAYMENTS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.DELETE_PAYMENT]: {
    label: "DELETE_PAYMENT",
    description: "",
    belongsTo: [p.GET_GOODS, p.GET_MATERIALS, p.GET_PAYMENTS],
    belongedBy: [],
    defaultValue: false,
  },

  // [p.GET_PAYMENT_CATEGORY]: {
  //   label: "GET_PAYMENT_CATEGORY",
  //   description: "",
  //   belongsTo: [],
  //   belongedBy: [],
  //   defaultValue: true,
  // },
  [p.GET_ACCOUNTS]: {
    label: "GET_ACCOUNTS",
    description: "",
    belongsTo: [],
    belongedBy: [],
    defaultValue: false,
  },
  [p.ADD_ACCOUNT]: {
    label: "ADD_ACCOUNT",
    description: "",
    belongsTo: [p.GET_ACCOUNTS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.EDIT_ACCOUNT]: {
    label: "EDIT_ACCOUNT",
    description: "",
    belongsTo: [p.GET_ACCOUNTS],
    belongedBy: [],
    defaultValue: false,
  },
  // [p.EDIT_ACCOUNT_ACTIVENESS]: {
  //   label: "EDIT_ACCOUNT_ACTIVENESS",
  //   description: "",
  //   belongsTo: [p.GET_ACCOUNTS],
  //   belongedBy: [],
  //   defaultValue: false,
  // },
  [p.GET_ORDERS]: {
    label: "GET_ORDERS",
    description: "",
    belongsTo: [],
    belongedBy: [],
    defaultValue: false,
  },
  // [p.GET_SINGLE_ORDER]: {
  //   label: "GET_SINGLE_ORDER",
  //   description: "",
  //   belongsTo: [],
  //   belongedBy: [],
  //   defaultValue: false,
  // },
  [p.ADD_ORDER]: {
    label: "ADD_ORDER",
    description: "",
    belongsTo: [p.GET_ORDERS, p.ADD_ORDER_DETAIL, p.EDIT_ORDER_DETAIL],
    belongedBy: [],
    defaultValue: false,
  },
  [p.EDIT_ORDER]: {
    label: "EDIT_ORDER",
    description: "",
    belongsTo: [p.GET_ORDERS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.ADD_ORDER_DETAIL]: {
    label: "ADD_ORDER_DETAIL",
    description: "",
    belongsTo: [p.GET_ORDERS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.EDIT_ORDER_DETAIL]: {
    label: "EDIT_ORDER_DETAIL",
    description: "",
    belongsTo: [p.GET_ORDERS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.ADD_PAYMENT_TO_ORDER]: {
    label: "ADD_PAYMENT_TO_ORDER",
    description: "",
    belongsTo: [p.GET_GOODS, p.GET_MATERIALS, p.GET_PAYMENTS, p.GET_ACCOUNTS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.PAY_SALARY_FOR_ORDER]: {
    label: "PAY_SALARY_FOR_ORDER",
    description: "",
    belongsTo: [p.GET_GOODS, p.GET_MATERIALS, p.GET_PAYMENTS, p.GET_ACCOUNTS],
    belongedBy: [],
    defaultValue: false,
  },
  // [p.EDIT_ORDER_DETAIL_PRICE]: {
  //   label: "EDIT_ORDER_DETAIL_PRICE",
  //   description: "",
  //   belongsTo: [p.GET_ORDERS],
  //   belongedBy: [],
  //   defaultValue: false,
  // },
  // [p.EDIT_ORDER_DETAIL_STATUS]: {
  //   label: "EDIT_ORDER_DETAIL_STATUS",
  //   description: "",
  //   belongsTo: [p.GET_ORDERS],
  //   belongedBy: [],
  //   defaultValue: false,
  // },
  // [p.EDIT_ORDER_CLIENT]: {
  //   label: "EDIT_ORDER_CLIENT",
  //   description: "",
  //   belongsTo: [p.GET_ORDERS],
  //   belongedBy: [],
  //   defaultValue: false,
  // },
  // [p.EDIT_ORDER_DESCRIPTION]: {
  //   label: "EDIT_ORDER_DESCRIPTION",
  //   description: "",
  //   belongsTo: [p.GET_ORDERS],
  //   belongedBy: [],
  //   defaultValue: false,
  // },
  [p.GET_STAFF_LIST]: {
    label: "GET_STAFF_LIST",
    description: "",
    belongsTo: [],
    belongedBy: [],
    defaultValue: false,
  },
  [p.ADD_STAFF]: {
    label: "ADD_STAFF",
    description: "",
    belongsTo: [p.GET_STAFF_LIST],
    belongedBy: [],
    defaultValue: false,
  },
  [p.EDIT_STAFF]: {
    label: "EDIT_STAFF",
    description: "",
    belongsTo: [p.GET_STAFF_LIST],
    belongedBy: [],
    defaultValue: false,
  },
  // [p.EDIT_STAFF_ACTIVENESS]: {
  //   label: "EDIT_STAFF_ACTIVENESS",
  //   description: "",
  //   belongsTo: [p.GET_STAFF_LIST],
  //   belongedBy: [],
  //   defaultValue: false,
  // },
  [p.GET_FILE_BY_ID]: {
    label: "GET_FILE_BY_ID",
    description: "",
    belongsTo: [],
    belongedBy: [],
    defaultValue: false,
  },
  [p.PAY_SALARY]: {
    label: "PAY_SALARY",
    description: "",
    belongsTo: [p.GET_PAYMENTS, p.GET_ACCOUNTS],
    belongedBy: [],
    defaultValue: false,
  },
  [p.GET_STAFF_SALARY]: {
    label: "GET_STAFF_SALARY",
    description: "",
    belongsTo: [],
    belongedBy: [
      p.GET_STAFF_LIST,
      p.GET_GOODS,
      p.GET_MATERIALS,
      p.GET_PAYMENTS,
    ],
    defaultValue: false,
  },
};

export const roles = [
  {
    name: "Ombor",
    permissions: [
      [p.GET_MATERIALS],
      [p.ADD_MATERIALS],
      [p.EDIT_MATERIAL],
      // [p.EDIT_MATERIAL_ACTIVENESS],
    ],
  },
  {
    name: "Yuk",
    permissions: [
      [p.GET_GOODS],
      // [p.GET_SINGLE_GOODS],
      // [p.GET_PROVIDERS],
      [p.ADD_GOODS],
      [p.EDIT_GOODS],
      [p.PAY_FOR_GOODS],

      // [p.ADD_MATERIAL_TO_GOODS],
      // [p.EDIT_MATERIAL_OF_GOODS],
      // [p.EDIT_PROVIDER_OF_GOODS],
      // [p.DELETE_MATERIAL_OF_GOODS],
    ],
  },
  {
    name: "Moliya",
    permissions: [
      [p.GET_PAYMENTS],
      [p.ADD_PAYMENTS],
      [p.EDIT_PAYMENT],
      [p.DELETE_PAYMENT],
      // [p.GET_PAYMENT_CATEGORY],
      [p.GET_ACCOUNTS],
      [p.ADD_ACCOUNT],
      [p.EDIT_ACCOUNT],
      // [p.EDIT_ACCOUNT_ACTIVENESS],
    ],
  },
  {
    name: "Buyurtmalar",
    permissions: [
      [p.GET_ORDERS],
      // [p.GET_SINGLE_ORDER],
      [p.ADD_ORDER],
      [p.EDIT_ORDER],
      [p.ADD_ORDER_DETAIL],
      [p.EDIT_ORDER_DETAIL],
      [p.ADD_PAYMENT_TO_ORDER],
      [p.PAY_SALARY_FOR_ORDER],

      // [p.EDIT_ORDER_DETAIL_PRICE],
      // [p.EDIT_ORDER_DETAIL_STATUS],
      // [p.EDIT_ORDER_CLIENT],
      // [p.EDIT_ORDER_DESCRIPTION],
    ],
  },
  {
    name: "Ishchilar",
    permissions: [
      [p.GET_STAFF_LIST],
      [p.ADD_STAFF],
      [p.EDIT_STAFF],
      [p.PAY_SALARY],
      [p.GET_STAFF_SALARY],
      // [p.EDIT_STAFF_ACTIVENESS],
    ],
  },
];
