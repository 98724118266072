import { Card, Col, Form, Popconfirm, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { PageContainer } from "../../components/container";
import { BASE_URL, PRINTER_BASE_URL } from "../../constants/link";
import {
  useDeleteReceipt,
  useGetMacAddress,
  useGetMe,
  useGetReceiptList,
} from "../../zustand/store";
import MacAddress from "./macAddress";
import { DocPart, MacAddressPart, ReceiptWrapper, Wrapper } from "./styles";
import { DeleteOutlined, LoadingOutlined } from "@ant-design/icons";
import ReceiptMarkUp from "./receiptMarkUp";

function PrinterPage() {
  const me = useGetMe();
  const getMacAddress = useGetMacAddress();
  const receiptList = useGetReceiptList();
  const deleteReceipt = useDeleteReceipt();
  const [macWidth, setMacWidth] = useState(0);

  useEffect(() => {
    getMacAddress.execute();
    receiptList.execute();
    setMacWidth(document.querySelector(".mac-address-part")?.clientWidth || 0);
  }, []);

  const restaurantId = me.data?.restaurant?.id;
  return (
    <PageContainer title={"Printer"}>
      <Wrapper>
        {/* <Col span={24}> */}
        <ReceiptMarkUp />
        {/* </Col> */}
        <DocPart macWidth={macWidth}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Card
                size="small"
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   justifyContent: "center",
              // }}
              >
                <Typography.Title style={{ margin: 0 }} copyable level={4}>
                  {`${PRINTER_BASE_URL}/poll/${restaurantId}`}
                </Typography.Title>
              </Card>
            </Col>
            <Col span={24}>
              <Card size="small">
                There will be documentation about how to connect printer
              </Card>
            </Col>
            {receiptList?.data?.map(({ id, data, is_printed }) => (
              <Col span={8}>
                <Card
                  size="small"
                  actions={[
                    deleteReceipt.loading ? (
                      <LoadingOutlined />
                    ) : (
                      <Popconfirm
                        onConfirm={() => {
                          deleteReceipt.execute({ params: { id } });
                        }}
                        title="Delete"
                        description="Do you really want to delete this?"
                      >
                        <DeleteOutlined />
                      </Popconfirm>
                    ),
                  ]}
                >
                  {is_printed ? "Printed" : "Not Printed"}
                  <ReceiptWrapper dangerouslySetInnerHTML={{ __html: data }} />
                  {/* {data} */}
                </Card>
              </Col>
            ))}
          </Row>
        </DocPart>
        <MacAddressPart className="mac-address-part">
          <MacAddress />
        </MacAddressPart>
      </Wrapper>
    </PageContainer>
  );
}

export default PrinterPage;
