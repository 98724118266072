import { Outlet } from "react-router-dom";
import { useGetMe } from "../../zustand/store";

export const PrivateComp = ({
  children = <Outlet />,
  p,
  or,
  and,
  route,
  device,
  project_owner,
}) => {
  const me = useGetMe();
  // const permissions = me?.data?.permissions;
  const data = me.data;
  const roles = data?.user?.inStore?.roles?.roles;
  const deviceData = data?.device;
  const owner = data?.user?.inStore?.owner;
  const po = data?.restaurant?.project_owner;

  console.log("DATA: ", {
    device,
    deviceName: deviceData?.name,
    po,
  }
  );

  if (po) {
    return project_owner ? children : <></>;
  } else if (project_owner) {
    return po ? children : <></>;
  } else if (owner) {
    return children;
  } else if (p) {
    return roles?.includes(p) ? children : <></>;
  } else if (or && Array.isArray(or)) {
    return roles?.reduce((t, v) => t || or.includes(v), false) ? (
      children
    ) : (
      <></>
    );
  } else if (and && Array.isArray(and)) {
    return roles?.reduce((t, v) => (and.includes(v) ? [...t, v] : t), [])
      ?.length === and?.length ? (
      children
    ) : (
      <></>
    );
  } else if (device) {
    console.log("DEVICE: ", device, deviceData);
    return device === deviceData?.name ? children : <></>;
  } else {
    return <></>;
    // return children;
  }
};
