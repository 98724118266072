import { Card } from "antd";
import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100vh - 200px);
  display: flex;
  gap: 10px;
`;

export const AddressListWrapper = styled(Card)`
  width: 300px;
  /* height: 100%; */
  max-height: 100%;
  height: fit-content;
  overflow: auto;
`;

export const MapWrapper = styled(Card)`
  width: calc(100% - 550px);
  height: 100%;
  .ant-card-body {
    height: 100%;
  }
`;

export const AreaWrapper = styled(Card)`
  width: 230px;
  max-height: 100%;
  height: fit-content;
  overflow: auto;
`;
