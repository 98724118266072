import React from "react";
import { Wrapper } from "./styles";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Switch,
  Typography,
} from "antd";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import moment from "moment";
import { extraPriceCalc } from "../../../utils/calculate";
import {
  FULL_SIDE,
  LEFT_SIDE,
  NO,
  RIGHT_SIDE,
  emoji,
} from "../../../constants/general";
import size from "../../../constants/size";
import {
  useGetMe,
  useReceiptMarkUp,
  useUpdateReceiptSetting,
} from "../../../zustand/store";
import { useEffect } from "react";
import { ReceiptWrapper } from "../styles";
import {
  charDict,
  enhancedSizeList,
  insertToMarkUp,
  receiptPartList,
  specialChars,
  textStyleList,
} from "../../../utils/receipt.helper";
import { useState } from "react";
// import { transform } from "receiptline";

// const order = {
//   id: 91,
//   selectedFood: [
//     {
//       id: 17,
//       desc: "",
//       name: "Veggie Lovers",
//       only: [
//         { name: "Banana Peppers", side: "NO" },
//         { name: "Black Olives", side: "LEFT" },
//         { name: "Green Peppers", side: "RIGHT" },
//       ],
//       size: "12_md",
//       sort: 23,
//       crust: "HAND_TOSSED",
//       extra: [{ id: "1", amount: 0 }],
//       price: 13.9,
//       amount: 1,
//       flavors: [],
//       options: [],
//       created_at: null,
//       extraPrice: 4.5,
//       updated_at: "2024-01-26T00:47:24.007+00:00",
//       ingredients: [
//         "Banana Peppers",
//         "Black Olives",
//         "Green Peppers",
//         "Mushrooms",
//         "Onions",
//         "Spinach",
//         "Tomatoes",
//       ],
//       display_type: null,
//       flavorAmount: 0,
//       selectedExtra: [
//         { id: 1, name: "Bacon", side: "LEFT" },
//         { id: 1, name: "Banana Peppers", side: "FULL" },
//         { id: 1, name: "Black Olives", side: "RIGHT" },
//       ],
//       subCategoryId: 127,
//       menuCategoryId: 19,
//       selectedFlavors: [],
//     },
//     {
//       id: 42,
//       desc: "",
//       name: "16 Piece",
//       only: [],
//       size: null,
//       sort: null,
//       crust: "HAND_TOSSED",
//       extra: null,
//       price: 20.99,
//       amount: 1,
//       flavors: ["Hot", "Mild", "BBQ", "Teriyaki", "Lemon Pepper", "Plain"],
//       options: [],
//       created_at: null,
//       extraPrice: 0,
//       updated_at: null,
//       ingredients: null,
//       display_type: null,
//       flavorAmount: 2,
//       selectedExtra: [],
//       subCategoryId: null,
//       menuCategoryId: 12,
//       selectedFlavors: [{ name: "Hot" }, { name: "Mild" }],
//     },
//     {
//       id: 65,
//       name: "Garden",
//       size: null,
//       sort: null,
//       extra: null,
//       price: 7.99,
//       amount: 1,
//       flavors: null,
//       options: [],
//       created_at: null,
//       updated_at: null,
//       ingredients: null,
//       display_type: null,
//       flavorAmount: 0,
//       subCategoryId: null,
//       menuCategoryId: 13,
//     },
//   ],
//   userData: {
//     block: "C",
//     address: "HSB",
//     roomNumber: "25",
//     desc: "Try faster",
//     addressData: {
//       id: 36,
//       name: "HSB",
//       areaId: 1,
//       blocks: ["A", "B", "C", "D", "E", "F", "G", "H", "J"],
//       coords: { lat: 33.66194825823711, lng: -78.919787035411 },
//       shortName: null,
//       created_at: "2023-10-12T01:37:45.919046+00:00",
//       updated_at: "2023-10-12T01:37:45.919046+00:00",
//       deliveryFee: 5,
//     },
//     deliveryFee: 5,
//     phoneNumber: "+18434460396",
//   },
//   isClosed: false,
//   isCanceled: false,
//   madeByBaker: false,
//   cancellationReason: null,
//   cashAmount: 0,
//   closedAt: null,
//   addedAt: "2024-01-29 20:46:56.928689 +00:00",
//   created_at: null,
//   updated_at: null,
//   msgId: null,
//   driver: null,
//   cash_out: null,
//   accepted_by_driver: false,
//   order_type: "DELIVERY",
//   madeByBakerAt: null,
//   canceledAt: null,
//   totals: {
//     tax: "6.02",
//     total: "58.40",
//     subTotal: "47.38",
//     serviceFee: 0,
//     deliveryFee: 5,
//   },
// };

const order = {
  id: 91,
  selectedFood: [
    {
      id: 17,
      desc: "",
      name: "Veggie Lovers",
      only: [
        { name: "Banana Peppers", side: "NO" },
        { name: "Black Olives", side: "LEFT" },
        { name: "Green Peppers", side: "RIGHT" },
      ],
      size: "12_md",
      sort: 23,
      crust: "HAND_TOSSED",
      extra: [{ id: "1", amount: 0 }],
      price: 13.9,
      amount: 1,
      flavors: [],
      options: [],
      created_at: null,
      extraPrice: 4.5,
      updated_at: "2024-01-26T00:47:24.007+00:00",
      ingredients: [
        "Banana Peppers",
        "Black Olives",
        "Green Peppers",
        "Mushrooms",
        "Onions",
        "Spinach",
        "Tomatoes",
      ],
      display_type: null,
      flavorAmount: 0,
      selectedExtra: [
        { id: 1, name: "Bacon", side: "LEFT" },
        { id: 1, name: "Banana Peppers", side: "FULL" },
        { id: 1, name: "Black Olives", side: "RIGHT" },
      ],
      subCategoryId: 127,
      menuCategoryId: 19,
      selectedFlavors: [],
    },
    {
      id: 42,
      desc: "",
      name: "16 Piece",
      only: [],
      size: null,
      sort: null,
      crust: "HAND_TOSSED",
      extra: null,
      price: 20.99,
      amount: 1,
      flavors: ["Hot", "Mild", "BBQ", "Teriyaki", "Lemon Pepper", "Plain"],
      options: [],
      created_at: null,
      extraPrice: 0,
      updated_at: null,
      ingredients: null,
      display_type: null,
      flavorAmount: 2,
      selectedExtra: [],
      subCategoryId: null,
      menuCategoryId: 12,
      selectedFlavors: [{ name: "Hot" }, { name: "Mild" }],
    },
    {
      id: 65,
      name: "Garden",
      size: null,
      sort: null,
      extra: null,
      price: 7.99,
      amount: 1,
      flavors: null,
      options: [],
      created_at: null,
      updated_at: null,
      ingredients: null,
      display_type: null,
      flavorAmount: 0,
      subCategoryId: null,
      menuCategoryId: 13,
    },
    {
      id: 35,
      name: "Special 4",
      size: null,
      sort: null,
      extra: [],
      price: 49.99,
      amount: 1,
      flavors: [],
      options: [
        { foods: [{ id: 38, extra: [{ id: "2", amount: 3 }] }], length: 2 },
        { foods: [{ id: 42 }], length: 1 },
        { foods: [{ id: 10 }], length: 1 },
        {
          foods: [
            { id: 5 },
            { id: 68 },
            { id: 72 },
            { id: 71 },
            { id: 70 },
            { id: 69 },
          ],
          length: 1,
        },
      ],
      created_at: null,
      updated_at: null,
      ingredients: [],
      display_type: null,
      flavorAmount: 0,
      subCategoryId: null,
      menuCategoryId: 14,
      selectedOption: [
        [
          {
            id: 38,
            ind: 0,
            desc: "",
            name: "1 Topping",
            only: [],
            size: "14_lg",
            sort: 2,
            crust: "HAND_TOSSED",
            extra: [{ id: "2", amount: 3 }],
            index: 0,
            price: 12.9,
            amount: 1,
            flavors: [],
            options: [],
            created_at: null,
            extraPrice: 0,
            updated_at: "2024-01-26T00:47:23.085+00:00",
            ingredients: [],
            display_type: null,
            flavorAmount: 0,
            selectedExtra: [
              { id: 2, name: "Bacon", side: "LEFT" },
              { id: 2, name: "Banana Peppers", side: "FULL" },
              { id: 2, name: "Black Olives", side: "RIGHT" },
            ],
            subCategoryId: 120,
            menuCategoryId: 19,
            selectedFlavors: [],
          },
          {
            id: 38,
            ind: 1,
            desc: "",
            name: "1 Topping",
            only: [],
            size: "14_lg",
            sort: 2,
            crust: "HAND_TOSSED",
            extra: [{ id: "2", amount: 3 }],
            index: 0,
            price: 12.9,
            amount: 1,
            flavors: [],
            options: [],
            created_at: null,
            extraPrice: 5.25,
            updated_at: "2024-01-26T00:47:23.085+00:00",
            ingredients: [],
            display_type: null,
            flavorAmount: 0,
            selectedExtra: [
              { id: 2, name: "Bacon", side: "LEFT" },
              { id: 2, name: "Banana Peppers", side: "FULL" },
              { id: 2, name: "Black Olives", side: "RIGHT" },
              { id: 2, name: "Onions", side: "LEFT" },
              { id: 2, name: "Pepperoni", side: "FULL" },
              { id: 2, name: "Pineapple", side: "RIGHT" },
            ],
            subCategoryId: 120,
            menuCategoryId: 19,
            selectedFlavors: [],
          },
        ],
        [
          {
            id: 42,
            ind: 0,
            desc: "",
            name: "16 Piece",
            only: [],
            size: null,
            sort: null,
            crust: "HAND_TOSSED",
            index: 1,
            price: 20.99,
            amount: 1,
            flavors: [
              "Hot",
              "Mild",
              "BBQ",
              "Teriyaki",
              "Lemon Pepper",
              "Plain",
            ],
            options: [],
            created_at: null,
            extraPrice: 0,
            updated_at: null,
            ingredients: null,
            display_type: null,
            flavorAmount: 2,
            selectedExtra: [],
            subCategoryId: null,
            menuCategoryId: 12,
            selectedFlavors: [{ name: "Hot" }, { name: "Teriyaki" }],
          },
        ],
        [
          {
            id: 10,
            name: "Regular Breadsticks",
            size: null,
            sort: null,
            price: 5.99,
            flavors: null,
            options: [],
            created_at: null,
            updated_at: null,
            ingredients: null,
            display_type: null,
            flavorAmount: 0,
            subCategoryId: null,
            menuCategoryId: 16,
          },
        ],
        [
          {
            id: 68,
            name: "Fanta",
            size: null,
            sort: 2,
            price: 3.99,
            flavors: [],
            options: [],
            created_at: null,
            updated_at: null,
            ingredients: [],
            display_type: null,
            flavorAmount: 0,
            subCategoryId: null,
            menuCategoryId: 18,
          },
        ],
      ],
    },
  ],
  userData: {
    call: true,
    block: "C",
    address: "HSB",
    roomNumber: "25",
    desc: "Try faster",
    addressData: {
      id: 36,
      name: "HSB",
      areaId: 1,
      blocks: ["A", "B", "C", "D", "E", "F", "G", "H", "J"],
      coords: { lat: 33.66194825823711, lng: -78.919787035411 },
      shortName: null,
      created_at: "2023-10-12T01:37:45.919046+00:00",
      updated_at: "2023-10-12T01:37:45.919046+00:00",
      deliveryFee: 5,
    },
    deliveryFee: 5,
    phoneNumber: "+18434460396",
  },
  isClosed: false,
  isCanceled: false,
  madeByBaker: false,
  cancellationReason: null,
  cashAmount: 0,
  closedAt: null,
  addedAt: "2024-01-29 20:46:56.928689 +00:00",
  created_at: null,
  updated_at: null,
  msgId: null,
  driver: null,
  cash_out: null,
  accepted_by_driver: false,
  order_type: "DELIVERY",
  madeByBakerAt: null,
  canceledAt: null,
  totals: {
    tax: "12.38",
    total: "120.00",
    subTotal: "102.62",
    serviceFee: 0,
    deliveryFee: 5,
  },
};

const listItemReceipt = ({ data, inner }, setting) => {
  let {
    category,
    createdAt,
    createdBy,
    id,
    ingredients,
    name,
    price,
    size: s,
    amount,
    only,
    no,
    // toppings,
    flavors,
    selectedFlavors,
    desc,
    selectedOption,
    options,
    crust,
    // toppingPrice,
    selectedExtra,
    extra,
    extraPrice,
  } = data;
  amount = amount || 1;
  extraPrice = extraPrice || extraPriceCalc(selectedOption) || 0;
  
  const space = inner ? "    " : "";
  const innerSpace = "|    " + space;

  const itemPrice =
    !inner && amount === 1
      ? `$${price}${extraPrice ? ` + $${extraPrice}` : ""}`
      : !inner && amount > 1
      ? `${extraPrice ? "(" : ""}$${price} ${
          extraPrice ? ` + $${extraPrice})` : ""
        } x ${amount} = $${((price + (extraPrice || 0)) * amount).toFixed(2)}`
      : "";
  return (
    `{width:*,${itemPrice?.length}}\n` +
    space +
    `${
      !inner ? `${insertToMarkUp(amount, setting?.amount)}` : ""
    } ${insertToMarkUp(name, setting?.name)} ${
      s ? `${insertToMarkUp(size?.[s], setting?.size)}` : ""
    } | ${insertToMarkUp(itemPrice, setting?.price)}|\n{width:*}` +
    (Boolean(no?.length)
      ? "\n" +
        // space +
        `${no
          ?.map(
            (v) =>
              innerSpace +
              insertToMarkUp(
                `${setting?.[NO] || "-"} ${v}`,
                setting?.customization
              )
          )
          .join("\n")}`
      : "") +
    (Boolean(only?.length)
      ? "\n" +
        `${only
          ?.map(
            (v) =>
              innerSpace +
              insertToMarkUp(
                `${setting?.[v?.side] || emoji[v?.side] || "ONLY"} ${
                  v?.name || v
                }`,
                setting?.customization
              )
          )
          .join("\n")}`
      : "") +
    (Boolean(selectedExtra?.length)
      ? "\n" +
        `${selectedExtra
          ?.map(
            (v) =>
              innerSpace +
              insertToMarkUp(
                `${setting?.[v?.side] || emoji[v?.side] || "ADD"} ${
                  v?.name || v
                }`,
                setting?.customization
              )
          )
          .join("\n")}`
      : "") +
    (Boolean(selectedFlavors?.length)
      ? `\n${innerSpace}${insertToMarkUp(
          `${selectedFlavors?.map((v) => `${v?.name}`)?.join(", ")}`,
          setting?.customization
        )}`
      : "") +
    (desc ? "\n|" + space + `${insertToMarkUp(desc, setting?.foodDesc)}` : "") +
    (Boolean(selectedOption?.length)
      ? `\n${selectedOption
          ?.map((sOption) =>
            sOption
              ?.map(
                (data) => `${listItemReceipt({ inner: true, data }, setting)}`
              )
              .join("\n")
          )
          .join("\n")}`
      : "")
  );
};

const receipt = (
  { id, selectedFood, order_type, userData, totals },
  setting
) => {
  const { subTotal, tax, deliveryFee, total, serviceFee } = totals || {};
  const list = selectedFood
    .map((data) => listItemReceipt({ data, inner: false }, setting))
    .join("\n---\n");

  const address = userData?.address || "UNKNOWN ADDRESS";
  let block = userData?.block || "";
  block = block ? `${block}-` : "";
  const roomNumber = userData?.roomNumber || "UNKNOWN ROOM NUMBER";
  const addressLine = `${address} ${block}${roomNumber}`;
  const phoneNumber =
    formatPhoneNumberIntl(userData?.phoneNumber) || "UNKNOWN PHONE NUMBER";
  const desc = userData?.desc;
  const call = userData?.call;
  const isDelivery = order_type === "DELIVERY";
  const currentDate = moment(Date.now());

  const date = currentDate.format("MM/DD/YYYY");
  const time = currentDate.format("HH:mm");
  return (
    `\n\n\n\n${insertToMarkUp(order_type, setting?.order_type)}\n${
      isDelivery || desc || call
        ? `${
            isDelivery
              ? `${insertToMarkUp(
                  phoneNumber,
                  setting?.phone_number
                )}\n${insertToMarkUp(addressLine, setting?.address)}`
              : ""
          } ${desc ? `\n|${insertToMarkUp(desc, setting?.desc)}` : ""} ${
            call ? `\n|${insertToMarkUp("CALL", setting?.call)}` : ""
          }`
        : ""
    }\n\n|${insertToMarkUp(`Date: ${date}`, setting?.date)} | ${insertToMarkUp(
      `Time: ${time}`,
      setting?.time
    )}|\n` +
    list +
    "\n---\n" +
    "{width:*,*}\n" +
    `|${insertToMarkUp("Subtotal", setting?.sub_total)} | ${insertToMarkUp(
      `$${subTotal}`,
      setting?.sub_total
    )}|\n` +
    `|${insertToMarkUp("Tax", setting?.sub_total)} | ${insertToMarkUp(
      `$${tax}`,
      setting?.sub_total
    )}|\n` +
    `${
      isDelivery
        ? `|${insertToMarkUp(
            "Delivery&ServiceFee",
            setting?.sub_total
          )} | ${insertToMarkUp(
            `$${(deliveryFee + serviceFee).toFixed(2)}`,
            setting?.sub_total
          )}|\n`
        : ""
    }` +
    "---\n" +
    `|${insertToMarkUp("Total", setting?.total)} | ${insertToMarkUp(
      `$${total}`,
      setting?.total
    )}|` +
    (isDelivery ? `\n\n{code:${id}; option:qrcode,8,H}\n` : "")
  );
};

function ReceiptMarkUp() {
  const receiptMarkUp = useReceiptMarkUp();
  const updateReceiptSetting = useUpdateReceiptSetting();
  const me = useGetMe();
  const [form] = Form.useForm();
  const [setting, setSetting] = useState({});
  useEffect(() => {
    receiptMarkUp.execute({ data: { data: receipt(order, setting) } });
  }, [setting]);

  useEffect(() => {
    let s =
      me.data?.restaurant?.receiptSetting ||
      // receiptSetting ||
      receiptPartList.reduce((t, v) => {
        t[v] = {
          visibility: true,
          size: "none",
          style: [],
        };
        return t;
      }, {});
    s = {
      ...s,
      [LEFT_SIDE]: "<",
      [FULL_SIDE]: "+",
      [RIGHT_SIDE]: ">",
      [NO]: "-",
    };
    form.setFieldsValue(s);
    setSetting(s);
  }, []);

  return (
    <Wrapper>
      <Card size="small">
        <Row gutter={10}>
          <Col span={6}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Card size="small" style={{ width: "fit-content" }}>
                  <ReceiptWrapper
                    dangerouslySetInnerHTML={{
                      __html: receiptMarkUp?.data || "",
                    }}
                  />
                </Card>
              </Col>
              <Col span={24}>
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    updateReceiptSetting.execute({ data: setting });
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
          <Col span={18}>
            {/* <Card size="small"> */}
            <Form
              form={form}
              marginBottom={0}
              layout="vertical"
              onValuesChange={(change, s) => {
                localStorage.setItem("receiptSetting", JSON.stringify(s));
                setSetting(s);
              }}
            >
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Row gutter={[10, 10]}>
                    {[
                      { side: LEFT_SIDE, icons: ["◖", "◀", "<"] },
                      { side: FULL_SIDE, icons: ["+", "⏺"] },
                      { side: RIGHT_SIDE, icons: [">", "▶", "◗"] },
                      { side: NO, icons: ["-", "x"] },
                    ].map(({ side, icons }) => (
                      <Col>
                        <Card size="small">
                          <Form.Item
                            name={side}
                            style={{ marginBottom: "0" }}
                            label={side}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                            >
                              {icons?.map((v) => (
                                <Radio value={v}>{v}</Radio>
                              ))}
                            </Radio.Group>
                          </Form.Item>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                </Col>
                {receiptPartList?.map((value) => (
                  <Col
                  // span={8}
                  >
                    <Card
                      // style={{ width: "247px" }}
                      size="small"
                    >
                      <Row
                        // align={"middle"}
                        justify={"space-between"}
                        gutter={[10, 10]}
                      >
                        <Col>
                          <Typography.Title level={4}>{value}</Typography.Title>
                        </Col>
                        <Col>
                          <Form.Item
                            style={{ marginBottom: 0 }}
                            valuePropName="checked"
                            name={[value, "visibility"]}
                          >
                            <Switch
                              checkedChildren="on"
                              unCheckedChildren="off"
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Form.Item
                        style={{ marginBottom: 0 }}
                        name={[value, "size"]}
                      >
                        <Radio.Group
                          optionType="button"
                          size="small"
                          buttonStyle="solid"
                        >
                          <div>
                            {enhancedSizeList.slice(0, 2).map((v) => (
                              <Radio value={v}>{charDict(v)}</Radio>
                            ))}
                          </div>
                          <div>
                            {enhancedSizeList.slice(2).map((v) => (
                              <Radio value={v}>{charDict(v)}</Radio>
                            ))}
                          </div>
                        </Radio.Group>
                      </Form.Item>
                      <Form.Item
                        style={{ marginBottom: 0 }}
                        name={[value, "style"]}
                      >
                        <Checkbox.Group style={{ width: "100%" }}>
                          <Row>
                            {textStyleList.map((v) => (
                              <Col>
                                <Checkbox value={v}>{v}</Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Form>
            {/* </Card> */}
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
}

export default ReceiptMarkUp;
